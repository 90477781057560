@import "../../../Page/mixin";
.step.lesson18 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .MainHeader {
    .header {
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #1c3e94;
      margin-bottom: 0; }
    .subheader {
      margin-top: 10px;
      font-size: 30px;
      line-height: 37px;
      color: #1c3e94; }
    &.bigHeader {
      border-bottom: none; } }
  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #039cdd;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #1c3e94; } } }
  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }
  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; }
    .PlanInFirstStep_el {
      font-weight: normal; } }
  .step2 {
    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &_el {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48%;
        margin-bottom: 20px;
        @include for-phone {
          width: 100%; }
        &_img {
          max-width: 100%; } } } }
  .step3 {
    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &_el {
        margin-bottom: 30px;
        &.left {
          width: 65%; }
        &.right {
          width: 31.5%; }
        &.bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%; }
        &_img {
          max-width: 100%; } } } }
  .step4 {
    .flex {
      display: flex;
      justify-content: space-between;
      &_el {
        &:first-child {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          .TextBlock {
            margin-bottom: 0; } } } } }
  .step7, .step8 {
    .flex {
      display: flex;
      justify-content: space-between; } }
  .step10, .step11, .step12 {
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px; } }
  .step13 {
    .flex {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &_el {
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @include for-phone {
          width: 100%; }
        &_img {
          margin-bottom: 20px;
          height: 208px;
          object-fit: contain;
          &_box {
            border: 1px solid rgba(249, 179, 19, .5);
            border-radius: 10px;
            padding: 35px 35px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            margin-bottom: 25px;
            min-height: 355px;
            @include for-phone {
              width: 239px; }
            &>.TextBlock {
              margin-bottom: 0; } } }
        &_text {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #f9b313;
          text-align: center;
          margin-bottom: 25px; } } } } }











