@import "../../Page/mixin";
.ProfileCard {
  width: 100%;
  padding: 60px 25px;
  background: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: relative;
  @media screen and (max-width: 1366px) {
    border-radius: 0; }
  .head {
    margin: 0;
    margin-bottom: 40px;
    font-family: 'Roboto', sans-serif;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    color: #005EAD; }
  //.edit
  //  position: absolute
  //  top: 10px
  //  right: 10px
  //  font-size: 12px
  //  color: #99BFDE
  //  display: flex
  //  justify-content: flex-end
  //  align-items: center
  //  cursor: pointer
  //  transition: .5s
  //  &Svg
  //    height: 35px
  //    width: 35px
  //    border-radius: 50%
  //    display: flex
  //    justify-content: center
  //    align-items: center
  //    background: #CCDFEF
  //    margin-left: 10px
  //    transition: .5s
  //  &:hover
  //    color: #005EAD
  //    .editSvg
  //      background: #005EAD
  .fileUploader {
    margin-bottom: 37px;
    .fileContainer {
      padding: 0;
      margin: 0 auto;
      box-shadow: none;
      .chooseFileButton {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 224px;
        height: 30px;
        margin: 0;
        padding: 0;
        font-family: inherit;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #039cdd;
        text-transform: uppercase;
        background: #ffffff;
        border: 1px solid #039cdd;
        transition: 0.5s;
        &:hover {
          background: #039cdd;
          color: #ffffff; } } } }
  .btn {
    width: 224px;
    height: 30px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    margin-bottom: 37px;
 }    //display: none
  .img {
    height: 95px;
    width: 95px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-bottom: 20px; }
  .wrp {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    .form {
      padding: 0 100px 0;
      border: 1px solid #fff;
      position: relative;
      width: 100%;
      max-width: 700px;
      @include for-tablet {
        padding: 0 50px 0;
        max-width: 100%;
        border: none; }
      @include for-phone {
        padding: 0 20px 0;
        margin-top: 20px; }
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include for-phone {
          display: block; }
        .inputBlock {
          width: 100%;
          margin-right: 33px;
          margin-bottom: 30px !important;
          &:last-child {
            margin-right: 0; } } }
      .checkboxs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 30px;
        @include for-tablet {
          justify-content: center; }
        @include for-phone {
          justify-content: space-between; }
        &Label {
          width: 60%;
          font-size: 16px;
          color: #005EAD;
          margin-bottom: 10px;
          @include for-tablet {
            font-size: 20px;
            width: 30%; }
          @include for-phone {
            font-size: 16px;
            width: 150px; } }
        .switch {
          display: flex;
          @include for-tablet {
            width: 120px;
            height: 48px;
            position: relative;
            border: 2px solid #005EAD;
            border-radius: 48px;
            transition: 0.5s; }
          @include for-phone {
            width: 90px;
            height: 38px; }
          &:before {
            @include for-tablet {
              content: "";
              position: absolute;
              top: 50%;
              width: 36px;
              height: 36px;
              background-color: #005EAD;
              border-radius: 50%;
              transform: translate(5px, -50%);
              transition: 0.5s; }
            @include for-phone {
              width: 28px;
              height: 28px; } }
          &.switch_right {
            @include for-tablet {
              background-color: #005EAD;
              transition: 0.5s; }
            &:before {
              @include for-tablet {
                transform: translate(75px, -50%);
                background-color: #ffffff;
                transition: 0.5s; }
              @include for-phone {
                transform: translate(55px, -50%); } } }
          &_yes {
            display: none;
            @include for-tablet {
              position: absolute;
              display: block;
              top: 50%;
              left: 15px;
              transform: translateY(-50%);
              font-size: 20px;
              line-height: 24px;
              color: #ffffff; } }
          &_no {
            display: none;
            @include for-tablet {
              position: absolute;
              display: block;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
              font-size: 20px;
              line-height: 0;
              color: #005EAD; } }
          .checkbox {
            margin-right: 20px;
            color: #005EAD;
            @include for-tablet {
              opacity: 0;
              margin-right: 10px; }
            &:before {
              border: 2px solid #005EAD; }
            &:after {
              border-color: #005EAD; }
            &:active {
              background-color: #ffffff; }
            &:last-child {
              margin-right: 0; } } } }
      .inputBlock {
        margin-bottom: 30px;
        &.required .star {
          color: #005EAD; }
        .input {
          color: #005EAD;
          border-bottom: 1px solid #005EAD;
          &::placeholder {
            color: #005EAD; }
          &.error {
            color: #BD4E3B;
            border-color: #BD4E3B;
            &::placeholder {
              color: #BD4E3B; } } }
        input[disabled] {
          opacity: 0.5; }
        .arrowSelect {
          border-top: 5px solid #005EAD;
          border-bottom: 5px solid transparent; }
        &.show {
          .options {
            margin-top: 5px;
            border: 2px solid #005EAD; }
          .input {
            border-radius: 0;
            padding-left: 0; }
          .arrowSelect {
            right: 0;
            transform: rotate(180deg);
            border-bottom: 5px solid transparent; } }
        &:last-child {
          margin-bottom: 0; } }
      .buttons {
        margin-top: 52px;
        display: flex;
        justify-content: center;
        .btn_blue {
          display: block;
          width: 280px;
          height: 50px;
          @include for-tablet {
            width: 327px;
            max-width: 323px;
            height: 72px;
            font-size: 22px;
            line-height: 26px; }
          @include for-phone {
            width: 280px;
            max-width: 280px;
            height: 62px;
            font-size: 18px;
            line-height: 21px; } }
        button:disabled {
          color: #99BFDE;
          background-color: transparent;
          border: 2px solid #99BFDE; } }
      .inputsPlus {
        display: flex;
        align-items: flex-start;
        &:last-child .inputBlock {
          margin-right: 12px; }
        &:not(:last-child) .btnPlus {
          display: none; }
        .btnPlus {
          display: flex;
          max-width: 88px;
          height: 30px;
          font-size: 12px;
          line-height: 14px; } }
      .flex_wrp {
        @include for-tablet {
          display: flex;
          align-items: center; }
        @include for-phone {
          display: block; } } } } }
