.coociesForm {
  background: #fff;
  border-radius: 20px;
  padding: 70px 20px; }
.coociesForm__container {
  max-width: 768px;
  width: 100%;
  margin: auto; }
.coociesForm__title {
  font-weight: normal;
  font-size: 40px;
  line-height: 38px;
  text-align: center;
  color: #00B1D8;
  margin-bottom: 48px; }
.coociesForm__subtitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  text-transform: uppercase;
  color: #2C3032;
  margin-bottom: 24px; }
.coociesForm__desc {
  margin-bottom: 20px;
  & p {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #2C3032;
      margin-bottom: 20px;
      & a {
        color: #00B1D8; } } }
.coociesForm__form {
  max-width: 498px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column; }

.coociesForm__form__input {
  border: none;
  border-bottom: 1px solid  #1581B8;
  padding: 10px 0;
  color: #1581B8;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 40px;
  &::-webkit-input-placeholder {
    color: #1581B8;
    font-size: 16px;
    line-height: 22px; } }
.coociesForm__form__textarea {
  padding: 10px;
  color: #1581B8;
  font-size: 16px;
  line-height: 22px;
  min-height: 100px;
  border: 1px solid  #1581B8;
  max-width: 100%;
  font-family: 'GothamPro', sans-serif;
  &::-webkit-input-placeholder {
    color: #1581B8;
    font-size: 16px;
    line-height: 22px;
    font-family: 'GothamPro', sans-serif; } }
.coociesForm__form__check {
  margin-top: 20px; }
.coociesForm__form__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #039CDD;
  border-radius: 50px;
  width: 250px;
  height: 50px;
  margin: auto;
  margin-top: 20px;
  border: 1px solid #039CDD;
  cursor: pointer;
  transition: 0.5s;
  &:disabled {
    opacity: 0.5;
    cursor: auto;
    background: #039CDD !important;
    color: #fff !important; }
  &:hover {
    background: transparent;
    color: #039CDD; } }

.coociesForm__form__check {
  display: flex;
  & > span {
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #2C3032; } }
.coociesForm__form__check__item {
  width: 30px;
  min-width: 30px;
  margin-right: 15px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #178FC7;
  box-sizing: border-box;
  position: relative;
  & input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 10;
    &:checked + span {
      display: block; } }
  & span {
    display: none;
    width: 17px;
    height: 8px;
    border-bottom: 2px solid #039CDD;
    border-left: 2px solid #039CDD;
    top: -4px;
    position: relative;
    transform: rotateZ(- 45deg); } }
@media screen and (max-width: 1367px) {
  .coociesForm {
    border-radius: 0; } }
.completed {
  text-align: center;
  opacity: 0;
  margin-top: 20px;
  transition: 1s; }
