.List {
  padding-left: 0;
  li {
    list-style: none;
    position: relative;
    padding-left: 25px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #3E373C;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: #049bdc; } } }
