@import "../../../Page/mixin";
.OnClickBlock {
  --quantity: 25%;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 35px -12px 0;
    @include for-phone {
      flex-direction: column;
      margin: 0; }
    &_el {
      width: var(--quantity);
      padding: 0 12px;
      @include for-phone {
        width: 100%;
        padding: 0;
        margin-bottom: 20px; }
      &_wrp {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85px;
        padding: 10px;
        font-size: 20px;
        color: #acdff5;
        text-transform: uppercase;
        font-weight: bold;
        background: #fff;
        border: 1px solid #c9dbf0;
        box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        text-align: center;
        transition: .5s;
        @include for-phone {
          font-size: 20px;
          line-height: 24px;
          color: #039cdd; }
        &.active,
        &:hover {
          box-shadow: none;
          color: #039cdd; } } }
    &.hidden {
      @include for-tablet {
        display: none; } } }

  .imgDiv {
    margin-top: 24px;
    border: 1px solid #acdff5;
    box-shadow: 0 2px 3px 0 rgba(74, 74, 74, 0.2);
    border-radius: 10px;
    margin-bottom: 40px;
    padding: 40px 47px;
    position: relative;
    @include for-tablet {
      padding: 0;
      border: none;
      box-shadow: none; }
    //&:before
    //  content: ''
    //  position: absolute
    //  left: 36%
    //  top: -1px
    //  transform: translate(-50%, -50%) rotate(-45deg)
    //  height: 12px
    //  width: 12px
    //  background: #fff
    //  border-top: 1px solid #acdff5
    //  border-right: 1px solid #acdff5
    //  transition: .5s
    //  @include for-tablet
    //    display: none
    //&.center
    //  &:before
    //    left: 64%
    //&.right
    //  &:before
    //    left: 91%
    //&_wrap
    //  & img
    //    display: block
 } }    //    margin: 0 auto
