.InfoBlock {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f0f5fb;
  margin-bottom: 25px;
  padding: 25px 30px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #657993;
  white-space: pre-line;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    padding-left: 14px;
    padding-right: 14px; }
  &_danger {
    background: #F9EEEC;
    color: #BD4E3B;
    font-weight: bold;
    align-items: flex-start; }
  svg {
    margin-right: 30px;
    flex-shrink: 0;
    @media screen and (max-width: 767px) {
      margin-right: 14px; } } }
