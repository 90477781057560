.LinkPreview {
  position: relative;
  display: block;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  border: 2px solid #99BFDE;
  border-radius: 30px;
  &_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }
