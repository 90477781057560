@import "../mixin";
.PPCVideo {
  padding: 0 20px;
  &_logo {
    margin-bottom: 65px; }
  &_text-block {
    max-width: 85%;
    margin-bottom: 80px; }
  &_text {
    font-size: 20px;
    line-height: 20px;
    color: #001299;
    @include for-phone {
      font-size: 16px; }
    &:not(:last-child) {
      margin-bottom: 24px; } }
  &_text-uppercase {
    max-width: 83%;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 0.015em;
    color: #001299;
    @include for-phone {
      font-size: 16px; } }
  &_title {
    margin-bottom: 48px;
    font-weight: 500;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    color: #001299;
    @include for-phone {
      font-size: 16px; } }
  .flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;
    &_el {
      width: 30%;
      margin-bottom: 20px;
      padding: 0 30px;
      @include for-tablet {
        width: 100%; }
      &_wrp {
        .VideoBlock {
          margin-top: 0;
          margin-bottom: 15px; } }
      &_head {
        min-height: 63px;
        margin-bottom: 10px;
        color: #333;
        font-size: 15px;
        font-weight: 700; }
      &_text {
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #001299;
        &_small {
          font-size: 13px;
          line-height: 13px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: #0a0a0a; } } } } }

