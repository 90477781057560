@import "../../../Page/mixin";
.step.lesson12 {
  padding-bottom: 48px;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  color: #3E373C;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .textBlue {
    color: #005EAD; }
  .textRed {
    color: #BD4E3B; }
  .infoPopap {
    position: fixed;
    z-index: 222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#345178, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrp {
      background: #fff;
      border-radius: 25px;
      padding: 70px;
      position: relative;
      width: 100%;
      max-width: 640px; }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba(#005EAD, .3);
        transition: .5s; }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: rgba(#005EAD, 1); }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }

    &_scroll {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
      overflow-y: auto; }
    &_iconDiv {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      overflow: hidden;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      &.red {
        color: #BD4E3B; } }
    &_text {
      font-size: 16px;
      color: #3E373C;
      margin-bottom: 30px;
      &_head {
        font-size: 16px;
        color: #005EAD;
        font-weight: bold;
        display: block; } }
    &_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      &ImgDiv {
        height: 96px;
        width: 96px;
        flex-shrink: 0;
        border: 2px solid #005EAD;
        border-radius: 50%;
        background: #fff;
        margin-right: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
        &.chain {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 100%;
            width: 3px;
            background: #005EAD; } } }
      &:last-child {
        .chain {
          &:before {
            display: none; } } }
      .infoPopap_text {
        margin-bottom: 0; } }


    &_wrp.danger {
      background: #F9EEEC;
      .infoPopap_iconDiv {
        background: #F9EEEC; }
      .infoPopap_close {
        &:before,
        &:after {
          background: rgba(#BD4E3B, .3); }
        &:hover {
          &:before,
          &:after {
            background: rgba(#BD4E3B, 1); } } }
      .infoPopap_text {
        li:before {
          background: #BD4E3B; } }
      .infoPopap_head {
        color: #BD4E3B; } }





    &_imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      max-width: 100%;
      img {
        margin-top: 45px;
        max-width: 100%; } }
    .btn {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      font-size: 14px;
      width: 160px; } }

  ul {
    padding-left: 0;
    li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      color: #3E373C;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; } } }
  .ul {
    .liHead {
      font-size: 18px;
      color: #005EAD;
      font-weight: bold;
      text-transform: uppercase;
      margin: 40px 0 35px; }
    .li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 35px;
      @media screen and (max-width: 499px) {
        flex-direction: column; }
      .number {
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        border: 1px solid #005EAD;
        border-radius: 50%;
        margin-right: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #005EAD;
        @media screen and (max-width: 499px) {
          margin-bottom: 10px; } }
      .text {
        font-size: 18px;
        color: #3E373C; } } }

  .infoPopap {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    background: rgba(#345178, .8);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 112px;
    &_wrp {
      width: 100%;
      max-width: 640px;
      padding: 70px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      .popap_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99BFDE;
        cursor: pointer;
        transition: .5s;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s; }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover {
          &:before,
          &:after {
            background: #005EAD; }
          &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(45deg); } } }
      .image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        &Bg {
          width: 100%;
          height: 100%;
          background: #E6EFF7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            border-radius: 50%; } } }
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line; }
      .subHead {
        color: #1F72B7;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        white-space: pre-line;
        margin-bottom: 30px; }
      .slider {
        border: 2px solid #CCDFEF;
        border-radius: 10px;
        position: relative;
        padding: 25px;
        margin-bottom: 50px;
        .slide {
          color: #4C8EC5;
          font-size: 16px;
          line-height: 24px; }
        .slick-arrow {
          bottom: 0; } }
      .btn {
        position: relative;
        left: 50%;
        transform: translate(-50%); } } }

  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    .flex {
      display: flex;
      margin: 0 -19px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 50%;
        padding: 0 19px;
        @include for-phone {
          width: 100%; }
        &_item {
          display: flex;
          align-items: center;
          height: 72px;
          margin-bottom: 15px;
          padding-left: 38px;
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          color: #2c3032;
          text-transform: uppercase;
          border: 1px solid #acdff5;
          box-sizing: border-box;
          border-radius: 10px; }
        &_icon {
          margin-right: 24px; } }
      &.flex--bottom {
        justify-content: center; } } }
  .step3 {
    position: relative;
    padding-bottom: 15px;
    .answers {
      margin-top: 25px;
      margin-bottom: 0;
      @include for-phone {
        margin-top: 20px; }
      .answer {
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 2px solid #CCDFEF;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 26px;
        color: #3E373C;
        cursor: pointer;
        transition: .5s;
        @include for-phone {
          font-size: 16px;
          line-height: 18px; }
        &:last-child {
          margin-bottom: 0; }
        .circle {
          height: 30px;
          width: 30px;
          border: 2px solid #CCDFEF;
          border-radius: 50%;
          flex-shrink: 0;
          margin-right: 15px;
          transition: .5s;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            transition: .5s;
            opacity: 0;
            display: none; } }
        &:hover,
        &.active {
          border: 2px solid #4085D8;
          box-sizing: border-box;
          box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
          color: #4085D8;
          .circle {
            border-color: #4085D8; } }
        &.active {
          .circle {
            background: #4085D8; }
          //svg
          //  opacity: 1

          &.correct {
            border-color: #4085D8;
            .circle {
              background: #4085D8;
              border-color: #4085D8;
              svg.correct {
                opacity: 1;
                display: block; } } }
          &.inCorrect {
            border-color: #BD4E3B;
            .circle {
              background: #BD4E3B;
              border-color: #BD4E3B;
              svg.inCorrect {
                opacity: 1;
                display: block; } } } }

        &.correct {
          border-color: #BD4E3B;
          .circle {
            background: #4085D8;
            border-color: #4085D8;
            svg.correct {
              opacity: 1;
              display: block; } } } } }
    .info {
      padding: 44px 48px;
      margin-top: 20px;
      border-radius: 10px;
      background: #005EAD;
      color: #fff;
      &_head {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px; }
      &Text {
        font-size: 18px; }
      &.error {
        background: #F9EEEC;
        .info_head {
          color: #BD4E3B; }
        .infoText {
          color: #3E373C; } } }
    .btn {
      position: absolute;
      z-index: 2;
      bottom: -48px;
      right: 0;
      transform: translate(0, 100%); } }
  .step4 {
    position: relative;
    padding-bottom: 100px;
    @include for-phone {
      padding-bottom: 0; }
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        position: relative;
        width: 31%;
        @include for-phone {
          width: 100%;
          margin-bottom: 100px; }
        &_head {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 18px;
          line-height: 30px;
          color: #039cdd;
          text-align: center;
          text-transform: uppercase; }
        &_wrp {
          height: 100%;
          padding: 36px 20px 50px;
          border: 1px solid #0db7ff;
          border-radius: 10px;
          @include for-phone {
            padding: 36px 36px 50px; }
          .header {
            font-size: 16px;
            line-height: 18px;
            font-weight: bold;
            margin: 0 0 15px; }
          li {
            font-size: 14px;
            line-height: 18px; } }
        &_img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 70%); }
        &:nth-child(1) {
          .flex_el_img {
            transform: translate(-50%, 85%);
            @include for-phone {
              transform: translate(-50%, 50%); } } }
        &:nth-child(2) {
          .flex_el_img {
            transform: translate(-50%, 85%);
            @include for-phone {
              transform: translate(-50%, 60%); } } } } } }
  .step5, .step7 {
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:first-child {
          width: 33%;
          @include for-phone {
            width: 100%; } }
        &:last-child {
          width: 64%;
          @include for-phone {
            width: 100%; } }
        &_item {
          display: flex;
          align-items: center;
          height: 48px;
          margin-bottom: 15px;
          padding-left: 38px;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          color: #039cdd;
          text-transform: uppercase;
          border: 1px solid #0db7ff;
          box-sizing: border-box;
          border-radius: 10px;
          @include for-phone {
            padding-left: 59px; } }
        &_icon {
          margin-right: 14px; }
        &_img {
          width: 100%;
          height: auto; } } } }
  .step6, .step8 {
    .imgDiv {
      position: relative;
      padding-top: 40px;
      @include for-phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 60px; }
      .point {
        position: absolute;
        height: 72px;
        width: 72px;
        border-radius: 50%;
        background: #E6EFF7;
        border: 2px solid #CCDFEF;
        box-shadow: 0px 5px 10px rgba(0, 94, 173, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #99BFDE;
        font-weight: bold;
        transition: .5s;
        cursor: pointer;
        z-index: 10;
        outline: none;
        @include for-phone {
          position: relative;
          margin-bottom: 66px; }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 68px;
          height: 68px;
          background-color: transparent;
          border: 9px solid #ffffff;
          border-radius: 50%;
          box-sizing: border-box; }
        &.active {
          color: #ffffff;
          background: #005EAD;
          border: 2px solid #005EAD; }
        &:hover {
          color: #ffffff;
          background: #99BFDE;
          border: 2px solid #99BFDE; }
        &:nth-child(1) {
          left: 100px;
          top: 60px;
          @media screen and (max-width: 850px) {
            left: 85px; }
          @include for-phone {
            left: auto;
            top: auto; } }
        &:nth-child(2) {
          right: 100px;
          top: 60px;
          @media screen and (max-width: 850px) {
            right: 85px; }
          @include for-phone {
            right: auto;
            top: auto; } }
        &:nth-child(3) {
          left: 100px;
          bottom: 20px;
          @media screen and (max-width: 850px) {
            left: 85px; }
          @include for-phone {
            left: auto;
            bottom: auto; } }
        &:nth-child(4) {
          right: 100px;
          bottom: 20px;
          @media screen and (max-width: 850px) {
            right: 85px; }
          @include for-phone {
            right: auto;
            bottom: auto; } }
        &.point_out {
          animation: scale-down-center 0.4s ease both; } }
      &_answers {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        @include for-phone {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 35px; }
        .answer {
          position: absolute;
          display: none;
          justify-content: center;
          align-items: center;
          width: 286px;
          height: 120px;
          padding: 0 24px;
          border: 1px solid #005EAD;
          box-sizing: border-box;
          border-radius: 10px;
          @media screen and (max-width: 850px) {
            width: 250px; }
          @include for-phone {
            position: relative;
            margin-bottom: 18px; }
          &:nth-child(1) {
            top: 10%;
            left: 0;
            @include for-phone {
              top: auto;
              left: auto; } }
          &:nth-child(2) {
            top: 10%;
            right: 0;
            @include for-phone {
              top: auto;
              right: auto; } }
          &:nth-child(3) {
            bottom: 0;
            left: 0;
            @include for-phone {
              bottom: auto;
              left: auto; } }
          &:nth-child(4) {
            bottom: 0;
            right: 0;
            @include for-phone {
              bottom: auto;
              right: auto; } }
          &_text {
            margin: 0;
            font-size: 16px;
            line-height: 22px;
            color: #3E373C; }
          &.answer_in {
            display: flex;
            transform: scale(0);
            animation: scale-up-center 0.4s ease 0.3s both; } } }
      .imgCenter {
        @include for-phone {
          left: auto;
          transform: translate(0, 0); } } }
    @keyframes scale-down-center {
      0% {
        transform: scale(1); }

      100% {
        transform: scale(0); } }
    @keyframes scale-up-center {
      0% {
        transform: scale(0); }

      100% {
        transform: scale(1); } } }
  .step7 {
    .flex {
      &_el {
        &:first-child {
          width: 45%;
          @include for-phone {
            width: 100%; } }
        &:last-child {
          width: 50%;
          @include for-phone {
            width: 100%; } } } } }
  .step8 {
    .imgDiv {
      @include for-phone {
        padding-top: 118px; }
      .point {
        @include for-phone {
          margin-bottom: 162px; }
        &:nth-child(1) {
          left: 100px;
          top: 44%;
          @media screen and (max-width: 850px) {
            left: 85px; }
          @include for-phone {
            left: auto;
            top: auto; } }
        &:nth-child(2) {
          right: 100px;
          top: 44%;
          @media screen and (max-width: 850px) {
            right: 85px; }
          @include for-phone {
            right: auto;
            top: auto;
            margin-bottom: 80px; } } } }
    .imgDiv_answers {
      .answer {
        height: 227px;
        &:nth-child(1) {
          top: 25%;
          left: 0;
          @include for-phone {
            top: auto;
            left: auto; } }
        &:nth-child(2) {
          top: 25%;
          right: 0;
          @include for-phone {
            top: auto;
            right: auto; } } } } }
  .step9 {
    position: relative;
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 27px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 31%;
        @include for-phone {
          width: 100%;
          margin-bottom: 37px; }
        &_head {
          min-height: 36px;
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          color: #039cdd;
          text-align: center;
          text-transform: uppercase;
          @include for-phone {
            min-height: auto; } }
        &_wrp {
          padding: 36px 20px 50px;
          border: 1px solid #0db7ff;
          border-radius: 10px;
          flex-grow: 1;
          @include for-phone {
            padding: 36px 36px 50px; }
          .header {
            font-size: 16px;
            line-height: 18px;
            font-weight: bold;
            margin: 0 0 15px; }
          li {
            font-size: 14px;
            line-height: 18px; } } } } }
  .step10 {
    .table {
      position: relative;
      margin-bottom: 18px;
      padding: 30px;
      border: 1px solid #0db7ff;
      box-sizing: border-box;
      border-radius: 10px;
      @include for-phone {
        padding: 0;
        border: none; }
      &_dry, &_wet {
        position: absolute;
        top: 0;
        padding: 10px 20px;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #039cdd;
        text-transform: uppercase;
        background-color: #ffffff;
        transform: translateY(-50%);
        @include for-phone {
          display: none; } }
      &_dry {
        left: 20%; }
      &_wet {
        right: 20%; }
      &_head {
        margin: 0 0 26px;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #039cdd; }
      .flex {
        display: flex;
        justify-content: space-between;
        @include for-phone {
          flex-direction: column; }
        &_el {
          width: 49%;
          @include for-phone {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            padding: 30px 17px 26px 29px;
            border: 1px solid #005EAD;
            box-sizing: border-box;
            border-radius: 10px; }
          &:last-child {
            margin-bottom: 41px; }
          &_item {
            display: flex;
            padding: 0 10px;
            margin-bottom: 20px;
            @include for-phone {
              align-items: center;
              padding: 0; } }
          &_icon {
            margin: 0;
            margin-right: 20px; }
          &_text {
            margin: 0;
            font-size: 16px;
            line-height: 22px; }
          &_dry, &_wet {
            display: none;
            @include for-phone {
              position: absolute;
              display: block;
              top: 0;
              left: 50%;
              width: 165px;
              padding: 10px 20px;
              font-weight: bold;
              font-size: 16px;
              line-height: 18px;
              color: #005EAD;
              text-align: center;
              text-transform: uppercase;
              background-color: #ffffff;
              transform: translate(-50%, -50%); } } }
        &.flex--top {
          margin-bottom: 27px;
          border-bottom: 1px solid #039cdd; }
        &.flex--bottom {
          .flex_el {
            &_item {
              min-height: 90px;
              @media screen and (max-width: 1023px) {
                min-height: 132px; }
              @include for-phone {
                min-height: 0; }
              &:last-child {
                margin-bottom: 0;
                min-height: 0; } } } } } }
    .advice {
      display: flex;
      justify-content: space-between;
      padding: 15px 0 15px 20px;
      border: 1px solid #0db7ff;
      box-sizing: border-box;
      border-radius: 10px;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_text {
        width: 70%;
        margin: 0;
        padding-right: 23px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #039cdd;
        box-sizing: border-box;
        @include for-phone {
          width: 100%;
          margin-bottom: 70px; } }
      &_feed {
        position: relative;
        display: flex;
        align-items: center;
        width: 180px;
        margin-right: 36px;
        font-size: 48px;
        line-height: 40px;
        color: #005EAD;
        @include for-phone {
          margin-right: 0; }
        &_big, &_small {
          position: absolute;
          top: 0; }
        &_big {
          top: -50px;
          left: 0; }
        &_small {
          right: 0; } }
      &_plus {
        margin-left: 90px; } } }
  .step11 {
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-wrap: wrap; }
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 138px;
        @include for-phone {
          width: 50%;
          margin-bottom: 22px; }
        @media screen and (max-width: 450px) {
          width: 100%; }
        &_head {
          margin: 0 0 7px;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          color: #039cdd;
          text-align: center;
          text-transform: uppercase; }
        &_img {
          margin-bottom: 13px; }
        &_text {
          margin: 0;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          color: #3E373C; } } } }
  .step12 {
    .TextBlock {
      margin-bottom: 50px; }
    .flex {
      display: flex;
      justify-content: space-between;
      margin: 0 -5px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 24%;
        padding: 0 5px;
        @include for-phone {
          width: 100%;
          margin-bottom: 45px; }
        &:last-child {
          width: 28%;
          @include for-phone {
            width: 100%; } }
        &_wrp {
          position: relative;
          height: 100%;
          padding: 40px 20px 25px;
          border: 1px solid #0db7ff;
          box-sizing: border-box;
          border-radius: 10px; }
        &_head {
          margin: 0 0 15px;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          color: #039cdd;
          text-align: center;
          text-transform: uppercase;
          @media screen and (max-width: 840px) {
            min-height: 36px; } }
        &_text {
          margin: 0;
          font-size: 16px;
          line-height: 22px;
          color: #3E373C;
          text-align: center; }
        &_box {
          position: absolute;
          display: flex;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          .flex_el_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 55px;
            height: 55px;
            border: 1px solid #0db7ff;
            border-radius: 50%;
            background-color: #ffffff;
            box-sizing: border-box;
            &:nth-child(2) {
              margin-left: 10px; } } } } } }
  .step13 {
    .flex {
      display: flex;
      justify-content: space-between;
      padding-bottom: 50px;
      @include for-phone {
        flex-direction: column;
        padding-bottom: 0; }
      &_el {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 48%;
        @include for-phone {
          width: 100%;
          margin-bottom: 80px; }
        &_head {
          margin: 0 0 10px;
          font-size: 16px;
          line-height: 18px;
          color: #039cdd;
          text-align: center;
          text-transform: uppercase; }
        &_text {
          padding: 30px 30px 130px;
          font-size: 16px;
          line-height: 22px;
          color: #3E373C;
          border: 1px solid #0db7ff;
          box-sizing: border-box;
          border-radius: 10px;
          flex-grow: 1; }
        &_img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 30%); } } } }
  .step14 {
    .img {
      display: block;
      margin: 0 auto;
      @media screen and (max-width: 400px) {
        width: 100%;
        height: auto; } }
    .advice {
      padding: 30px;
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      color: #039cdd;
      border: 1px solid #0db7ff;
      box-sizing: border-box;
      border-radius: 10px;
      @include for-phone {
        padding: 20px; } } }
  .step15 {
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 19%;
        height: 69px;
        color: #ffffff;
        border-radius: 10px;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-transform: uppercase;
        @include for-phone {
          width: 100%; }
        &:not(:last-child) {
          @include for-phone {
            margin-bottom: 18px; } }
        &.flex_el--economy {
          background-color: #0A64B0; }
        &.flex_el--premium {
          background-color: #4C8EC5; }
        &.flex_el--super {
          color: #005EAD;
          background-color: #CCDFEF; } } }
    img {
      display: block;
      margin: 0 auto; } }
  .step16 {
    img {
      display: block;
      margin: 0 auto; } }
  .step17 {
    img {
      display: block;
      margin: 0 auto 40px; }
    .advice {
      padding: 30px;
      font-size: 18px;
      line-height: 26px;
      color: #039cdd;
      border: 1px solid #0db7ff;
      box-sizing: border-box;
      border-radius: 10px;
      @include for-phone {
        padding: 20px; } } }
  .step18 {
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:first-child {
          margin-right: 30px;
          @include for-phone {
            margin-right: 0; } } }
      ul {
        li {
          font-size: 18px;
          line-height: 26px;
          &:before {
            top: 10px; } } }
      img {
        display: block;
        margin: 0 auto; } } }
  .step19 {
    position: relative;
    padding-bottom: 15px;
    .PopupInStep {
      .popup_content {
        .btn.btn_blue {
          position: relative;
          bottom: auto;
          right: 50%;
          transform: translate(-50%, 0);
          width: 160px; } } }
    .answers {
      margin-top: 25px;
      margin-bottom: 0;
      @include for-phone {
        margin-top: 20px; }
      .answer {
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 2px solid #CCDFEF;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 26px;
        color: #3E373C;
        cursor: pointer;
        transition: .5s;
        @include for-phone {
          font-size: 16px;
          line-height: 18px; }
        &:last-child {
          margin-bottom: 0; }
        .circle {
          height: 30px;
          width: 30px;
          border: 2px solid #CCDFEF;
          border-radius: 50%;
          flex-shrink: 0;
          margin-right: 15px;
          transition: .5s;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            transition: .5s;
            opacity: 0;
            display: none; } }
        &:hover,
        &.active {
          border: 2px solid #4085D8;
          box-sizing: border-box;
          box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
          color: #4085D8;
          .circle {
            border-color: #4085D8; } }
        &.active {
          .circle {
            background: #4085D8; }
          //svg
          //  opacity: 1

          &.correct {
            border-color: #4085D8;
            .circle {
              background: #4085D8;
              border-color: #4085D8;
              svg.correct {
                opacity: 1;
                display: block; } } }
          &.inCorrect {
            border-color: #BD4E3B;
            .circle {
              background: #BD4E3B;
              border-color: #BD4E3B;
              svg.inCorrect {
                opacity: 1;
                display: block; } } } }

        &.correct {
          border-color: #BD4E3B;
          .circle {
            background: #4085D8;
            border-color: #4085D8;
            svg.correct {
              opacity: 1;
              display: block; } } } } }
    .info {
      padding: 44px 48px;
      margin-top: 20px;
      border-radius: 10px;
      background: #005EAD;
      color: #fff;
      @include for-phone {
        padding: 25px 5px; }
      &_head {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px;
        @include for-phone {
          padding: 0 27px; } }
      .flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        &_head {
          margin: 20px 0 20px;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase; }
        &_el {
          position: relative;
          display: flex;
          align-items: center;
          padding: 0 115px 0 15px;
          width: 360px;
          height: 64px;
          background-color: #ffffff;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: #1F72B7;
          text-transform: uppercase;
          border: 1.5px solid #005EAD;
          box-sizing: border-box;
          border-radius: 10px;
          @include for-phone {
            width: 100%;
            max-width: 360px;
            padding: 0 55px 0 15px; }
          &:not(:last-child) {
            margin-bottom: 26px; }
          .point {
            position: absolute;
            top: -5px;
            right: 20px;
            height: 72px;
            width: 72px;
            border-radius: 50%;
            background: #E6EFF7;
            border: 2px solid #CCDFEF;
            box-shadow: 0px 5px 10px rgba(0, 94, 173, 0.15);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: #99BFDE;
            font-weight: bold;
            transition: .5s;
            cursor: pointer;
            z-index: 10;
            outline: none;
            @include for-phone {
              right: -15px; }
            &:after {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 68px;
              height: 68px;
              background-color: transparent;
              border: 9px solid #ffffff;
              border-radius: 50%;
              box-sizing: border-box; }
            &.active {
              color: #ffffff;
              background: #005EAD;
              border: 2px solid #005EAD; }
            &:hover {
              color: #ffffff;
              background: #99BFDE;
              border: 2px solid #99BFDE; } } } }
      &Text {
        font-size: 18px;
        @include for-phone {
          padding: 0 23px; }
        &Bold {
          font-weight: bold;
          margin: 0 0 20px; } }
      &.error {
        background: #F9EEEC;
        .info_head {
          color: #BD4E3B; }
        .flex_head {
          color: #005EAD; }
        .infoText {
          color: #3E373C; } } }
    .btn.btn_answer {
      position: absolute;
      z-index: 2;
      bottom: -48px;
      right: 50%;
      transform: translate(50%, 100%);
      font-size: 14px;
      @include for-tablet {
        bottom: -10px; }
      @include for-phone {
        width: 225px;
        bottom: -5px; } } } }











