.ModalAfterStep {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#345178, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    top: 92px;
    align-items: flex-start; }
  .modal {
    background: #fff;
    padding: 70px;
    border-radius: 25px;
    position: relative;
    width: 100%;
    //height: 80%
    max-width: 640px;
    @media screen and (max-width: 767px) {
      padding: 40px 20px;
      max-width: initial;
      border-radius: 0;
      height: calc(100vh - 92px);
      overflow-y: auto; }
    &_content {
      height: 75%;
      overflow-y: auto; }
    .modal_closed {
      position: absolute;
      top: 20px;
      right: 20px;
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #99BFDE;
      cursor: pointer;
      transition: .5s;
      @media screen and (max-width: 767px) {
        position: fixed;
        top: 0;
        right: 0;
        height: 92px;
        width: 92px;
        background-color: #00559C; }
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #99BFDE;
        transform-origin: center;
        top: 50%;
        left: 50%;
        transition: .5s;
        @media screen and (max-width: 767px) {
          background: #ffffff;
          width: 28px; } }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: #005EAD; }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }
    &_img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: 50%;
      padding: 12px;
      @media screen and (max-width: 767px) {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(0, 0);
        left: 0;
        padding: 0;
        margin-bottom: 20px; }
      img {
        width: 96px;
        height: 96px;
        border-radius: 50%;
        @media screen and (max-width: 767px) {
          width: 155px;
          height: 155px; } } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      white-space: pre-line;
      margin-bottom: 25px; }
    &_text {
      font-size: 16px;
      line-height: 24px;
      color: #3E373C;
      white-space: pre-line;
      margin-bottom: 20px;
      .text_dark-blue {
        color: #005EAD; }
      .text_blue {
        font-weight: bold;
        color: #039cdd; } }
    .img2 {
      display: block;
      margin: 0 auto 20px;
      width: 100%; }
    &_btn {
      display: flex;
      justify-content: center;
      margin-top: 50px; }
    &.whiskas {
      height: 60%;
      background: #a7268e;
      .modal_img {
        display: none; }
      .modal_content {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        overflow-y: visible; }
      .modal_text {
        font-size: 25px;
        line-height: 24px;
        color: #fff; }
      .btn_blue {
        color: #a7268e;
        background: #fff;
        border: none;
        &:hover {
          border: none;
          opacity: 0.9; } } }
    &.cf {
      background-color: #FFF;
      .modal_head {
        font-size: 28px;
        color: #a7268e; }
      .modal_text {
        font-size: 16px;
        color: #000;
        text-align: center;
        span {
          color: #a7268e; } }
      .btn_violet {
        color: #fff;
        background: #a7268e;
        border-radius: 50px;
        height: 50px;
        max-width: 250px;
        text-align: center;
        margin-right: 30px; } } } }
