.DefinitionBlock {
  margin-bottom: 20px;
  padding: 25px;
  border-radius: 10px;
  border: 1px solid #acdff5;
  &_head {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-weight: bold; }
  &_item {
    font-style: italic;
    &:not(:last-child) {
      border-bottom: 1px solid #acdff5;
      margin-bottom: 20px;
      padding-bottom: 20px; }
    .text_blue {
      color: #039cdd;
      font-weight: bold; } }
  &_text {
    margin: 0;
    font-size: 15px;
    line-height: 17px; } }
