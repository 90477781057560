.Resume {
  position: relative;
  padding: 24px 24px 5px 24px; //335px
  background: #f0f5fb;
  border-radius: 10px;
  margin-bottom: 20px;
  @media screen and (max-width: 1023px) {
    margin-bottom: 40px;
    padding: 20px 20px 20px; } //250px
  &_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1023px) {
      flex-direction: column; } }
  &_list {
    flex-grow: 1;
    padding: 0;
    margin-right: 20px;
    @media screen and (max-width: 1023px) {
      margin-right: 0; } }
  &_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 35px;
    @media screen and (max-width: 499px) {
      flex-direction: column; } }
  &_number {
    flex-shrink: 0;
    height: 48px;
    width: 48px;
    border: 1px solid #039cdd;
    border-radius: 50%;
    margin-right: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #039cdd;
    @media screen and (max-width: 499px) {
      margin-bottom: 10px; } }
  &_text {
    font-size: 18px;
    white-space: pre-line;
    @media screen and (max-width: 767px) {
      font-size: 16px; } }
  &_img {
    @media screen and (max-width: 767px) {
      width: auto;
      height: 300px; } }
  //  position: absolute
  //  right: 25px
  //  bottom: -30px
  //  width: 300px
  //  height: auto
  //  @media screen and (max-width: 1023px)
  //    position: absolute
  //    right: 50%
  //    bottom: -30px
  //    width: auto
  //    height: 300px
 }  //    transform: translate(50%, 0)

