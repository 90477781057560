.select {
    margin-bottom: 30px;

    &_hidden {
        display: none;
    }

    &__header, &__item {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        color: #FEFAEF;
        padding: 15px 44px;
    }
    &__header {
        border: 2px solid #FFF;
        border-radius: 20px 20px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.error {
            border: 2px solid red;
        }
    }

    &__body {
        display: none;
        flex-direction: column;
        margin-bottom: 10px;
        border: 2px solid #FFF;
        border-radius: 0 0 20px 20px;
        padding: 0 0 10px;
    }

    &__item {
        display: flex;
        justify-content: flex-start;

        &:hover {
            background-color: #FFF;
            color: #001299;
        }
    }

}
@media screen and (max-width:768px) {
    .select {

        &__header {
            padding: 10px 10px;
            font-size: 14px;
            line-height: 16px;
        }

        &__title {
            margin:  10px 0;
            width: calc(100% - 16px);
        }
    }
}