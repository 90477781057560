.radio-main {

    &__input {
        position: absolute;
        opacity: 0;
        width: 95%;
        height: 57px;

        &:checked+label::before {
            border: 2px solid #4DD06A;
            background: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='14' cy='14' r='14' fill='%234DD06A'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.9479 9.01553C20.3812 9.40097 20.4199 10.0647 20.0345 10.4979L12.5615 18.8979C12.3619 19.1222 12.0759 19.2504 11.7756 19.25C11.4754 19.2496 11.1897 19.1207 10.9907 18.8959L7.26376 14.6852C6.87941 14.2509 6.91986 13.5874 7.35409 13.203C7.78833 12.8187 8.45192 12.8591 8.83626 13.2933L11.779 16.618L18.4655 9.1021C18.851 8.66884 19.5147 8.63008 19.9479 9.01553Z' fill='white'/%3E%3C/svg%3E") center center no-repeat;
        }
    }

    &__label {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        text-transform: uppercase;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            width: 27px;
            height: 27px;
            margin-right: 15px;
            background-color: #FFF;
            border-radius: 50%;
            border: 2px solid #FFF;
        }
    }
}

.login {

    &__question {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin: 60px 0 20px;
    }

    &__answer {
        width: 80%;
        border: 1px solid #FFFFFF;
        border-radius: 34px;
        padding: 13px;
        position: relative;
        margin: 7px auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

    }
}

@media screen and (max-width:1280px) {
    .login {
    
        &__answer {
            width: 100%;
        }
    }
    .radio-main {
    
        &__label {
            font-size: 14px;
            line-height: 14px;
        }
    }
  }