.login {
  background-color: #001299;
  width: 100%;
  min-height: 100vh;

  &__container {
    background: url('../../Images/login_bg.png') top center no-repeat;
    background-size: cover;
    padding: 55px 170px 275px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &__logo {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__image {
    max-width: 245px;
  }

  &__title {
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 70px;
    text-transform: uppercase;
    color: #FEFAEF;
    margin: 55px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__form {
    width: 50%;
    margin: 0 auto;
    border: 2px solid #FEFAEF;
    border-radius: 26px;
    padding: 36px 60px 50px;
  }

  &__tabs {
    display: flex;
    flex-direction: column;
  }

  &__tabs-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__tabs-item {
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 41px;
    text-transform: uppercase;
    color: #98A3F3;

    &.active {
      color: #FFFFFF;
    }

    &:nth-of-type(2){
      margin: 0 18px;
    }
  }

  &__tabs-body {
    width: 100%;
    display: none;
    flex-direction: column;

    &.active {
      display: flex;
    }
  }

  &__info-block {
    background: #EFEFEF;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px;
    margin: 36px 0;

    svg {
      margin-right: 12px;
    }

    &_text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #77777B;
      opacity: 0.75;
    }
  }

  &__error-block {
    margin-top: 24px;
    color: red;
    text-align: center;
  }
}

.petSection {
  display: none;

  &.active {
    display: flex;
    flex-direction: column;
  }
}

.pet__information {
  flex-direction: column;
  display: none;

  &.show {
    display: flex;
  }
}

.registration__text {
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
}

.rdp {
  color: #fff;
  padding: 20px;
  border: 2px  solid #FFF;
  border-radius: 20px;
  display: flex;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0!important;
  max-width: 340px;
  background-color: #001299;
}
.rdp-day_selected {
  background-color: #FFF !important;
  color: #001299 !important;
}

.rdp-button:hover {
  color: #001299;
}
.rdp-dropdown_month option {
  background-color: #001299;
  color: #FFF;
}
.rdp-dropdown_year option {
  background-color: #001299;
  color: #FFF;
}
.rdp-dropdown_year select::selection {
  background: #001299;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  background-color: #001299 !important;
  border: 2px solid #001299 !important;
  border-radius: 6px;
}

.forget__password {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
}

@media screen and (max-width:1280px) {
  .login {
  
    &__container {
      padding: 20px 20px;
    }

    &__form {
      width: 460px;
      padding: 20px 20px;
    }
  }
}


@media screen and (max-width:768px) {
  .login {
  
    &__container {
      background: #001299 top center no-repeat;
      background-size: cover;
      padding: 20px;
    }

    &__form {
      width: 420px;
      padding: 10px;
    }

    &__tabs-items {
      margin-bottom: 10px;
    }

    &__tabs-item {
      font-size: 24px;
      line-height: 30px;
    }

    &__title {
      font-size: 30px;
      line-height: 35px;
    }
  }
}

@media screen and (max-width:480px) {
  .login {

    &__container {
      background: #001299 top center no-repeat;
      background-size: cover;
      padding: 20px;
    }

    &__form {
      width: 100%;
      padding: 10px 10px;
    }

    &__tabs-items {
      margin-bottom: 10px;
    }

    &__tabs-item {
      font-size: 24px;
      line-height: 30px;
    }

    &__title {
      font-size: 30px;
      line-height: 35px;
    }
  }
}