@import "../../../Page/mixin";
.step.lesson33 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .MainHeader {
    .header {
      font-weight: bold;
      font-size: 20px;
      line-height: 19px;
      text-transform: uppercase;
      color: #00B1D8;
      margin-bottom: 0; }
    .subheader {
      margin-top: 10px;
      font-size: 32px;
      line-height: 37px;
      color: #00B1D8; }
    &.bigHeader {
      border-bottom: none; } }

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #00b1d8; } } }
  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step3, .step4 {
    .imgCenter {
      margin-bottom: 30px; }
    .list {
      margin-bottom: 30px; } }
  .step5 {
    .head {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 40px; } }
  .step7 {
    .flex {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48%;
        margin-bottom: 48px;
        &_question {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 18px;
          color: #fff;
          font-weight: 700;
          text-transform: uppercase;
          padding: 18px 24px;
          border-radius: 10px 10px 0 0;
          height: 72px;
          text-align: center;
          width: 100%;
          &.red {
            background: #dc3b6c; }
          &.blue {
            background: #19b5dd; }
          &.green {
            background: #c1d72f; }
          &.orange {
            background: #f08128; } }
        &_answer {
          font-size: 14px;
          line-height: 18px;
          padding: 18px 24px;
          border-radius: 0 0 10px 10px;
          border: 1px solid #b3e6fc;
          border-top: 0 none;
          flex-grow: 1; } } } } }




