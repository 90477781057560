.Popup {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  background: rgba(#345178, .8);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 112px;
  overflow-y: auto;
  @media screen and (max-width: 767px) {
    top: 92px;
    height: 100%;
    padding-top: 0; }
  .Popup_block {
    width: 100%;
    max-width: 640px;
    padding: 70px;
    background: #fff;
    position: relative;
    border-radius: 10px;
    @media screen and (max-width: 767px) {
      height: calc(100vh - 92px);
      padding: 40px 20px;
      max-width: initial;
      border-radius: 0;
      overflow-y: auto; }
    .Popup_closed {
      position: absolute;
      top: 20px;
      right: 20px;
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #99BFDE;
      cursor: pointer;
      transition: .5s;
      @media screen and (max-width: 767px) {
        position: fixed;
        top: 0;
        right: 0;
        height: 92px;
        width: 92px;
        background-color: #00559C; }
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #99BFDE;
        transform-origin: center;
        top: 50%;
        left: 50%;
        transition: .5s;
        @media screen and (max-width: 767px) {
          background: #ffffff;
          width: 28px; } }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: #005EAD; }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } } } }
