.QuestionCorrect {
  border-radius: 10px;
  padding: 45px;
  margin-bottom: 25px;
  .head {
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px; }
  .text {
    font-size: 18px;
    line-height: 26px; }
  &.correct {
    background: #005EAD;
    .head,
    .text {
      color: #fff; } }
  &.inCorrect {
    background: #F9EEEC;
    .head {
      color: #BD4E3B; }
    .text {
      color: #3E373C; } } }

