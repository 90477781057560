.PanelStudy {
  width: calc((100% / 4) - 15px);
  background: #fff;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 15px;
  @media screen and (max-width: 1600px) {
    width: calc((100% / 3) - 15px); }
  @media screen and (max-width: 1100px) {
    width: calc((100% / 2) - 15px); }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0; }
  @media screen and (max-width: 500px) {
    border-radius: 0; }
  &.BlueBorder {
    border: 2px solid #001299;
    border-radius: 26px; }

  .done {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.02em; }
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #99BFDE;

    &.Blue {
      color: #001299;
      text-transform: uppercase; }
    &.pyaterochka {
      color: #77777B; } }
  .desc {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 15px;
    width: 100%;
    .img {
      height: 96px;
      width: 96px;
      border-radius: 100px;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 24px;
      img {
        width: 96px;
        height: 96px;
        object-fit: cover; } }
    .text {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      span {
        display: block;
        font-size: 14px;
        line-height: 18px;
        color: #005EAD;
        font-weight: bold;
        margin-bottom: 10px;

        &.Blue {
          color: #001299;
          text-transform: uppercase; }
        &.pyaterochka {
          color: #333333; } }
      .button {
        width: 100%; } } }
  .star {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #005EAD;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    position: relative;
    white-space: nowrap;
    .line {
      width: 100%;
      height: 1px;
      margin-left: 20px; }
    svg {
      margin-right: 8px; }

    &.Blue {
      color: #001299;
      text-transform: uppercase; }
    &.pyaterochka {
      color: #A5338A; } }



  &_disabled {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#EDF3F9, .8);
      box-shadow: 0px 3px 10px rgba(42, 46, 126, 0.1);
      border-radius: 15px; } }

  .btn_darkblue {
    background: #001299;
    color: #FFF;
    border-radius: 15px;
    border: 2px solid #001299;

    &:hover {
      background: #FFF;
      color: #001299; } }
  .btn_pink {
    background: #fff;
    color: #333333;
    border-radius: 15px;
    border: 2px solid #A5338A;

    &:hover {
      background: #A5338A;
      color: #fff; } }
  .btn_pink_white {
    background: #fff;
    color: #333333;
    border-radius: 15px;
    border: 2px solid #A5338A;

    &:hover {
      border: 2px solid #ffffff;
      background: #A5338A;
      color: #fff; } } }
