@import "../../../Page/mixin";
.step.lesson8 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .flex {
    display: flex; }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; } } }
  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }
  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 140px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 50%;
        margin-right: 48px;
        padding: 15px 30px;
        position: relative;
        font-size: 16px;
        border: 1px solid #acdff5;
        box-sizing: border-box;
        box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
        border-radius: 10px;
        @include for-phone {
          width: 100%;
          margin-right: 0;
          margin-bottom: 88px; }
        span {
          display: block;
          font-size: 16px;
          color: #039cdd;
          font-weight: bold; }
        img {
          position: absolute;
          bottom: 16px;
          right: 32px; }
        &:last-child {
          margin-right: 0;
          @include for-phone {
            margin-bottom: 0; } } } } }
  .step3 {
    .li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 35px;
      &Head {
        font-size: 18px;
        color: #039cdd;
        font-weight: 700;
        margin: 40px 0 35px; }
      .number {
        font-weight: bold;
        font-size: 18px;
        border: 0 none;
        height: auto;
        width: auto;
        color: #039cdd;
        margin-right: 20px; }
      .text {
        color: #3E373C;
        font-size: 18px;
        padding-right: 24px; }
      .quest {
        height: 36px;
        width: 36px;
        flex-shrink: 0;
        background: #039cdd;
        border-radius: 50%;
        position: relative;
        border: 1px solid #039cdd;
        cursor: pointer;
        transition: .5s;
        &:before {
          content: '?';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 25px;
          line-height: 1;
          color: #ffffff;
          font-weight: bold;
          transition: .5s; }
        &:hover {
          background: #fff;
          &:before {
            color: #039cdd; } } } }
    .PopupInStep {
      .popup {
        &_head {
          margin: 0; }
        &_text {
          max-height: 35vh; } }
      .textBlue {
        font-weight: bold;
        color: #039cdd;
        &--big {
          font-size: 22px;
          sup {
            font-size: 16px; } } }
      .img {
        position: relative;
        left: 50%;
        transform: translate(-50%);
        max-width: 100%; } }
    .formula {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #2c3032;
      font-weight: 700;
      &_box {
        width: 317px;
        margin-right: 20px;
        @include for-phone {
          width: auto; } }
      &_numerator {
        text-align: center;
        border-bottom: 1px solid #2c3032; }
      &_denominator {
        text-align: center; } } }
  .step4 {
    position: relative;
    @include for-tablet {
      padding-bottom: 50px; }
    .PopupInStep {
      padding-top: 30px;
      @include for-phone {
        padding-top: 0; }
      .popup {
        padding-top: 10px;
        padding-bottom: 30px;
        .popup_text {
          margin-bottom: 0; }
        .popup_imgContent {
          margin-bottom: 0; } }
      .textBlue {
        font-weight: bold;
        color: #039cdd;
        &--big {
          font-size: 22px;
          sup {
            font-size: 16px; } } } }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 60px;
      @include for-phone {
        margin-top: 0; }
      .left {
        flex-shrink: 0;
        margin-right: 48px;
        @include for-phone {
          display: none; } }
      .right {
        @include for-phone {
          width: 100%; }
        &_row {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 20px;
          &_img {
            @include for-phone {
              display: none; } }
          img {
            margin-left: 48px;
            margin-right: 20px;
            @include for-phone {
              margin: 0; } }
          &:nth-child(2) {
            .row_block {
              &:before {
                left: auto;
                right: -1px;
                transform: translate(50%, -50%) rotate(135deg); } } }
          .row_img_mobile {
            display: none;
            @include for-phone {
              display: flex;
              justify-content: center; }
            &_dog {
              @include for-phone {
                width: 150px;
                height: auto; } }
            &_eat {
              @include for-phone {
                width: 75px;
                height: auto; } } }
          .row_block {
            border: 1px solid #acdff5;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 33px 38px 4px;
            width: 100%;
            position: relative;
            @include for-phone {
              padding: 20px 20px 4px; }
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              width: 15px;
              height: 15px;
              border-left: 1px solid #acdff5;
              border-top: 1px solid #acdff5;
              background: #fff;
              transform: translate(-50%, -50%) rotate(-45deg);
              @include for-phone {
                display: none; } } } } } }
    .textDown {
      width: 100%;
      text-align: center;
      color: #60B6FF;
      font-size: 18px;
      font-weight: bold;
      margin-top: 30px; }
    .btn_shpora {
      position: absolute;
      z-index: 2;
      left: 50%;
      bottom: -25px;
      transform: translate(-50%, 100%);
      background: #fff;
      color: #60B6FF;
      border: 1px solid #60B6FF;
      @include for-tablet {
        bottom: 20px; }
      &:hover {
        background: #60B6FF;
        color: #fff; } } }
  .step5 {
    .OnClickTest {
      .btn_blue {
        bottom: -25px; } } }
  .step6 {
    .list-numeral {
      color: #1dade6;
      font-size: 18px;
      font-weight: bold;
      &_item {
        &:not(:last-child) {
          margin-bottom: 20px; } }
      &_text {
        color: #2c3032;
        font-weight: normal; }
      .text_blue {
        color: #039cdd; } } }
  .step7 {
    .box {
      display: flex;
      justify-content: center;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      .img {
        max-width: 100%;
        &:first-child {
          margin-right: 20px;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 20px; } } } } }
  .step9 {
    .flex {
      display: flex;
      justify-content: center;
      margin: 0 -20px;
      @include for-phone {
        flex-direction: column;
        margin: 0; }
      &_img-box {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        @include for-phone {
          width: 100%;
          padding: 0;
          margin-bottom: 40px; } }
      .img {
        max-width: 100%; } } }
  .step10 {
    .TextBlock {
      .text_blue {
        font-weight: normal; } } }
  .step11 {
    .imgCenter {
      margin-bottom: 20px; } }
  .step12, .step24 {
    .OnClickBlock {
      margin-bottom: 40px;
      .imgDiv_wrap {
        &_flex {
          display: flex;
          @include for-phone {
            flex-direction: column;
            align-items: center; }
          .TextBlock {
            margin-right: 20px; } } } }
    .imgCenter.hidden {
      display: none; } }
  .step14, .step15 {
    .imgCenter {
      margin-bottom: 20px; } }
  .step16 {
    .text {
      position: relative;
      margin-bottom: 25px;
      padding-right: 400px;
      font-size: 18px;
      line-height: 24px;
      @include for-phone {
        padding-right: 0; }
      &_img {
        position: absolute;
        right: 0;
        bottom: -35px;
        @include for-phone {
          position: static;
          display: block;
          max-width: 100%;
          margin: 20px auto 0; } } } }
  .step17, .step18 {
    .head {
      display: flex;
      margin-bottom: 10px;
      @include for-phone {
        display: none; }
      &_el {
        font-size: 18px;
        color: #3d5294;
        font-weight: 700;
        text-transform: uppercase;
        &.left {
          width: 191px;
          text-align: center; }
        &.right {
          flex-grow: 1;
          padding-left: 50px; } } }
    .info {
      display: flex;
      margin-bottom: 20px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &.right {
          position: relative;
          display: flex;
          align-items: center;
          flex-grow: 1;
          padding: 10px 20px 10px 30px;
          font-size: 16px;
          line-height: 22px;
          white-space: pre-line;
          border: 1px solid #3d5294;
          border-radius: 10px;
          &:before {
            content: '';
            position: absolute;
            left: -1px;
            top: 50%;
            transform: translate(-50%, -50%) rotate(-135deg);
            height: 12px;
            width: 12px;
            background: #fff;
            border-top: 1px solid #3d5294;
            border-right: 1px solid #3d5294;
            transition: .5s;
            @include for-phone {
              left: 50%;
              top: -1px;
              transform: translate(-50%, -50%) rotate(-45deg); } } }
        &.left {
          flex-shrink: 0;
          margin-right: 25px;
          @include for-phone {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0;
            margin-bottom: 20px; } } } } }
  .step19 {
    .imgCenter {
      margin-bottom: 20px; } }
  .step20 {
    .InfoBlock {
      margin-bottom: 20px;
      &Tablet {
        display: none;
        @include for-tablet {
          display: flex;
          margin-top: 20px; }
        &.hidden {
          @include for-tablet {
            display: none; } } } }
    .wrp {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      overflow: visible;
      margin-top: 60px;
      .box {
        width: 58%;
        @include for-phone {
          width: auto; } }
      &_img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        @include for-tablet {
          display: block;
          width: 50%;
          margin: 0 auto; }
        @include for-phone {
          margin-top: 20px; }
        @media screen and (max-width: 499px) {
          width: 100%; } }
      &_right {
        padding-top: 30px;
        width: 100%; } }
    .flex {
      display: flex;
      flex-direction: column;
      width: 40%;
      @include for-phone {
        width: 100%; }
      &_el {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 67px;
        width: 100%;
        margin-bottom: 20px;
        color: #acdff5;
        text-align: center;
        background: #fff;
        border: 2px solid #acdff5;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        transition: .5s;
        @include for-phone {
          width: 100%;
          height: auto;
          margin-right: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #4C8EC5;
          border: 2px solid #99BFDE;
          text-transform: uppercase;
          padding: 15px 20px; }
        &.active,
        &:hover {
          background: #049bdc;
          box-shadow: none;
          border: 2px solid #049bdc;
          color: #fff;
          font-weight: bold; }
        &:last-child {
          margin-bottom: 0; } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv {
      width: 100%;
      height: 100%;
      border: 2px solid #049bdc;
      border-radius: 10px;
      padding: 50px 35px;
      position: relative;
      &_closed {
        display: none;
        @include for-tablet {
          display: block;
          position: absolute;
          bottom: -40px;
          right: 50%;
          height: 35px;
          width: 35px;
          transform: translateX(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #99BFDE;
          background-color: #005EAD;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            background: #ffffff;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }

      &:before {
        content: '';
        position: absolute;
        left: -2px;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-135deg);
        height: 12px;
        width: 12px;
        background: #fff;
        border-top: 2px solid #049bdc;
        border-right: 2px solid #049bdc;
        transition: .5s;
        @include for-phone {
          left: 50%;
          top: -1px;
          transform: translate(-50%, -50%) rotate(-45deg); } }
      .img_box {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 55px;
        background: #ffffff;
        border: 2px solid #005EAD;
        border-radius: 50%;
        @include for-phone {
          left: 50%;
          transform: translate(-50%, 0); } }
      .TextBlock {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px; }
      .List li {
        font-size: 16px;
        line-height: 24px;
        &:before {
          top: 10px; } }
      &_picture {
        width: 100%;
        height: auto;
        margin-bottom: 20px; }
      &_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        img {
          flex-shrink: 0;
          margin-left: 5px; } }
      &.first {
        background: url("../../../image/lessons/lesson8/step20-2.png") no-repeat;
        &:before {
          top: 6%; } }
      &.second {
        background: url("../../../image/lessons/lesson8/step20-3.png") no-repeat;
        &:before {
          top: 20%; } }
      &.third {
        background: url("../../../image/lessons/lesson8/step20-4.png") no-repeat;
        &:before {
          top: 35%; } }
      &.fourth {
        background: url("../../../image/lessons/lesson8/step20-5.png") no-repeat;
        &:before {
          top: 50%; } }
      &.fifth {
        background: url("../../../image/lessons/lesson8/step20-6.png") no-repeat;
        &:before {
          top: 65%; } }
      &.sixth {
        background: url("../../../image/lessons/lesson8/step20-7.png") no-repeat;
        &:before {
          top: 80%; } }
      &.seventh {
        background: url("../../../image/lessons/lesson8/step20-8.png") no-repeat;
        &:before {
          top: 95%; } } }
    .TextBlock {
      line-height: 26px;
      &.hidden {
        @include for-tablet {
          display: none; } } }
    .PopupInStep {
      .popup {
        &_imgHead {
          width: 96px;
          height: 96px;
          padding: 10px;
          border: 2px solid #99BFDE;
          picture {
            display: flex;
            align-items: center;
            justify-content: center; }
          &Bg {
            width: 70%;
            height: 70%;
            background: #ffffff;
            border-radius: 0; } }
        &_text {
          height: auto;
          max-height: 100%;
          overflow: visible; } } } }
  .step22, .step23 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_img {
        flex-shrink: 0;
        max-width: 100%;
        margin-left: 30px;
        @include for-phone {
          margin-left: 0; } } } }
  .step26 {
    .flex {
      display: flex;
      align-items: center;
      border: 2px solid #039cdd;
      border-radius: 10px;
      padding: 0 40px 0 10px;
      @include for-phone {
        flex-direction: column;
        margin: 0 -20px;
        border-radius: 0;
        border: none;
        border-top: 2px solid #039cdd;
        border-bottom: 2px solid #039cdd; }
      &_el {
        &:first-child {
          margin-right: 30px; }
        &_img {
          display: block;
          margin: 0 auto;
          max-width: 100%; } } } } }









