@import "../../../Page/mixin";
.step.lesson25 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .MainHeader {
    &.bigHeader {
      border-bottom: none; } }
  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #039cdd;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; } } }
  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }
  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px;
      margin-top: 40px;
      border-top: 1px solid #acdff5;
      padding-top: 40px; }
    .info {
      padding: 30px 37px;
      background: #f0f5fb;
      border: 2px solid #d3deeb;
      box-sizing: border-box;
      border-radius: 10px;
      white-space: pre-line; } }
  .step2 {
    .block {
      padding-top: 20px;
      padding-bottom: 82px;
      background-image: url(../../../image/lessons/lesson25/step2-1.png);
      background-repeat: no-repeat;
      background-position: 89% 0;
      .TextBlock {
        max-width: 384px; } } }
  .step3, .step7, .step8, .step13 {
    .flex {
      display: flex;
      margin-bottom: 30px;
      &_icon {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        margin-right: 40px;
        width: 96px; } } }
  .step4, .step5, .step6 {
    .VideoBlock {
      margin-bottom: 20px; } }
  .step9 {
    .advice {
      &_list {
        margin-top: 0;
        margin-bottom: 20px; }
      &_headline {
        margin-bottom: 6px;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #039cdd; }
      &_description {
        margin-bottom: 48px;
        margin-left: 0;
        font-size: 18px;
        line-height: 25px; } } }
  .step10 {
    .block {
      &_el {
        padding: 28px 0;
        border-bottom: 1px solid #bfe4f4;
        &:first-child {
          border-top: 1px solid #bfe4f4; }
        &_headline {
          display: flex;
          align-items: center; }
        &_icon {
          width: 40px;
          height: 40px;
          margin-right: 20px;
          padding-top: 20px;
          font-weight: 700;
          font-size: 25px;
          line-height: 0;
          text-align: center;
          color: #fff;
          background-color: #d3deeb;
          border-radius: 50%;
          box-sizing: border-box;
          transition: .5s; }
        &_question {
          flex-grow: 1;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          color: #1581b8;
          transition: .5s; }
        &_btn {
          position: relative;
          width: 40px;
          height: 40px;
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            border-right: 2px solid #d3deeb;
            border-bottom: 2px solid #d3deeb;
            box-sizing: border-box;
            transform: translate(-50%,-50%) rotate(45deg);
            transition: .5s; } }
        &_description {
          padding-left: 60px;
          padding-right: 60px;
          display: none;
          font-size: 16px;
          line-height: 22px; }
        &.active {
          .block_el_icon {
            background-color: #039cdd; }
          .block_el_question {
            color: #039cdd; }
          .block_el_btn:before {
            transform: translate(-50%,-50%) rotate(-135deg);
            border-right: 2px solid #039cdd;
            border-bottom: 2px solid #039cdd; }
          .block_el_description {
            display: block; } } } } }
  .step13 {
    .flex {
      &_icon {
        align-items: flex-start; } } } }











