@import "../../../Page/mixin";
.step.lesson7 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; }
      &_blue {
        font-weight: bold;
        color: #039cdd; } } }
  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }
  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .test_wrp {
          display: flex;
          flex-direction: column;
          margin-bottom: 38px;
          .flex {
            display: flex;
            flex-grow: 1;
            .answers {
              position: relative;
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: 0;
              margin-bottom: 0;
              .product {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 48%;
                &:not(:last-child) {
                  margin-right: 15px; }
                &:nth-child(1) {
                  .product_box {
                    background: url("../../../image/lessons/lesson7/step2-1.png") no-repeat;
                    background-size: cover; } }

                &:nth-child(2) {
                  .product_box {
                    background: url("../../../image/lessons/lesson7/step2-2.png") no-repeat;
                    background-size: cover; } }
                .head {
                  font-size: 18px;
                  color: #039cdd;
                  font-weight: bold;
                  text-transform: uppercase;
                  margin: 0 0 20px; }
                &_box {
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  flex-grow: 1;
                  width: 100%;
                  border: 1px solid #acdff5;
                  border-radius: 15px;
                  position: relative;
                  padding: 20px 30px 10px; } }
              &_box {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 10px; }
              &_item {
                position: relative;
                width: 100%; }
              //&:not(:last-child)
              //  margin-bottom: 12px
              .answer {
                position: relative;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 80px;
                font-weight: bold;
                font-size: 17px;
                line-height: 22px;
                color: #ffffff;
                text-align: center;
                background-color: rgba(255, 255, 255, 0.3);
                border: none;
                border-radius: 10px;
                box-shadow: none;
                padding: 10px;
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center; }

                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8;
                  .icon svg {
                    fill: #4085D8; } }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B;
                  .icon svg {
                    fill: #BD4E3B; } } } } }
          .questions {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: 0 -15px 24px;
            .question_el {
              display: flex;
              justify-content: space-between;
              width: 33%;
              height: 80px;
              padding: 0 15px;
              &:not(:last-child) {
                margin-bottom: 12px; }
              &.hidden {
                .ddcontainersource {
                  font-size: 0;
                  background-color: #ffffff;
                  box-shadow: none;
                  border: none;
                  cursor: default;
                  img {
                    opacity: 0; } } } }
            .ddcontainer {
              width: 100%; }
            .ddcontainersource {
              display: flex!important;
              align-items: center;
              justify-content: center;
              text-align: center;
              width: 100%;
              height: 80px;
              margin: 0 auto;
              padding: 0 10px;
              font-size: 14px;
              line-height: 22px;
              color: #1dade6;
              cursor: pointer;
              border: 1px solid #acdff5;
              border-radius: 10px; }
            .ddcontainerghost {
              display: flex;
              align-items: center;
              text-align: center;
              width: 245px;
              height: 80px;
              padding: 0 10px;
              font-size: 14px;
              line-height: 22px;
              color: #1dade6;
              background: #ffffff;
              border: 1px solid #acdff5;
              border-radius: 10px;
              opacity: 1;
              @include for-tablet {
                width: 210px; }
              & > div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center; } } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } }
      //Стили мобильной версии
      .test_mobile {
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 0;
          .flex {
            display: flex;
            flex-direction: column;
            margin-bottom: 48px;
            &_el {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              margin-bottom: 40px;
              color: #039cdd;
              font-size: 14px;
              font-weight: bold;
              &:nth-child(1) {
                .flex_el_box {
                  background: url("../../../image/lessons/lesson7/step2-1.png") no-repeat;
                  background-size: cover; } }

              &:nth-child(2) {
                .flex_el_box {
                  background: url("../../../image/lessons/lesson7/step2-2.png") no-repeat;
                  background-size: cover; } }

              &_head {
                margin-right: 15px;
                text-transform: uppercase; }
              &_box {
                width: 270px;
                border: 1px solid #acdff5;
                border-radius: 15px;
                padding: 20px 10px 10px 20px; } } }
          &_item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 60px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            color: #ffffff;
            background-color: rgba(255, 255, 255, 0.3);
            border: none;
            border-radius: 10px;
            text-align: center;
            &.selected {
              color: #ffffff;
              background-color: rgba(255, 255, 255, 0.3);
              border: none;
              &.correct {
                background: #ffffff;
                border-color: #4085D8;
                color: #4085D8; }
              &.inCorrect {
                background: #ffffff;
                border-color: #BD4E3B;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          overflow-y: auto;
          padding-bottom: 92px;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 30px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #2c3032;
              background-color: #ffffff;
              border: 1px solid #039cdd;
              border-radius: 50%; }
            .questions {
              &_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 50px;
                margin: 0 auto 20px;
                font-size: 14px;
                line-height: 18px;
                color: #1dade6;
                border: 1px solid #acdff5;
                border-radius: 10px;
                &.selected {
                  opacity: 0.3; } } } }
          &_text {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #e3fffb;
          margin-bottom: 48px;
          color: #2c3032;
          @include for-phone {
            padding: 20px; }
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: #3fc4b1; }
          &Text {
            font-size: 18px;
            @include for-phone {
              font-size: 16px; } }
          &.error {
            background: #ffe3e3;
            .info_head {
              color: #f12626; }
            .infoText {
              color: #2c3032; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -25px;
          right: 0;
          transform: translate(0, 100%); } } } }
  .step3, .step8 {
    .text_blue {
      color: #1dade6;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px; }
    .img {
      display: block;
      max-width: 100%;
      margin: 30px auto 0; } }
  .step4, .step9 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:first-child {
          margin-right: 20px; }
        &:last-child {
          flex-shrink: 0; } } }
    .list-numeral {
      color: #1dade6;
      font-size: 18px;
      font-weight: bold;
      &_item {
        &:not(:last-child) {
          margin-bottom: 20px; } }
      &_text {
        color: #2c3032;
        font-weight: normal; } }
    .img {
      @include for-phone {
        display: block;
        max-width: 100%;
        margin: 0 auto; } } }
  .step5, .step10 {
    padding-bottom: 100px; }
  .step6, .step11 {
    .img {
      display: block;
      max-width: 100%;
      margin: 0 auto 40px; }
    .advice {
      background-color: #039cdd;
      color: #ffffff;
      padding: 25px;
      font-weight: bold;
      border-radius: 10px;
      margin-bottom: 25px; } }
  .step12, .step13, .step23 {
    padding-bottom: 100px;
    @include for-phone {
      padding-bottom: 50px; }
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        flex-direction: column;
        width: 48%;
        @include for-phone {
          width: 100%;
          margin-bottom: 80px; }
        &_head {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #039cdd;
          text-align: center;
          text-transform: uppercase; }
        &_box {
          position: relative;
          flex-grow: 1;
          padding: 25px 25px 120px 25px;
          border: 1px solid #acdff5;
          border-radius: 10px; }
        &_img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 30%); } } } }
  .step15, .step19, .step24, .step29 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:last-child {
          flex-shrink: 0; }
        &_head {
          color: #1dade6;
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 15px; }
        &_img {
          display: block;
          max-width: 100%;
          margin: 0 auto; } } }
    .advice {
      background-color: #039cdd;
      color: #ffffff;
      padding: 25px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      border-radius: 10px;
      margin-bottom: 25px;
      @include for-phone {
        font-size: 16px; } } }
  .step16 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: 43px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 50%;
        margin-right: 48px;
        height: 136px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 38px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        @include for-phone {
          width: 100%;
          margin-bottom: 40px;
          padding: 20px; }
        &:first-child {
          border: 1px solid #cc3333;
          color: #cc3333; }
        &:last-child {
          margin-right: 0;
          border: 1.5px solid #3fc4b1;
          color: #3fc4b1; }
        .icon {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%); } } } }
  .step17 {
    .advice {
      background-color: #039cdd;
      color: #ffffff;
      padding: 25px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      border-radius: 10px;
      margin-bottom: 25px;
      @include for-phone {
        font-size: 16px; } } }
  .step18 {
    .img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      @include for-phone {
        max-width: initial; } }
    .img-box {
      @include for-phone {
        overflow-x: scroll; } } }

  .step20 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 25%;
        margin-right: 24px;
        @include for-phone {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px; }
        &:last-child {
          margin-right: 0; }
        .head {
          height: 60px;
          width: 100%;
          border: 1px solid #acdff5;
          box-sizing: border-box;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #039cdd;
          font-weight: bold; }
        .TextBlock {
          margin-top: 18px;
          font-size: 15px;
          line-height: 20px;
          color: #3E373C;
          text-align: center; } } }
    .imgCenter {
      margin-top: 47px; } }

  .step21 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:first-child {
          flex-shrink: 0;
          margin-right: 30px;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 30px; } }
        &_head {
          color: #2c3032;
          font-size: 18px;
          margin-bottom: 25px; }
        &_blue {
          font-weight: bold;
          color: #014785; }
        &_img {
          display: block;
          max-width: 100%;
          margin: 0 auto; }
        .list {
          &_item {
            &:before {
              background: #014785; } } } } } }

  .step25, .step26, .step27 {
    padding-bottom: 100px;
    @include for-phone {
      padding-bottom: 50px; }
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        flex-direction: column;
        width: 48%;
        @include for-phone {
          width: 100%;
          margin-bottom: 80px; }
        &_head {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #039cdd;
          text-align: center;
          text-transform: uppercase; }
        &_box {
          position: relative;
          flex-grow: 1;
          padding: 25px 25px 120px 25px;
          border: 1px solid #acdff5;
          border-radius: 10px;
          @include for-phone {
            padding-bottom: 25px; } }
        &_img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 30%);
          @include for-phone {
            position: relative;
            transform: translate(-50%, 0); } } } } }
  .step30 {
    .OnClickBlock {
      .imgDiv_wrap {
        &_flex {
          display: flex;
          justify-content: space-between;
          @include for-phone {
            flex-direction: column; }
          .TextBlock {
            margin-right: 20px;
            @media screen and (max-width: 550px) {
              margin-right: 0; } } }
        &_img {
          display: block;
          margin: 0 auto;
          max-width: 100%; }
        &_text-blue {
          margin-bottom: 20px;
          font-weight: bold;
          color: #1dade6;
          font-size: 18px; } }
      &>.flex {
        &>.flex_el:last-of-type {
          width: 22%; } } } }
  .step31, .step32, .step33, .step34 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:first-child {
          flex-shrink: 0;
          margin-right: 30px;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 30px; } }
        &:last-child {
          .TextBlock {
            text-align: center; } }
        &_img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
          &.second {
            margin-bottom: 20px; } } } } }
  .step32, .step33 {
    .flex {
      &_el {
        &:last-child {
          .TextBlock {
            text-align: left; } } } } }
  .step36 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:last-child {
          flex-shrink: 0; }
        &_img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
          @include for-phone {
            margin-top: 30px; } } } } } }
