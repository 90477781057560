.popapAfterTest {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background: rgba(#345178, .8);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    top: 92px;
    height: 100%;
    padding-top: 0;
    align-items: start; }
  &_wrp {
    background: #fff;
    border-radius: 25px;
    padding: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    @media screen and (max-width: 767px) {
      height: calc(100vh - 92px);
      padding: 40px 20px;
      width: 100%;
      border-radius: 0;
      overflow-y: auto; } }
  &_close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 20px;
    @media screen and (max-width: 767px) {
      position: fixed;
      top: 0;
      right: 0;
      height: 92px;
      width: 92px;
      background-color: #00559C; }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 100%;
      background: rgba(#005EAD, .6);
      transition: .5s;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        background: #ffffff;
        width: 28px; } }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg); }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg); }
    &:hover {
      &:before,
      &:after {
        background: rgba(#005EAD, 1); }
      &:before {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(45deg); } } }


  &_head {
    font-size: 32px;
    color: #005EAD;
    text-align: center; }
  &_text {
    font-size: 16px;
    line-height: 24px;
    color: #3E373C;
    text-align: center;
    margin: 30px 0; }
  .btn {
    font-size: 14px;
    width: 160px; } }
