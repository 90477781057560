@import "../../../Page/mixin";
.step.lesson23 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .MainHeader {
    .header {
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      text-transform: uppercase;
      color: #B7090B;
      margin-bottom: 0; }
    .subheader {
      margin-top: 10px;
      font-size: 32px;
      line-height: 37px;
      color: #B7090B; }
    &.bigHeader {
      border-bottom: none; } }

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #b7090b; } } }
  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step3 {
    .head {
      margin-bottom: 28px;
      color: #630606;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold; }
    .flex {
      display: flex;
      margin-bottom: 40px;
      &_el {
        &:first-child {
          flex-shrink: 0;
          margin-right: 20px; }
        &_list {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          margin: 0;
          list-style: none; }
        &_item {
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          line-height: 16px; }
        &_icon {
          margin-right: 15px; } } }
    .info {
      display: flex;
      align-items: center;
      border: 1px solid #b7090b;
      font-size: 20px;
      color: #b7090b;
      padding-right: 24px;
      font-weight: 700;
      &_img {
        margin-right: 24px; } } }
  .step4 {
    .head {
      margin-bottom: 28px;
      color: #630606;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold; }
    .flex {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .flex_img {
        margin-bottom: 30px;
        max-width: 100%; } }
    .list {
      &_item {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 16px;
        &:before {
          top: 5px; } }
      &_red {
        font-weight: bold;
        color: #b7090b; } } }
  .step5 {
    .head {
      margin-bottom: 28px;
      color: #630606;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold; }
    .block {
      display: flex;
      justify-content: center;
      margin-top: 100px;
      &_box {
        position: relative; }
      .point {
        position: absolute;
        background: #fef2e3;
        border: 4px solid #630606;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        z-index: 2;
        transition: 0.5s;
        cursor: pointer;
        &:first-child {
          top: 30%;
          left: 23%;
          .point_img {
            top: -140px;
            left: -195px; } }
        &:nth-child(2) {
          top: 30%;
          left: 62%;
          .point_img {
            top: -125px;
            left: 15px; } }
        &:nth-child(3) {
          top: 50%;
          left: 10%;
          z-index: 3;
          .point_img {
            top: 18px;
            left: -179px; } }
        &:nth-child(4) {
          top: 55%;
          left: 65%;
          .point_img {
            top: -144px;
            left: 15px; } }
        &:nth-child(5) {
          top: 65%;
          left: 15%;
          .point_img {
            top: -130px;
            left: -207px; } }
        &_img {
          position: absolute;
          transition: 0.5s;
          transform: scale(0);
          &.active {
            transform: scale(1); } } } } }
  .step6 {
    .head {
      margin-bottom: 28px;
      color: #630606;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold; }
    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &_el {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 48%;
        margin-bottom: 48px;
        border: 1px solid #d5c3a3;
        border-radius: 7px;
        cursor: pointer;
        transition: 0.5s;
        &_img-box {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 160px;
          width: 100%;
          flex-grow: 1;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px; }
        &_box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 0;
          overflow: hidden;
          padding: 0 20px;
          transition: 0.5s;
          &_head {
            font-size: 22px;
            font-weight: 700;
            color: #b7090b;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &_text {
            font-size: 17px;
            line-height: 24px;
            transition: 0.5s; } }
        &.active {
          border: 2px solid #b7090b;
          .flex_el_img-box {
            background: #fef2e3; }
          .flex_el_box {
            height: 140px; } } } } }
  .step7 {
    .head {
      margin-bottom: 28px;
      color: #630606;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold; }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      &_el {
        &_row {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 30px;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: #d5c3a3; }
          &:first-child:before {
            height: calc((100% - 4px) / 2);
            top: auto;
            bottom: 0; }
          &:last-child:before {
            height: calc((100% + 5px) / 2); }
          &_block {
            min-height: 130px; }
          &_head {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            color: #f3993e; }
          &_without {
            display: inline-block;
            padding: 7px 10px;
            background-color: #73be44;
            border-radius: 7px 7px 0 0;
            color: #fff;
            margin-right: 10px; }
          &_wrp {
            padding: 16px 20px;
            background-color: #f1f8ec;
            border: 2px solid #73be44;
            border-radius: 0 7px 7px 7px;
            font-size: 16px; }
          &_img-box {
            flex-shrink: 0;
            margin-left: 30px;
            padding-right: 46px;
            position: relative;
            margin-top: 34px;
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              height: 1px;
              width: 46px;
              background-color: #d5c3a3; } } }
        &:last-child {
          flex-shrink: 0;
          margin-left: 30px; } } } }
  .step8 {
    padding-bottom: 100px;
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      &_el {
        width: 48%;
        &_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 60px;
          font-size: 20px;
          color: #b7090b;
          text-transform: uppercase;
          border-radius: 7px;
          font-weight: 700;
          transition: .5s;
          cursor: pointer;
          border: 1px solid #b7090b; }
        &_img {
          width: 100%;
          display: none; }
        &.active {
          .flex_el_btn {
            background-color: #b7090b;
            color: #fff;
            border-radius: 7px 7px 0 0; }
          .flex_el_img {
            display: block; } } }
      &_img {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-40%, 110%);
        transition: 0.5s;
        &.active {
          transform: translate(-35%, 30%); } } } }
  .step9 {
    padding-bottom: 200px;
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      &_el {
        width: 100%;
        &_btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 60px;
          font-size: 20px;
          color: #742564;
          text-transform: uppercase;
          border-radius: 7px;
          font-weight: 700;
          transition: .5s;
          cursor: pointer;
          border: 1px solid #742564; }
        &_img {
          width: 100%;
          display: none; }
        &.active {
          .flex_el_btn {
            background-color: #742564;
            color: #fff;
            border-radius: 7px 7px 0 0; }
          .flex_el_img {
            display: block; } } }
      &_img {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(0, 110%);
        transition: 0.5s;
        &.active {
          transform: translate(0, 40%); } } } } }







