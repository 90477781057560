@import "../../../Page/mixin";
.step.lesson16 {
  padding-bottom: 48px;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  color: #3E373C;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #039cdd;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #039cdd;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #039cdd;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #039cdd;
      background: #039cdd;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #039cdd; } } }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #039cdd;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .textBlue {
    color: #005EAD; }
  .textRed {
    color: #BD4E3B; }
  .infoPopap {
    position: fixed;
    z-index: 222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#345178, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrp {
      background: #fff;
      border-radius: 25px;
      padding: 70px;
      position: relative;
      width: 100%;
      max-width: 640px; }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba(#005EAD, .3);
        transition: .5s; }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: rgba(#005EAD, 1); }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }

    &_scroll {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
      overflow-y: auto; }
    &_iconDiv {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      overflow: hidden;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      &.red {
        color: #BD4E3B; } }
    &_text {
      font-size: 16px;
      color: #3E373C;
      margin-bottom: 30px;
      &_head {
        font-size: 16px;
        color: #005EAD;
        font-weight: bold;
        display: block; } }
    &_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      &ImgDiv {
        height: 96px;
        width: 96px;
        flex-shrink: 0;
        border: 2px solid #005EAD;
        border-radius: 50%;
        background: #fff;
        margin-right: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
        &.chain {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 100%;
            width: 3px;
            background: #005EAD; } } }
      &:last-child {
        .chain {
          &:before {
            display: none; } } }
      .infoPopap_text {
        margin-bottom: 0; } }


    &_wrp.danger {
      background: #F9EEEC;
      .infoPopap_iconDiv {
        background: #F9EEEC; }
      .infoPopap_close {
        &:before,
        &:after {
          background: rgba(#BD4E3B, .3); }
        &:hover {
          &:before,
          &:after {
            background: rgba(#BD4E3B, 1); } } }
      .infoPopap_text {
        li:before {
          background: #BD4E3B; } }
      .infoPopap_head {
        color: #BD4E3B; } }





    &_imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      max-width: 100%;
      img {
        margin-top: 45px;
        max-width: 100%; } }
    .btn {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      font-size: 14px;
      width: 160px; } }

  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; } } }
  .infoPopap {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    background: rgba(#345178, .8);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 112px;
    &_wrp {
      width: 100%;
      max-width: 640px;
      padding: 70px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      .popap_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99BFDE;
        cursor: pointer;
        transition: .5s;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s; }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover {
          &:before,
          &:after {
            background: #005EAD; }
          &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(45deg); } } }
      .image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        &Bg {
          width: 100%;
          height: 100%;
          background: #E6EFF7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            border-radius: 50%; } } }
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line; }
      .subHead {
        color: #1F72B7;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        white-space: pre-line;
        margin-bottom: 30px; }
      .slider {
        border: 2px solid #CCDFEF;
        border-radius: 10px;
        position: relative;
        padding: 25px;
        margin-bottom: 50px;
        .slide {
          color: #4C8EC5;
          font-size: 16px;
          line-height: 24px; }
        .slick-arrow {
          bottom: 0; } }
      .btn {
        position: relative;
        left: 50%;
        transform: translate(-50%); } } }

  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2, .step5, .step8 {
    .TextBlock {
      text-align: center; } }
  .step3, .step4, .step5, .step6, .step9, .step15, .step16, .step17, .step18, .step20 {
    .imgCenter {
      @include for-phone {
        width: 100%;
        max-width: 330px; } } }
  .step5 {
    .text {
      margin-top: 30px;
      font-size: 15px;
      padding-left: 40px;
      color: #5c6773; } }
  .step6 {
    padding-bottom: 400px;
    @include for-phone {
      padding-bottom: 0; }
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      &_img {
        position: relative; }
      &_point {
        position: absolute;
        top: 65%;
        left: 24%;
        width: 50px;
        height: 70px;
        z-index: 2;
        cursor: pointer;
        @include for-phone {
          width: 30px;
          height: 40px;
          top: 62%; }
        &:nth-child(2) {
          top: 27%;
          left: auto;
          right: 5%;
          @include for-phone {
            top: 26%;
            right: 4%; } } }
      .block {
        position: absolute;
        transform: scale(0);
        transform-origin: 50% 20px;
        padding: 20px 25px;
        border-radius: 10px;
        transition: .5s;
        background: #fff;
        width: 335px;
        z-index: 3;
        @include for-phone {
          display: none; }
        &.active {
          transform: scale(1); }
        &.green {
          top: 60%;
          left: -10px;
          border: 1px solid #00c5b2;
          .block_head {
            color: #00c5b2; }
          .List {
            li:before {
              background: #00c5b2; } } }
        &.red {
          top: 30%;
          right: -50px;
          border: 1px solid #ed3030;
          .block_head {
            color: #ed3030; }
          .List {
            li:before {
              background: #ed3030; } } }
        &_head {
          font-size: 15px;
          text-align: center;
          text-transform: uppercase; } } }
    .Popup {
      .info {
        &_head {
          text-transform: uppercase; }
        &.green {
          .info_head {
            color: #00c5b2; }
          .List {
            li:before {
              background: #00c5b2; } } }
        &.red {
          .info_head {
            color: #ed3030; }
          .List {
            li:before {
              background: #ed3030; } } } }
      .btn_blue {
        margin: 0 auto; } } }
  .step7 {
    .InfoBlock {
      @include for-tablet {
        display: none; } }
    .slider {
      margin-top: 25px;
      padding: 50px;
      border: 2px solid #039cdd;
      border-radius: 10px;
      display: none;
      position: relative;
      transition: .5s;
      margin-bottom: 20px;
      @include for-tablet {
        padding: 40px 0 47px;
        border: none;
        border-radius: 0; }
      &.show {
        display: block; }
      &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: calc(25% - 12px);
        transform: translate(-50%) rotate(45deg);
        height: 15px;
        width: 15px;
        border-left: 2px solid #1F72B7;
        border-top: 2px solid #1F72B7;
        background: #fff;
        transition: 1s;
        @include for-tablet {
          display: none; } }
      &_right {
        &:before {
          left: calc(75% + 12px); } }
      &_noArrow {
        &:before {
          display: none; } }
      &_el {
        font-size: 18px;
        line-height: 26px;
        color: #3E373C;
        position: relative;
        transition: .5s;
        .head {
          color: #039cdd;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 30px;
          text-transform: uppercase; }
        .slider_flex_img {
          margin-left: 35px;
          margin-bottom: 30px;
          @media screen and (max-width: 767px) {
            display: none; }
          &.left {
            margin-left: 0;
            margin-right: 20px; } }
        .TextBlock {
          font-size: 17px;
          line-height: 22px; }
        .imgMobile {
          display: none;
          @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center; }
          img {
            @media screen and (max-width: 767px) {
              display: block; } } } }
      &_flex {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        transition: .5s;
        &_box {
          .TextBlock {
            .text_blue.big {
              font-size: 24px; } }
          &_text {
            min-height: 100px; } }
        &_note {
          font-size: 12px;
          line-height: 16px;
          color: #5e6773; } }
      &_img {
        max-width: 100%; }
      &_img-mobile {
        width: 100%;
        max-width: 300px;
        margin-bottom: 30px; }
      .mobile-hidden {
        @include for-phone {
          display: none; } }
      .slick-prev {
        position: absolute;
        bottom: -50px;
        top: auto;
        left: 55px;
        transform: translate(0, 50%);
        height: 36px;
        width: 36px;
        border-radius: 50%;
        border: 2px solid #039cdd;
        background: #fff;
        cursor: pointer;
        transition: .5s;
        &.slick-disabled {
          cursor: default;
          opacity: 0; }
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% - 1px), -50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 8px solid #039cdd;
          border-bottom: 5px solid transparent;
          transition: .5s; }
        &:hover {
          background: #039cdd;
          &:before {
            border-right: 8px solid #fff; } } }
      .slick-next {
        position: absolute;
        bottom: -50px;
        top: auto;
        right: 55px;
        transform: translate(0, 50%);
        height: 46px;
        width: 46px;
        border-radius: 50%;
        border: 2px solid #039cdd;
        background: #039cdd;
        cursor: pointer;
        transition: .5s;
        &.slick-disabled {
          cursor: default;
          opacity: 0; }
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% + 1px), -50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 8px solid #fff;
          border-bottom: 5px solid transparent;
          transition: .5s; }
        &:hover {
          background: #fff;
          &:before {
            border-left: 8px solid #039cdd; } } } } }
  .step10 {
    .box {
      display: flex;
      align-items: center;
      @include for-phone {
        justify-content: center; }
      &_img {
        position: relative; }
      &_point {
        position: absolute;
        top: -4px;
        left: 315px;
        width: 50px;
        height: 50px;
        z-index: 2;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        @include for-phone {
          width: 30px;
          height: 40px;
          top: 200px;
          left: 225px; }
        &:nth-child(2) {
          top: 40px;
          left: 441px;
          @include for-phone {
            top: 276px;
            left: 217px; } }
        &:nth-child(3) {
          top: 85px;
          left: 580px;
          @include for-phone {
            top: 355px;
            left: 153px; } }
        &:nth-child(4) {
          top: 190px;
          left: 515px;
          @include for-phone {
            top: 309px;
            left: 74px; } }
        &:nth-child(5) {
          top: 290px;
          left: 367px;
          @include for-phone {
            top: 219px;
            left: 23px; } }
        &_img {
          transform: scale(0);
          transform-origin: 20% 60%;
          transition: 0.5s;
          @include for-phone {
            position: absolute;
            top: 52%;
            left: 52%;
            transform: translate(-50%, -50%) scale(0);
            transform-origin: 50% 50%;
            width: 80px; }
          &.active {
            transform: scale(1);
            @include for-phone {
              transform: translate(-50%, -50%) scale(1); } } } }
      .img {
        @include for-phone {
          max-width: 280px; } } }
    .info {
      padding: 20px;
      font-size: 18px;
      color: #039cdd;
      font-weight: 700;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 2px solid #039cdd;
      border-radius: 10px;
      margin-top: 60px;
      transition: 0.5s ease-in;
      transform: scale(0);
      &.show {
        transform: scale(1); }
      &_big {
        font-size: 30px; } } }
  .step11 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .flex {
          display: flex;
          flex-wrap: wrap;
          margin-top: 48px;
          margin-bottom: 24px;
          justify-content: space-between;
          align-items: center;
          &_el {
            margin-bottom: 58px;
            &:first-child {
              width: 28%; }
            &:last-child {
              width: 70%; }
            .answers {
              position: relative;
              display: flex;
              flex-direction: column;
              margin-top: 0;
              margin-bottom: 0;
              .property {
                position: relative;
                display: flex;
                align-items: center;
                height: 55px;
                &:not(:last-child) {
                  margin-bottom: 15px; } }
              &_item {
                position: relative;
                &:not(:last-child) {
                  margin-bottom: 15px; }
                &.green {
                  .answer {
                    color: #aed137;
                    border: 2px solid #aed137; } }
                &.yellow {
                  .answer {
                    color: #fcb814;
                    border: 2px solid #fcb814; } }
                &.blue {
                  .answer {
                    color: #4475a4;
                    border: 2px solid #4475a4; } }
                &.red {
                  .answer {
                    color: #cf2228;
                    border: 2px solid #cf2228; } }
                &.violet {
                  .answer {
                    color: #937bb2;
                    border: 2px solid #937bb2; } } }
              .answer {
                position: relative;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #99BFDE;
                text-align: center;
                text-transform: uppercase;
                background-color: #ffffff;
                border: 2px solid #005EAD;
                box-shadow: none;
                padding: 10px;
                @media screen and (max-width: 550px) {
                  font-size: 10px;
                  line-height: 13px; }
                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8; }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B; } } } }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } } } }
        .questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .question_el {
            width: 32%;
            height: 50px;
            margin-bottom: 24px;
            &:nth-child(1) {
              order: 1; }
            &:nth-child(2) {
              order: 3; }
            &:nth-child(3) {
              order: 2; }
            &:nth-child(4) {
              width: 48%;
              order: 6; }
            &:nth-child(5) {
              width: 48%;
              order: 4; }
            &:nth-child(6) {
              order: 5; }
            &.hidden {
              cursor: default;
              .ddcontainersource {
                font-size: 0;
                background-color: #E6EFF7;
                box-shadow: none;
                border: none; } }
            .ddcontainer {
              width: 100%; } }
          .ddcontainersource {
            width: 100%;
            height: 50px;
            padding: 5px 10px;
            display: flex!important;
            justify-content: center;
            align-items: center;
            border: 1px solid #039cdd;
            border-radius: 10px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            cursor: pointer; }
          .ddcontainerghost {
            width: 410px;
            height: 50px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #039cdd;
            border-radius: 10px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            @include for-tablet {
              width: 340px; }
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center; } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 14px;
          line-height: 16px; } }
      //Стили мобильной версии
      .test_mobile {
        .flex {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
          &_el {
            width: 100%;
            max-width: 300px; }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } }
            .img {
              width: 100%;
              max-width: 300px;
              height: auto; } } }
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          box-sizing: border-box;
          .property {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            margin-bottom: 65px;
            padding-left: 80px;
            padding-right: 70px;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #ffffff;
            text-transform: uppercase;
            background-color: #005EAD;
            border-radius: 10px;
            &_img {
              position: absolute;
              top: -15px;
              left: 7px; }
            &_arrow, &_plus {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0); }
            &_arrow {
              bottom: -45px; }
            &_plus {
              font-weight: normal;
              font-size: 30px;
              line-height: 45px;
              color: #005EAD;
              bottom: -174px; } }
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 55px;
            margin-bottom: 60px;
            padding: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #99BFDE;
            background-color: #ffffff;
            border: 2px solid #99BFDE;
            border-radius: 10px;
            text-align: center;
            text-transform: uppercase;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } }
          &_number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border: 1px solid #4C8EC5;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            color: #3E373C;
            background-color: #ffffff;
            border-radius: 50%;
            &.selected {
              color: #4C8EC5;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 48px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%; }
            .questions {
              display: flex;
              flex-direction: column;
              &_item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                font-weight: bold;
                color: #005EAD;
                text-transform: uppercase;
                border: 1px solid #99BFDE;
                border-radius: 10px;
                box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
                margin-bottom: 20px;
                &:nth-child(1) {
                  order: 1; }
                &:nth-child(2) {
                  order: 4; }
                &:nth-child(3) {
                  order: 2; }
                &:nth-child(4) {
                  order: 5; }
                &:nth-child(5) {
                  order: 3; }
                &:nth-child(6) {
                  order: 6; }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; } } } }
          &_text {
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 10px; } } } }
  .step16, .step17 {
    .imgCenter {
      margin-bottom: 30px; } }
  .step17 {
    .note {
      font-size: 12px;
      line-height: 16px;
      color: #5e6773; } }
  .step21 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .flex {
          display: flex;
          height: 500px;
          margin-top: 48px;
          margin-bottom: 24px;
          justify-content: center;
          align-items: center;
          background: url("../../../image/lessons/lesson16/step21-1.png") no-repeat center;
          &_el {
            width: 178px;
            margin-top: 140px;
            .answers {
              position: relative;
              display: flex;
              flex-direction: column;
              margin-top: 0;
              margin-bottom: 0;
              .property {
                position: relative;
                display: flex;
                align-items: center;
                height: 55px;
                &:not(:last-child) {
                  margin-bottom: 15px; } }
              &_item {
                position: relative;
                &:not(:last-child) {
                  margin-bottom: 44px; }
                &.green {
                  .answer {
                    color: #aed137;
                    border: 2px solid #aed137; } }
                &.yellow {
                  .answer {
                    color: #fcb814;
                    border: 2px solid #fcb814; } }
                &.blue {
                  .answer {
                    color: #4475a4;
                    border: 2px solid #4475a4; } }
                &.red {
                  .answer {
                    color: #cf2228;
                    border: 2px solid #cf2228; } }
                &.violet {
                  .answer {
                    color: #937bb2;
                    border: 2px solid #937bb2; } } }
              .answer {
                position: relative;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 36px;
                font-weight: bold;
                font-size: 15px;
                line-height: 22px;
                color: #99BFDE;
                text-align: center;
                text-transform: uppercase;
                background-color: #ffffff;
                border: 2px solid #005EAD;
                box-shadow: none;
                padding: 10px;
                @media screen and (max-width: 550px) {
                  font-size: 10px;
                  line-height: 13px; }
                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8; }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B; } } } }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } } } }
        .questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .question_el {
            width: 32%;
            height: 50px;
            margin-bottom: 24px;
            &:nth-child(1) {
              order: 1; }
            &:nth-child(2) {
              order: 3; }
            &:nth-child(3) {
              order: 2; }
            &.hidden {
              cursor: default;
              .ddcontainersource {
                font-size: 0;
                background-color: #E6EFF7;
                box-shadow: none;
                border: none; } }
            .ddcontainer {
              width: 100%; } }
          .ddcontainersource {
            width: 100%;
            height: 40px;
            padding: 5px 10px;
            display: flex!important;
            justify-content: center;
            align-items: center;
            border: 1px solid #039cdd;
            border-radius: 10px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            cursor: pointer; }
          .ddcontainerghost {
            width: 275px;
            height: 40px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #039cdd;
            border-radius: 10px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            @include for-tablet {
              width: 340px; }
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center; } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 14px;
          line-height: 16px; } }
      //Стили мобильной версии
      .test_mobile {
        .flex {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
          &_el {
            width: 100%;
            max-width: 300px; }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } }
            .img {
              width: 100%;
              max-width: 300px;
              height: auto; } } }
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          box-sizing: border-box;
          .property {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            margin-bottom: 65px;
            padding-left: 80px;
            padding-right: 70px;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #ffffff;
            text-transform: uppercase;
            background-color: #005EAD;
            border-radius: 10px;
            &_img {
              position: absolute;
              top: -15px;
              left: 7px; }
            &_arrow, &_plus {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0); }
            &_arrow {
              bottom: -45px; }
            &_plus {
              font-weight: normal;
              font-size: 30px;
              line-height: 45px;
              color: #005EAD;
              bottom: -174px; } }
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 55px;
            margin-bottom: 60px;
            padding: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #99BFDE;
            background-color: #ffffff;
            border: 2px solid #99BFDE;
            border-radius: 10px;
            text-align: center;
            text-transform: uppercase;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } }
          &_number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border: 1px solid #4C8EC5;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            color: #3E373C;
            background-color: #ffffff;
            border-radius: 50%;
            &.selected {
              color: #4C8EC5;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 48px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%; }
            .questions {
              display: flex;
              flex-direction: column;
              &_item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                font-weight: bold;
                color: #005EAD;
                text-transform: uppercase;
                border: 1px solid #99BFDE;
                border-radius: 10px;
                box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
                margin-bottom: 20px;
                &:nth-child(1) {
                  order: 1; }
                &:nth-child(2) {
                  order: 4; }
                &:nth-child(3) {
                  order: 2; }
                &:nth-child(4) {
                  order: 5; }
                &:nth-child(5) {
                  order: 3; }
                &:nth-child(6) {
                  order: 6; }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; } } } }
          &_text {
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 10px; } } } }
  .step25 {
    .block {
      width: 300px;
      margin: 20px auto;
      text-align: center; } }
  .step26 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .flex {
          display: flex;
          flex-direction: column;
          height: 650px;
          margin-top: 48px;
          margin-bottom: 24px;
          align-items: center;
          background: url("../../../image/lessons/lesson16/step26-1.png") no-repeat center top;
          &_el {
            width: 717px;
            &:first-child {
              margin-bottom: 95px; }
            .answers {
              position: relative;
              display: flex;
              justify-content: space-between;
              margin-top: 45px;
              margin-bottom: 0;
              &_column {
                &.left {
                  width: 288px;
                  padding-left: 95px; }
                &.right {
                  width: 409px; } }
              .property {
                position: relative;
                display: flex;
                align-items: center;
                height: 55px;
                &:not(:last-child) {
                  margin-bottom: 15px; } }
              &_item {
                position: relative;
                &:not(:last-child) {
                  margin-bottom: 4px; }
                &:nth-child(2) {
                  margin-bottom: 70px; }
                &.dark-blue {
                  .answer {
                    color: #134e79;
                    border: 2px solid #134e79; } }
                &.dark-blueBg {
                  .answer {
                    color: #ffffff;
                    background: #134e79;
                    border: 2px solid #134e79; } }
                &.blue {
                  .answer {
                    color: #3a89c3;
                    border: 2px solid #3a89c3; } }
                &.blueBg {
                  .answer {
                    color: #ffffff;
                    background: #3a89c3;
                    border: 2px solid #3a89c3; } }
                &.dark-orange {
                  .answer {
                    color: #ee681f;
                    border: 2px solid #ee681f; } }
                &.dark-orangeBg {
                  .answer {
                    color: #ffffff;
                    background: #ee681f;
                    border: 2px solid #ee681f; } }
                &.orange {
                  .answer {
                    color: #f29514;
                    border: 2px solid #f29514; } }
                &.orangeBg {
                  .answer {
                    color: #ffffff;
                    background: #f29514;
                    border: 2px solid #f29514; } }
                &.yellow {
                  .answer {
                    color: #f0b441;
                    border: 2px solid #f0b441; } }
                &.yellowBg {
                  .answer {
                    color: #ffffff;
                    background: #f0b441;
                    border: 2px solid #f0b441; } } }
              .answer {
                position: relative;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 36px;
                font-weight: bold;
                font-size: 13px;
                line-height: 22px;
                color: #99BFDE;
                text-align: center;
                text-transform: uppercase;
                background-color: #ffffff;
                border: 2px solid #005EAD;
                box-shadow: none;
                padding: 10px;
                @media screen and (max-width: 550px) {
                  font-size: 10px;
                  line-height: 13px; }
                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8; }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B; } } } }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } } } }
        .questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .question_el {
            height: 50px;
            margin-bottom: 24px;
            &:nth-child(-n + 5) {
              width: 18%;
              margin-bottom: 40px;
              .ddcontainerghost {
                width: 130px; } }
            &:nth-child(6),
            &:nth-child(7) {
              width: 48%;
              margin-bottom: 10px;
              .ddcontainerghost {
                width: 344px; } }
            &:nth-child(n + 8) {
              width: 31%;
              .ddcontainerghost {
                width: 222px; } }
            //&:nth-child(1)
            //  order: 1
            //&:nth-child(2)
            //  order: 3
            //&:nth-child(3)
            //  order: 2
            &.hidden {
              cursor: default;
              .ddcontainersource {
                font-size: 0;
                background-color: #E6EFF7;
                box-shadow: none;
                border: none; } }
            .ddcontainer {
              width: 100%; } }
          .ddcontainersource {
            width: 100%;
            height: 40px;
            padding: 5px 10px;
            display: flex!important;
            justify-content: center;
            align-items: center;
            border: 1px solid #039cdd;
            border-radius: 10px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            cursor: pointer; }
          .ddcontainerghost {
            width: 275px;
            height: 40px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #039cdd;
            border-radius: 10px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            @include for-tablet {
              width: 340px; }
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center; } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 14px;
          line-height: 16px; } }
      //Стили мобильной версии
      .test_mobile {
        .flex {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
          &_el {
            width: 100%;
            max-width: 300px; }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } }
            .img {
              width: 100%;
              max-width: 300px;
              height: auto; } } }
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          box-sizing: border-box;
          .property {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            margin-bottom: 65px;
            padding-left: 80px;
            padding-right: 70px;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #ffffff;
            text-transform: uppercase;
            background-color: #005EAD;
            border-radius: 10px;
            &_img {
              position: absolute;
              top: -15px;
              left: 7px; }
            &_arrow, &_plus {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0); }
            &_arrow {
              bottom: -45px; }
            &_plus {
              font-weight: normal;
              font-size: 30px;
              line-height: 45px;
              color: #005EAD;
              bottom: -174px; } }
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 55px;
            margin-bottom: 60px;
            padding: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #99BFDE;
            background-color: #ffffff;
            border: 2px solid #99BFDE;
            border-radius: 10px;
            text-align: center;
            text-transform: uppercase;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } }
          &_number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border: 1px solid #4C8EC5;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            color: #3E373C;
            background-color: #ffffff;
            border-radius: 50%;
            &.selected {
              color: #4C8EC5;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 48px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%; }
            .questions {
              display: flex;
              flex-direction: column;
              &_item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                font-weight: bold;
                color: #005EAD;
                text-transform: uppercase;
                border: 1px solid #99BFDE;
                border-radius: 10px;
                box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
                margin-bottom: 20px;
                &:nth-child(1) {
                  order: 1; }
                &:nth-child(2) {
                  order: 4; }
                &:nth-child(3) {
                  order: 2; }
                &:nth-child(4) {
                  order: 5; }
                &:nth-child(5) {
                  order: 3; }
                &:nth-child(6) {
                  order: 6; }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; } } } }
          &_text {
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 10px; } } } } }










