.VideoBlock {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  border: 2px solid #99BFDE;
  border-radius: 16px;
  margin-top: 48px;
  @media screen and (max-width: 1023px) {
    margin-top: 40px; }
  @media screen and (max-width: 767px) {
    margin-top: 20px; }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }
