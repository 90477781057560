.studyPanels {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  @media screen and (max-width: 1366px) {
    padding-left: 50px;
    padding-right: 35px; }
  @media screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px; }
  @media screen and (max-width: 500px) {
    padding-left: 0;
    padding-right: 0; } }
.top-banner {
  display: block;
  width: 100%;
  margin-bottom: 48px;
  &_img {
    width: 100%; } }
.popupText {
  font-size: 18px;
  line-height: 24px;
  color: #2c3032;
  border: 8px dashed #efefef;
  border-radius: 24px;
  padding: 24px; }
