.login__checkbox_rules {
    position: relative;
    color: #FFF;
}

.checkbox1 {
    position: absolute;
    opacity: 0;
    width: 10%;
    height: 30px;

    &.error+label {
        color: red;
    }

    &.error+label::before {
        border: 2px solid red;
    }

    &:checked+label::before {
        border-color: #001299;
        background-color: #001299;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }

    &__label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #FEFAEF;
        margin-left: 12px;
        display: inline;
        align-items: center;
        width: 100%;
        text-align: left;

        a {
            color: inherit;
            cursor: pointer;
        }
        
        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            flex-grow: 0;
            border:  2px solid #FFF;
            border-radius: 5px;
            margin-right: 5px;
        }
    }
}

.login {

    &__rules {
        width: 100%;

        &--underline {
            text-decoration: underline;
        }
    }

    &__checkbox_rules {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: 12px 0;

        &:nth-of-type(3) {
            margin-left: 8px;
        }
    }
}

@media screen and (max-width:768px) {
    .checkbox1 {

        &__label {
            font-size: 10px;
            line-height: 12px;
        }
    }
}