.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: .5s;
  right: auto;
  border: 0 none;
  @media screen and (max-width: 767px) {
    font-size: 12px;
    font-weight: normal; }
  &_center {
    position: relative;
    left: 50%;
    transform: translate(-50%); }
  &_right {
    position: relative;
    left: 100%;
    transform: translate(-100%); }
  &_blue {
    color: #fff;
    background: #039cdd;
    border-radius: 50px;
    height: 50px;
    max-width: 250px;
    @media screen and (max-width: 767px) {
      width: 135px;
      height: 30px; }
    &:hover {
      color: #039cdd;
      background: #fff;
      border: 1px solid #039cdd; } }
  &_white {
    color: #039cdd;
    background: #fff;
    border-radius: 50px;
    height: 50px;
    max-width: 250px;
    &:hover {
      color: #fff;
      background: #039cdd;
      border: 1px solid #fff; } }
  &_whiteBlueBorder {
    color: #039cdd;
    background: #fff;
    border-radius: 50px;
    height: 50px;
    max-width: 250px;
    border: 1px solid #039cdd;
    @media screen and (max-width: 767px) {
      width: 135px;
      height: 30px; }
    &:hover {
      color: #fff;
      background: #039cdd;
      border: 1px solid #fff; } }
  &_gift {
    max-width: 150px;
    margin-bottom: 5px; }


  &.dn {
    position: relative;
    transform: translate(500px); }
  &:disabled {
    pointer-events: none;
    cursor: default;
    opacity: .5; } }


