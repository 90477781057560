@import "../mixin";
.GiftsWhiskas {
  @media screen and (max-width: 1366px) {
    padding: 50px; }
  @include for-phone {
    padding: 20px; }
  &_head {
    margin-bottom: 48px;
    color: #039cdd;
    font-size: 32px;
    font-weight: 300; }
  &_box {
    display: flex;
    @include for-phone {
      flex-direction: column; }
    &_head {
      margin-bottom: 20px;
      color: #2c3032;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase; }
    &_el {
      &.video {
        flex-grow: 1;
        margin-right: 48px;
        @include for-phone {
          margin-right: 0; } }
      &.certificate {
        flex-shrink: 0;
        width: 315px;
        @include for-phone {
          width: 100%; } }
      .flex {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        &_el {
          width: 33.25%;
          margin-bottom: 20px;
          padding: 0 15px;
          @media screen and (max-width: 1366px) {
            width: 50%; }
          @include for-tablet {
            width: 100%; }
          &_wrp {
            .VideoBlock {
              margin-top: 0;
              margin-bottom: 15px; } }
          &_head {
            min-height: 63px;
            margin-bottom: 10px;
            color: #333;
            font-size: 15px;
            font-weight: 700; }
          &_text {
            color: #333;
            font-size: 15px; } } } }
    &_img {
      max-width: 100%;
      margin-bottom: 20px; } } }

