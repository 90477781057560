.login {

    &__button {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        button {
            border: none;
            outline: none;
            border-radius: 25px;
            border: 2px solid #FEFAEF;
            background-color: transparent;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #FEFAEF;
            padding: 18px 50px;
            cursor: pointer;
        }
    }
}