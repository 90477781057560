.CourseStat {
  width: calc(50% - 10px);
  padding: 17px 25px 10px;
  background: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  @media screen and (max-width: 1600px) {
    width: calc(66% - 10px); }
  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-right: 15px; }
  @media screen and (max-width: 767px) {
    margin-right: 0;
    padding-bottom: 30px; }
  @media screen and (max-width: 500px) {
    border-radius: 0; }
  &.Blue {
    background: #FEFAEF;
    border: 2px solid #001299;
    border-radius: 26px; }
  &.pyaterochka,
  &.perekrestok {
    background: #ffffff;
    border: 2px solid #ffffff; }

  &Full {
    width: 100%; }
  .head {
    width: 100%;
    color: #99BFDE;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 18px; }
    &.Blue {
      color: #001299;
      text-transform: uppercase; }
    &.pyaterochka {
      color: #333333; }
    &.perekrestok {
      color: #333333; } }
  .icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid #005EAD;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
    flex-shrink: 0;
    &.pyaterochka,
    &.perekrestok {
      border-color: #C6C6C6; } }
  .balls,
  .status {
    width: calc(50% - 24px);
    margin-right: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #005EAD;
    font-size: 16px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 15px; }
    .balls__box {
      @media screen and (max-width: 767px) {
        display: flex;
        flex-wrap: wrap; }
      &.Blue {
        color: #001299;
        text-transform: uppercase; }
      &.pyaterochka {
        color: #333333;
        .pyaterochka {
          color: #A5338A; } }
      &.perekrestok {
        color: #333333;
        .perekrestok {
          color: #A5338A; } } } }
  .status {
    margin-right: 0; }
  .progress {
    width: 100%;
    margin-right: 48px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #005EAD;
    padding-right: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 0;
      margin-right: 0; }
    &.progressHulf {
      width: calc(50% - 24px);
      &:not(:last-child) {
        margin-bottom: 20px; }
      @media screen and (max-width: 767px) {
        width: 100%; } }
    &:last-child {
      margin-right: 0; }
    .desc {
      width: 100%;
      position: relative;
      .line {
        margin-top: 5px;
        width: 100%;
        height: 5px;
        background: #C4C4C4;
        border-radius: 5px;
        position: relative;
        .lineProgress {
          position: absolute;
          top: 0;
          left: 0;
          height: 5px;
          background: #005EAD;
          border-radius: 5px;
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(50%, -50%);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #C4C4C4;
            border: 4px solid #005EAD;
            @media screen and (max-width: 767px) {
              display: none; } }
          //&.pyaterochka,
          //&.perekrestok
          //  background: #A5338A
          //  &:after
          //    background: #A5338A
 } }          //    border: 4px solid #A5338A
      .textProgress {
        display: inline-block;
        font-size: 28px;
        color: #005EAD;
        font-weight: 500;
        position: relative;
        transform: translate(-50%);
        margin-top: 5px;
        @media screen and (max-width: 767px) {
          display: none; }
        &--mobile {
          display: none;
          @media screen and (max-width: 767px) {
            display: inline-block;
            font-size: 16px;
            line-height: 21px;
            font-weight: bold;
            position: initial;
            transform: initial; } } }
      &.Blue {
        color: #001299;
        text-transform: uppercase; }
      &.pyaterochka,
      &.perekrestok {
        color: #333333;
        .line {
          .lineProgress {
            background: #A5338A;
            &:after {
              background: #A5338A;
              border: 4px solid #A5338A; } } }
        .textProgress {
          color: #A5338A; } } } } }


