@import "mixin";
* {
  box-sizing: border-box;
  outline: none; }
.wrp {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  overflow-x: hidden;

  &.Blue {
    background: url("../image/main_bg.png") center top no-repeat;
    background-size: cover;

    &.short_bg {
      background: url("../image/short_bg.png") center top no-repeat;
      background-size: cover; } }

  &.pyaterochka {
    background: #FFF8E9; }
  &.perekrestok {
    background: #FFF8E9; }
  .content {
    width: 100%;
    height: auto;
    //min-height: 100vh
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    &_withLeftSideBar {
      margin-left: 218px;
      @media screen and (max-width: 1366px) {
        margin-left: 0; }
      //&.blue
 }      //  margin-left: 267px
    &_wrp {
      padding: 50px;
      min-height: calc(100vh - 55px);
      //background: #E6EFF7
      @media screen and (max-width: 1366px) {
        padding: 0; }
      @include for-phone {
        min-height: calc(100vh - 161px); }
      &Guru {
        min-height: calc(100vh - 181px); } }
    &_lesson,
    &_test {
      padding: 50px;
      min-height: calc(100vh - 55px);
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin: -50px 234px -50px -50px;
      @media screen and (max-width: 1366px) {
        margin: 0; }
      @include for-phone {
        min-height: calc(100vh - 161px);
        padding: 20px;
        padding-top: 60px; }
      .step,
      .test {
        width: 100%; }
      &_step {
        margin: -50px;
        @include for-phone {
          margin: 0;
          padding: 0; } } }
    &_test.finalTest {
      margin-right: -50px; }
    &_lesson {
      &.les27 {
        background-image: url("../image/lessons/lesson27/step1-2.png");
        background-repeat: no-repeat;
        background-size: cover; }
      /*---------- Стили для урока 17 шаг 1 ----------*/
      .imgBottom-box {
        position: relative;
        height: 288px;
        .imgBottom {
          position: absolute;
          bottom: -50px;
          left: -50px; } }
      /*---------- Закончились стили для урока 17 шаг 1 ----------*/ }
    &_head {
      font-size: 32px;
      color: #005EAD;
      margin-bottom: 15px;
      margin-left: 24px;
      @media screen and (max-width: 1366px) {
        margin-left: 50px;
        margin-top: 20px; }
      @include for-phone {
        margin-left: 0;
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 25px;
        line-height: 30px; }
      &.Blue {
        color: #001299;
        text-transform: uppercase; }
      &.pyaterochka {
        color: #E52322; }
      &.perekrestok {
        color: #24582E; } }
    .test {
      @include for-phone {
        display: flex;
        flex-direction: column; }
      .subheader {
        @include for-phone;
        display: none; }
      .TextBlock {
        @include for-phone {
          order: 2; } }
      .answers {
        @include for-phone {
          order: 3; } }
      .QuestionCorrect {
        @include for-phone {
          order: 1; } }
      .MainButton {
        @include for-phone {
          order: 4; } }
      .InfoBlock {
        display: none;
        @include for-phone {
          display: flex;
          margin-bottom: 20px; }
        &.hidden {
          @include for-phone {
            display: none; } } } } }
  .noMatch {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center; } }
