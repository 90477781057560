.radio {

    &-group {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 30px;
        flex-wrap: wrap;

        &__label {
            font-size: 18px;
            line-height: 20px;
            color: #FFF;
            text-transform: uppercase;
            text-align: left;
            width: 100%;
            margin-bottom: 20px;
        }

        &__item {
            margin-right: 30px;
            position: relative;
        }

        &__error {
            position: absolute;
            top: calc(100% + 12px);
            left: 0;
            text-align: center;
            color: red;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 20px;
        left: 0;

        &:checked+label::before {
            background: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10.5' cy='10.5' r='9.5' stroke='%23FEFAEF' stroke-width='2'/%3E%3Ccircle cx='10.5' cy='10.5' r='4.5' fill='%23FEFAEF'/%3E%3C/svg%3E") center center no-repeat;
        }
    }

    &__label {
        font-size: 18px;
        line-height: 20px;
        color: #FFF;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            width: 17px;
            height: 17px;
            margin-right: 5px;
            background-color: #001299;
            border-radius: 50%;
            border: 2px solid #FFF;
        }
    }
}

@media screen and (max-width:768px) {
    .radio {

        &-group {
    
            &__label {
                font-size: 14px;
                line-height: 14px;
            }

            &__item {
                margin-right: 20px;
            }
        }
    

    
        &__label {
            font-size: 14px;
            line-height: 14px;
        }
    }
}