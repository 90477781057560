.StatisticsBlock {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f0f5fb;
  margin: 40px 0;
  padding: 28px 28px 26px 204px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #657993;
  white-space: pre-line;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    padding: 28px 28px 26px 204px; }
  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    padding: 20px; }
  .img {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translate(0, -50%);
    width: 137px;
    height: auto;
    @media screen and (max-width: 550px) {
      position: relative;
      top: auto;
      left: auto;
      transform: translate(0, 0);
      margin-bottom: 20px; } } }

