.preloader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  opacity: 1;
  visibility: visible;
  z-index: 1001;

  &__view {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 13px solid rgba(0, 18, 153, 0.2);
    border-right: 13px solid rgba(0, 18, 153, 0.2);
    border-bottom: 13px solid rgba(0, 18, 153, 0.2);
    border-left: 13px solid rgba(0, 18, 153, 1);
    transform: translateZ(0);
    animation: load8 1.7s infinite linear;

    &::after {
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }
  }

  &.loaded {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transition: 0.5s;

    .preloader__view {
      display: none;
    }
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}