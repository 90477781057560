@import "../../../Page/mixin";
.step.lesson9 {
  padding-bottom: 48px;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  color: #3E373C;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #039cdd;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #039cdd;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #039cdd;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #039cdd;
      background: #039cdd;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #039cdd; } } }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .textBlue {
    color: #005EAD; }
  .textRed {
    color: #BD4E3B; }
  .infoPopap {
    position: fixed;
    z-index: 222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#345178, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrp {
      background: #fff;
      border-radius: 25px;
      padding: 70px;
      position: relative;
      width: 100%;
      max-width: 640px; }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba(#005EAD, .3);
        transition: .5s; }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: rgba(#005EAD, 1); }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }

    &_scroll {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
      overflow-y: auto; }
    &_iconDiv {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      overflow: hidden;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      &.red {
        color: #BD4E3B; } }
    &_text {
      font-size: 16px;
      color: #3E373C;
      margin-bottom: 30px;
      &_head {
        font-size: 16px;
        color: #005EAD;
        font-weight: bold;
        display: block; } }
    &_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      &ImgDiv {
        height: 96px;
        width: 96px;
        flex-shrink: 0;
        border: 2px solid #005EAD;
        border-radius: 50%;
        background: #fff;
        margin-right: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
        &.chain {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 100%;
            width: 3px;
            background: #005EAD; } } }
      &:last-child {
        .chain {
          &:before {
            display: none; } } }
      .infoPopap_text {
        margin-bottom: 0; } }


    &_wrp.danger {
      background: #F9EEEC;
      .infoPopap_iconDiv {
        background: #F9EEEC; }
      .infoPopap_close {
        &:before,
        &:after {
          background: rgba(#BD4E3B, .3); }
        &:hover {
          &:before,
          &:after {
            background: rgba(#BD4E3B, 1); } } }
      .infoPopap_text {
        li:before {
          background: #BD4E3B; } }
      .infoPopap_head {
        color: #BD4E3B; } }





    &_imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      max-width: 100%;
      img {
        margin-top: 45px;
        max-width: 100%; } }
    .btn {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      font-size: 14px;
      width: 160px; } }

  .ul {
    .liHead {
      font-size: 18px;
      color: #005EAD;
      font-weight: bold;
      text-transform: uppercase;
      margin: 40px 0 35px; }
    .li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 35px;
      @media screen and (max-width: 499px) {
        flex-direction: column; }
      .number {
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        border: 1px solid #039cdd;
        border-radius: 50%;
        margin-right: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #039cdd;
        @media screen and (max-width: 499px) {
          margin-bottom: 10px; } }
      .text {
        font-size: 18px;
        color: #3E373C; } } }

  .infoPopap {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    background: rgba(#345178, .8);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 112px;
    &_wrp {
      width: 100%;
      max-width: 640px;
      padding: 70px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      .popap_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99BFDE;
        cursor: pointer;
        transition: .5s;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s; }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover {
          &:before,
          &:after {
            background: #005EAD; }
          &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(45deg); } } }
      .image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        &Bg {
          width: 100%;
          height: 100%;
          background: #E6EFF7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            border-radius: 50%; } } }
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line; }
      .subHead {
        color: #1F72B7;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        white-space: pre-line;
        margin-bottom: 30px; }
      .slider {
        border: 2px solid #CCDFEF;
        border-radius: 10px;
        position: relative;
        padding: 25px;
        margin-bottom: 50px;
        .slide {
          color: #4C8EC5;
          font-size: 16px;
          line-height: 24px; }
        .slick-arrow {
          bottom: 0; } }
      .btn {
        position: relative;
        left: 50%;
        transform: translate(-50%); } } }

  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .flex_head {
      font-weight: bold;
      font-size: 22px;
      color: #005EAD; }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 50px;
      &Img {
        height: 240px;
        width: 240px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 25px;
        flex-shrink: 0; } }
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333; }
    .text {
      font-size: 18px;
      line-height: 26px;
      color: #3E373C;
      margin-top: 20px; }
    .lessonSteps {
      margin-top: 25px;
      &_el {
        margin-bottom: 20px;
        padding: 15px 20px;
        border: 1px solid #acdff5;
        border-radius: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #2c3032;
        box-shadow: 0 2px 3px 0 rgba(74, 74, 74, 0.2);
        &:last-child {
          margin-bottom: 0; } } } }
  .step2 {
    .TextBlock {
      @include for-phone {
        margin-bottom: 10px; } }
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      .flex_el {
        img {
          @media screen and (max-width: 499px) {
            width: 100%;
            height: auto; } } } } }
  .step3 {
    img {
      width: 100%;
      height: auto; } }
  .step4 {
    img {
      width: 100%;
      height: auto; } }
  .step5 {
    img {
      width: 100%;
      height: auto; } }
  .step6 {
    .InfoBlock.hidden {
      @include for-tablet {
        display: none; } }
    .flex {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      @include for-phone {
        flex-direction: column;
        margin-bottom: 0; }
      &_el {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 67px;
        width: 50%;
        margin-right: 24px;
        color: #acdff5;
        background: #fff;
        border: 1px solid #acdff5;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        transition: .5s;
        @include for-tablet {
          height: 120px;
          border: 2px solid #acdff5;
          color: #acdff5;
          font-weight: bold; }
        @include for-phone {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          font-size: 20px;
          line-height: 24px; }
        &.active,
        &:hover {
          background: #039cdd;
          box-shadow: none;
          border: 1px solid #039cdd;
          color: #fff;
          font-weight: bold; }
        &:last-child {
          margin-right: 0; } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv {
      margin-top: 24px;
      border: 1px solid #acdff5;
      border-radius: 10px;
      padding: 38px 47px;
      position: relative;
      @include for-tablet {
        padding: 0;
        border: none; }
      &_closed {
        display: none;
        @include for-tablet {
          position: absolute;
          bottom: -40px;
          right: 50%;
          height: 35px;
          width: 35px;
          transform: translateX(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #99BFDE;
          background-color: #005EAD;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            background: #ffffff;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }
      &:before {
        content: '';
        position: absolute;
        left: 24.2%;
        top: -1px;
        transform: translate(-50%, -50%) rotate(-45deg);
        height: 12px;
        width: 12px;
        background: #fff;
        border-top: 1px solid #acdff5;
        border-right: 1px solid #acdff5;
        transition: .5s;
        @include for-tablet {
          display: none; } }
      &.imgDiv--2 {
        &:before {
          left: 76%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 3px 0 60px; } }

      &_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        img {
          margin: 0 38px 0 90px;
          flex-shrink: 0;
          @include for-phone {
            display: none; } } } }

    .TextBlock {
      line-height: 26px;
      @include for-tablet {
        font-size: 16px;
        line-height: 24px; }
      &.hidden {
        @include for-tablet {
          display: none; } }

      .imgDiv_flex {
        margin: 0 0 32px; } }
    .imgPhone {
      display: none;
      @include for-phone {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0 30px; } }
    img {
      display: block;
      margin: 0 auto; } }
  .step7 {
    position: relative;
    @include for-tablet {
      padding-bottom: 50px; }
    .PopupInStep {
      padding-top: 30px;
      @include for-phone {
        padding-top: 0; }
      .popup {
        padding-top: 10px;
        padding-bottom: 30px;
        .popup_text {
          margin-bottom: 0; }
        .popup_imgContent {
          margin-bottom: 0; } } }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 60px;
      @include for-phone {
        margin-top: 0; }
      .left {
        flex-shrink: 0;
        margin-right: 48px;
        @include for-phone {
          display: none; } }
      .right {
        @include for-phone {
          width: 100%; }
        &_row {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 20px;
          &_img {
            @include for-phone {
              display: none; } }
          img {
            margin-left: 48px;
            margin-right: 20px;
            @include for-phone {
              margin: 0; } }
          &:nth-child(2) {
            .row_block {
              &:before {
                left: auto;
                right: -1px;
                transform: translate(50%, -50%) rotate(135deg); } } }
          .row_img_mobile {
            display: none;
            @include for-phone {
              display: flex;
              justify-content: center; }
            &_dog {
              @include for-phone {
                width: 150px;
                height: auto; } }
            &_eat {
              @include for-phone {
                width: 75px;
                height: auto; } } }
          .row_block {
            border: 1px solid #acdff5;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 33px 38px 4px;
            width: 100%;
            position: relative;
            @include for-phone {
              padding: 20px 20px 4px; }
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: -1px;
              width: 15px;
              height: 15px;
              border-left: 1px solid #acdff5;
              border-top: 1px solid #acdff5;
              background: #fff;
              transform: translate(-50%, -50%) rotate(-45deg);
              @include for-phone {
                display: none; } } } } } }
    .textDown {
      width: 100%;
      text-align: center;
      color: #60B6FF;
      font-size: 18px;
      font-weight: bold;
      margin-top: 30px; }
    .btn_shpora {
      position: absolute;
      z-index: 2;
      left: 50%;
      bottom: -48px;
      transform: translate(-50%, 100%);
      background: #fff;
      color: #60B6FF;
      border: 1px solid #60B6FF;
      @include for-tablet {
        bottom: 20px; }
      &:hover {
        background: #60B6FF;
        color: #fff; } } }
  .step8 {
    position: relative;
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 36px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 33%;
        margin-right: 24px;
        height: 87px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #039cdd;
        border: 1px solid #acdff5;
        box-sizing: border-box;
        box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
        border-radius: 10px;
        transition: .5s;
        cursor: pointer;
        @include for-phone {
          width: 100%;
          margin-bottom: 20px; }
        &:hover,
        &.active {
          background: #E6EFF7; }
        &.active {
          &.inCorrect {
            background: #F9EEEC;
            border: 1px solid #BD4E3B;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            color: #BD4E3B; } }
        &:last-child {
          margin-right: 0;
          @include for-phone {
            margin-bottom: 0; } } } }
    .imgCenter {
      margin: 48px 0; }
    .info {
      padding: 44px 48px;
      border-radius: 10px;
      background: #005EAD;
      color: #fff;
      &_head {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px; }
      &Text {
        font-size: 18px; }
      &.error {
        background: #F9EEEC;
        .info_head {
          color: #BD4E3B; }
        .infoText {
          color: #3E373C; } } }
    .btn {
      position: absolute;
      z-index: 2;
      bottom: -48px;
      right: 0;
      transform: translate(0, 100%); } }
  .step9 {
    .ul .li .number {
      height: auto;
      width: auto;
      font-size: 18px;
      color: #039cdd;
      border: 0 none; }
    .textBlue {
      white-space: nowrap; } }
  .step10 {
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-left: -10px;
      margin-right: -10px;
      padding-bottom: 160px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        width: 33.25%;
        padding: 10px;
        @include for-phone {
          width: 100%; }
        &:not(:last-child) {
          @include for-phone {
            margin-bottom: 20px; } } }
      &_wrp {
        position: relative;
        padding: 40px 20px;
        text-align: center;
        border: 1px solid #acdff5;
        border-radius: 10px;
        @include for-phone {
          padding: 40px 20px 0; } }
      &_count {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border: 1px solid #acdff5;
        background-color: #ffffff;
        border-radius: 50%;
        font-size: 18px;
        font-weight: bold;
        color: #039cdd; }
      img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0); } } }
  .step11 {
    img {
      width: 100%;
      height: auto; } }
  .step12 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 5px 5px 48px;
      background: #f0f5fb;
      border-radius: 10px;
      margin-bottom: 20px;
      @include for-tablet {
        flex-direction: column;
        padding: 40px 20px 20px; }
      .li {
        align-items: center; }
      img {
        flex-shrink: 0;
        margin-left: 70px;
        @include for-tablet {
          margin-left: 0; }
        @media screen and (max-width: 499px) {
          width: 90%;
          height: auto; } } } }
  .step13 {
    img {
      width: 100%;
      height: auto; } }
  .step14 {
    .wrp {
      display: flex;
      justify-content: space-between;
      @include for-tablet {
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 0;
        overflow: visible; }
      img {
        width: 50%;
        height: auto;
        @include for-tablet {
          display: block;
          margin: 0 auto; }
        @include for-phone {
          margin-top: 20px; }
        @media screen and (max-width: 499px) {
          width: 100%; } } }
    .flex {
      display: flex;
      flex-direction: column;
      width: 40%;
      @include for-tablet {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%; }
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 67px;
        width: 100%;
        margin-bottom: 20px;
        color: #acdff5;
        background: #fff;
        border: 1px solid #acdff5;
        box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        transition: .5s;
        @include for-tablet {
          width: calc(50% - 10px);
          height: 120px;
          margin-right: 0;
          margin-bottom: 20px;
          border: 2px solid #005EAD;
          color: #005EAD;
          font-weight: bold; }
        @include for-phone {
          width: 100%;
          margin-right: 0;
          font-size: 20px;
          line-height: 24px; }
        &.active,
        &:hover {
          background: #049bdc;
          box-shadow: none;
          border: 1px solid #049bdc;
          color: #fff;
          font-weight: bold; }
        &:last-child {
          margin-bottom: 0; }
        &:nth-child(2n+1) {
          @include for-tablet {
            margin-right: 10px; }
          @include for-phone {
            margin-right: 0; } }
        &:nth-child(2n) {
          @include for-tablet {
            margin-left: 10px; }
          @include for-phone {
            margin-left: 0; } } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv {
      width: 55%;
      border: 1px solid #acdff5;
      border-radius: 10px;
      padding: 40px 47px;
      position: relative;
      @include for-tablet {
        padding: 0;
        border: none;
        width: 100%; }
      &_closed {
        display: none;
        @include for-tablet {
          display: block;
          position: absolute;
          bottom: -40px;
          right: 50%;
          height: 35px;
          width: 35px;
          transform: translateX(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #99BFDE;
          background-color: #005EAD;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            background: #ffffff;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }

      &:before {
        content: '';
        position: absolute;
        left: -1px;
        top: 5%;
        transform: translate(-50%, -50%) rotate(-135deg);
        height: 12px;
        width: 12px;
        background: #fff;
        border-top: 1px solid #acdff5;
        border-right: 1px solid #acdff5;
        transition: .5s;
        @include for-tablet {
          display: none; } }
      .TextBlock {
        margin-bottom: 10px; }
      & img {
        @include for-phone {
          width: 280px;
          height: 163px; } }
      &.imgDiv--2 {
        & img {
          @include for-phone {
            width: 112px;
            height: 140px; } }
        &:before {
          top: 23%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 17px 0 50px; } }
      &.imgDiv--3 {
        & img {
          @include for-phone {
            width: 157px;
            height: 166px; } }
        &:before {
          top: 37%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 6px 0 0 45px; } }
      &.imgDiv--4 {
        & img {
          @include for-phone {
            width: 190px;
            height: 240px; } }
        &:before {
          top: 50%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 0 0 43px; } }
      &_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        img {
          margin: 0 0 5px 30px;
          flex-shrink: 0;
          @include for-phone {
            display: none; } } } }

    .TextBlock {
      line-height: 26px;
      @include for-tablet {
        margin-bottom: 0; }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgPhone {
      display: none;
      @include for-phone {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0 30px; } } }
  .step15 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 5px 5px 48px;
      background: #f0f5fb;
      border-radius: 10px;
      margin-bottom: 20px;
      @include for-tablet {
        flex-direction: column;
        padding: 40px 20px 20px; }
      .li {
        align-items: center; }
      img {
        flex-shrink: 0;
        margin-left: 70px;
        @include for-tablet {
          margin-left: 0; }
        @media screen and (max-width: 499px) {
          width: 90%;
          height: auto; } } } }
  .step16 {
    .InfoBlock {
      margin-bottom: 20px;
      @include for-tablet {
        display: none; }
      &Tablet {
        display: none;
        @include for-tablet {
          display: flex;
          margin-top: 20px; }
        &.hidden {
          @include for-tablet {
            display: none; } } } }
    .wrp {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      overflow: visible;
      margin-top: 60px;
      @include for-tablet {
        flex-direction: column;
        margin-top: 20px;
        padding-bottom: 0; }
      &_img {
        width: 100%;
        height: auto;
        @include for-tablet {
          display: block;
          width: 50%;
          margin: 0 auto; }
        @include for-phone {
          margin-top: 20px; }
        @media screen and (max-width: 499px) {
          width: 100%; } }
      &_right {
        padding-top: 30px;
        width: 50%;
        @include for-tablet {
          width: 100%; } } }
    .flex {
      display: flex;
      flex-direction: column;
      width: 40%;
      @include for-tablet {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%; }
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 67px;
        width: 100%;
        margin-bottom: 20px;
        color: #acdff5;
        background: #fff;
        border: 1px solid #acdff5;
        box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        transition: .5s;
        @include for-tablet {
          width: calc(50% - 10px);
          height: 120px;
          margin-right: 0;
          margin-bottom: 20px;
          border: 2px solid #005EAD;
          color: #005EAD;
          font-weight: bold; }
        @include for-phone {
          width: 100%;
          margin-right: 0;
          font-size: 20px;
          line-height: 24px; }
        &.active,
        &:hover {
          background: #049bdc;
          box-shadow: none;
          border: 1px solid #049bdc;
          color: #fff;
          font-weight: bold; }
        &:last-child {
          margin-bottom: 0; }
        &:nth-child(2n+1) {
          @include for-tablet {
            margin-right: 10px; }
          @include for-phone {
            margin-right: 0; } }
        &:nth-child(2n) {
          @include for-tablet {
            margin-left: 10px; }
          @include for-phone {
            margin-left: 0; } } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv {
      width: 55%;
      border: 1px solid #acdff5;
      border-radius: 10px;
      padding: 50px 47px;
      position: relative;
      @include for-tablet {
        padding: 0;
        border: none;
        width: 100%; }
      &_closed {
        display: none;
        @include for-tablet {
          display: block;
          position: absolute;
          bottom: -40px;
          right: 50%;
          height: 35px;
          width: 35px;
          transform: translateX(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #99BFDE;
          background-color: #005EAD;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            background: #ffffff;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }

      &:before {
        content: '';
        position: absolute;
        left: -1px;
        top: 5%;
        transform: translate(-50%, -50%) rotate(-135deg);
        height: 12px;
        width: 12px;
        background: #fff;
        border-top: 1px solid #acdff5;
        border-right: 1px solid #acdff5;
        transition: .5s;
        @include for-tablet {
          display: none; } }
      &_img {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        @include for-tablet {
          position: relative;
          transform: translateY(0);
          margin-bottom: 20px; }
        @include for-phone {
          left: 50%;
          transform: translate(-50%, 0); } }
      .TextBlock {
        margin-bottom: 10px; }
      &.imgDiv--2 {
        &:before {
          top: 18%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 17px 0 50px; } }
      &.imgDiv--3 {
        &:before {
          top: 31%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 6px 0 0 45px; } }
      &.imgDiv--4 {
        &:before {
          top: 44%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 0 0 43px; } }
      &.imgDiv--5 {
        &:before {
          top: 57%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 0 0 43px; } }
      &.imgDiv--6 {
        &:before {
          top: 70%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 0 0 43px; } }
      &.imgDiv--7 {
        &:before {
          top: 83%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 0 0 43px; } }
      &.imgDiv--8 {
        &:before {
          top: 96%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 0 0 43px; } }
      &_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        img {
          margin: 0 0 5px 30px;
          flex-shrink: 0;
          @include for-phone {
            display: none; } } } }

    .TextBlock {
      line-height: 26px;
      @include for-tablet {
        margin-bottom: 0; }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgPhone {
      display: none;
      @include for-phone {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0 30px; } } }
  .step17 {
    .flex {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include for-tablet {
        flex-wrap: wrap; }
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 67px;
        width: 50%;
        margin-right: 24px;
        color: #acdff5;
        background: #fff;
        border: 1px solid #acdff5;
        box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        transition: .5s;
        @include for-tablet {
          width: calc(50% - 10px);
          height: 120px;
          margin-right: 0;
          margin-bottom: 20px;
          border: 2px solid #005EAD;
          color: #005EAD;
          font-weight: bold; }
        @include for-phone {
          width: 100%;
          margin-right: 0;
          font-size: 20px;
          line-height: 24px; }
        &.active,
        &:hover {
          background: #049bdc;
          box-shadow: none;
          border: 1px solid #049bdc;
          color: #fff;
          font-weight: bold; }
        &:last-child {
          margin-right: 0; }
        &:nth-child(2n+1) {
          @include for-tablet {
            margin-right: 10px; }
          @include for-phone {
            margin-right: 0; } }
        &:nth-child(2n) {
          @include for-tablet {
            margin-left: 10px; }
          @include for-phone {
            margin-left: 0; } } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv {
      margin-top: 24px;
      border: 1px solid #acdff5;
      border-radius: 10px;
      padding: 40px 47px;
      position: relative;
      @include for-tablet {
        padding: 0;
        border: none; }
      &_closed {
        display: none;
        @include for-tablet {
          display: block;
          position: absolute;
          bottom: -40px;
          right: 50%;
          height: 35px;
          width: 35px;
          transform: translateX(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #99BFDE;
          background-color: #005EAD;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            background: #ffffff;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }

      &:before {
        content: '';
        position: absolute;
        left: 11.3%;
        top: -1px;
        transform: translate(-50%, -50%) rotate(-45deg);
        height: 12px;
        width: 12px;
        background: #fff;
        border-top: 1px solid #acdff5;
        border-right: 1px solid #acdff5;
        transition: .5s;
        @include for-tablet {
          display: none; } }
      & img {
        @include for-phone {
          width: auto;
          height: auto; } }
      &.imgDiv--2 {
        & img {
          @include for-phone {
            width: auto;
            height: auto; } }
        &:before {
          left: 37.1%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 17px 0 50px; } }
      &.imgDiv--3 {
        & img {
          @include for-phone {
            width: auto;
            height: auto; } }
        &:before {
          left: 63%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 6px 0 0 45px; } }
      &.imgDiv--4 {
        & img {
          @include for-phone {
            width: auto;
            height: auto; } }
        &:before {
          left: 88.9%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 0 0 0 43px; } }
      &_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        img {
          margin: 0 0 5px 30px;
          flex-shrink: 0;
          @include for-phone {
            display: none; } } } }

    .TextBlock {
      line-height: 26px;
      @include for-tablet {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0; }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv_flex {
      margin: 0 0 34px; }
    .imgPhone {
      display: none;
      @include for-phone {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0 30px; } } }
  .step18 {
    .flex {
      display: flex;
      justify-content: center;
      @include for-phone {
        flex-direction: column; }
      .flex_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 50%;
        @include for-phone {
          width: 100%; }
        &:first-child {
          @include for-phone {
            margin-bottom: 20px; } }
        .attention {
          background-color: #039cdd;
          border-radius: 10px;
          color: #fff;
          font-weight: 700;
          padding: 25px;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          @include for-phone {
            font-size: 16px;
            padding: 15px; } } }
      &_img {
        width: 100%;
        height: auto;
        @include for-phone {
          width: 60%; }
        @media screen and (max-width: 450px) {
          width: 100%; } } } }
  .step19 {
    .img {
      width: 100%;
      height: auto; }
    .attention {
      background-color: #039cdd;
      border-radius: 10px;
      color: #fff;
      font-weight: 700;
      padding: 25px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      @include for-phone {
        font-size: 16px;
        padding: 15px; }
      &_head {
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 20px; }
      &_text {
        margin: 0; } } }
  .step20 {
    .InfoBlock {
      @include for-tablet {
        display: none; } }
    .flex {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_el {
        width: 50%;
        margin-right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #FFFFFF;
        border: 2px solid #CCDFEF;
        box-sizing: border-box;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        height: 67px;
        transition: .5s;
        font-size: 16px;
        color: #99BFDE;
        //cursor: pointer
        &:last-child {
          margin-right: 0; }
        &.active {
          background: #146BB3;
          color: #fff;
          font-weight: bold;
          box-shadow: none;
          border: none; } } }


    .slider {
      margin-top: 25px;
      padding: 50px;
      border: 2px solid #1F72B7;
      border-radius: 10px;
      display: none;
      position: relative;
      transition: .5s;
      margin-bottom: 20px;
      @include for-tablet {
        padding: 40px 0 47px;
        border: none;
        border-radius: 0; }
      &.show {
        display: block; }
      &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: calc(25% - 12px);
        transform: translate(-50%) rotate(45deg);
        height: 15px;
        width: 15px;
        border-left: 2px solid #1F72B7;
        border-top: 2px solid #1F72B7;
        background: #fff;
        transition: 1s;
        @include for-tablet {
          display: none; } }
      &_right {
        &:before {
          left: calc(75% + 12px); } }
      &_noArrow {
        &:before {
          display: none; } }
      &_el {
        font-size: 18px;
        line-height: 26px;
        color: #3E373C;
        position: relative;
        transition: .5s;
        .head {
          color: #039cdd;
          font-size: 25px;
          font-weight: bold;
          margin-bottom: 30px; }
        img {
          margin-left: 35px;
          margin-bottom: 30px;
          @media screen and (max-width: 767px) {
            display: none; } }
        .imgMobile {
          display: none;
          @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center; }
          img {
            @media screen and (max-width: 767px) {
              display: block; } } } }
      &_flex {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        transition: .5s; }
      &_popup {
        position: absolute;
        top: 100px;
        right: 50px;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #039cdd;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #039cdd;
        font-weight: 700;
        transition: .5s;
        cursor: pointer;
        @include for-phone {
          top: 15px;
          right: calc(40% - 20px); } }
      .slick-prev {
        position: absolute;
        bottom: -50px;
        top: auto;
        left: 55px;
        transform: translate(0, 50%);
        height: 36px;
        width: 36px;
        border-radius: 50%;
        border: 2px solid #039cdd;
        background: #fff;
        cursor: pointer;
        transition: .5s;
        &.slick-disabled {
          cursor: default;
          opacity: 0; }
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% - 1px), -50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 8px solid #039cdd;
          border-bottom: 5px solid transparent;
          transition: .5s; }
        &:hover {
          background: #039cdd;
          &:before {
            border-right: 8px solid #fff; } } }
      .slick-next {
        position: absolute;
        bottom: -50px;
        top: auto;
        right: 55px;
        transform: translate(0, 50%);
        height: 46px;
        width: 46px;
        border-radius: 50%;
        border: 2px solid #039cdd;
        background: #039cdd;
        cursor: pointer;
        transition: .5s;
        &.slick-disabled {
          cursor: default;
          opacity: 0; }
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% + 1px), -50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 8px solid #fff;
          border-bottom: 5px solid transparent;
          transition: .5s; }
        &:hover {
          background: #fff;
          &:before {
            border-left: 8px solid #039cdd; } } } } }
  .step21 {
    .img {
      width: 100%;
      height: auto; } }
  .step22 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 5px 5px 48px;
      background: #f0f5fb;
      border-radius: 10px;
      margin-bottom: 20px;
      @include for-tablet {
        flex-direction: column;
        padding: 40px 20px 20px; }
      .li {
        align-items: center; }
      img {
        flex-shrink: 0;
        margin-left: 70px;
        @include for-tablet {
          margin-left: 0; }
        @media screen and (max-width: 499px) {
          width: 90%;
          height: auto; } } } }
  .step23 {
    position: relative;
    @include for-tablet {
      padding-bottom: 50px; }
    .better {
      width: 50%;
      @include for-phone {
        width: 100%; }
      &_header {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        color: #039cdd; } }
    .img {
      position: absolute;
      top: 145px;
      right: 0;
      @include for-phone {
        position: initial;
        display: block;
        margin: 0 auto; } }

    .PopupInStep {
      padding-top: 30px;
      @include for-phone {
        padding-top: 0; }
      .popup {
        @include for-phone {
          padding-top: 20px; }
        &_content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          @include for-phone {
            display: block; } }
        &_head {
          text-align: left; }
        .popup_text {
          margin-bottom: 0;
          &2 {
            width: 60%;
            @include for-phone {
              width: 100%; } } }
        .popup_imgContent {
          margin-bottom: 20px;
          width: 182px;
          height: 174px;
          @include for-phone {
            display: block;
            width: 182px;
            height: auto;
            margin: 0 auto; } } } }
    .btn_shpora {
      position: absolute;
      z-index: 2;
      left: 50%;
      bottom: -73px;
      transform: translate(-50%, 100%);
      background: #fff;
      color: #60B6FF;
      border: 1px solid #60B6FF;
      @include for-tablet {
        bottom: 20px; }
      &:hover {
        background: #60B6FF;
        color: #fff; } } }
  .step24 {
    .flex {
      display: flex;
      margin: 0 -5px;
      @include for-tablet {
        flex-wrap: wrap;
        justify-content: center; }
      &_el {
        display: flex;
        width: 33.25%;
        min-height: 320px;
        font-size: 15px;
        line-height: 18px;
        padding: 0 5px;
        @include for-tablet {
          width: 260px; }
        &:not(:last-child) {
          @include for-tablet {
            margin-bottom: 20px; } }
        &:last-child {
          @include for-tablet {
            width: 100%;
            justify-content: center; } } }
      &_wrp {
        padding: 26px 13px 13px;
        position: relative;
        text-align: center;
        font-size: 15px;
        line-height: 18px;
        border: 2px solid #c33;
        border-radius: 10px;
        @include for-tablet {
          width: 260px; }
        &--green {
          border: 2px solid #3fc4b1; } }
      &_red {
        color: #c33;
        font-weight: bold; }
      &_green {
        color: #3fc4b1;
        font-weight: bold; }
      &_status {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        border-radius: 50%; }
      .img_3 {
        position: absolute;
        bottom: -35px;
        left: 50%;
        transform: translateX(-50%); } } }
  .step25 {
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; } }
    .attention {
      background-color: #039cdd;
      border-radius: 10px;
      color: #fff;
      font-weight: 700;
      padding: 25px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      @include for-phone {
        font-size: 16px;
        padding: 15px; }
      &_head {
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 20px; }
      &_text {
        margin: 0; } }
    .img {
      @include for-phone {
        width: 100%;
        height: auto; } } }
  .step26 {
    .imgDiv {
      position: relative;
      padding-top: 40px;
      .point {
        position: absolute;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #039cdd;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #039cdd;
        font-weight: 700;
        -webkit-transition: .5s;
        transition: .5s;
        cursor: pointer;
        z-index: 10;
        &:nth-child(1) {
          left: 50px;
          top: 50px;
          @include for-phone {
            left: 0; } }
        &:nth-child(2) {
          right: 50px;
          top: 50px;
          @include for-phone {
            right: 0; } }
        &:nth-child(3) {
          left: 50px;
          bottom: 20px;
          @include for-phone {
            left: 0; } }
        &:nth-child(4) {
          right: 50px;
          bottom: 20px;
          @include for-phone {
            right: 0; } } } } }
  .step27 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 5px 5px 48px;
      background: #f0f5fb;
      border-radius: 10px;
      margin-bottom: 20px;
      @include for-tablet {
        flex-direction: column;
        padding: 40px 20px 20px; }
      .li {
        align-items: center; }
      img {
        flex-shrink: 0;
        margin-left: 70px;
        @include for-tablet {
          margin-left: 0; }
        @media screen and (max-width: 499px) {
          width: 90%;
          height: auto; } } } }
  .step28 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      .img {
        @include for-phone {
          display: block;
          margin: 0 auto; } } } } }






