@import "../../../Page/mixin";
.step.lesson15 {
  padding-bottom: 48px;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  color: #3E373C;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #039cdd;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #039cdd;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #039cdd;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #039cdd;
      background: #039cdd;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #039cdd; } } }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .textBlue {
    color: #005EAD; }
  .textRed {
    color: #BD4E3B; }
  .infoPopap {
    position: fixed;
    z-index: 222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#345178, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrp {
      background: #fff;
      border-radius: 25px;
      padding: 70px;
      position: relative;
      width: 100%;
      max-width: 640px; }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba(#005EAD, .3);
        transition: .5s; }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: rgba(#005EAD, 1); }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }

    &_scroll {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
      overflow-y: auto; }
    &_iconDiv {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      overflow: hidden;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      &.red {
        color: #BD4E3B; } }
    &_text {
      font-size: 16px;
      color: #3E373C;
      margin-bottom: 30px;
      &_head {
        font-size: 16px;
        color: #005EAD;
        font-weight: bold;
        display: block; } }
    &_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      &ImgDiv {
        height: 96px;
        width: 96px;
        flex-shrink: 0;
        border: 2px solid #005EAD;
        border-radius: 50%;
        background: #fff;
        margin-right: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
        &.chain {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 100%;
            width: 3px;
            background: #005EAD; } } }
      &:last-child {
        .chain {
          &:before {
            display: none; } } }
      .infoPopap_text {
        margin-bottom: 0; } }


    &_wrp.danger {
      background: #F9EEEC;
      .infoPopap_iconDiv {
        background: #F9EEEC; }
      .infoPopap_close {
        &:before,
        &:after {
          background: rgba(#BD4E3B, .3); }
        &:hover {
          &:before,
          &:after {
            background: rgba(#BD4E3B, 1); } } }
      .infoPopap_text {
        li:before {
          background: #BD4E3B; } }
      .infoPopap_head {
        color: #BD4E3B; } }





    &_imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      max-width: 100%;
      img {
        margin-top: 45px;
        max-width: 100%; } }
    .btn {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      font-size: 14px;
      width: 160px; } }

  .ul {
    .liHead {
      font-size: 18px;
      color: #005EAD;
      font-weight: bold;
      text-transform: uppercase;
      margin: 40px 0 35px; }
    .li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 35px;
      @media screen and (max-width: 499px) {
        flex-direction: column; }
      .number {
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        border: 1px solid #039cdd;
        border-radius: 50%;
        margin-right: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #039cdd;
        @media screen and (max-width: 499px) {
          margin-bottom: 10px; } }
      .text {
        font-size: 18px;
        color: #3E373C; } } }

  .infoPopap {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    background: rgba(#345178, .8);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 112px;
    &_wrp {
      width: 100%;
      max-width: 640px;
      padding: 70px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      .popap_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99BFDE;
        cursor: pointer;
        transition: .5s;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s; }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover {
          &:before,
          &:after {
            background: #005EAD; }
          &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(45deg); } } }
      .image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        &Bg {
          width: 100%;
          height: 100%;
          background: #E6EFF7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            border-radius: 50%; } } }
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line; }
      .subHead {
        color: #1F72B7;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        white-space: pre-line;
        margin-bottom: 30px; }
      .slider {
        border: 2px solid #CCDFEF;
        border-radius: 10px;
        position: relative;
        padding: 25px;
        margin-bottom: 50px;
        .slide {
          color: #4C8EC5;
          font-size: 16px;
          line-height: 24px; }
        .slick-arrow {
          bottom: 0; } }
      .btn {
        position: relative;
        left: 50%;
        transform: translate(-50%); } } }

  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    @include for-phone {
      padding-bottom: 150px; }
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      &.mobile {
        display: none;
        @include for-phone {
          display: flex; }
        .flex_el:first-child {
          @include for-phone {
            padding-left: 0; } } }
      &_el {
        &:first-child {
          margin-right: 30px;
          width: 50%;
          flex-shrink: 0;
          @include for-phone {
            width: 100%;
            padding-left: 30px;
            margin-right: 0; } }
        &_block {
          position: relative;
          padding: 25px 25px 80px;
          border-radius: 10px;
          background-color: #f0f5fb;
          &.desktop {
            @include for-phone {
              display: none; } } }
        &_text {
          font-size: 16px; }
        &_box {
          display: flex;
          align-items: center; }
        &_img {
          flex-shrink: 0;
          margin-right: 20px; } } }
    .wrap {
      @include for-phone {
        padding-left: 30px; } }
    .rating {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 62px;
      margin-bottom: 45px;
      margin-left: 45px;
      padding: 0 20px 0 50px;
      border-radius: 10px;
      border: 1px solid #acdff5;
      box-shadow: 0 2px 3px 0 rgba(74, 74, 74, 0.2);
      @include for-phone {
        margin-left: 0; }
      &_img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%); }
      &.dog {
        width: 70%;
        @include for-phone {
          width: 100%; } }
      &.cat {
        width: 60%;
        @include for-phone {
          width: 100%; } }
      &.fish {
        width: 70%;
        @include for-phone {
          width: 100%; } }
      &.other {
        width: 50%;
        @include for-phone {
          width: 100%; } }
      &.nobody {
        width: 80%;
        @include for-phone {
          width: 100%; } }
      &_text {
        margin: 0;
        font-size: 18px; }
      &_percent {
        position: relative;
        right: -80px;
        margin: 0;
        font-size: 25px;
        font-weight: bold;
        color: #039cdd;
        @include for-phone {
          position: static; } } } }
  .step3, .step8, .step12 {
    .OnClickTest {
      .btn_blue {
        bottom: -25px; } }
    .imgCenter {
      display: none;
      &.show {
        display: block; } } }
  .step4 {
    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-image: url("../../../image/lessons/lesson15/step4-1.png");
      background-position: center;
      background-repeat: no-repeat;
      @include for-phone {
        flex-direction: column; }
      &_el {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 45%;
        padding: 40px 25px 25px;
        background: #ffffff;
        border: 1px solid #acdff5;
        border-radius: 5px;
        margin-bottom: 40px;
        @include for-phone {
          width: 100%;
          background: transparent; }
        &_number {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          color: #039cdd;
          font-size: 24px;
          background: #ffffff;
          border-radius: 50%;
          border: 1px solid #039cdd; }
        &_img {
          margin-bottom: 20px; }
        .TextBlock {
          align-self: flex-start; } } } }
  .step6, .step9 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 50%;
        @include for-phone {
          width: auto;
          margin: 0 -20px 30px;
          padding-top: 30px;
          border-top: 2px solid #b2e6fc;
          border-bottom: 2px solid #b2e6fc; }
        &.left {
          border-right: 1px solid #b2e6fc;
          @include for-phone {
            border-right: none; }
          .TextBlock, .flex_el_img, .flex_el_box, .flex_el_town {
            padding-right: 25px;
            @include for-phone {
              padding-left: 25px; } } }
        &.right {
          .TextBlock, .flex_el_img, .flex_el_box, .flex_el_town {
            padding-left: 45px;
            @include for-phone {
              padding-left: 25px;
              padding-right: 25px; } } }
        &_img {
          max-width: 100%;
          margin-bottom: 20px; }
        &_town {
          height: 48px;
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 24px;
          color: #2c3032;
          text-align: center; }
        &_box {
          display: flex;
          align-items: center;
          height: 80px;
          &:not(:last-child) {
            border-bottom: 1px solid #b2e6fc; }
          &_img {
            flex-shrink: 0;
            margin-right: 20px; } }
        .TextBlock {
          height: 56px; } } } }
  .step7, .step11 {
    .block {
      margin-bottom: 25px;
      &_head {
        margin-bottom: 25px;
        font-size: 18px;
        line-height: 24px;
        color: #2c3032; }
      &_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include for-phone {
          flex-direction: column; }
        &_el {
          &:first-child {
            margin-right: 25px;
            @include for-phone {
              margin-right: 0;
              margin-bottom: 25px; } }
          &_img {
            max-width: 100%; }
          &_description {
            display: flex;
            align-items: center;
            margin-bottom: 15px; }
          &_point {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            margin-right: 10px;
            border-radius: 50%;
            &.dark-blue {
              background: #005cb3; }
            &.blue {
              background: #1dade6; }
            &.green {
              background: #78ddc5; } }
          .img {
            @include for-phone {
              display: none; } } } } } }
  .step9 {
    .flex {
      &_el {
        &_description {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          padding-left: 45px;
          &_point {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            margin-right: 10px;
            border-radius: 50%;
            &.yellow {
              background: #f8d04d; }
            &.blue {
              background: #1dade6; }
            &.green {
              background: #78ddc5; }
            &.black {
              background: #00205b; }
            &.dark-blue {
              background: #005cb3; } } } } } }
  .step10 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include for-phone {
        flex-direction: column; }
      .img {
        max-width: 100%;
        margin-bottom: 30px; } } }
  .step15 {
    .block {
      position: relative;
      width: 100%;
      max-width: 700px;
      height: 360px;
      margin: 0 auto;
      background: url(../../../image/lessons/lesson15/step15-1.png) no-repeat top center;
      background-size: cover;
      border-radius: 15px;
      @include for-phone {
        width: 270px;
        height: 350px;
        background: url(../../../image/lessons/lesson15/step15-1xs.png); }
      &_text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 430px;
        font-weight: bold;
        font-size: 25px;
        color: #ffffff;
        text-align: center;
        @include for-phone {
          width: 230px;
          font-size: 20px; } } } }
  .step16, .step17, .step18 {
    .head {
      font-weight: bold;
      color: #039cdd;
      font-size: 20px;
      margin-bottom: 25px;
      text-transform: uppercase; }
    .flex {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 192px;
        margin-right: 12px;
        padding: 0 15px;
        font-size: 15px;
        text-align: center;
        color: #039cdd;
        font-weight: bold;
        background: #fff;
        border: 1px solid #c9dbf0;
        box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        overflow: hidden;
        transition: .5s;
        @include for-phone {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          font-size: 20px;
          line-height: 24px;
          color: #039cdd; }
        &.active,
        &:hover {
          box-shadow: none; }
        &.hidden {
          box-shadow: none;
          .flex_el_click {
            &.left {
              transform: translateX(-100%); }
            &.right {}
            transform: translateX(100%); } }
        &.total {
          color: #3fc4b1;
          background: #e3fffb; }
        &:last-child {
          margin-right: 0; }
        &.flex_el_inactive {
          color: #ffffff;
          background-color: #039cdd;
          box-shadow: none; }
        &_sign {
          margin-right: 12px;
          font-size: 20px;
          font-weight: bold;
          color: #039cdd;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 12px; } }
        &_text {
          font-weight: normal;
          font-size: 13px; }
        &_click {
          position: absolute;
          top: 0;
          display: flex;
          align-items: center;
          width: 50%;
          height: 100%;
          margin: 0;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          background: #ffffff;
          color: #039cdd;
          transition: 0.5s ease-in;
          &.left {
            left: 0;
            justify-content: flex-end;
            padding-right: 5px;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px; }
          &.right {
            right: 0;
            justify-content: flex-start;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px; } } } } }
  .step18 {
    .flex {
      justify-content: flex-start;
      &_el {
        &_value {
          margin: 0; }
        &_hr {
          width: 70%;
          height: 2px;
          background: #039cdd;
          border: none; } } } }
  .step19 {
    @include for-phone {
      padding-bottom: 50px; }
    .TextBlock {
      width: 50%;
      margin-bottom: -100px;
      @include for-phone {
        width: 100%;
        margin-bottom: 30px; } }
    .imgCenter {
      left: 0;
      transform: translate(0);
      margin-bottom: 40px;
      @include for-phone {
        left: 50%;
        transform: translate(-50%);
        margin-bottom: 0; } }
    .StatisticsBlock {
      padding-left: 265px;
      @include for-phone {
        flex-direction: column-reverse;
        padding-left: 20px;
        padding-bottom: 110px; }
      .img {
        left: 0;
        width: auto;
        @include for-phone {
          position: absolute;
          left: 50%;
          top: auto;
          bottom: 0;
          transform: translate(-50%, 70%); } } } }
  .step20, .step24 {
    .block {
      &_description {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        padding-left: 45px;
        &_point {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          margin-right: 10px;
          border-radius: 50%;
          &.blue {
            background: #1dade6; }
          &.green {
            background: #39ce79; }
          &.black {
            background: #00205b; }
          &.red {
            background: #cc3433; } } } } }
  .step23 {
    .OnClickBlock {
      .imgDiv {
        &_wrap {
          &_flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include for-phone {
              flex-direction: column; }
            &_el {
              width: 40%;
              @include for-phone {
                width: 100%;
                margin-bottom: 20px; }
              &.arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20%; }
              .info {
                display: flex;
                align-items: center;
                @include for-phone {
                  flex-direction: column; }
                &_img {
                  margin-right: 20px;
                  @include for-phone {
                    margin-right: 0;
                    margin-bottom: 20px; } }
                &_box {
                  &_head {
                    font-weight: bold;
                    font-size: 14px;
                    margin-bottom: 12px;
                    text-transform: uppercase;
                    @include for-phone {
                      text-align: center; } }
                  &_text {
                    font-size: 17px; } } }
              .arrowImg {
                @include for-phone {
                  transform: rotate(90deg); } } } } } } } }
  .step25 {
    .flex {
      display: flex;
      align-items: center;
      @include for-phone {
        flex-direction: column; }
      &_img {
        flex-shrink: 0;
        max-width: 100%;
        margin-right: 20px;
        @include for-phone {
          margin: 0 auto 30px; } }
      &_text {
        font-size: 25px;
        color: #039cdd;
        text-align: center;
        @include for-phone {
          font-size: 18px; } } } }
  .step26 {
    .block {
      display: flex;
      &_empty {
        width: 25%; }
      &_el {
        font-weight: bold;
        text-align: center;
        width: 37.5%;
        text-transform: uppercase;
        color: #2c3032;
        font-size: 14px; } }
    .cards {
      display: flex;
      margin-top: 30px;
      &_empty {
        width: 25%;
        border-right: 1px solid #b2e6fc; }
      &_box {
        display: flex;
        justify-content: center;
        width: 12.5%;
        padding-bottom: 10px;
        border-right: 1px solid #b2e6fc; } }
    .test {
      display: flex;
      margin-bottom: 40px;
      .questions {
        flex-shrink: 0;
        width: 25%;
        border-right: 1px solid #b2e6fc;
        &_el {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 101px;
          margin: 0;
          font-size: 14px;
          line-height: 16px;
          border-top: 1px solid #b2e6fc;
          &:last-child {
            border-bottom: 1px solid #b2e6fc; } } }
      .answers {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        margin-top: 0;
        margin-bottom: 0;
        border-bottom: 1px solid #b2e6fc;
        @include for-phone {
          margin-top: 20px; }
        .answer {
          width: 16.666%;
          height: 101px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-top: 1px solid #b2e6fc;
          border-right: 1px solid #b2e6fc;
          box-shadow: none;
          border-radius: 0;
          margin-bottom: 0;
          font-size: 18px;
          line-height: 26px;
          color: #3E373C;
          cursor: pointer;
          transition: .5s;
          @include for-phone {
            font-size: 16px;
            line-height: 18px; }
          &:last-child {
            margin-bottom: 0; }
          .circle {
            height: 30px;
            width: 30px;
            border: 2px solid #CCDFEF;
            border-radius: 50%;
            flex-shrink: 0;
            margin-right: 0;
            transition: .5s;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              transition: .5s;
              opacity: 0;
              display: none; } }
          //&:hover,
          //&.active
          //  border: 2px solid #4085D8
          //  box-sizing: border-box
          //  box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1)
          //  color: #4085D8
          //  .circle
          //    border-color: #4085D8
          &.active {
            .circle {
              background: #4085D8; }
            //svg
            //  opacity: 1

            &.correct {
              background-color: rgba(64, 133, 216, 0.2);
              border-color: #D3DEEB;
              .circle {
                background: #4085D8;
                border-color: #4085D8;
                svg.correct {
                  opacity: 1;
                  display: block; } } }
            &.inCorrect {
              background-color: #F9EEEC;
              border-color: #D3DEEB;
              .circle {
                background: #BD4E3B;
                border-color: #BD4E3B;
                svg.inCorrect {
                  opacity: 1;
                  display: block; } } } }

          &.correct {
            border-color: #D3DEEB;
            .circle {
              background: #4085D8;
              border-color: #4085D8;
              svg.correct {
                opacity: 1;
                display: block; } } } } } }
    .info {
      padding: 44px 48px;
      margin-top: 20px;
      border-radius: 10px;
      background: #005EAD;
      color: #fff;
      &_head {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px; }
      &Text {
        font-size: 18px; }
      &.error {
        background: #F9EEEC;
        .info_head {
          color: #BD4E3B; }
        .infoText {
          color: #3E373C; } } }
    .btn_blue {
      margin: 0 auto; } } }






