.MainHeader {
  margin-bottom: 25px;
  width: 100%;
  @media screen and (max-width: 1366px) {
    margin-top: 60px;
    margin-bottom: 40px; }
  @media screen and (max-width: 767px) {
    margin-bottom: 20px; }
  &_mobile {
    @media screen and (max-width: 767px) {
      order: 2; } }
  .header {
    font-size: 32px;
    line-height: 40px;
    font-weight: normal;
    color: #039cdd;
    margin: 0; }
  .subheader {
    font-size: 25px;
    font-weight: normal;
    line-height: 30px;
    color: #039cdd;
    margin: 41px 0 30px;
    @media screen and (max-width: 1366px) {
      margin: 0;
      margin-top: 60px;
      padding-bottom: 30px;
      font-size: 32px;
      line-height: 40px;
      border-bottom: 1px solid #acdff5; }
    @media screen and (max-width: 767px) {
      margin-top: 50px;
      font-size: 25px;
      line-height: 30px;
      border-bottom: none;
      padding-bottom: 0; } }
  .text {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 30px;
    color: #8d8d8d;
    @media screen and (max-width: 1366px) {
      padding-bottom: 0; } }
  &.bigHeader {
    border-bottom: 1px solid #acdff5;
    margin-bottom: 40px;
    @media screen and (max-width: 1366px) {
      height: auto;
      border-bottom: none; }
    .header {
      font-size: 40px;
      line-height: 46px;
      font-weight: bold;
      @media screen and (max-width: 1366px) {
        margin-top: 60px;
        margin-bottom: 30px; }
      @media screen and (max-width: 767px) {
        font-size: 30px;
        line-height: 42px; } }
    .subheader {
      font-size: 32px;
      font-weight: normal;
      line-height: 40px;
      margin: 15px 0 30px;
      @media screen and (max-width: 1366px) {
        display: none; } }
    &.noBB {
      border-bottom: 0 none;
      height: auto;
      margin-bottom: 22px; } }


  .testHead {
    margin-top: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .testName {
      @media screen and (max-width: 767px) {
        display: none; } }
    span {
      color: #039cdd;
      font-size: 25px;
      font-weight: bold; }
    .answer {
      font-size: 14px;
      text-align: right;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      color: #99BFDE;
      span {
        color: #039cdd;
        font-size: 14px;
        font-weight: normal; } }
    &Mobile {
      display: none;
      @media screen and (max-width: 767px) {
        display: flex;
        margin-top: 0; }
      .answer {
        @media screen and (max-width: 767px) {
          display: none; } }
      .testName {
        @media screen and (max-width: 767px) {
          display: block; } } } } }



