@import "../mixin";
.Pyaterochka {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  min-height: 100vh;
  background-image: url("../../image/bg-pyaterochka.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  .wrp {
    width: 100%;
    min-height: calc(100vh - 55px);
    display: flex;
    justify-content: center;
    align-items: center;
    .form {
      padding: 0 65px 60px;
      //border: 1px solid #42bffa
      position: relative;
      width: 100%;
      max-width: 506px;
      margin-top: 70px;
      margin-bottom: 50px;
      @include for-tablet {
        padding: 195px 50px 80px;
        max-width: 100%;
        border: none; }
      @include for-phone {
        padding: 110px 20px 50px;
        margin-top: 20px; }
      .form_type {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        &_el {
          font-weight: bold;
          font-size: 24px;
          color: rgba(255, 255, 255, 0.4);
          cursor: pointer;
          &:first-child {
            margin-right: 12px;
            padding-right: 12px;
            border-right: 4px solid rgba(255, 255, 255, 0.4); }
          &.active {
            color: #ffffff; } } }
      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 25px; }
      .head {
        text-align: center;
        color: #fff;
        margin-bottom: 32px;
        @include for-tablet {
          font-size: 20px;
          line-height: 24px; }
        @include for-phone {
          font-size: 16px;
          margin-bottom: 30px; }
        a {
          color: #60B6FF;
          cursor: pointer;
          @include for-tablet {
            font-size: 20px;
            line-height: 24px; }
          @include for-phone {
            font-size: 16px; } } }
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include for-phone {
          display: block; }
        .inputBlock {
          width: 100%;
          margin-right: 33px;
          &:last-child {
            margin-right: 0; } } }
      .checkboxs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 30px;
        @include for-tablet {
          justify-content: center; }
        @include for-phone {
          justify-content: space-between; }
        &Label {
          width: 60%;
          font-size: 16px;
          color: #fff;
          margin-bottom: 10px;
          @include for-tablet {
            font-size: 20px;
            width: 30%; }
          @include for-phone {
            font-size: 16px;
            width: 150px; } }
        .switch {
          display: flex;
          @include for-tablet {
            width: 120px;
            height: 48px;
            position: relative;
            border: 2px solid #FFFFFF;
            border-radius: 48px;
            transition: 0.5s; }
          @include for-phone {
            width: 90px;
            height: 38px; }
          &:before {
            @include for-tablet {
              content: "";
              position: absolute;
              top: 50%;
              width: 36px;
              height: 36px;
              background-color: #ffffff;
              border-radius: 50%;
              transform: translate(5px, -50%);
              transition: 0.5s; }
            @include for-phone {
              width: 28px;
              height: 28px; } }
          &.switch_right {
            @include for-tablet {
              background-color: #ffffff;
              transition: 0.5s; }
            &:before {
              @include for-tablet {
                transform: translate(75px, -50%);
                background-color: #4085D8;
                transition: 0.5s; }
              @include for-phone {
                transform: translate(55px, -50%); } } }
          &_yes {
            display: none;
            @include for-tablet {
              position: absolute;
              display: block;
              top: 50%;
              left: 15px;
              transform: translateY(-50%);
              font-size: 20px;
              line-height: 24px;
              color: #005EAD; } }
          &_no {
            display: none;
            @include for-tablet {
              position: absolute;
              display: block;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
              font-size: 20px;
              line-height: 0;
              color: #ffffff; } }
          .checkbox {
            margin-right: 20px;
            @include for-tablet {
              opacity: 0;
              margin-right: 10px; }
            &:active {
              background-color: #005EAD; }
            &:last-child {
              margin-right: 0; } } } }
      .agree {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        a {
          color: #fff;
          font-size: 16px;
          line-height: 24px;
          text-decoration: none;
          @include for-tablet {
            line-height: 16px; }
          .link-no {
            text-decoration: none; }
          .link-yes {
            text-decoration: underline; } }
        .checkbox {
          margin-right: 5px;
          opacity: 1;
          @include for-tablet {
            padding-left: 61px; }
          @include for-phone {
            padding-left: 51px; }
          &:before {
            opacity: 1;
            @include for-tablet {
              width: 32px;
              height: 32px;
              background-color: #ffffff;
              border-radius: 5px; } }
          &:after {
            @include for-tablet {
              width: 19px;
              height: 13px;
              top: 50%;
              left: 9px;
              border: none;
              background-image: url("../../image/check-mark.svg");
              background-repeat: no-repeat;
              transform: translate(0, -50%) rotate(0); } } } }
      .info {
        display: flex;
        align-items: center;
        padding: 14px 18px;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: #99BFDE;
        width: 100%;
        position: relative;
        top: 20px;
        border: 1px solid #99BFDE;
        box-sizing: border-box;
        border-radius: 10px;
        @include for-tablet {
          width: 60%;
          top: 0; }
        @include for-phone {
          width: 100%;
          top: 20px; }
        .info__icon {
          margin: 0;
          margin-right: 14px; }
        .info__text {
          margin: 0;
          @include for-phone {
            letter-spacing: -0.02em;
            font-size: 14px; } }
        .info__star {
          margin-right: 5px; } }
      .forgot {
        display: flex;
        justify-content: flex-end;
        margin-top: -20px;
        a {
          font-size: 16px;
          font-weight: bold;
          color: #60B6FF;
          cursor: pointer; } }
      .buttons {
        margin-top: 22px;
        display: flex;
        justify-content: center;
        .btn_white {
          @include for-tablet {
            width: 327px;
            max-width: 323px;
            height: 72px;
            font-size: 22px;
            line-height: 26px; }
          @include for-phone {
            width: 280px;
            max-width: 280px;
            height: 62px;
            font-size: 18px;
            line-height: 21px; } }
        button:disabled {
          color: #99BFDE;
          background-color: transparent;
          border: 2px solid #99BFDE; } }
      .partners {
        position: absolute;
        bottom: -18px;
        left: 50%;
        transform: translate(-50%);
        text-align: center;
        background: #005EAD;
        color: #fff;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 240px;
        padding: 0 18px;
        @include for-tablet {
          width: 320px;
          font-size: 20px;
          line-height: 18px; }
        @include for-phone {
          font-size: 14px;
          line-height: 18px;
          bottom: -48px; }
        .partners__box {
          margin-top: 10px; }
        img {
          width: 91px;
          @include for-tablet {
            width: 135px; }
          @include for-phone {
            width: 96px; } }
        a {
          margin-right: 12px;
          &:last-child {
            margin-right: 0; } } }
      .inputsPlus {
        display: flex;
        align-items: flex-start;
        &:last-child .inputBlock {
          margin-right: 12px; }
        &:not(:last-child) .btnPlus {
          display: none; }
        .btnPlus {
          max-width: 88px;
          height: 30px;
          font-size: 12px;
          line-height: 14px; } }
      .flex_wrp {
        @include for-tablet {
          display: flex;
          align-items: center; }
        @include for-phone {
          display: block; } } } }
  .email_send {
    font-size: 17px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    &_icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px; }
    a {
      color: #ffffff;
      font-size: 17px;
      line-height: 24px;
      text-decoration: underline;
      &:hover {
        text-decoration: none; } } }
  .password_send {
    font-size: 17px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
    &_icon {
      margin-bottom: 30px; } }
  .footer .partners {
    display: none; }
  .btn_transparent_pink {
    height: 55px;
    background: transparent;
    color: #ffffff;
    border-radius: 15px;
    border: 2px solid #ffffff;

    &:hover {
      background: #ffffff;
      color: #A5338A; } } }
