@import "../../../Page/mixin";
.step.lesson21 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .MainHeader {
    .header {
      color: #A7268E; }
    .subheader {
      color: #A7268E; }
    &.bigHeader {
      border-bottom: 1px solid #dedede; } }

  .OnClickBlock {
    .flex {
      &_el {
        &_wrp {
          font-size: 16px;
          height: 120px;
          color: #a7268e;
          border: 2px solid #a7268e;
          &.active,
          &:hover {
            color: #ffffff;
            background: #a7268e; } } } }
    .imgDiv {
      border: 1px solid #a7268e; } }
  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
    position: absolute;
    bottom: -50px;
    top: auto;
    right: 55px;
    transform: translate(0, 50%);
    height: 46px;
    width: 46px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #1F72B7;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% + 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 8px solid #fff;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #fff;
      &:before {
        border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #c82da5; } } }
  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; }
    .flex {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      &_el {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 32%;
        height: 40px;
        font-size: 17px;
        color: #fff;
        background-color: #b32896;
        border-radius: 10px; } } }
  .step2 {
    .flex {
      display: flex;
      align-items: center;
      &_img {
        flex-shrink: 0;
        margin-left: 20px; } } }
  .step3 {
    .TextBlock {
      margin-bottom: 0; }
    .box {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      &_img {
        position: relative; }
      &_point {
        position: absolute;
        top: 45%;
        left: 24%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        z-index: 2;
        cursor: pointer;
        background: #a7268e;
        border-radius: 50%;
        font-size: 17px;
        color: #ffffff;
        border: 1px solid #a7268e;
        transition: 0.5s;
        @include for-phone {
          width: 30px;
          height: 40px;
          top: 62%; }
        &:nth-child(2) {
          top: 27%;
          left: auto;
          right: 47%;
          @include for-phone {
            top: 26%;
            right: 4%; } }
        &:nth-child(3) {
          top: 45%;
          left: 73%; }
        &.active,
        &:hover {
          background: #ffffff;
          color: #a7268e; } }
      .block {
        position: absolute;
        transform: scale(0);
        top: -120%;
        left: 0;
        transform-origin: 50% 20px;
        padding: 20px 25px;
        border-radius: 10px;
        transition: .5s;
        background: #fff;
        width: 335px;
        border: 1px solid #a7268e;
        z-index: 3;
        @include for-phone {
          display: none; }
        &.active {
          transform: scale(1); } } }
    .Popup {
      .info {
        &_head {
          text-transform: uppercase; }
        &.green {
          .info_head {
            color: #00c5b2; }
          .List {
            li:before {
              background: #00c5b2; } } }
        &.red {
          .info_head {
            color: #ed3030; }
          .List {
            li:before {
              background: #ed3030; } } } }
      .btn_blue {
        margin: 0 auto; } } }
  .step4 {
    .InfoBlock.hidden {
      @include for-tablet {
        display: none; } }
    .flex {
      margin-top: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      @include for-phone {
        flex-direction: column;
        margin-bottom: 0; }
      &_el {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        padding: 10px;
        color: #acdff5;
        border: 2px solid transparent;
        background: #fff;
        border-radius: 10px;
        cursor: pointer;
        transition: .5s;
        @include for-tablet {
          height: 120px;
          border: 2px solid #acdff5;
          color: #acdff5;
          font-weight: bold; }
        @include for-phone {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          font-size: 20px;
          line-height: 24px; }
        &.active {
          background: #e2fffb;
          box-shadow: none;
          border: 2px solid #3fc4b1;
          color: #fff;
          font-weight: bold;
          &.error {
            border: 2px solid red;
            background: #fef2f2; } }
        &:last-child {
          margin-right: 0;
          margin-left: 20px; } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .info {
      margin-top: 24px;
      padding: 44px 48px;
      border-radius: 10px;
      background: #e3fffb;
      color: #fff;
      position: relative;
      transition: 0.5s;
      @include for-tablet {
        padding: 0;
        border: none; }
      &_head {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 30px;
        color: #3fc4b1; }
      &_el {
        display: flex;
        margin-bottom: 20px;
        &_number {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 50px;
          height: 50px;
          margin-right: 14px;
          border: 1px solid #d2a94f;
          color: #d2a94f;
          font-size: 30px;
          border-radius: 50%; }
        &_text {
          font-size: 17px;
          line-height: 24px;
          color: #2c3032;
          font-weight: 400; } }
      &.error {
        background: #ffe3e3;
        .info_head {
          color: #f12626; }
        .infoText {
          color: #2c3032; } } }
    .TextBlock {
      line-height: 26px;
      @include for-tablet {
        font-size: 16px;
        line-height: 24px; }
      &.hidden {
        @include for-tablet {
          display: none; } }

      .imgDiv_flex {
        margin: 0 0 32px; } }
    .imgPhone {
      display: none;
      @include for-phone {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0 30px; } } }
  .step5 {
    .OnClickTest {
      .flex {
        flex-wrap: wrap;
        &_el {
          margin-bottom: 25px;
          &_wrp {
            border: 1px solid #a7268e;
            color: #a7268e;
            &.correct {
              border: 1px solid #3fc4b1;
              color: #3fc4b1; }
            &.inCorrect {
              border: 1px solid #f4eff2; } } } } } }
  .step6 {
    .head {
      font-size: 30px;
      line-height: 30px;
      font-weight: 700;
      color: #a7268e;
      margin-bottom: 20px;
      margin-top: 40px; }
    .info {
      &_el {
        display: flex;
        margin-bottom: 20px;
        &_number {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 45px;
          height: 45px;
          margin-right: 14px;
          border: 2px solid #d4ac56;
          background: #a7268e;
          color: #ffffff;
          font-size: 17px;
          border-radius: 50%; }
        &_text {
          font-size: 17px;
          line-height: 24px;
          color: #2c3032;
          font-weight: 400; } } } }
  .step7, .step12 {
    .DefinitionBlock {
      border: 1px solid #a7268e;
      .text_pink {
        color: #A7268E; } }
    .flex {
      display: flex;
      &_el {
        &:first-child {
          margin-right: 50px;
          flex-shrink: 0; } } }
    .info {
      &_el {
        display: flex;
        margin-bottom: 20px;
        &_number {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: 14px;
          border: 2px solid #a7268e;
          background: #ffffff;
          color: #a7268e;
          font-weight: bold;
          font-size: 14px;
          border-radius: 50%; }
        &_text {
          font-size: 17px;
          line-height: 24px;
          color: #2c3032;
          font-weight: 400; } }
      &_purple {
        font-weight: bold;
        color: #a7268e; } } }
  .step8 {
    .OnClickBlock {
      .flex {
        &_el {
          &_wrp {
            height: 60px; } } }
      .imgDiv {
        border: 1px solid #a7268e;
        &_wrap {
          &_flex {
            display: flex;
            align-items: center;
            &_img {
              border-radius: 50%;
              margin-right: 30px; }
            &_head {
              font-size: 18px;
              line-height: 24px;
              margin-bottom: 10px; } } } } } }
  .step9 {
    .info {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      &_img {
        flex-shrink: 0;
        margin-right: 30px; } }
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      &_el {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 32%;
        height: 84px;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: bold;
        color: #a7268e;
        border: 2px solid #a7268e;
        border-radius: 10px;
        cursor: pointer;
        &.active,
        &:hover {
          color: #ffffff;
          background: #a7268e; } } }
    .Popup {
      .head {
        font-weight: bold;
        font-size: 25px;
        color: #a7268e;
        margin-bottom: 20px; } } }
  .step11 {
    position: relative;
    .flex {
      display: flex;
      align-items: center;
      margin-top: 50px;
      &_el {
        &:first-child {
          flex-grow: 1;
          @include for-phone {
            width: 100%; } }
        &:last-child {
          flex-shrink: 0;
          margin-left: 20px; }
        &.correct {
          .rangeslider {
            background-color: #4DD06A;
            &__fill {
              background-color: #4DD06A; }
            &__handle {
              background-color: #4DD06A; } } }
        &.inCorrect {
          .rangeslider {
            background-color: #4DD06A;
            &__fill {
              background-color: #CC3333; }
            &__handle {
              background-color: #CC3333; } } } } }
    .arrow {
      position: relative;
      width: 100%;
      height: 1px;
      background: #a7268e;
      &:before {
        content: "Гц";
        display: block;
        position: absolute;
        font-weight: 700;
        font-size: 17px;
        color: #a7268e;
        top: -25px;
        right: 0; }
      &:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-left: 23px solid #a7268e;
        border-bottom: 6px solid transparent;
        position: absolute;
        right: -2px;
        top: -5px; } }
    .range {
      width: 100%;
      //height: 62px
      height: 24px;
      //padding: 5px
      //border: 1px solid #a7268e
      box-sizing: border-box;
      margin: 50px 0 0;
      border-right: 1px dashed #C6C6C6;
      border-left: 1px dashed #C6C6C6;
      //box-shadow: 0 4px 10px rgba(167,38,142,.15)
      //border-radius: 10px
      //margin: 50px 0
      //background: #ffffff
      &_inner {
        display: flex;
        width: 100%;
        height: 100%;
        //background: #efeff1
 }        //border-radius: 10px
      &_el {
        position: relative;
        height: 100%;
        //border-right: 2px dashed #a7268e
        &.answer {
 }          //background: #efc3c3
        &:after {
          display: block;
          font-weight: 700;
          font-size: 17px;
          color: #a7268e;
          position: absolute;
          top: -35px;
          left: 0;
          @include for-phone {
            font-size: 12px;
            top: -18px; } }
        &.active {
          //background: #f0c975
          &.answer {
 } }            //background: #b5e1db
        &:first-child {
          //width: 18%
          width: 46%;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          &:after {
            content: "45"; } }
        &:nth-child(2) {
          width: 5%;
          //&:after
          //  content: "15"
 }          //  transform: translate(-50%, 0)
        &:nth-child(3) {
          width: 8%;
          //border-right: 2px solid #a7268e
          //&:after
          //  content: "16"
 }          //  transform: translate(-50%, 0)
        &:nth-child(4) {
          width: 15%;
          border-right: 1px dashed #C6C6C6; }
        &:nth-child(5) {
          width: 18%;
          border-right: 1px dashed #C6C6C6;
          &:after {
            content: "20 000";
            transform: translate(-50%, 0);
            @include for-phone {
              content: "20к"; } } }
        &:nth-child(6) {
          width: 36%;
          border-right: none;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          &:after {
            content: "40 000";
            transform: translate(-50%, 0);
            @include for-phone {
              content: "40к"; } }
          &:before {
            //content: "60 000"
            content: "65 000";
            display: block;
            font-weight: 700;
            font-size: 17px;
            color: #a7268e;
            position: absolute;
            top: -35px;
            right: 0;
            @include for-phone {
              content: "65к";
              font-size: 12px;
              top: -18px; } } } } }

    .rangeslider {
      margin-top: 0;
      background-color: #B2B2B2;
      &-horizontal {
        height: 5px;
        box-shadow: none; }
      &__fill {
        box-shadow: none;
        background-color: transparent; }
      &__handle {
        width: 13px;
        height: 13px;
        background: #a7268e;
        border: none;
        box-shadow: none;
        &:after {
          display: none;
          //position: absolute
          //top: -36px
          //left: 50%
          //transform: translate(-50%, 0)
          //width: 0
          //height: 0
          //border: 13px solid transparent
          //border-bottom: 30px solid #a7268e
          //background: transparent
          //box-shadow: none
 } }          //border-radius: 0
      &__handle-label {}
      &__handle-tooltip {
        display: block;
        top: 20px;
        &:after {
          display: none;
          background-color: transparent;
          box-shadow: none; } } }
    .info {
      margin-bottom: 20px;
      padding: 44px 48px;
      border-radius: 10px;
      background: #e3fffb;
      @include for-phone {
        padding: 30px 20px; }
      &_head {
        font-size: 18px;
        font-weight: bold;
        //text-transform: uppercase
        margin-bottom: 15px;
        color: #3fc4b1; }
      &Text {
        //color: #2c3032
        font-size: 18px;
        white-space: pre-line;
        @include for-phone {
          font-size: 14px;
          line-height: 16px; } }
      &.error {
        background: #ffe3e3;
        .info_head {
          color: #f12626; } } }
    .btn {
      position: absolute;
      z-index: 2;
      bottom: -25px;
      right: 0;
      transform: translate(0, 100%);
      @include for-tablet {
        right: 0;
        transform: translate(0, 100%); }
      &.btn_blue {
        //max-width: 280px
        max-width: 240px;
        @include for-phone {
          max-width: 140px; } } } }
  .step12 {
    .flex {
      align-items: center;
      &_el {}
      .info {
        &_el {
          &:not(:last-child) {
            margin-bottom: 50px; } } } } }
  .step13 {
    .DefinitionBlock {
      border: 1px solid #a7268e;
      .text_pink {
        color: #A7268E; } }
    .flex {
      display: flex;
      align-items: center;
      &_img {
        flex-shrink: 0; } } }
  .step14 {
    .info {
      &_el {
        display: flex;
        margin-bottom: 20px;
        &_number {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: 14px;
          border: 2px solid #a7268e;
          background: #ffffff;
          color: #a7268e;
          font-weight: bold;
          font-size: 14px;
          border-radius: 50%; }
        &_head {
          margin: 2px 0 20px;
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
          color: #a7268e; }
        &_description {
          margin: 0;
          font-size: 17px;
          line-height: 24px;
          color: #2c3032;
          font-weight: 400; } }
      &_purple {
        font-weight: bold;
        color: #a7268e; } }
    .imgCenter {
      margin-bottom: 40px; } }
  .step15 {
    padding-bottom: 600px;
    .flex {
      display: flex;
      justify-content: space-between;
      &_el {
        width: 50%;
        &:last-child {
          width: 46%; } } }
    .OnClickBlock {
      position: relative;
      .flex {
        flex-wrap: wrap;
        margin-top: 0;
        &_el {
          width: 50%;
          margin-bottom: 20px;
          &_wrp {
            height: 53px;
            border-radius: 5px;
            border: 1px solid #a7268e; } } }
      .imgDiv {
        position: absolute;
        left: 0;
        top: 300px;
        width: 200%;
        background: #ffffff;
        .info {
          &_head {
            margin-bottom: 10px;
            font-size: 17px;
            color: #a7268e;
            font-weight: 700;
            text-transform: uppercase; }
          &_flex {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            &_el {
              &:first-child {
                margin-right: 20px;
                flex-shrink: 0; } } }
          &_definition {
            margin-bottom: 20px;
            padding: 25px;
            font-size: 17px;
            border-radius: 10px;
            border: 1px solid #a7268e;
            font-style: italic; } } } } }
  .step16 {
    .info {
      &_el {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &_number {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 60px;
          height: 60px;
          margin-right: 14px;
          background: #d631ae;
          border-radius: 50%; }
        &_text {
          font-size: 17px;
          line-height: 24px;
          color: #2c3032;
          font-weight: 400; } }
      &_purple {
        font-weight: bold;
        color: #a7268e; } } }
  .step17 {
    .box {
      padding: 40px;
      border: 1px solid #c82da5;
      box-sizing: border-box;
      border-radius: 20px;
      @include for-phone {
        padding: 0;
        border: none; } }
    .VideoBlock {
      border: none;
      margin-top: 0; } }
  .step18 {
    .TextBlock {
      line-height: 26px; }
    .flex {
      margin-top: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_el {
        display: flex;
        justify-content: center;
        &.active,
        &:hover {
          .flex_wrp {
            box-shadow: none;
            opacity: 1; }
          .flex_img {
            background: #a7268e;
            &_white {
              display: block; }
            &_pink {
              display: none; } } }
        &:last-child {
          margin-right: 0; } }
      &_wrp {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 120px;
        width: 120px;
        background: #fff;
        border: 1px solid #a7268e;
        box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
        border-radius: 50%;
        opacity: 0.5;
        cursor: pointer;
        transition: .5s; }
      &_img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        background: #efeff1;
        &_white {
          display: none; } } }

    .textDiv {
      margin-top: 19px;
      border: 1px solid #a7268e;
      border-radius: 10px;
      padding: 38px 30px 41px 46px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 6.5%;
        top: -1px;
        transform: translate(-50%, -50%) rotate(-45deg);
        height: 12px;
        width: 12px;
        background: #fff;
        border-top: 1px solid #a7268e;
        border-right: 1px solid #a7268e;
        transition: .5s; }
      &.textDiv--2 {
        &:before {
          left: 28.2%; } }
      &.textDiv--3 {
        &:before {
          left: 49.9%; } }
      &.textDiv--4 {
        &:before {
          left: 71.9%; } }
      &.textDiv--5 {
        &:before {
          left: 94.1%; } }
      .TextBlock {
        margin: 0; }
      &_head {
        color: #a7268e;
        font-weight: 700;
        font-size: 25px;
        margin-bottom: 20px; }
      &_flex {
        display: flex;
        align-items: center;
        &_el {
          &:first-child {
            flex-shrink: 0;
            margin-right: 30px; }
          .List {
            li:before {
              background: #f0c975; } } }
        &_img {
          border-radius: 50%; } } } }
  .step19 {
    .imgCenter {
      margin-bottom: 30px; }
    .info {
      margin-bottom: 40px;
      &_el {
        display: flex;
        margin-bottom: 20px;
        &_number {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          width: 24px;
          height: 24px;
          margin-right: 14px;
          border: 2px solid #a7268e;
          background: #ffffff;
          color: #a7268e;
          font-weight: bold;
          font-size: 14px;
          border-radius: 50%; }
        &_text {
          font-size: 17px;
          line-height: 24px;
          color: #2c3032;
          font-weight: 400; } }
      &_purple {
        font-weight: bold;
        color: #a7268e; } }
    .cat {
      display: flex;
      align-items: center;
      background: #f0f5fb;
      border-radius: 10px;
      padding: 20px 10px;
      &_img {
        margin-right: 30px; } } }
  .step20 {
    .flex {
      display: flex;
      margin-bottom: 40px;
      &_el {
        &:first-child {
          flex-shrink: 0;
          margin-right: 30px; } } }
    .question {
      display: flex;
      margin-bottom: 30px;
      &_el {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        height: 70px;
        font-weight: bold;
        font-size: 15px;
        color: #ffffff;
        text-align: center;
        background: #a7268e;
        border: 1px solid #a7268e;
        border-radius: 10px;
        &.arrow {
          border: none;
          background: #ffffff;
          width: 20%; }
        &.right {
          background: #ffffff;
          color: #a7268e; } } } }
  .step21 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_el {
        position: relative;
        width: 50%;
        @include for-phone {
          width: 100%; }
        &:first-child {
          margin-bottom: 30px; }
        &:last-child {
          width: 370px; }
        &_img {
          display: block;
          max-width: 100%;
          margin: 0 auto; } } }
    .block {
      &_head {
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
        color: #a7268e;
        &.bottom {
          text-transform: uppercase; } }
      .list {
        &_item {
          margin-bottom: 30px;
          font-size: 18px;
          line-height: 24px;
          padding-left: 40px;
          &:before {
            background: url("../../../image/lessons/lesson20/step5-2.png") no-repeat;
            height: 36px;
            width: 30px;
            top: 0; } } } } }
  .step22 {
    .flex {
      display: flex;
      align-items: center;
      &_el {
        &:first-child {
          flex-shrink: 0;
          margin-right: 30px; } } } }
  .step23 {
    .flex {
      display: flex;
      align-items: center;
      &_el {
        &:last-child {
          flex-shrink: 0;
          margin-left: 30px; } } } }
  .step25 {
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_text {
        font-weight: bold;
        font-size: 25px;
        line-height: 28px;
        color: #a7268e;
        text-transform: uppercase;
        width: 250px; }
      &_img {
        margin-top: -45px; } }
    .OnClickBlock {
      .imgDiv {
        &_wrap {
          &_flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            &_text {
              font-weight: bold;
              font-size: 18px;
              line-height: 24px;
              width: 130px;
              text-align: center; }
            .element {
              &:first-child {
                margin-right: 20px; }
              &:last-child {
                margin-left: 20px; } } }
          &_head {
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: #2c3032;
            margin-bottom: 30px; } } } } }
  .step26 {
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_text {
        font-weight: bold;
        font-size: 25px;
        line-height: 28px;
        color: #a7268e;
        text-transform: uppercase;
        width: 310px; }
      &_img {
        margin-top: -45px; } }
    .features {
      margin-bottom: 40px;
      font-size: 20px;
      line-height: 25px;
      color: #2c3032;
      text-align: center;
      &_pink {
        font-weight: bold;
        color: #a7268e; } }
    .substances {
      display: flex;
      &_el {
        &:first-child {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          flex-grow: 1;
          margin-right: 20px; }
        &_block {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48%;
          height: 59px;
          font-size: 14px;
          line-height: 18px;
          color: #a7268e;
          text-align: center;
          font-weight: bold;
          border: 1px solid #f2deee;
          box-shadow: 0 3px 4px rgba(0,0,0,.15);
          border-radius: 10px;
          cursor: pointer;
          transition: 0.5s;
          &:hover {
            color: #ffffff;
            border: 1px solid #a7268e;
            background: #a7268e; } }
        &:last-child {
          width: 200px; } } }
    .Popup {
      &_head {
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 25px;
        line-height: 29px;
        color: #a7268e;
        text-align: center;
        text-transform: uppercase; } }
    .List {
      li:before {
        background: #a7268e; } }
    .note {
      font-size: 14px;
      line-height: 18px;
      font-style: italic; }
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .flex {
          display: flex;
          flex-wrap: wrap;
          margin-top: 48px;
          margin-bottom: 24px;
          justify-content: space-between;
          align-items: center;
          &_el {
            margin-bottom: 58px;
            width: 50%;
            .answers {
              position: relative;
              display: flex;
              flex-direction: column;
              margin-top: 0;
              margin-bottom: 0;
              .property {
                position: relative;
                display: flex;
                align-items: center;
                &:not(:last-child) {
                  margin-bottom: 15px; } }
              &_item {
                position: relative;
                &:not(:last-child) {
                  margin-bottom: 15px; }
                &.green {
                  .answer {
                    color: #aed137;
                    border: 2px solid #aed137; } }
                &.yellow {
                  .answer {
                    color: #fcb814;
                    border: 2px solid #fcb814; } }
                &.blue {
                  .answer {
                    color: #4475a4;
                    border: 2px solid #4475a4; } }
                &.red {
                  .answer {
                    color: #cf2228;
                    border: 2px solid #cf2228; } }
                &.violet {
                  .answer {
                    color: #937bb2;
                    border: 2px solid #937bb2; } } }
              .answer {
                position: relative;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #a7268e;
                text-align: center;
                text-transform: uppercase;
                background-color: #ffffff;
                border: 2px solid #a7268e;
                box-shadow: none;
                padding: 10px;
                @media screen and (max-width: 550px) {
                  font-size: 10px;
                  line-height: 13px; }
                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8; }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B; } } } }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } } } }
        .questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .question_el {
            width: 32%;
            height: 50px;
            margin-bottom: 24px;
            &:nth-child(1) {
              order: 1; }
            &:nth-child(2) {
              order: 3; }
            &:nth-child(3) {
              order: 2; }
            &:nth-child(4) {
              order: 6; }
            &:nth-child(5) {
              order: 4; }
            &:nth-child(6) {
              order: 5; }
            &:nth-child(7) {
              order: 7;
              margin: 0 auto;
              width: 40%; }
            &.hidden {
              cursor: default;
              .ddcontainersource {
                font-size: 0;
                background-color: #E6EFF7;
                box-shadow: none;
                border: none; } }
            .ddcontainer {
              width: 100%; } }
          .ddcontainersource {
            width: 100%;
            height: 50px;
            padding: 5px 10px;
            display: flex!important;
            justify-content: center;
            align-items: center;
            border: 1px solid #a7268e;
            border-radius: 10px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #a7268e;
            text-align: center;
            text-transform: uppercase;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            cursor: pointer; }
          .ddcontainerghost {
            width: 260px;
            height: 50px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #a7268e;
            border-radius: 10px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #a7268e;
            text-align: center;
            text-transform: uppercase;
            @include for-tablet {
              width: 340px; }
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center; } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 14px;
          line-height: 16px; } }
      //Стили мобильной версии
      .test_mobile {
        .flex {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
          &_el {
            width: 100%;
            max-width: 300px; }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } }
            .img {
              width: 100%;
              max-width: 300px;
              height: auto; } } }
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          box-sizing: border-box;
          .property {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            margin-bottom: 65px;
            padding-left: 80px;
            padding-right: 70px;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #ffffff;
            text-transform: uppercase;
            background-color: #005EAD;
            border-radius: 10px;
            &_img {
              position: absolute;
              top: -15px;
              left: 7px; }
            &_arrow, &_plus {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0); }
            &_arrow {
              bottom: -45px; }
            &_plus {
              font-weight: normal;
              font-size: 30px;
              line-height: 45px;
              color: #005EAD;
              bottom: -174px; } }
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 55px;
            margin-bottom: 60px;
            padding: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #99BFDE;
            background-color: #ffffff;
            border: 2px solid #99BFDE;
            border-radius: 10px;
            text-align: center;
            text-transform: uppercase;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } }
          &_number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border: 1px solid #4C8EC5;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            color: #3E373C;
            background-color: #ffffff;
            border-radius: 50%;
            &.selected {
              color: #4C8EC5;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 48px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%; }
            .questions {
              display: flex;
              flex-direction: column;
              &_item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                font-weight: bold;
                color: #005EAD;
                text-transform: uppercase;
                border: 1px solid #99BFDE;
                border-radius: 10px;
                box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
                margin-bottom: 20px;
                &:nth-child(1) {
                  order: 1; }
                &:nth-child(2) {
                  order: 4; }
                &:nth-child(3) {
                  order: 2; }
                &:nth-child(4) {
                  order: 5; }
                &:nth-child(5) {
                  order: 3; }
                &:nth-child(6) {
                  order: 6; }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; } } } }
          &_text {
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 10px; } } }
    .imgDiv {
      &_wrap {
        &_head {
          margin-bottom: 30px;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: center; }
        &_img {
          margin-bottom: 40px; } } } }
  .step27 {
    .block {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      &_img {
        display: block;
        margin-left: auto; }
      .DefinitionBlock {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 65%;
        background: #ffffff;
        border: 1px solid #f2deee;
        .text_pink {
          color: #a7268e; } } }
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: -20px;
      &_el {
        width: 48%;
        border: 1px solid #f2deee;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 24px;
        margin-right: 20px;
        &_li {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          &_img {
            width: 60px;
            height: 60px;
            margin-right: 20px; }
          &_text {
            font-size: 17px;
            line-height: 24px; }
          &_pink {
            color: #a7268e; } } } } }
  .step28 {
    .flex {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      &_el {
        &:first-child {
          margin-right: 20px; } } }
    .advantages {
      display: flex;
      margin-bottom: 25px;
      &_number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        height: 27px;
        width: 27px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #a7268e;
        border: 1px solid #a7268e;
        border-radius: 50%;
        margin-right: 18px; } }
    .imgCenter {
      margin-bottom: 30px; } }
  .step29 {
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &_text {
        font-weight: bold;
        font-size: 25px;
        line-height: 28px;
        color: #a7268e;
        text-transform: uppercase;
        width: 310px; }
      &_img {
        margin-top: -45px; } }
    .imgDiv {
      &_wrap {
        &_flex {
          display: flex;
          justify-content: space-between; } } } }
  .step30, .step31, .step32 {
    padding-bottom: 100px;
    @include for-phone {
      padding-bottom: 50px; }
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_el {
        display: flex;
        flex-direction: column;
        width: 48%;
        @include for-phone {
          width: 100%;
          margin-bottom: 80px; }
        &_head {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #a7268e;
          text-align: center;
          text-transform: uppercase; }
        &_box {
          position: relative;
          flex-grow: 1;
          padding: 25px 25px 120px 25px;
          border: 1px solid #f2deee;
          border-radius: 10px;
          @include for-phone {
            padding-bottom: 25px; } }
        &_img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 30%);
          @include for-phone {
            position: relative;
            transform: translate(0, 0);
            left: auto;
            max-width: 100%; } } } }
    .TextBlock {
      .text_whiskas {
        font-weight: normal; } } }
  .step33 {
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      &_el {
        width: 48%;
        &_head {
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          text-transform: uppercase;
          color: #2c3032;
          font-weight: bold;
          margin-bottom: 20px; }
        &_text {
          font-size: 18px;
          line-height: 21px;
          text-transform: uppercase;
          color: #2c3032;
          margin-bottom: 20px;
          text-align: center;
 }          //padding-left: 25px
        .list {
          &_item {
 } } } } } }            //text-transform: uppercase





