@font-face {
  font-family: 'GothamPro';
  src: url('fonts/GothamProBold.eot');
  src: url('fonts/GothamProBold.eot?#iefix') format('embedded-opentype'),
  url('fonts/GothamProBold.woff2') format('woff2'),
  url('fonts/GothamProBold.woff') format('woff'),
  url('fonts/GothamProBold.ttf') format('truetype'),
  url('fonts/GothamProBold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url('fonts/GothamProRegular.eot');
  src: url('fonts/GothamProRegular.eot?#iefix') format('embedded-opentype'),
  url('fonts/GothamProRegular.woff2') format('woff2'),
  url('fonts/GothamProRegular.woff') format('woff'),
  url('fonts/GothamProRegular.ttf') format('truetype'),
  url('fonts/GothamProRegular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamPro';
  src: url('fonts/GothamProMedium.eot');
  src: url('fonts/GothamProMedium.eot?#iefix') format('embedded-opentype'),
  url('fonts/GothamProMedium.woff2') format('woff2'),
  url('fonts/GothamProMedium.woff') format('woff'),
  url('fonts/GothamProMedium.ttf') format('truetype'),
  url('fonts/GothamProMedium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'GothamPro';
  src: url('fonts/gothaprolig-webfont.eot');
  src: url('fonts/gothaprolig-webfont.eot?#iefix') format('embedded-opentype'),
  url('fonts/gothaprolig-webfont.woff') format('woff'),
  url('fonts/gothaprolig-webfont.ttf') format('truetype'),
  url('fonts/gothaprolig-webfont.svg') format('svg');
  font-weight: 100;
  font-style: normal;
}

body {
  margin: 0;
  overflow-y: scroll;
  font-family: 'GothamPro', sans-serif;
}
