.PanelStudy {
  width: calc((100% / 4) - 15px);
  background: #005EAD;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 15px;
  @media screen and (max-width: 1600px) {
    width: calc((100% / 3) - 15px); }
  @media screen and (max-width: 1100px) {
    width: calc((100% / 2) - 15px); }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0; }
  @media screen and (max-width: 500px) {
    border-radius: 0; }

  .done {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 0.02em; }
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #fff; }

  .desc {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 15px;
    width: 100%;
    color: #fff;
    .steps {
      height: 96px;
      width: 96px;
      border-radius: 100px;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border: 2px solid #fff;
      position: relative;
      background: #fff;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 2px;
        background: #005EAD;
        transform: translate(-50%, -50%) rotate(-45deg);
        transform-origin: center; }
      .userSteps {
        font-size: 34px;
        font-weight: bold;
        color: #005EAD;
        letter-spacing: -0.1em;
        position: relative;
        height: 40px;
        padding-right: 25px; }
      .allStep {
        font-size: 26px;
        color: #005EAD;
        letter-spacing: -0.05em;
        position: relative;
        text-align: center;
        height: 40px;
        padding-left: 25px; } }




    .text {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      color: #fff;
      span {
        display: block;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        font-weight: bold;
        margin-bottom: 10px; }
      .button {
        width: 100%;
        .btn {
          height: 30px;
          font-size: 12px; } } } }
  .star {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    position: relative;
    white-space: nowrap;
    .line {
      width: 100%;
      height: 1px;
      margin-left: 20px;
      background: #fff; }
    svg {
      margin-right: 8px; } }

  .disabled {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#EDF3F9, .7); }



  &.active {
    background: #fff;
    .name {
      color: #99BFDE; }
    .desc {
      color: #005EAD;
      .steps {
        border-color: #005EAD; }
      .text span {
        color: #005EAD; } }
    .star {
      color: #005EAD;
      svg path {
          fill: #005EAD; }
      .line {
        background: #005EAD; } } }
  &.hidden {
    display: none; } }

