@import "../../../Page/mixin";
.ArrowBack {
  display: none;
  @include for-tablet {
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: -40px;
    left: 50%;
    width: 35px;
    height: 35px;
    background-color: #ffffff;
    border: 2px solid #039cdd;
    border-radius: 50%;
    transform: translateX(-50%);
    animation: ArrowBack 1s linear infinite;
    div {
      position: absolute;
      top: 14px;
      left: 6px;
      width: 20px;
      height: 3px;
      background-color: #039cdd;
      display: block;
      border-radius: 10px;
      &::after {
        content: '';
        position: absolute;
        width: 10px;
        height: 3px;
        top: -3px;
        left: -2px;
        background-color: #039cdd;
        transform: rotate(-45deg);
        border-radius: 10px; }

      &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 3px;
        top: 3px;
        left: -2px;
        background-color: #039cdd;
        transform: rotate(45deg);
        border-radius: 10px; } } }
  &.hidden {
    display: none; }

  @keyframes ArrowBack {
    0% {
      left: 5%; }
    50% {
      left: 2%; }
    100% {
      left: 5%; } } }
