.SwipeBlock {
  display: none;
  justify-content: flex-start;
  align-items: center;
  background: #E6EFF7;
  padding: 20px 30px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #99BFDE;
  white-space: pre-line;
  @media screen and (max-width: 1023px) {
    display: flex; }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 20px; }
  &_danger {
    background: #F9EEEC;
    color: #BD4E3B;
    font-weight: bold;
    align-items: flex-start; }
  svg {
    margin-right: 30px;
    flex-shrink: 0; }
  &.hidden {
    display: none; } }
