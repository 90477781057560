@import "../../../Page/mixin";
.step.lesson36 {
  padding-bottom: 48px;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  color: #3E373C;

  .MainHeader {
    .header {
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      color: #BA0D0E;
      text-transform: uppercase; }
    .subheader {
      margin-top: 10px;
      font-size: 30px;
      line-height: 37px;
      color: #BA0D0E; } }
  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .textBlue {
    color: #005EAD; }
  .textRed {
    color: #BD4E3B; }
  .infoPopap {
    position: fixed;
    z-index: 222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#345178, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrp {
      background: #fff;
      border-radius: 25px;
      padding: 70px;
      position: relative;
      width: 100%;
      max-width: 640px; }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba(#005EAD, .3);
        transition: .5s; }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: rgba(#005EAD, 1); }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }

    &_scroll {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
      overflow-y: auto; }
    &_iconDiv {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      overflow: hidden;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      &.red {
        color: #BD4E3B; } }
    &_text {
      font-size: 16px;
      color: #3E373C;
      margin-bottom: 30px;
      &_head {
        font-size: 16px;
        color: #005EAD;
        font-weight: bold;
        display: block; } }
    &_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      &ImgDiv {
        height: 96px;
        width: 96px;
        flex-shrink: 0;
        border: 2px solid #005EAD;
        border-radius: 50%;
        background: #fff;
        margin-right: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
        &.chain {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 100%;
            width: 3px;
            background: #005EAD; } } }
      &:last-child {
        .chain {
          &:before {
            display: none; } } }
      .infoPopap_text {
        margin-bottom: 0; } }


    &_wrp.danger {
      background: #F9EEEC;
      .infoPopap_iconDiv {
        background: #F9EEEC; }
      .infoPopap_close {
        &:before,
        &:after {
          background: rgba(#BD4E3B, .3); }
        &:hover {
          &:before,
          &:after {
            background: rgba(#BD4E3B, 1); } } }
      .infoPopap_text {
        li:before {
          background: #BD4E3B; } }
      .infoPopap_head {
        color: #BD4E3B; } }





    &_imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      max-width: 100%;
      img {
        margin-top: 45px;
        max-width: 100%; } }
    .btn {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      font-size: 14px;
      width: 160px; } }

  ul {
    padding-left: 0;
    li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      color: #3E373C;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #BA0E0E; }
      @include for-phone {
        font-size: 16px; } } }
  .ul {
    .liHead {
      font-size: 18px;
      color: #005EAD;
      font-weight: bold;
      text-transform: uppercase;
      margin: 40px 0 35px; }
    .li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 35px;
      @media screen and (max-width: 499px) {
        flex-direction: column; }
      .number {
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        border: 1px solid #005EAD;
        border-radius: 50%;
        margin-right: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #005EAD;
        @media screen and (max-width: 499px) {
          margin-bottom: 10px; } }
      .text {
        font-size: 18px;
        color: #3E373C; } } }

  .infoPopap {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    background: rgba(#345178, .8);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 112px;
    &_wrp {
      width: 100%;
      max-width: 640px;
      padding: 70px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      .popap_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99BFDE;
        cursor: pointer;
        transition: .5s;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s; }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover {
          &:before,
          &:after {
            background: #005EAD; }
          &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(45deg); } } }
      .image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        &Bg {
          width: 100%;
          height: 100%;
          background: #E6EFF7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            border-radius: 50%; } } }
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line; }
      .subHead {
        color: #1F72B7;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        white-space: pre-line;
        margin-bottom: 30px; }
      .slider {
        border: 2px solid #CCDFEF;
        border-radius: 10px;
        position: relative;
        padding: 25px;
        margin-bottom: 50px;
        .slide {
          color: #4C8EC5;
          font-size: 16px;
          line-height: 24px; }
        .slick-arrow {
          bottom: 0; } }
      .btn {
        position: relative;
        left: 50%;
        transform: translate(-50%); } } }

  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .flex_head {
      font-weight: bold;
      font-size: 22px;
      color: #005EAD; }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 50px;
      &Img {
        height: 240px;
        width: 240px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 25px;
        flex-shrink: 0; } }
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #146BB3; }
    .text {
      font-size: 18px;
      line-height: 26px;
      color: #3E373C;
      margin-top: 20px; }
    .lessonSteps {
      margin-top: 25px;
      &_el {
        margin-bottom: 20px;
        padding: 15px 20px;
        border: 2px solid #005EAD;
        border-radius: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #005EAD;
        &:last-child {
          margin-bottom: 0; } } } }
  .step2, .step5 {
    .box {
      padding: 36px;
      border: 1px solid #4C8EC5;
      box-sizing: border-box;
      border-radius: 20px;
      @include for-phone {
        padding: 0;
        border: none; } }
    .VideoBlock {
      border: none;
      margin-top: 0; } }
  .step3 {
    .fact, .history {
      padding: 35px;
      margin-bottom: 30px;
      background: #E6EFF7;
      border-radius: 10px;
      @include for-phone {
        padding: 20px; }
      &_el {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 26px; }
        &_img {
          margin-right: 24px; }
        .TextBlock {
          margin-bottom: 0;
          color: #4C8EC5;
          @include for-phone {
            color: #2C3032; }
          & b {
            font-weight: normal; } } } }
    .history {
      background: #ffffff;
      border: 2px solid #E6EFF7;
      box-sizing: border-box;
      border-radius: 10px;
      @include for-phone {
        padding: 0;
        border: none; }
      &_el {
        position: relative;
        padding: 12px 10px 12px 0;
        @include for-phone {
          height: 72px;
          overflow: hidden;
          padding: 12px 5px 12px 0;
          border-radius: 10px; }
        &:before {
          content: "";
          position: absolute;
          z-index: 2;
          top: 0;
          width: 100%;
          height: 100%;
          margin: 0 -6px;
          background: #E6EFF7;
          border: 1px solid #99BFDE;
          box-sizing: border-box;
          box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
          border-radius: 10px;
          transition: .5s;
          cursor: pointer;
          @include for-phone {
            margin: 0; } }
        &_img-box {
          z-index: 3;
          @include for-phone {
            position: absolute;
            //left: 50%
            //top: 50%
            //transform: translate(-50%, -50%)
            transition: 0.5s;
            width: 100%;
            display: flex;
            justify-content: center; } }
        &_img {
          z-index: 3;
          cursor: pointer;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          @include for-phone {
            display: block;
            margin-right: 0; } }
        .TextBlock {
          margin-bottom: 0;
          color: #3E373C; }
        &.active {
          height: auto;
          overflow: visible;
          &:before {
            border: none;
            opacity: 0; }
          .history_el_img-box {
            position: relative;
            //left: 0
            //transform: translate(0, 0)
            width: auto;
            justify-content: flex-start;
            margin-right: 24px; } } } } }
  .step4 {
    .img {
      margin: 20px 0; }
    .info {
      position: relative;
      height: 60px;
      @include for-phone {
        display: none; }
      &_item {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -100%) scaleY(0);
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 320px;
        height: 200px;
        padding: 0 40px;
        background: #FFFFFF;
        border: 2px solid #BA0E0E;
        box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        transition: 0.5s 0s;
        transform-origin: 0% 100%;
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -2px;
          transform: translate(-50%, 50%) rotate(-45deg);
          height: 12px;
          width: 12px;
          background: #fff;
          border-bottom: 2px solid #BA0E0E;
          border-left: 2px solid #BA0E0E;
          transition: .5s; }
        &:first-child {
          left: -15px;
          transform: translate(0, -100%) scaleY(0);
          &:before {
            left: 30px; } }
        &:nth-child(2) {
          left: 12.8%; }
        &:nth-child(3) {
          left: 23.3%;
          &:before {
            left: 50%; } }
        &:nth-child(4) {
          left: 33.9%;
          &:before {
            left: 50%;
            transform: translate(-50%, 50%) rotate(-45deg); } }
        &:nth-child(5) {
          left: 44.4%;
          &:before {
            left: 50%;
            transform: translate(-50%, 50%) rotate(-45deg); } }
        &:nth-child(6) {
          left: 55.2%;
          &:before {
            left: 50%;
            transform: translate(-50%, 50%) rotate(-45deg); } }
        &:nth-child(7) {
          left: 65.9%;
          &:before {
            left: 50%;
            transform: translate(-50%, 50%) rotate(-45deg); } }
        &:nth-child(8) {
          left: 76.4%;
          &:before {
            left: 50%;
            transform: translate(-50%, 50%) rotate(-45deg); } }
        &:nth-child(9) {
          left: 87%; }
        &:nth-child(10) {
          left: auto;
          right: -15px;
          transform: translate(0, -100%) scaleY(0);
          &:before {
            left: auto;
            right: 16px; } }
        &_show {
          transform: translate(-50%, -100%) scaleY(1);
          transition: 0.5s 0.5s;
          &:first-child {
            transform: translate(0, -100%) scaleY(1); }
          &:last-child {
            transform: translate(0, -100%) scaleY(1); } } }
      &_year {
        margin: 0;
        font-weight: bold;
        font-size: 25px;
        line-height: 35px;
        color: #BA0E0E;
        text-align: center; }
      &_text {
        margin: 0;
        font-size: 17px;
        line-height: 24px;
        text-align: center; } }
    .progress {
      position: relative;
      --left: 1%;
      @include for-phone {
        display: none; }
      &_big {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 46px);
        display: flex;
        height: 12px;
        background: #E6EFF7;
        &_item {
          width: 11.11%;
          height: 12px;
          background: #FDB813;
          transition: 0.5s;
          transform: scaleX(0);
          transform-origin: 0 100%;
          &.active {
            transform: scaleX(1); } } }
      &_small {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 46px);
        display: flex;
        height: 2px;
        background: #99BFDE;
        z-index: 3;
        &_item {
          width: 12%;
          height: 2px;
          background: #BA0E0E;
          transition: 0.5s ease-in-out;
          transform: scaleX(0);
          transform-origin: 0 100%;
          &:first-child {
            width: 1.6%; }
          &:last-child {
            width: 9%; }
          &.active-first {
            transform: scaleX(1); }
          &.active {
            transform: scaleX(1); } } }
      &_points {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        &_item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          background: #E6EFF7;
          border-radius: 50%;
          transition: 0.5s 0.2s;
          cursor: pointer;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          &.active {
            background: #FDB813; }
          &_red {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            background: #99BFDE;
            border-radius: 50%;
            font-size: 0;
            color: #ffffff;
            transition: 0.5s 0.2s;
            cursor: pointer;
            z-index: 4;
            &.active {
              background: #BA0E0E; } } } }
      &_cursor {
        position: absolute;
        top: 0;
        left: var(--left);
        transform: translate(0, -130%);
        opacity: 0;
        transition: 0.5s;
        &.show {
          opacity: 1; } } }
    .progress-phone {
      display: none;
      @include for-phone {
        display: block; }
      &_box {
        display: none;
        margin: 0 -40px 0 -20px;
        &.active {
          display: flex; }
        &:first-child {
          .progress-phone_el.second {
            .progress-phone_el_point {
              justify-content: flex-end;
              .progress-phone_el_small {
                width: 60%; } } } }
        &:last-child {
          .progress-phone_el_point {
            justify-content: flex-start;
            .progress-phone_el_small {
              width: 60%; } } } }
      &_el {
        display: flex;
        align-items: center;
        &.first {
          width: 10%;
          margin-right: -10px; }
        &.second {
          width: 55%;
          margin-right: -10px;
          .progress-phone_el_big {
            margin-left: -10px; } }
        &.third {
          width: 35%;
          .progress-phone_el_big {
            margin-left: -10px; } }
        &_big {
          display: flex;
          align-items: center;
          width: 100%;
          height: 36px;
          background: #E6EFF7;
          &.active {
            background: #FDB813; } }
        &_small {
          width: 100%;
          height: 4px;
          background: #99BFDE;
          &.active {
            background: #BA0E0E; } }
        &_point {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 96px;
          height: 96px;
          background: #E6EFF7;
          border-radius: 50%;
          flex-shrink: 0;
          &.active {
            background: #FDB813; }
          &_red {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            font-weight: bold;
            font-size: 24px;
            line-height: 34px;
            color: #ffffff;
            text-align: center;
            background: #99BFDE;
            border-radius: 50%;
            &.active {
              background: #BA0E0E; } } } } }
    .Popup {
      &_year {
        margin: 0 0 10px;
        font-weight: bold;
        font-size: 25px;
        line-height: 35px;
        color: #BA0E0E;
        text-align: center; }
      .TextBlock {
        text-align: center; }
      .btn_blue {
        margin: 0 auto; } } }
  .step6 {
    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 48%;
        margin-bottom: 30px;
        @include for-phone {
          width: 100%; }
        &_img {
          position: relative;
          display: block;
          margin: 0 auto;
          transition: 0.5s;
          transform: scale(1);
          z-index: 2;
          &.active {
            transform: scale(0.5);
            @include for-phone {
              transform: scale(1); } } }
        &_block {
          border: 2px solid #BA0E0E;
          box-sizing: border-box;
          border-radius: 10px;
          transition: 0.5s;
          transform: translate(0, 0) scale(0);
          &.active {
            margin-top: -10px;
            transform: translate(0, -30px) scale(1);
            @include for-phone {
              transform: translate(0, -5px) scale(1); } } }
        &_head {
          padding: 18px 15px 13px;
          font-size: 17px;
          line-height: 17px;
          color: #ffffff;
          text-transform: uppercase;
          text-align: center;
          border-radius: 5px 5px 0 0;
          background: #BA0E0E; }
        &_description {
          padding: 15px;
          font-size: 17px;
          line-height: 24px;
          text-align: center; } } } }
  .step7 {
    .block {
      position: relative;
      width: 100%;
      margin-top: 96px;
      .point {
        position: absolute;
        transform: translate(-50%, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        font-weight: bold;
        font-size: 17px;
        line-height: 17px;
        color: #ffffff;
        background: #BA0E0E;
        border: 2px solid #ffffff;
        border-radius: 50%;
        cursor: pointer;
        z-index: 2;
        &.active {
          color: #BA0E0E;
          background: #ffffff;
          border: 2px solid #BA0E0E; }
        &:nth-child(1) {
          top: 20%;
          left: 54%; }
        &:nth-child(2) {
          top: 60%;
          left: 52%; }
        &:nth-child(3) {
          top: 77%;
          left: 54%; } }
      .string {
        position: absolute;
        width: 100px;
        height: 2px;
        background: #BA0E0E;
        transition: 0.5s;
        @include for-phone {
          display: none; }
        &:nth-child(4) {
          width: 200px;
          top: 17%;
          left: 54%;
          transform: rotate(-17deg) scale(0); }
        &:nth-child(5) {
          width: 250px;
          top: 48%;
          right: 47%;
          transform: rotate(30deg) scale(0); }
        &:nth-child(6) {
          width: 200px;
          top: 73%;
          left: 54%;
          transform: rotate(-20deg) scale(0); }
        &.active {
          &:nth-child(4) {
            width: 200px;
            top: 17%;
            left: 54%;
            transform: rotate(-17deg); }
          &:nth-child(5) {
            width: 250px;
            top: 48%;
            right: 47%;
            transform: rotate(30deg); }
          &:nth-child(6) {
            width: 200px;
            top: 73%;
            left: 54%;
            transform: rotate(-20deg); } } }
      &_img {
        display: block;
        margin: 0 auto; }
      .Popup {
        &_number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
          margin: 0 auto 30px;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          color: #BA0E0E;
          border: 4px solid #BA0E0E;
          border-radius: 50%;
          text-align: center; }
        &_head {
          margin-bottom: 20px;
          font-size: 24px;
          line-height: 24px;
          text-align: center;
          color: #BA0E0E; }
        &_description {
          margin-bottom: 48px;
          font-size: 16px;
          line-height: 22px;
          text-align: center; }
        .btn_blue {
          margin: 0 auto; }
        .List {
          margin-bottom: 48px; } } }
    .info {
      position: absolute;
      width: 280px;
      border: 2px solid #BA0E0E;
      box-sizing: border-box;
      border-radius: 10px;
      background: #ffffff;
      transition: 0.5s;
      transform: scale(0);
      @include for-phone {
        display: none; }
      &.top {
        top: 0;
        right: 0; }
      &.left {
        top: 0;
        left: 0; }
      &.bottom {
        bottom: 0;
        right: 0; }
      &.active {
        transform: scale(1); }
      &_head {
        padding: 18px 15px 13px;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        border-radius: 5px 5px 0 0;
        background: #BA0E0E; }
      &_description {
        padding: 15px;
        font-size: 14px;
        line-height: 14px; }
      ul {
        padding-left: 20px;
        li {
          margin-bottom: 15px;
          font-size: 14px;
          line-height: 14px;
          padding-left: 20px; } } } }
  .step8 {
    .InfoBlock {
      margin-bottom: 40px; }
    .flex {
      display: flex;
      justify-content: space-between;
      margin: 70px 0 10px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 33.25%;
        margin-bottom: 30px;
        @include for-phone {
          width: 100%; }
        &_img {
          position: relative;
          display: block;
          margin: 0 auto;
          transition: 0.5s;
          z-index: 2;
          opacity: 0.5;
          cursor: pointer;
          @include for-phone {
            opacity: 1; }
          &.active {
            opacity: 1; } }
        &_block {
          padding: 20px 10px 20px 30px;
          border: 4px solid #BA0E0E;
          box-sizing: border-box;
          border-radius: 10px;
          box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
          transition: 0.5s;
          transform: translate(0, 0) scale(0);
          @include for-phone {
            display: none; }
          &.active {
            margin-top: -10px;
            transform: translate(0, -10px) scale(1); } } } }
    .statistic {
      display: flex;
      width: 80%;
      margin: 0 auto;
      border: 4px solid #BA0E0E;
      box-sizing: border-box;
      border-radius: 10px;
      @include for-phone {
        flex-direction: column;
        width: auto;
        margin-right: -20px;
        margin-left: -20px;
        border: none;
        border-bottom: 4px solid #BA0E0E;
        border-radius: 0; }
      &_el {
        padding: 20px;
        @include for-phone;
        &.left {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          font-weight: bold;
          font-size: 17px;
          line-height: 20px;
          color: #ffffff;
          background: #BA0E0E;
          border-radius: 5px 0 0 5px;
          @include for-phone {
            border-radius: 0; } }
        li {
          color: #BA0E0E; } } }
    .Popup {
      &_img {
        display: block;
        margin: 0 auto 30px; }
      ul {
        max-width: 200px;
        margin: 0 auto 50px; }
      .btn_blue {
        margin: 0 auto; } } }
  .step9 {
    position: relative;
    padding-bottom: 5px;
    .InfoBlock {
      margin-bottom: 40px; }
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .flex {
          position: relative;
          display: flex;
          margin-top: 48px;
          margin-bottom: 24px;
          justify-content: space-between;
          align-items: center;
          &_el {
            width: 50%;
            background: #E6EFF7;
            &:nth-child(2) {
              background: rgba(230, 239, 247, 0.5); }
            &_head {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 182px;
              padding: 0 115px;
              font-size: 18px;
              line-height: 26px;
              color: #005EAD;
              text-align: center; } }
          .answers {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            margin-top: 0;
            padding: 10px;
            margin-bottom: 0;
            &_item {
              width: 48%;
              position: relative;
              &:nth-child(1), &:nth-child(2) {
                margin-bottom: 24px; } }
            .answer {
              position: relative;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 240px;
              height: 96px;
              font-size: 12px;
              line-height: 14px;
              color: #2C3032;
              text-align: center;
              background-color: #ffffff;
              border: 1px solid #99BFDE;
              box-shadow: none;
              padding: 10px;
              @media screen and (max-width: 550px) {
                font-size: 10px;
                line-height: 13px; }
              &.correct {
                font-weight: bold;
                color: #005EAD;
                background-color: #ffffff;
                border: 2px solid #005EAD; }
              &.inCorrect {
                color: #2C3032;
                background-color: #ffffff;
                border: 2px solid #CC3333; } } }
          &_img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0); } }
        .questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .question_el {
            width: 24%;
            height: 96px;
            margin-bottom: 24px;
            &:nth-child(1) {
              order: 1; }
            &:nth-child(2) {
              order: 4; }
            &:nth-child(3) {
              order: 2; }
            &:nth-child(4) {
              order: 5; }
            &:nth-child(5) {
              order: 3; }
            &:nth-child(6) {
              order: 6; }
            &.hidden {
              cursor: default;
              .ddcontainersource {
                font-size: 0;
                background-color: #E6EFF7;
                box-shadow: none;
                border: none; } }
            .ddcontainer {
              width: 100%; } }
          .ddcontainersource {
            width: 100%;
            height: 96px;
            padding: 5px 10px;
            display: flex!important;
            justify-content: center;
            align-items: center;
            border: 1px solid #99BFDE;
            border-radius: 10px;
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 14px;
            color: #2C3032;
            text-align: center;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            cursor: pointer; }
          .ddcontainerghost {
            width: 200px;
            height: 96px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #99BFDE;
            border-radius: 10px;
            font-size: 12px;
            line-height: 14px;
            color: #2C3032;
            text-align: center;
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center; } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } }
      //Стили мобильной версии
      .test_mobile {
        .flex {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
          &_el {
            width: 100%;
            max-width: 300px; }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } }
            .img {
              width: 100%;
              max-width: 300px;
              height: auto; } } }
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: auto;
          margin: 0 -20px;
          box-sizing: border-box;
          &_box {
            padding: 20px;
            background: #E6EFF7;
            &.bottom {
              background: rgba(230, 239, 247, 0.5); } }
          &_head {
            margin: 0 0 15px;
            font-weight: bold;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #333333; }
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 120px;
            padding: 10px;
            font-weight: bold;
            font-size: 25px;
            line-height: 30px;
            color: #2C3032;
            background-color: #ffffff;
            border: 2px solid #99BFDE;
            border-radius: 10px;
            text-align: center;
            &.selected {
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              color: #2C3032;
              background-color: #ffffff;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } }
            &:not(:last-child) {
              margin-bottom: 24px; }
            &.selected {
              color: #2C3032;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            height: calc(100vh - 92px);
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 48px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%; }
            .questions {
              display: flex;
              flex-direction: column;
              &_item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 120px;
                margin-bottom: 20px;
                padding: 20px;
                font-size: 16px;
                line-height: 19px;
                color: #2C3032;
                text-align: center;
                border: 1px solid #4C8EC5;
                border-radius: 10px;
                box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
                &:nth-child(1) {
                  order: 1; }
                &:nth-child(2) {
                  order: 4; }
                &:nth-child(3) {
                  order: 2; }
                &:nth-child(4) {
                  order: 5; }
                &:nth-child(5) {
                  order: 3; }
                &:nth-child(6) {
                  order: 6; }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; } } } }
          &_text {
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } } } }
  .step10 {
    .text {
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 19px;
      line-height: 27px;
      color: #4C8EC5;
      text-align: center;
      @include for-phone {
        font-size: 16px;
        line-height: 19px;
        color: #BA0E0E; } } }
  .step11, .step19, .step26 {
    .info {
      margin-bottom: 30px;
      padding: 35px 40px;
      border: 4px solid #BA0E0E;
      border-radius: 10px;
      @include for-phone {
        margin: 0 -20px 30px;
        padding: 20px;
        border: none;
        border-top: 4px solid #BA0E0E;
        border-bottom: 4px solid #BA0E0E;
        border-radius: 0; }
      &_el {
        display: flex;
        align-items: center;
        &_icon {
          margin-right: 20px; }
        &_text {
          font-weight: bold;
          font-size: 17px;
          line-height: 24px; } } } }
  .step12 {
    .confirmation {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      margin-top: 30px;
      padding: 35px 40px;
      border: 4px solid #BA0E0E;
      border-radius: 10px;
      @include for-phone {
        flex-direction: column;
        align-items: center;
        margin: 30px -20px 30px;
        padding: 20px;
        border: none;
        border-top: 4px solid #BA0E0E;
        border-bottom: 4px solid #BA0E0E;
        border-radius: 0; }
      &_img {
        margin-right: 15px; }
      &_text {
        font-weight: bold;
        font-size: 17px;
        line-height: 24px;
        color: #BA0E0E; } } }
  .step13 {
    .confirmation {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      margin-top: 30px;
      padding: 35px 40px;
      border: 4px solid #BA0E0E;
      border-radius: 10px;
      @include for-phone {
        flex-direction: column;
        align-items: center;
        margin: 30px -20px 30px;
        padding: 20px;
        border: none;
        border-top: 4px solid #BA0E0E;
        border-bottom: 4px solid #BA0E0E;
        border-radius: 0; }
      &_img {
        margin-right: 15px; }
      &_text {
        font-weight: bold;
        font-size: 17px;
        line-height: 24px;
        color: #BA0E0E; } }
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_el {
        &.recommend {
          position: relative;
          .flex_el_img {
            @include for-phone {
              display: block;
              width: 280px;
              height: auto;
              margin: 0 auto; } } }
        &_click {
          position: absolute;
          background-color: transparent;
          box-shadow: 0 10px 25px #000;
          cursor: pointer;
          &:hover {
            box-shadow: none; }
          &.doctor {
            top: 150px;
            right: 30px;
            width: 220px;
            height: 225px;
            @include for-phone {
              top: 80px;
              right: 17px;
              width: 130px;
              height: 135px; } }
          &.logo {
            left: 140px;
            bottom: 30px;
            width: 100px;
            height: 90px;
            @include for-phone {
              left: 80px;
              bottom: 14px;
              width: 60px;
              height: 55px; } } }
        &_img {
          max-width: 100%; } } }
    .Popup {
      &_block {
        background: #F7A932;
        border: 4px solid #FFFFFF;
        @include for-phone {
          background: #ffffff;
          border: none; } }
      &_img {
        max-width: 100%;
        margin-bottom: 40px; }
      .btn_blue {
        margin: 0 auto; } } }
  .step14, .step20 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        display: flex;
        flex-direction: column-reverse;
        .flex {
          display: flex;
          margin-top: 48px;
          margin-bottom: 24px;
          justify-content: space-between;
          align-items: center;
          border: 2px solid #99BFDE;
          border-radius: 20px;
          .answers {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 40%;
            margin-top: 0;
            &_item {
              position: relative;
              min-width: 210px;
              &:not(:last-child) {
                margin-bottom: 24px; } }
            &_number {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              left: 0;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 30px;
              height: 30px;
              border: 2px solid #99BFDE;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #99BFDE;
              background: #E6EFF7;
              border-radius: 50%;
              &.correct {
                background: #4085D8;
                border: 2px solid #4085D8;
                color: #ffffff; }
              &.inCorrect {
                background: #BD4E3B;
                border: 2px solid #BD4E3B;
                color: #ffffff; } }
            .answer {
              position: relative;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 240px;
              height: 60px;
              font-weight: bold;
              font-size: 16px;
              line-height: 22px;
              color: #99BFDE;
              text-align: center;
              background: #E6EFF7;
              border: 2px solid #99BFDE;
              box-shadow: none;
              padding: 10px;
              @media screen and (max-width: 550px) {
                font-size: 10px;
                line-height: 13px; }
              &.correct {
                font-weight: bold;
                color: #4085D8;
                background-color: #ffffff;
                border: 2px solid #4085D8; }
              &.inCorrect {
                font-weight: bold;
                color: #BD4E3B;
                background-color: #ffffff;
                border: 2px solid #BD4E3B; } } }
          &_img {
            position: relative;
            display: flex;
            justify-content: center;
            width: 60%;
            .img {
              transition: 0.5s;
              transform: scale(0);
              &.right {
                position: absolute;
                top: 0;
                left: 16%;
                transition: 0.5s 0.3s ease-in; }
              &.active {
                transform: scale(1); } } } }
        .questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .question_el {
            width: 25%;
            height: 50px;
            margin-bottom: 24px;
            &:nth-child(1) {
              order: 3; }
            &:nth-child(2) {
              order: 2; }
            &:nth-child(3) {
              order: 1; }
            &.hidden {
              cursor: default;
              .ddcontainersource {
                font-size: 0;
                background-color: #E6EFF7;
                box-shadow: none;
                border: none; } }
            .ddcontainer {
              width: 100%; } }
          .ddcontainersource {
            width: 100%;
            height: 50px;
            padding: 5px 10px;
            display: flex!important;
            justify-content: center;
            align-items: center;
            border: 2px solid #99BFDE;
            border-radius: 10px;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 22px;
            color: #3E373C;
            text-align: center;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            cursor: pointer; }
          .ddcontainerghost {
            width: 210px;
            height: 50px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: 2px solid #99BFDE;
            border-radius: 10px;
            font-size: 16px;
            line-height: 22px;
            color: #3E373C;
            text-align: center;
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center; } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } }
      //Стили мобильной версии
      .test_mobile {
        .flex {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          margin-bottom: 48px;
          &_el {
            width: 100%;
            max-width: 300px; }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } }
            .img {
              width: 100%;
              max-width: 300px;
              height: auto; } } }
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          padding-left: 15px;
          box-sizing: border-box;
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 60px;
            padding: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #99BFDE;
            background-color: #E6EFF7;
            border: 1px solid #99BFDE;
            border-radius: 10px;
            text-align: center;
            text-transform: uppercase;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid #005EAD;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #CC3333;
                color: #CC3333; } }
            &:not(:last-child) {
              margin-bottom: 24px; } }
          &_number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border: 1px solid #99BFDE;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            color: #E6EFF7;
            background-color: #99BFDE;
            border-radius: 50%;
            &.selected {
              color: #ffffff;
              background: #005EAD;
              border: 2px solid #005EAD;
              &.correct {
                background: #4085D8;
                border: 2px solid #4085D8;
                color: #ffffff; }
              &.inCorrect {
                background: #CC3333;
                border: 2px solid #CC3333;
                color: #ffffff; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 48px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #3E373C;
              background-color: #FDB813;
              border: 1px solid #FDB813;
              border-radius: 50%; }
            .questions {
              display: flex;
              flex-direction: column;
              &_item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 60px;
                margin-bottom: 20px;
                color: #000000;
                border: 2px solid #005EAD;
                border-radius: 10px;
                box-shadow: 0px 5px 10px rgba(0, 94, 173, 0.15);
                &:nth-child(1) {
                  order: 3; }
                &:nth-child(2) {
                  order: 2; }
                &:nth-child(3) {
                  order: 1; }
                &.selected {
                  color: #99BFDE;
                  border: 2px solid #CCDFEF;
                  box-shadow: none; } } } }
          &_text {
            color: #978974;
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } } } }
  .step15 {
    .InfoBlock {
      margin-bottom: 40px; }
    .flex {
      display: flex;
      justify-content: space-between;
      margin: 70px 0 10px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 224px;
        margin-bottom: 30px;
        @include for-tablet {
          width: 204px; }
        @include for-phone {
          width: 100%; }
        &_number {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          margin: 0 auto;
          font-size: 17px;
          line-height: 17px;
          background: #ffffff;
          border: 2px solid #005EAD;
          border-radius: 50%;
          transition: 0.5s;
          transform: scale(1);
          z-index: 2;
          cursor: pointer;
          @include for-phone {
            width: 48px;
            height: 48px; }
          &.active {
            font-weight: bold;
            font-size: 32px;
            line-height: 32px;
            color: #ffffff;
            background: #005EAD;
            transform: scale(0.5);
            @include for-phone {
              font-size: 18px;
              line-height: 18px;
              transform: scale(1); } } }
        &_block {
          transition: 0.5s;
          transform: translate(0, 0) scale(0);
          height: 0;
          &.active {
            margin-top: -18px;
            transform: translate(0, -10px) scale(1);
            height: auto; } }
        &_img-box {
          margin-bottom: 20px;
          padding: 20px 10px 20px 30px;
          border: 2px solid #005EAD;
          border-radius: 10px;
          @include for-phone {
            padding: 0; } }
        &_img {
          display: block;
          margin: 0 auto;
          @include for-phone {
            max-width: 100%; } }
        &_description {
          text-align: center; } } } }
  .step16 {
    .InfoBlock {
      margin-bottom: 40px; }
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_el {
        position: relative;
        width: 50%;
        @include for-phone {
          width: 100%; }
        &:first-child {
          margin-bottom: 30px; }
        &_img {
          display: block;
          max-width: 100%;
          margin: 0 auto; }
        &_block {
          position: absolute;
          top: -30px;
          right: 50%;
          width: 320px;
          padding: 30px 30px 20px;
          background: #ffffff;
          border: 4px solid #FDB813;
          box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          transition: 0.5s;
          transform: translate(40%, 0) scale(0);
          @include for-phone {
            display: none; }
          &.active {
            transform: translate(40%, 0) scale(1); }
          &_head {
            margin-bottom: 15px;
            font-weight: bold;
            font-size: 17px;
            line-height: 20px;
            color: #4C8EC5;
            text-align: center; }
          &_img {
            position: absolute;
            bottom: 50px;
            right: 20px; } }
        &_img-button {
          position: absolute;
          bottom: 210px;
          right: 50%;
          transform: translate(0, 50%);
          cursor: pointer; }
        ul {
          li {
            margin-bottom: 15px;
            font-size: 17px;
            line-height: 20px;
            &:before {
              background: #005EAD; } } } } }
    .Popup {
      display: none;
      @include for-phone {
        display: block; }
      &_block {
        position: relative; }
      &_img {
        display: block;
        margin: 0 auto 30px;
        &.bottom {
          position: absolute;
          top: 290px;
          left: 60%; } }
      &_head {
        margin-bottom: 30px;
        text-align: center;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: #BA0E0E; }
      ul {
        margin-bottom: 50px;
        li {
          margin-bottom: 15px;
          font-size: 17px;
          line-height: 20px;
          &:before {
            background: #005EAD; } } }
      .btn_blue {
        margin: 0 auto; } } }
  .step17 {
    .InfoBlock {
      margin-bottom: 40px; }
    .flex {
      display: flex;
      justify-content: space-between;
      margin: 70px 0 10px;
      @include for-phone {
        flex-direction: column;
        margin: 70px -20px 10px; }
      &_el {
        width: 33.25%;
        margin-bottom: 30px;
        @include for-phone {
          width: 100%; }
        &_name {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 120px;
          height: 120px;
          margin: 0 auto;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          background: #ffffff;
          border: 4px solid #BA0E0E;
          border-radius: 50%;
          transition: 0.5s;
          z-index: 2;
          opacity: 0.5;
          cursor: pointer;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          @include for-phone {
            opacity: 1; }
          &.active {
            color: #ffffff;
            background: #BA0E0E;
            opacity: 1; } }
        &_block {
          padding: 20px 10px 20px 30px;
          border: 4px solid #BA0E0E;
          box-sizing: border-box;
          border-radius: 10px;
          box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
          transition: 0.5s;
          transform: translate(0, 0) scale(0);
          @include for-phone {
            height: 0;
            border: none;
            border-bottom: 4px solid #BA0E0E;
            border-top: 4px solid #BA0E0E;
            border-radius: 0;
            box-shadow: none;
            transform: translate(0, 0) scale(1, 0);
            transform-origin: 100% 0; }
          &.active {
            overflow: hidden;
            margin-top: -10px;
            transform: translate(0, -10px) scale(1);
            @include for-phone {
              height: auto;
              transform: translate(0, -10px) scale(1, 1); } } } } }
    .substances {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.hidden {
        display: none; }
      &_img {
        max-width: 100%; } } }
  .step18 {
    .InfoBlock {
      margin-bottom: 40px; }
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 70px 0 10px;
      padding-bottom: 280px;
      @include for-phone {
        flex-direction: column;
        margin: 70px -20px 10px;
        padding-bottom: 0; }
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33.25%;
        margin-bottom: 30px;
        @include for-phone {
          width: 100%; }
        &_box {
          @include for-phone {
            display: flex;
            flex-direction: row-reverse;
            align-items: center; } }
        &_head {
          width: 120px;
          height: 48px;
          margin-bottom: 10px;
          text-align: center;
          @include for-phone {
            height: auto; } }
        &_img {
          position: relative;
          display: block;
          margin: 0 auto;
          transition: 0.5s;
          z-index: 0;
          opacity: 0.5;
          cursor: pointer;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          @include for-phone {
            opacity: 1; }
          &.active {
            opacity: 1;
            z-index: 3; } }
        &_block {
          position: absolute;
          top: 160px;
          left: 0;
          width: 100%;
          padding: 20px 10px 20px 30px;
          background: #ffffff;
          border: 4px solid #BA0E0E;
          box-sizing: border-box;
          border-radius: 10px;
          box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
          transition: 0.5s;
          transform: translate(0, 0) scale(0);
          @include for-phone {
            position: static;
            top: auto;
            left: auto;
            height: 0;
            border: none;
            border-bottom: 4px solid #BA0E0E;
            border-top: 4px solid #BA0E0E;
            border-radius: 0;
            box-shadow: none;
            transform: translate(0, 0) scale(1, 0);
            transform-origin: 100% 0; }
          &.active {
            transform: translate(0, 0) scale(1);
            z-index: 2;
            @include for-phone {
              height: auto;
              margin-top: -20px; } } }
        &:last-child {
            .flex_el_block.active {
              transform: translate(0, 0) scale(1); } } } } }
  .step20 {
    .test {
      .test_desktop {
        .flex {
          padding-top: 30px;
          .flex_img {
            padding-left: 20px;
            .img {
              max-width: 100%;
              transform: scale(1); } } }
        .questions {
          justify-content: flex-start;
          margin: 0 -15px;
          .question_el {
            width: 33.25%;
            padding: 0 15px;
            &:nth-child(3) {
              margin-right: 0; } } } } } }
  .step23 {
    .statistics {
      margin-top: 40px;
      margin-bottom: 70px;
      @include for-phone {
        margin: 0 -20px 70px; }
      &_text {
        display: flex;
        align-items: center;
        margin-bottom: 38px;
        padding: 28px 100px;
        font-weight: bold;
        text-align: center;
        font-size: 19px;
        line-height: 23px;
        color: #BA0D0E;
        border: 4px solid #BA0D0E;
        border-radius: 10px;
        @include for-phone {
          padding: 28px 20px;
          border: none;
          border-top: 4px solid #BA0D0E;
          border-bottom: 4px solid #BA0D0E;
          border-radius: 0; } }
      &_dogs {
        display: flex;
        justify-content: space-around;
        @include for-phone {
          padding-left: 20px;
          flex-wrap: wrap; }
        @media screen and (max-width: 480px) {
          justify-content: flex-start; } }

      &_img {
        @include for-phone {
          width: 72px;
          height: 72px;
          margin-right: 20px; }
        &:first-child {
          @media screen and (max-width: 387px) {
            order: 3; } }
        &:nth-child(2) {
          @media screen and (max-width: 387px) {
            order: 2; } }
        &:last-child {
          @media screen and (max-width: 387px) {
            order: 1; } } } } }
  .step24 {
    .flex {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      &_el {
        &:first-child {
          margin-right: 30px;
          @include for-phone {
            margin-right: 10px; } }
        &:last-child {
          flex-shrink: 0; } } } }
  .step25 {
    .info {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      &_el {
        &:first-child {
          margin-right: 30px;
          @include for-phone {
            margin-right: 10px; } }
        &:last-child {
          flex-shrink: 0; } } }
    .InfoBlock {
      margin-bottom: 40px; }
    .flex {
      display: flex;
      justify-content: space-between;
      margin: 70px 0 10px;
      @include for-phone {
        flex-direction: column;
        margin: 70px -20px 10px; }
      &_el {
        width: 48%;
        margin-bottom: 30px;
        @include for-phone {
          width: 100%; }
        &_img {
          position: relative;
          display: block;
          margin: 0 auto;
          transition: 0.5s;
          z-index: 2;
          opacity: 0.5;
          cursor: pointer;
          @include for-phone {
            opacity: 1; }
          &.active {
            opacity: 1; } }
        &_block {
          padding: 20px 10px 20px 30px;
          border: 4px solid #BA0E0E;
          box-sizing: border-box;
          border-radius: 10px;
          box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
          transition: 0.5s;
          transform: translate(0, 0) scale(0);
          @include for-phone {
            padding: 20px;
            height: 0;
            border: none;
            border-bottom: 4px solid #BA0E0E;
            border-top: 4px solid #BA0E0E;
            border-radius: 0;
            box-shadow: none;
            transform: translate(0, 0) scale(1, 0);
            transform-origin: 100% 0; }
          &_head {
            margin-bottom: 20px;
            font-weight: bold;
            font-size: 19px;
            line-height: 23px;
            color: #BA0E0E;
            text-align: center; }
          &_text {
            margin-bottom: 40px;
            font-size: 16px;
            line-height: 19px; }
          &.active {
            overflow: hidden;
            margin-top: -10px;
            transform: translate(0, -10px) scale(1);
            @include for-phone {
              height: auto;
              transform: translate(0, -10px) scale(1, 1); } } } } } }
  .step27 {
    @include for-phone {
      margin: 0 -20px; }
    .TextBlock {
      padding: 0 20px; }
    .info {
      margin-top: 40px;
      padding: 25px;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      border: 4px solid #BA0E0E;
      border-radius: 10px;
      @include for-phone {
        margin: 30px 0 0;
        padding: 20px;
        border: none;
        border-top: 4px solid #BA0E0E;
        border-bottom: 4px solid #BA0E0E;
        border-radius: 0; } } }
  .step28 {
    .TextBlock {
      font-size: 25px;
      @include for-phone {
        font-size: 16px; } }
    .flex {
      display: flex;
      padding-bottom: 300px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        position: relative;
        &_img {
          display: block;
          margin-bottom: 20px;
          cursor: pointer; }
        &:first-child {
          margin-right: 48px; }
        &:last-child {
          flex-grow: 1; }
        &_text {
          margin: 0 0 24px;
          font-weight: bold;
          font-size: 19px;
          line-height: 24px;
          color: #BA0E0E;
          @include for-phone {
            display: none; }
          &.mobile {
            display: none;
            @include for-phone {
              display: block;
              font-size: 16px;
              line-height: 17px;
              text-align: center; } } }
        &_box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          ul {
            margin-right: 20px;
            @include for-phone {
              display: none; }
            li {
              margin-bottom: 10px;
              &:before {
                top: 10px; } } }
          &_img {
            @include for-phone {
              display: block;
              max-width: 100%;
              margin:  0 auto; } } }
        .info {
          position: absolute;
          top: 0;
          left: -10px;
          width: 360px;
          padding: 48px 36px 36px 48px;
          background: #ffffff;
          border: 4px solid #BA0E0E;
          border-radius: 10px;
          box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
          transition: 0.5s;
          transform: scale(0);
          &:before {
            content: '';
            position: absolute;
            left: -3px;
            top: 9%;
            transform: translate(-50%, -50%) rotate(-135deg);
            height: 15px;
            width: 15px;
            background: #fff;
            border-top: 4px solid #BA0E0E;
            border-right: 4px solid #BA0E0E;
            transition: .5s; }
          &:nth-child(4) {
            &:before {
              top: 35%; } }
          &.active {
            transform: scale(1); } }
        &_wrp {
          @include for-phone {
            display: flex;
            justify-content: space-between; }
          ul {
            display: none;
            @include for-phone {
              display: block;
              margin: 0; }
            li {
              @include for-phone;
              margin-bottom: 10px; } } } } }
    .Popup {
      display: none;
      @include for-phone {
        display: block; }
      &_img {
        display: block;
        margin: 0 auto 20px; }
      .imgCenter {
        margin-bottom: 30px; }
      .btn_blue {
        margin: 0 auto; } } }
  .step30 {
    .InfoBlock {
      margin-bottom: 48px; }
    .head {
      margin: 0 0 48px;
      font-weight: bold;
      font-size: 25px;
      line-height: 35px;
      color: #4C8EC5; }
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding-bottom: 160px;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_el {
        &_head {
          width: 100%;
          margin-bottom: 20px;
          font-weight: bold;
          font-size: 17px;
          line-height: 24px;
          color: #4C8EC5;
          text-align: center; }
        &.left {
          width: 30%;
          @include for-phone {
            width: 100%; }
          .flex_el_img-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;
            .flex_el_img {
              cursor: pointer;
              @include for-phone {
                width: 120px;
                height: 120px; } } } }
        &.right {
          width: 60%;
          @include for-phone {
            width: 100%; }
          .flex_el_box {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            .flex_el_img-box {
              display: flex;
              justify-content: center;
              width: 50%;
              margin-bottom: 30px;
              .flex_el_img {
                cursor: pointer;
                @include for-phone {
                  width: 120px;
                  height: 120px; } } } } } }
      .info {
        position: absolute;
        top: 40px;
        left: -10px;
        width: 360px;
        padding: 48px 36px 36px 48px;
        background: #ffffff;
        border: 4px solid #BA0E0E;
        border-radius: 10px;
        box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
        transition: 0.5s;
        transform: scale(0);
        @include for-phone {
          display: none;
          transform: scale(1);
          padding: 0; }
        &:before {
          content: '';
          position: absolute;
          left: -3px;
          top: 9%;
          transform: translate(-50%, -50%) rotate(-135deg);
          height: 15px;
          width: 15px;
          background: #fff;
          border-top: 4px solid #BA0E0E;
          border-right: 4px solid #BA0E0E;
          transition: .5s;
          @include for-phone {
            display: none; } }
        &.active {
          transform: scale(1);
          @include for-phone {
            position: fixed;
            z-index: 555;
            top: 92px;
            left: 0;
            background: rgba(#345178, .8);
            height: 100vh;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            border: none;
            border-radius: 0;
            box-shadow: none;
            transition: none; } }
        &_box {
          @include for-phone {
            width: 100%;
            background: #fff;
            height: calc(100vh - 92px);
            padding: 0 0 20px;
            max-width: initial;
            border-radius: 0;
            overflow-y: auto; } }
        &_close {
          position: absolute;
          top: 20px;
          right: 20px;
          height: 25px;
          width: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #BA0E0E;
          cursor: pointer;
          transition: .5s;
          @include for-phone {
            position: fixed;
            top: -92px;
            right: 0;
            height: 92px;
            width: 92px;
            background-color: #00559C; }
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background: #BA0E0E;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff;
              width: 28px; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } }
        &_head {
          font-weight: bold;
          font-size: 19px;
          line-height: 23px;
          color: #333333;
          text-align: center;
          @include for-phone {
            padding: 0 20px; } }
        &_note {
          font-size: 14px;
          line-height: 17px;
          color: #333333;
          text-align: center;
          @include for-phone {
            margin-bottom: 20px; } }
        &_color {
          display: none;
          @include for-phone {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
            margin-bottom: 30px;
            background: #F4F2EA; }
          &:before {
            @include for-phone {
              content: '';
              position: absolute;
              left: 50%;
              bottom: 2px;
              transform: translate(-50%, 50%) rotate(-225deg);
              height: 20px;
              width: 20px;
              background: #F4F2EA;
              transition: .5s; } } }
        &.first {
          left: 30%;
          @include for-phone {
            left: auto; }
          &:before {
            top: 15%; }
          .info_color {
            background: #F4F2EA;
            &:before {
              background: #F4F2EA; } } }
        &.second {
          left: 30%;
          @include for-phone {
            left: auto; }
          &:before {
            top: 52%; }
          .info_color {
            background: #AFDCF1;
            &:before {
              background: #AFDCF1; } } }
        &.third {
          left: -35px;
          @include for-phone {
            left: auto; }
          &:before {
            top: 15%;
            left: auto;
            right: -12px;
            transform: rotate(45deg); }
          .info_color {
            background: #EA377B;
            &:before {
              background: #EA377B; } } }
        &.fourth {
          left: 30%;
          @include for-phone {
            left: auto; }
          &:before {
            top: 15%;
            left: auto;
            right: -12px;
            transform: rotate(45deg); }
          .info_color {
            background: #83140B;
            &:before {
              background: #83140B; } } }
        &.fifth {
          left: -35px;
          @include for-phone {
            left: auto; }
          &:before {
            top: 52%;
            left: auto;
            right: -12px;
            transform: rotate(45deg); }
          .info_color {
            background: #008CC7;
            &:before {
              background: #008CC7; } } }
        &.sixth {
          left: 30%;
          @include for-phone {
            left: auto; }
          &:before {
            top: 52%;
            left: auto;
            right: -12px;
            transform: rotate(45deg); }
          .info_color {
            background: #BA0E0E;
            &:before {
              background: #BA0E0E; } } }
        .btn_blue {
          display: none;
          @include for-phone {
            display: block;
            margin: 0 auto; } } } } }
  .step31 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_img {
        opacity: 0.5;
        cursor: pointer;
        @include for-phone {
          opacity: 1; }
        &:not(:last-child) {
          margin-bottom: 20px; }
        &.active {
          opacity: 1; } } }
    .info {
      position: relative;
      padding: 48px 95px 25px 95px;
      border: 4px solid #BA0E0E;
      border-radius: 10px;
      @include for-phone {
        position: fixed;
        z-index: 555;
        top: 92px;
        left: 0;
        background: rgba(#345178, .8);
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        transition: none; }
      &:before {
        content: '';
        position: absolute;
        left: 15%;
        top: -3px;
        transform: translate(-50%, -50%) rotate(-45deg);
        height: 15px;
        width: 15px;
        background: #fff;
        border-top: 4px solid #BA0E0E;
        border-right: 4px solid #BA0E0E;
        transition: .5s;
        @include for-phone {
          display: none; } }
      &.center {
        &:before {
          left: 50%; } }
      &.right {
        &:before {
          left: 87%; } }
      &_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #BA0E0E;
        cursor: pointer;
        transition: .5s;
        @include for-phone {
          position: fixed;
          top: 0;
          right: 0;
          height: 92px;
          width: 92px;
          background-color: #00559C; }
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #BA0E0E;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s;
          @media screen and (max-width: 767px) {
            background: #ffffff;
            width: 28px; } }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); } }
      &_box {
        display: flex;
        flex-wrap: wrap;
        @include for-phone {
          flex-wrap: nowrap;
          flex-direction: column;
          width: 100%;
          background: #fff;
          height: calc(100vh - 92px);
          padding: 0 0 20px;
          max-width: initial;
          border-radius: 0;
          overflow-y: auto; } }
      &_color {
        display: none;
        @include for-phone {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          height: 200px;
          margin-bottom: 30px;
          background: #AFDCF1; }
        &:before {
          @include for-phone {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 2px;
            transform: translate(-50%, 50%) rotate(-225deg);
            height: 20px;
            width: 20px;
            background: #AFDCF1;
            transition: .5s; } }
        &.second {
          background: #EA377B;
          &:before {
            background: #EA377B; } }
        &.third {
          background: #008CC7;
          &:before {
            background: #008CC7; } } }
      .btn_blue {
        display: none;
        @include for-phone {
          display: block;
          flex-shrink: 0;
          margin: 30px auto 0; } }
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        @include for-phone {
          width: 100%; }
        &_head {
          max-width: 220px;
          font-weight: bold;
          font-size: 19px;
          line-height: 23px;
          text-align: center; } } } }
  .step32 {
    .flex {
      &_el {
        display: flex;
        align-items: center;
        margin-bottom: 48px;
        @include for-phone {
          flex-direction: column;
          align-items: center; }
        &_img {
          flex-shrink: 0;
          margin-right: 48px;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 20px; } }
        &_text {
          font-size: 20px;
          line-height: 28px;
          color: #2C3032;
          @include for-phone {
            font-size: 16px;
            line-height: 20px; } }
        &_green {
          font-weight: bold;
          color: #7AB920; }
        &_blue {
          font-weight: bold;
          color: #78C7E6; } } } }
  .step33 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_img {
        flex-shrink: 0;
        margin-left: 48px; } } }
  .step34 {
    padding-bottom: 250px;
    @include for-phone {
      padding-bottom: 0; }
    .head {
      margin-bottom: 48px;
      font-weight: bold;
      font-size: 25px;
      line-height: 35px;
      color: #4C8EC5; }
    .info {
      position: relative;
      height: 160px;
      &_item {
        position: absolute;
        top: 260px;
        left: 50%;
        transform: translate(-50%, -100%) scaleY(0);
        display: flex;
        align-items: center;
        width: 240px;
        height: 206px;
        padding: 0 40px;
        background: #FFFFFF;
        border: 2px solid #BA0E0E;
        box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        transition: 0.5s 0s;
        transform-origin: 100% 0;
        @include for-phone {
          display: none; }
        &:before {
          content: '';
          position: absolute;
          left: 50%;
          top: -16px;
          transform: translate(-50%, 50%) rotate(135deg);
          height: 12px;
          width: 12px;
          background: #fff;
          border-bottom: 2px solid #BA0E0E;
          border-left: 2px solid #BA0E0E;
          transition: .5s; }
        &_show {
          transform: translate(-50%, -100%) scaleY(1);
          transition: 0.5s 0.5s; }
        .TextBlock {
          margin-bottom: 0; } }
      &_year {
        margin: 0;
        font-weight: bold;
        font-size: 25px;
        line-height: 35px;
        color: #BA0E0E;
        text-align: center; }
      &_text {
        margin: 0;
        font-size: 17px;
        line-height: 24px;
        text-align: center; } }
    .progress {
      position: relative;
      width: 600px;
      margin: 98px auto 48px;
      --left: 1%;
      @include for-phone {
        width: 96px;
        height: 600px; }
      &_big {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 46px);
        display: flex;
        height: 12px;
        background: #E6EFF7;
        @include for-phone {
          flex-direction: column;
          width: 36px;
          height: calc(100% - 46px); }
        &_item {
          width: 33.9%;
          height: 12px;
          background: #FDB813;
          transition: 0.5s;
          transform: scaleX(0);
          transform-origin: 0 100%;
          @include for-phone {
            width: 36px;
            height: 33.9%; }
          &.active {
            transform: scaleX(1); } } }
      &_small {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 46px);
        display: flex;
        height: 2px;
        background: #99BFDE;
        z-index: 3;
        @include for-phone {
          flex-direction: column;
          width: 4px;
          height: calc(100% - 50px); }
        &_item {
          width: 33.9%;
          height: 2px;
          background: #BA0E0E;
          transition: 0.5s ease-in-out;
          transform: scaleX(0);
          transform-origin: 0 100%;
          @include for-phone {
            width: 4px;
            height: 35%; }
          &:first-child {
            width: 2.3%;
            @include for-phone {
              width: 4px;
              height: 15%; } }
          &:last-child {
            width: 30%;
            @include for-phone {
              width: 4px;
              height: 30%; } }
          &.active-first {
            transform: scaleX(1); }
          &.active {
            transform: scaleX(1); } } }
      &_points {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
        @include for-phone {
          flex-direction: column;
          height: 100%; }
        &_item {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 36px;
          height: 36px;
          background: #E6EFF7;
          border-radius: 50%;
          transition: 0.5s 0.2s;
          cursor: pointer;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          @include for-phone {
            width: 96px;
            height: 96px; }
          &.active {
            background: #FDB813; }
          &_red {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            background: #99BFDE;
            border-radius: 50%;
            font-size: 0;
            color: #ffffff;
            transition: 0.5s 0.2s;
            cursor: pointer;
            z-index: 4;
            @include for-phone {
              width: 48px;
              height: 48px;
              font-size: 24px; }
            &.active {
              background: #BA0E0E; } }
          &:nth-child(3) {
            .info_item {
              width: 600px;
              max-width: 837px;
              height: 300px;
              left: -70px;
              top: 355px;
              &:before {
                left: 65%; } } }
          .week {
            position: absolute;
            top: 0;
            left: 50%;
            width: 86px;
            transform: translate(-50%, -130%);
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            @include for-phone {
              top: 50%;
              text-align: left;
              transform: translate(70%, -50%); } } } }
      &_cursor {
        position: absolute;
        top: 0;
        left: var(--left);
        transform: translate(0, -130%);
        opacity: 0;
        transition: 0.5s;
        &.show {
          opacity: 1; } } }
    .Popup {
      &_number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        margin: 0 auto 40px;
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        background: #BA0E0E;
        border-radius: 50%; }
      .TextBlock {
        text-align: center; }
      .btn_blue {
        margin: 0 auto; } } }
  .step35 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px; }
      &_el {
        &:last-child {
          flex-shrink: 0;
          margin-left: 48px; }
        &_head {
          margin-bottom: 36px;
          font-weight: bold;
          font-size: 25px;
          line-height: 35px; } } }
    .text {
      margin-bottom: 48px;
      font-weight: bold;
      font-size: 17px;
      line-height: 24px;
      color: #4C8EC5; }
    ul {
      li {
        &:before {
          background: #005EAD; } } }
    .img-box {
      width: 100%; } }
  .step36 {
    .head {
      margin-bottom: 48px;
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      color: #BA0E0E;
      ul {
        @include for-phone {
          margin-bottom: 20px; } } } }
  .step37 {
    .head {
      margin-bottom: 48px;
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      color: #BA0E0E; }
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; } } }
  .step38, .step39 {
    @include for-phone {
      margin: 0 -20px; }
    .TextBlock {
      @include for-phone {
        padding: 0 20px; } }
    .head {
      margin-bottom: 48px;
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      color: #BA0E0E;
      @include for-phone {
        padding: 0 20px; } }
    .imgCenter {
      margin-bottom: 48px; }
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column;
        padding: 0 20px; }
      ul {
        &:first-child {
          margin-right: 48px; } } } }
  .step39 {
    .info {
      padding: 35px 45px 35px;
      font-weight: bold;
      font-size: 17px;
      line-height: 24px;
      color: #BA0E0E;
      border: 4px solid #BA0E0E;
      border-radius: 20px;
      @include for-phone {
        border: none;
        border-top: 4px solid #BA0E0E;
        border-bottom: 4px solid #BA0E0E;
        border-radius: 0; } } }
  .step40, .step41, .step42, .step43, .step44 {
    .head {
      margin-bottom: 48px;
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      color: #BA0E0E; }
    .imgCenter {
      margin-bottom: 48px; }
    .flex {
      display: flex;
      align-items: center;
      @include for-phone {
        flex-direction: column; }
      .left {
        max-width: 100%;
        margin-right: 48px;
        @include for-phone {
          margin-right: 0; } } } }
  .step41 {
    .text {
      margin-bottom: 10px;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #333333;
      text-align: center;
      @include for-phone {
        font-size: 16px;
        margin-bottom: 10px;
        text-align: left; } }
    .author {
      text-align: right;
      font-weight: bold;
      font-style: italic;
      font-size: 18px;
      line-height: 25px;
      @include for-phone {
        font-size: 16px;
        margin-bottom: 40px; } }
    li {
      margin-bottom: 10px; } }
  .step43 {
    .info {
      padding: 35px 45px 35px;
      font-weight: bold;
      font-size: 17px;
      line-height: 24px;
      text-align: center;
      color: #BA0E0E;
      border: 4px solid #BA0E0E;
      border-radius: 20px;
      @include for-phone {
        margin: 0 -20px;
        border: none;
        border-top: 4px solid #BA0E0E;
        border-bottom: 4px solid #BA0E0E;
        border-radius: 0; } }
    .text-red {
      font-weight: bold;
      font-size: 17px;
      line-height: 24px;
      color: #BA0E0E; }
    ul {
      margin-bottom: 40px; } }
  .step45 {
    .flex {
      display: flex;
      margin: 105px -20px 35px;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_el {
        display: flex;
        justify-content: center;
        width: 33.25%;
        padding: 0 20px;
        @include for-phone {
          width: 100%;
          margin-bottom: 20px; }
        &_content {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 140px;
          cursor: pointer;
          transition: 0.5s;
          @include for-phone {
            flex-direction: row-reverse;
            justify-content: flex-end;
            width: 100%; }
          &.inactive {
            opacity: 0.5; } }
        &_head {
          display: flex;
          align-items: flex-end;
          height: 60px;
          margin-bottom: 15px;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          @include for-phone {
            text-align: start;
            margin-bottom: 0;
            height: auto; } }
        &_img-box {
          @include for-phone {
            margin-right: 15px; } }
        &_img {
          @include for-phone {
            display: block; } } } }
    .content {
      position: relative;
      padding: 50px 100px;
      border: 4px solid #BA0D0E;
      box-sizing: border-box;
      box-shadow: 2px -3px 30px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
      overflow: visible;
      @include for-phone {
        position: fixed;
        z-index: 555;
        top: 92px;
        left: 0;
        height: 100%;
        background: rgba(#345178, .8);
        border: none;
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        overflow-y: auto; }
      &:before {
        content: '';
        position: absolute;
        left: 14.5%;
        top: -3px;
        transform: translate(-50%, -50%) rotate(-45deg);
        height: 30px;
        width: 30px;
        background: #fff;
        border-top: 4px solid #BA0D0E;
        border-right: 4px solid #BA0D0E;
        transition: .5s left;
        @include for-phone {
          display: none; } }
      &_close {
        position: fixed;
        top: 0;
        right: 0;
        height: 92px;
        width: 92px;
        background-color: #00559C;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s;
          @media screen and (max-width: 767px) {
            background: #ffffff;
            width: 28px; } }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); } }
      &.blue {
        padding: 50px 0 0;
        background: #E6F0F3;
        @include for-phone {
          padding: 0;
          background: #ffffff; }
        &.middle:before {
          left: 50%;
          background: #E6F0F3; } }
      //.content_list
      //  @include for-phone
      //    background: #E6F0F3
      &.right:before {
        left: 85%; }
      &_box {
        @include for-phone {
          position: relative;
          padding: 50px 0 150px;
          background: #ffffff; }
        &.stix {
          .content_item_img {
            height: 240px;
            width: auto; } }
        &.jumbone {
          .content_subhead {
            font-size: 17px;
            font-weight: 700;
            line-height: 20px;
            text-align: center; }

          .content_list {
            justify-content: center;
            .content_item {
              width: 176px;
              &_img {
                width: 100%;
                height: auto; } } } } }
      &_header {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        @include for-phone {
          margin-bottom: 30px; } }
      &_subhead {
        margin-bottom: 38px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        @include for-phone {
          display: none; } }
      &_list {
        display: flex;
        justify-content: space-between;
        @include for-phone {
          flex-direction: column;
          align-items: center; }
        &.top {
          border-bottom: 4px solid #ffffff;
          .content_item {
            display: flex;
            justify-content: center;
            width: 33.25%;
            @include for-phone {
              width: 100%;
              padding: 60px 20px 40px;
              background: #E6F0F3;
              border-bottom: 4px solid #ffffff; } } }
        &.bottom {
          .content_item {
            display: flex;
            justify-content: center;
            width: 33.25%;
            padding-top: 25px;
            border-right: 4px solid #ffffff;
            @include for-phone {
              width: 100%;
              padding-top: 0; }
            &:first-child {
              background: rgba(0, 164, 208, 0.1); }
            &:nth-child(2) {
              background: rgba(232, 41, 44, 0.1); }
            &:nth-child(3) {
              background: rgba(53, 168, 84, 0.1); }
            &_box {
              @include for-phone {
                width: 100%; } }
            &_header {
              width: 100%;
              font-weight: bold;
              font-size: 25px;
              line-height: 30px;
              text-align: center;
              @include for-phone {
                padding: 30px 0;
                background: #ffffff; } }
            &_description {
              margin-bottom: 36px;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              @include for-phone {
                display: none; } }
            &_img {
              margin: 50px 0; } } } }
      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 145px;
        &_box {
          display: flex;
          align-items: center;
          flex-direction: column; }
        &_head {
          margin-bottom: 10px;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          text-align: center;
          @include for-phone {
            display: none; } }
        &_img {
          margin-bottom: 25px; }
        &_description {
          height: 48px;
          margin-bottom: 15px;
          font-size: 17px;
          line-height: 24px;
          color: #000000;
          text-align: center; } }
      &_icon {
        display: block;
        margin: 0 auto; } }
    .play {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include for-phone {
        position: static;
        top: auto;
        right: auto;
        transform: translate(0, 0);
        display: flex;
        justify-content: center;
        align-items: center; }
      &_text {
        margin-right: 20px;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #BA0D0E;
        text-align: center; } }
    .Popup {
      &_closed {
        &:before {
          background: #BA0D0E;
          @include for-phone {
            background: #ffffff; } }
        &:after {
          background: #BA0D0E;
          @include for-phone {
            background: #ffffff; } } }
      &_block {
        max-width: 767px;
        border: 4px solid #BA0D0E;
        border-radius: 20px;
        @include for-phone {
          border: none;
          border-radius: 0; } }
      &_head {
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        text-align: center; }
      &_subhead {
        display: block;
        margin-top: 20px;
        font-size: 17px;
        line-height: 20px; }
      &_icon {
        display: block;
        margin: 0 auto; } }
    .Popup_content {
      position: relative;
      display: flex;
      padding-bottom: 60px;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &.block {
        display: block; }
      &_item {
        &:first-child {
          margin-right: 48px;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 40px; } } }
      &_description {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        ul {
          li {
            font-size: 12px;
            line-height: 14px;
            &:before {
              background: #BA0D0E; } } }
        &_red {
          color: #BA0D0E;
          font-weight: bold; } }
      &_icon {
        flex-shrink: 0;
        margin-right: 20px; }
      &_text {
        margin: 0;
        font-size: 14px;
        line-height: 17px; }
      &_red {
        font-weight: bold;
        color: #BA0D0E; }
      .play {
        top: auto;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 40%);
        @include for-phone {
          transform: translate(0); } }
      .VideoBlock {
        border: none;
        border-radius: 0;
        @include for-phone {
          margin-bottom: 30px; } } } }
  .step46 {
    .header {
      margin-bottom: 32px;
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      color: #BA0D0E; }
    .statistics {
      margin-bottom: 70px;
      @include for-phone {
        margin: 0 -20px 70px; }
      &_text {
        display: flex;
        align-items: center;
        margin-bottom: 38px;
        padding: 28px 100px;
        font-weight: bold;
        text-align: center;
        font-size: 19px;
        line-height: 23px;
        color: #BA0D0E;
        border: 4px solid #BA0D0E;
        border-radius: 10px;
        @include for-phone {
          padding: 28px 20px;
          border: none;
          border-top: 4px solid #BA0D0E;
          border-bottom: 4px solid #BA0D0E;
          border-radius: 0; } }
      &_dogs {
        display: flex;
        justify-content: space-around;
        @include for-phone {
          padding-left: 20px;
          flex-wrap: wrap; }
        @media screen and (max-width: 480px) {
          justify-content: flex-start; } }

      &_img {
        @include for-phone {
          width: 72px;
          height: 72px;
          margin-right: 20px; }
        &:first-child {
          @media screen and (max-width: 387px) {
            order: 3; } }
        &:nth-child(2) {
          @media screen and (max-width: 387px) {
            order: 2; } }
        &:last-child {
          @media screen and (max-width: 387px) {
            order: 1; } } } }
    .teeth {
      display: flex;
      margin-bottom: 48px;
      @include for-phone {
        flex-direction: column;
        margin: 0 -20px; }
      &_el {
        @include for-phone {
          position: relative; }
        &_head {
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          margin-bottom: 15px;
          @include for-phone {
            //position: absolute
            top: 0;
            left: 50%;
            //transform: translate(-50%, -50%)
            font-weight: bold;
            font-size: 24px;
            line-height: 34px;
            color: #BA0D0E; } }
        &:first-child {
          width: 20%;
          flex-shrink: 0;
          padding-right: 25px;
          @include for-phone {
            padding-right: 0;
            width: 100%;
            margin-bottom: 30px; }
          .teeth_el_img-box {
            padding: 20px 0;
            border: 4px solid #BA0D0E;
            box-sizing: border-box;
            border-radius: 10px;
            @include for-phone {
              border: none;
              border-top: 4px solid #BA0D0E;
              border-bottom: 4px solid #BA0D0E;
              border-radius: 0; }
            .teeth_el_img {
              display: block;
              margin: 0 auto; }
            .teeth_el_arrow {
              position: absolute;
              left: auto;
              right: 0;
              top: 50%;
              transform: translate(70%, -50%); } }
          .teeth_el_description {
            @include for-phone {
              position: relative;
              padding: 20px 20px 70px; } }
          .teeth_el_arrow-mobile {
            display: none;
            @include for-phone {
              position: absolute;
              display: block;
              top: 80px;
              left: 50%;
              transform: translate(-50%, 0); } } }
        &:last-child {
          flex-grow: 1; }
        &_img-box {
          position: relative;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          padding: 20px 0;
          border-top: 4px solid #BA0D0E;
          @include for-phone {
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
            border-bottom: 4px solid #BA0D0E; } }
        &_img {
          @include for-phone {
            margin-bottom: 48px; } }
        &_arrow {
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          @include for-phone {
            display: none; }
          &:first-child {
            left: 20%; }
          &:nth-child(2) {
            left: 50%; }
          &:nth-child(3) {
            left: 77%; }
          &-mobile {
            display: none;
            @include for-phone {
              position: absolute;
              display: block;
              top: 0;
              left: 50%;
              transform: translate(-50%, 0); }
            &:nth-child(4) {
              top: 22%; }
            &:nth-child(5) {
              top: 46%; }
            &:nth-child(6) {
              top: 70%; } } }
        &_description {
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          @include for-phone {
            padding: 0 20px; }
          ul {
            li {
              font-size: 14px;
              line-height: 20px;
              color: #000000;
              &:before {
                background: #BA0D0E; } } } } } }
    .info {
      display: flex;
      align-items: center;
      min-height: 96px;
      padding: 0 150px;
      font-weight: bold;
      font-size: 19px;
      line-height: 23px;
      text-align: center;
      color: #ffffff;
      background: #BA0D0E;
      border-radius: 10px;
      @include for-phone {
        margin: 0 -20px;
        padding: 20px;
        font-size: 18px;
        line-height: 25px;
        border-radius: 0; } } }
  .step47 {
    .header {
      margin-bottom: 32px;
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      color: #BA0D0E; }
    .text {
      margin-bottom: 40px;
      font-size: 17px;
      line-height: 24px;
      font-weight: bold; }
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column;
        margin: 0 -20px; }
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48%;
        padding-top: 44px;
        border: 4px solid #CC3333;
        border-radius: 20px;
        @include for-phone {
          position: relative;
          width: 100%;
          border-radius: 0;
          border: none;
          border-top: 4px solid #CC3333; }
        &:not(:last-child) {
          @include for-phone {
            margin-bottom: 72px; } }
        &:before {
          @include for-phone {
            content: "1";
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 48px;
            height: 48px;
            font-weight: bold;
            font-size: 24px;
            line-height: 24px;
            color: #ffffff;
            background: #CC3333;
            border-radius: 50%; } }
        &_head {
          font-weight: bold;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
          margin-bottom: 42px; }
        &_img-box {
          position: relative; }
        &_icon {
          position: absolute;
          top: 0; }
        &_img {
          margin-bottom: 48px; }
        &_description {
          width: 100%;
          flex-grow: 1;
          padding: 48px 80px 62px;
          font-size: 16px;
          line-height: 22px;
          color: #ffffff;
          text-align: center;
          background: #CC3333;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          @include for-phone {
            border-radius: 0;
            padding: 38px 20px 42px; } }
        &.right {
          border: 4px solid #4085D8;
          @include for-phone {
            border: none;
            border-top: 4px solid #4085D8; }
          .flex_el_description {
            background: #4085D8; }
          &:before {
            @include for-phone {
              content: "2";
              position: absolute;
              background: #4085D8; } } } } } }
  .step48 {
    .head {
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 25px;
      line-height: 35px;
      color: #BA0D0E;
      @include for-phone {
        font-size: 24px;
        line-height: 29px; } }
    .img {
      display: block;
      margin-top: -100px;
      margin-left: auto;
      text-align: right;
      @include for-phone {
        margin: 0 auto;
        max-width: 100%; } } }
  .step49, .step50 {
    .head {
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 25px;
      line-height: 35px;
      color: #BA0D0E;
      @include for-phone {
        font-size: 24px;
        line-height: 29px; } }
    .flex {
      display: flex;
      justify-content: space-between;
      margin-top: 165px;
      @include for-phone {
        flex-direction: column;
        margin: 100px -20px 0; }
      &_el {
        position: relative;
        width: 48%;
        padding: 75px 25px 30px;
        border: 2px solid #BA0D0E;
        border-radius: 15px;
        @include for-phone {
          width: 100%;
          border: none;
          border-top: 4px solid #BA0D0E;
          border-bottom: 4px solid #BA0D0E;
          border-radius: 0; }
        &:not(:last-child) {
          @include for-phone {
            margin-bottom: 108px; } }
        &_head {
          margin-bottom: 15px;
          font-weight: bold;
          font-size: 17px;
          line-height: 24px;
          text-align: center; }
        &_text {
          font-size: 14px;
          line-height: 20px;
          text-align: center; }
        &_img-box {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -70%);
          padding: 20px;
          background: #ffffff;
          border: 2px solid #BA0D0E;
          border-radius: 50%;
          @include for-phone {
            padding: 0;
            width: 120px;
            height: 120px; } }
        &_img {
          @include for-phone {
            width: 100%;
            height: auto; } } } } }
  .step50 {
    .flex {
      @include for-phone {
        margin-top: 130px; }
      &_el {
        &:not(:last-child) {
          @include for-phone {
            margin-bottom: 143px; } }
        &_img-box {
          width: 160px;
          padding: 0;
          border: none;
          background: transparent;
          transform: translate(0, -70%);
          @include for-phone {
            width: 120px;
            transform: translate(0, -90%); }
          &_text {
            height: 65px;
            margin-bottom: 15px;
            font-size: 14px;
            line-height: 20px;
            @include for-phone {
              height: 30px;
              text-align: center;
              font-size: 10px;
              line-height: 14px; } }
          &.left {
            left: 10%; }
          &.right {
            left: auto;
            right: 0;
            @include for-phone {
              left: auto;
              right: 10%; }
            .flex_el_img-box_text {
              font-weight: bold;
              color: #BA0D0E; } } }
        &_img-wrp {
          max-width: 120px;
          padding: 10px;
          background: #ffffff;
          border: 2px solid #BA0D0E;
          border-radius: 50%; }
        &_img {
          display: block; } } } }
  .step51, .step52 {
    .img {
      display: block;
      margin: 0 auto; } } }









