.LessonPlan {
  position: fixed;
  top: 0;
  right: 0;
  width: 284px;
  height: calc(100vh);
  overflow-y: auto;
  background: #C4C4C4;
  padding-bottom: 34px;
  z-index: 10;
  @media screen and (max-width: 1366px) {
    position: absolute;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    left: 0;
    width: 100%;
    height: initial;
    background-color: #E6EFF7;
    overflow-y: visible; }
  .image {
    margin-top: 34px;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 1px solid #fff;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    @media screen and (max-width: 1366px) {
      display: none; }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover; } }
  .name {
    padding: 0 15px;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 17px;
    white-space: pre-line;
    @media screen and (max-width: 1366px) {
      display: none; } }
  .steps {
    margin-top: 70px;
    width: 100%;
    border-bottom: 1px solid #fff;
    @media screen and (max-width: 1366px) {
      margin-top: 0;
      border-bottom: none;
      width: auto;
      flex-grow: 1; }
    &.disclosure {
      position: absolute;
      width: 100%; }
    .step {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      padding: 15px 10px;
      transition: .5s;
      border-top: 1px solid #fff;
      @media screen and (max-width: 1366px) {
        display: none;
        background-color: #E6EFF7;
        padding: 26px 0px 26px 30px;
        text-align: left;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        border-top: none;
        transition: none; }
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
        padding-left: 10px; }
      &:first-child {
        @media screen and (max-width: 1366px) {
          border-top: none; } }
      &.active {
        color: #4C8EC5;
        @media screen and (max-width: 1366px) {
          display: block; }
        &.opened {
          font-weight: bold; } }
      &.opened {
        display: block;
        color: #4C8EC5;
        border-top: 1px solid #fff;
        &:first-child {
          border-top: none; } } } }

  .lessonNumber {
    display: none;
    @media screen and (max-width: 1366px) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 95px;
      background-color: #CCDFEF; }
    .lessonNumeral {
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      color: #4C8EC5;
      text-transform: uppercase;
      margin: 0; }
    .lessonWord {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #4C8EC5;
      text-transform: uppercase;
      margin: 0; } }
  .buttonPlan {
    display: none;
    @media screen and (max-width: 1366px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 92px;
      .openArrow {
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-top: 8px solid #4C8EC5;
        transform: translate(0, 50%); }
      .closeArrow {
        width: 16px;
        height: 2px;
        background-color: #4C8EC5;
        border: none;
        transform: rotate(45deg);
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2px;
          height: 16px;
          background-color: #4C8EC5;
          transform: translate(-50%, -50%); } } } } }

