.guru {
  width: 100%;
  height: 120px;
  background: #fff;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_el {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
    position: relative;
    top: 20px;
    opacity: .5;
    &.active {
      opacity: 1;
      .img_img {
        filter: none; } }
    .img {
      height: 90px;
      width: 90px;
      background: #CCDFEF;
      border-radius: 50%;
      position: relative;
      &_img {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        height: 104px;
        width: 104px;
        filter: saturate(0); } }
    .name {
      margin-top: 20px;
      text-align: center;
      font-size: 14px;
      color: #005EAD;
      font-weight: bold;
      letter-spacing: 0.02em;
      text-transform: uppercase; }
    .arrow {
      //position: absolute
      //top: 0
      //right: 0
      //transform: translate(50%, 0)
      height: 20px;
      width: 49px;
      padding-top: 3px;
      margin: 0 10px;
      .up {
        position: relative;
        top: 45px; }
      .down {
        position: relative;
        top: 25px; }
      &_img {
        width: 100%;
        fill: #039cdd; } } }
  &.Blue {
    background: transparent; }
  &.pyaterochka {
    background: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    .name {
      color: #E52322; }
    .arrow_img {
      fill: #E52322; } }
  &.perekrestok {
    background: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    .name {
      color: #24582E; }
    .arrow_img {
      fill: #24582E; } }

  //&:nth-child(2n)
  ////  .up
  ////    display: none
  ////&:nth-child(2n - 1)
  ////  .down
  ////    display: none
  ////&:last-child
  ////  .arrow
  ////    display: none
  .slick-slider {
    width: 100%;
    height: 100%;
    .slick-list {
      height: 100%;
      .slick-track {
        display: flex;
        justify-content: space-between; }
      .slick-slide {
        //max-width: 180px
        width: auto !important;
        &:nth-child(2n) {
          .up {
            display: none; } }
        &:nth-child(2n - 1) {
          .down {
            display: none; } }
        &:last-child {
          .arrow {
            display: none; } } } } } }

