@import "../../../Page/mixin";
.step.lesson35 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .MainHeader {
    .header {
      font-weight: bold;
      font-size: 15px;
      line-height: 19px;
      text-transform: uppercase;
      color: #AC8748;
      margin-bottom: 0; }
    .subheader {
      margin-top: 10px;
      font-size: 32px;
      line-height: 37px;
      color: #AC8748; }
    &.bigHeader {
      border-bottom: none; } }

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
    position: absolute;
    bottom: -50px;
    top: auto;
    right: 55px;
    transform: translate(0, 50%);
    height: 46px;
    width: 46px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #1F72B7;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% + 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-left: 8px solid #fff;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #fff;
      &:before {
        border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #ac8748; } } }
  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    .flex {
      display: flex;
      &_el {
        &:last-child {
          flex-shrink: 0;
          margin-left: 20px; } } } }
  .step3 {
    .flex {
      display: flex;
      &_el {
        &:first-child {
          flex-grow: 1;
          margin-right: 30px; }
        &:last-child {
          flex-shrink: 0; }
        .block {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 72px;
          width: 100%;
          border: 2px solid #d5c3a3;
          border-radius: 10px;
          margin-bottom: 30px;
          padding: 0 30px;
          font-weight: bold;
          font-size: 17px;
          line-height: 24px;
          color: #ac8748;
          &_point {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #ac8748;
            font-size: 17px;
            line-height: 1;
            color: #fff;
            font-weight: 700; } } } } }
  .step4, .step5, .step6 {
    .imgCenter {
      margin-bottom: 40px; }
    .info {
      padding: 24px;
      border: 2px solid #d5c3a3;
      border-radius: 10px;
      text-align: center;
      color: #ac8748;
      font-size: 20px;
      line-height: 25px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 30px; } }
  .step9 {
    .block {
      display: flex;
      justify-content: center;
      margin-top: 100px;
      &_box {
        position: relative; }
      .point {
        position: absolute;
        z-index: 2;
        transition: 0.5s;
        cursor: pointer;
        &_number {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 68px;
          width: 68px;
          border-radius: 50%;
          background: linear-gradient(330deg,#19222d 13.4%,#283444 50%,#313f52 86.6%);
          color: #e7c371;
          font-weight: 700;
          font-size: 48px;
          line-height: 68px;
          text-align: center;
          border: 4px solid #e7c371;
          transition: .5s; }
        &_info {
          display: none; }
        &:first-child {
          top: 144px;
          left: 50%;
          transform: translate(calc(-50% - 201px)); }
        &:nth-child(2) {
          top: 54px;
          left: 50%;
          transform: translate(calc(-50% - 120px)); }
        &:nth-child(3) {
          top: 0;
          left: 50%;
          transform: translate(-50%); }
        &:nth-child(4) {
          top: 54px;
          left: 50%;
          transform: translate(calc(-50% + 120px)); }
        &:nth-child(5) {
          top: 144px;
          left: 50%;
          transform: translate(calc(-50% + 201px)); }
        &.active {
          .point_number {
            height: 30px;
            width: 30px;
            background: #ac8748;
            border: 0 none;
            color: #fff;
            font-size: 17px;
            line-height: 32px;
            position: relative;
            z-index: 3; }
          .point_info {
            display: block;
            position: absolute;
            z-index: 2;
            width: 220px;
            padding: 12px 24px;
            text-align: center;
            color: #ac8748;
            font-size: 15px;
            border: 2px solid #d5c3a3;
            border-radius: 7px; }
          &:nth-child(1), &:nth-child(2) {
            .point_info {
              padding: 6px 24px;
              top: 50%;
              right: 15px;
              transform: translateY(-50%); } }
          &:nth-child(3) {
            .point_info {
              bottom: 15px;
              left: 50%;
              transform: translate(-50%); } }
          &:nth-child(4), &:nth-child(5) {
            .point_info {
              padding: 6px 24px;
              top: 50%;
              left: 15px;
              transform: translateY(-50%); } } } } } }
  .step10 {
    .flex {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      &_img {
        margin-bottom: 30px; } } }
  .step11, .step12, .step13, .step14, .step15, .step16 {
    .head {
      margin-bottom: 40px;
      color: #ac8748;
      font-size: 25px;
      line-height: 120%;
      font-weight: 700; }
    .advantages {
      display: flex;
      margin-bottom: 40px;
      &_el {
        width: 45%;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #d8af74;
        border-radius: 10px;
        color: #fff;
        font-size: 17px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0; }
      &_plus {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10%; } }
    .flex {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &_img {
        margin-bottom: 30px; } } } }
