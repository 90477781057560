@import "../../Page/mixin";
//Убирают дефолтные стрелки для input с типом number
input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

//Основные стили
.inputBlock {
  width: 100%;
  height: auto;
  position: relative;
  & .star {
    display: none; }
  &.required .star {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    color: #fff;
    font-size: 25px;
    line-height: 30px; }
  .arrowSelect {
    position: absolute;
    top: 16px;
    right: 0;
    transform: translate(0, -50%);
    width: 0;
    height: 0;
    border-top: 5px solid #fff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    transition: .5s; }
  .input {
    width: 100%;
    border: 0 none;
    border-bottom: 1px solid #fff;
    background: transparent;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    padding: 5px 0;
    transition: .5s;
    @include for-tablet {
      font-size: 20px;
      line-height: 26px; }
    @include for-phone {
      font-size: 16px;
      line-height: 24px; }
    &::placeholder {
      color: #fff;
      font-weight: normal;
      text-indent: 0;
      @include for-tablet {
        font-size: 20px;
        line-height: 26px; }
      @include for-phone {
        font-size: 16px;
        line-height: 24px; } }
    &.error {
      border-color: #BD4E3B;
      color: #BD4E3B;
      &::placeholder {
        color: #BD4E3B; } }

    &.disabled {
      opacity: .5;
      pointer-events: none;
      cursor: default;
      &.error {
        border-color: #fff;
        color: #fff;
        &::placeholder {
          color: #fff; } } } }
  &.show {
    .arrowSelect {
      @media screen and (min-width: 1024px) {
        top: 14px;
        right: 8px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid #333333; } }
    .input {
      @media screen and (min-width: 1024px) {
        background-color: #fff;
        color: #333333;
        border-radius: 10px;
        padding-left: 20px; }
      &::placeholder {
        @media screen and (min-width: 1024px) {
          color: #333333; } } } }
  .options {
    width: 100%;
    height: auto;
    max-height: 0;
    overflow-y: hidden;
    background: #005EAD;
    transition: .5s;
    @include for-tablet {
      margin-top: 5px; }
    .option {
      background-color: #fff;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      font-size: 16px;
      line-height: 24px;
      padding: 5px 10px;
      cursor: pointer;
      transition: .5s;
      &:hover,
      &.active {
        background-color: #4085D8;
        color: #fff; }
      &:not(:last-child) {
        border-bottom: 1px solid #99BFDE; }
      &:first-child {
        border-top: 1px solid #99BFDE; } }
    &.showOptions {
      max-height: 400px;
      border-radius: 10px;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4); } }
  .errorText {
    position: absolute;
    bottom: -25px;
    color: #BD4E3B;
    font-size: 14px; }
  input[name='breed'] {
    text-overflow: ellipsis; } }



