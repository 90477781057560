@import "../../../Page/mixin";
.step.lesson11 {
  padding-bottom: 48px;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  color: #3E373C;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .textBlue {
    color: #005EAD; }
  .textRed {
    color: #BD4E3B; }
  .infoPopap {
    position: fixed;
    z-index: 222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#345178, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrp {
      background: #fff;
      border-radius: 25px;
      padding: 70px;
      position: relative;
      width: 100%;
      max-width: 640px; }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba(#005EAD, .3);
        transition: .5s; }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: rgba(#005EAD, 1); }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }

    &_scroll {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
      overflow-y: auto; }
    &_iconDiv {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      overflow: hidden;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      &.red {
        color: #BD4E3B; } }
    &_text {
      font-size: 16px;
      color: #3E373C;
      margin-bottom: 30px;
      &_head {
        font-size: 16px;
        color: #005EAD;
        font-weight: bold;
        display: block; } }
    &_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      &ImgDiv {
        height: 96px;
        width: 96px;
        flex-shrink: 0;
        border: 2px solid #005EAD;
        border-radius: 50%;
        background: #fff;
        margin-right: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
        &.chain {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 100%;
            width: 3px;
            background: #005EAD; } } }
      &:last-child {
        .chain {
          &:before {
            display: none; } } }
      .infoPopap_text {
        margin-bottom: 0; } }


    &_wrp.danger {
      background: #F9EEEC;
      .infoPopap_iconDiv {
        background: #F9EEEC; }
      .infoPopap_close {
        &:before,
        &:after {
          background: rgba(#BD4E3B, .3); }
        &:hover {
          &:before,
          &:after {
            background: rgba(#BD4E3B, 1); } } }
      .infoPopap_text {
        li:before {
          background: #BD4E3B; } }
      .infoPopap_head {
        color: #BD4E3B; } }





    &_imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      max-width: 100%;
      img {
        margin-top: 45px;
        max-width: 100%; } }
    .btn {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      font-size: 14px;
      width: 160px; } }

  ul {
    padding-left: 0;
    li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      color: #3E373C;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; } } }
  .ul {
    .liHead {
      font-size: 18px;
      color: #005EAD;
      font-weight: bold;
      text-transform: uppercase;
      margin: 40px 0 35px; }
    .li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 35px;
      @media screen and (max-width: 499px) {
        flex-direction: column; }
      .number {
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        border: 1px solid #005EAD;
        border-radius: 50%;
        margin-right: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #005EAD;
        @media screen and (max-width: 499px) {
          margin-bottom: 10px; } }
      .text {
        font-size: 18px;
        color: #3E373C; } } }

  .infoPopap {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    background: rgba(#345178, .8);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 112px;
    &_wrp {
      width: 100%;
      max-width: 640px;
      padding: 70px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      .popap_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99BFDE;
        cursor: pointer;
        transition: .5s;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s; }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover {
          &:before,
          &:after {
            background: #005EAD; }
          &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(45deg); } } }
      .image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        &Bg {
          width: 100%;
          height: 100%;
          background: #E6EFF7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            border-radius: 50%; } } }
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line; }
      .subHead {
        color: #1F72B7;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        white-space: pre-line;
        margin-bottom: 30px; }
      .slider {
        border: 2px solid #CCDFEF;
        border-radius: 10px;
        position: relative;
        padding: 25px;
        margin-bottom: 50px;
        .slide {
          color: #4C8EC5;
          font-size: 16px;
          line-height: 24px; }
        .slick-arrow {
          bottom: 0; } }
      .btn {
        position: relative;
        left: 50%;
        transform: translate(-50%); } } }

  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .flex {
          display: flex;
          margin-top: 48px;
          margin-bottom: 24px;
          justify-content: space-between;
          align-items: center;
          .answers {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 50%;
            margin-top: 0;
            &_item {
              position: relative;
              &:not(:last-child) {
                margin-bottom: 24px; } }
            &_number {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              left: 0;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 30px;
              height: 30px;
              border: 2px solid #4C8EC5;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #4C8EC5;
              background-color: #ffffff;
              border-radius: 50%;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } }
            .answer {
              position: relative;
              justify-content: center;
              align-items: center;
              width: 100%;
              max-width: 240px;
              height: 60px;
              font-weight: bold;
              font-size: 16px;
              line-height: 22px;
              color: #4085D8;
              text-align: center;
              text-transform: uppercase;
              background-color: #ffffff;
              border: 2px solid #4085D8;
              box-shadow: none;
              padding: 10px;
              @media screen and (max-width: 550px) {
                font-size: 10px;
                line-height: 13px; }
              &.correct {
                font-weight: bold;
                color: #4085D8;
                background-color: #ffffff;
                border: 2px solid #4085D8; }
              &.inCorrect {
                font-weight: bold;
                color: #BD4E3B;
                background-color: #ffffff;
                border: 2px solid #BD4E3B; } } }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              //&:nth-child(5)
              //  bottom: 27%
              //  left: 15%
              &:nth-child(5) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } } } }
        .questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .question_el {
            width: 31%;
            height: 50px;
            margin-bottom: 24px;
            &:nth-child(1) {
              order: 1; }
            &:nth-child(2) {
              order: 4; }
            &:nth-child(3) {
              order: 2; }
            &:nth-child(4) {
              order: 5; }
            &:nth-child(5) {
              order: 3; }
            &:nth-child(6) {
              order: 6; }
            &.hidden {
              cursor: default;
              .ddcontainersource {
                font-size: 0;
                background-color: #E6EFF7;
                box-shadow: none;
                border: none; } }
            .ddcontainer {
              width: 100%; } }
          .ddcontainersource {
            width: 100%;
            height: 50px;
            padding: 5px 10px;
            display: flex!important;
            justify-content: center;
            align-items: center;
            border: 1px solid #4C8EC5;
            border-radius: 10px;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 22px;
            color: #3E373C;
            text-align: center;
            text-transform: uppercase;
            box-shadow: 0px 5px 10px rgba(0, 94, 173, 0.15);
            cursor: pointer; }
          .ddcontainerghost {
            width: 250px;
            height: 50px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #4C8EC5;
            border-radius: 10px;
            font-size: 16px;
            line-height: 22px;
            color: #3E373C;
            text-align: center;
            text-transform: uppercase;
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center; } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } }
      //Стили мобильной версии
      .test_mobile {
        .flex {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
          &_el {
            width: 100%;
            max-width: 300px; }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              //&:nth-child(5)
              //  bottom: 27%
              //  left: 15%
              &:nth-child(5) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } }
            .img {
              width: 100%;
              max-width: 300px;
              height: auto; } } }
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          padding-left: 15px;
          box-sizing: border-box;
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 60px;
            padding: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #99BFDE;
            background-color: #E6EFF7;
            border: 1px solid #99BFDE;
            border-radius: 10px;
            text-align: center;
            text-transform: uppercase;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } }
            &:not(:last-child) {
              margin-bottom: 24px; } }
          &_number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border: 1px solid #4C8EC5;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            color: #3E373C;
            background-color: #ffffff;
            border-radius: 50%;
            &.selected {
              color: #4C8EC5;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 48px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%; }
            .questions {
              display: flex;
              flex-direction: column;
              &_item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 60px;
                text-transform: uppercase;
                border: 1px solid #4C8EC5;
                border-radius: 10px;
                box-shadow: 0px 5px 10px rgba(0, 94, 173, 0.15);
                &:nth-child(1) {
                  order: 1; }
                &:nth-child(2) {
                  order: 4; }
                &:nth-child(3) {
                  order: 2; }
                &:nth-child(4) {
                  order: 3; }
                &:nth-child(5) {
                  order: 5; }
                //&:nth-child(6)
                //  order: 6
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; }
                &:not(:last-child) {
                  margin-bottom: 20px; } } } }
          &_text {
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } } } }
  .step3, .step4 {
    .flex {
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column-reverse; }
      &_el {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.left {
          width: 40%;
          margin-right: 20px;
          @include for-phone {
            width: 100%;
            padding-left: 15px;
            box-sizing: border-box; } }
        &.right {
          align-items: center;
          @include for-phone {
            margin-bottom: 48px; }
          .img {
            width: 100%;
            max-width: 384px;
            height: auto; } }
        &_item {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 60px;
          padding: 12px 8px 12px 23px;
          font-size: 14px;
          line-height: 18px;
          color: #3E373C;
          text-transform: uppercase;
          border: 2px solid #4C8EC5;
          border-radius: 10px;
          @include for-phone {
            justify-content: center;
            text-align: center;
            padding: 12px 23px 12px 23px; }
          &:not(:last-child) {
            margin-bottom: 18px; } }
        &_number {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          left: 0;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
          border: 2px solid #4C8EC5;
          font-size: 14px;
          line-height: 18px;
          font-weight: bold;
          color: #3E373C;
          background-color: #ffffff;
          border-radius: 50%; } } } }
  .step4 {
    .flex {
      &_el {
        &_item {
          &:not(:last-child) {
            margin-bottom: 72px;
            @include for-phone {
              margin-bottom: 24px; } } } } } }
  .step5 {
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-left: -10px;
      margin-right: -10px;
      padding-bottom: 10px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        width: 33.25%;
        padding: 10px;
        @include for-phone {
          width: 100%; }
        &:not(:last-child) {
          @include for-phone {
            margin-bottom: 20px; } } }
      &_wrp {
        position: relative;
        padding: 40px 20px;
        text-align: center;
        border: 1px solid #acdff5;
        border-radius: 10px;
        @include for-phone {
          padding: 40px 20px 0; } }
      &_count {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border: 1px solid #acdff5;
        background-color: #ffffff;
        border-radius: 50%;
        font-size: 18px;
        font-weight: bold;
        color: #039cdd; } }
    .img {
      display: block;
      margin: 0 auto; } }
  .step6 {
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:not(:last-child) {
          @include for-phone {
            margin-bottom: 48px; } }
        &_wrp {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          @include for-phone {
            flex-direction: column; } }
        &_head {
          min-height: 44px;
          margin: 0 0 29px;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #039cdd;
          @include for-phone {
            min-height: auto; } }
        &_item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45%;
          height: 48px;
          margin-bottom: 12px;
          border: 1px solid #acdff5;
          border-radius: 10px;
          @include for-phone {
            width: 100%; } }
        &_icon {
          margin-right: 14px; }
        &_text {
          font-size: 16px;
          line-height: 22px;
          text-transform: uppercase; }
        &.left {
          width: 60%;
          margin-right: 72px;
          @include for-phone {
            width: 100%; } }
        &.right {
          width: 35%;
          @include for-phone {
            width: 100%; }
          .flex_el_item {
            width: 100%;
            height: 72px;
            margin-bottom: 24px; }
          .flex_el_text {
            width: 156px;
            font-size: 18px;
            line-height: 22px; } } } }
    .img {
      display: block;
      margin: 0 auto; } }
  .step7 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .test_wrp {
          display: flex;
          margin-bottom: 38px;
          .flex {
            display: flex;
            flex-grow: 1;
            .answers {
              position: relative;
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: 0;
              margin-bottom: 0;
              .product {
                width: 21%;
                &_img {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-height: 86px;
                  .img {
                    width: auto;
                    height: auto; } }
                .head {
                  margin: 0 0 12px;
                  font-size: 16px;
                  line-height: 22px;
                  color: #005EAD;
                  text-align: center;
                  text-transform: uppercase; } }
              &_item {
                position: relative;
                &:not(:last-child) {
                  margin-bottom: 12px; } }
              .answer {
                position: relative;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 240px;
                height: 36px;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #99BFDE;
                text-align: center;
                text-transform: uppercase;
                background-color: #E6EFF7;
                border: 1px solid #99BFDE;
                border-radius: 5px;
                box-shadow: none;
                padding: 0 15px;
                .icon {
                  display: flex;
                  align-items: center;
                  svg {
                    fill: #99BFDE;
                    margin-right: 10px; } }
                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8;
                  .icon svg {
                    fill: #4085D8; } }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B;
                  .icon svg {
                    fill: #BD4E3B; } } } } }
          .questions {
            display: flex;
            flex-direction: column;
            margin-right: 24px;
            padding-top: 120px;
            .question_el {
              width: 100%;
              height: 36px;
              &:not(:last-child) {
                margin-bottom: 12px; }
              //&.hidden
              //  cursor: default
              //  .ddcontainersource
              //    font-size: 0
              //    background-color: #E6EFF7
              //    box-shadow: none
              //    border: none
              //    img
              //      opacity: 0
              .ddcontainer {
                width: 100%; } }
            .ddcontainersource {
              width: 134px;
              height: 36px;
              padding: 5px 15px;
              display: flex!important;
              align-items: center;
              border: 1px solid #4C8EC5;
              border-radius: 5px;
              margin-bottom: 10px;
              font-size: 14px;
              line-height: 22px;
              color: #3E373C;
              text-align: center;
              text-transform: uppercase;
              box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
              cursor: pointer;
              & > div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center; }
              .icon {
                display: flex;
                align-items: center;
                svg {
                  fill: #4C8EC5;
                  margin-right: 10px; } } }
            .ddcontainerghost {
              width: 134px;
              height: 36px;
              padding: 5px 15px;
              align-items: center;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 5px;
              font-size: 14px;
              line-height: 22px;
              color: #3E373C;
              text-align: center;
              text-transform: uppercase;
              & > div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center; }
              .icon {
                display: flex;
                align-items: center;
                svg {
                  fill: #4C8EC5;
                  margin-right: 10px; } } } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } }
      //Стили мобильной версии
      .test_mobile {
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          margin-bottom: 0;
          box-sizing: border-box;
          .flex {
            display: flex;
            justify-content: space-between;
            margin-bottom: 48px;
            &_el {
              width: 48%;
              .product_img {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 86px;
                .img {
                  width: auto;
                  height: auto; } }
              .head {
                margin: 0 0 12px;
                font-size: 16px;
                line-height: 22px;
                color: #005EAD;
                text-align: center;
                text-transform: uppercase; } } }
          &_icon {
            display: flex;
            align-items: center;
            svg {
              fill: #4C8EC5;
              margin-right: 10px; } }
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 36px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #99BFDE;
            background-color: #E6EFF7;
            border: 1px solid #99BFDE;
            border-radius: 10px;
            text-transform: uppercase;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8;
                .answers_icon svg {
                  fill: #4085D8; } }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B;
                .answers_icon svg {
                  fill: #BD4E3B; } } }
            &:not(:last-child) {
              margin-bottom: 24px; } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 16px; }
            .questions {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              &_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 48%;
                height: 128px;
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                border: 1px solid #4C8EC5;
                border-radius: 15px;
                box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
                .question_icon {
                  margin-bottom: 8px;
                  svg {
                    fill: #4C8EC5; } }
                &:nth-child(1) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 30px;
                      height: 36px; } } }
                &:nth-child(2) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 36px;
                      height: 36px; } } }
                &:nth-child(3) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 48px;
                      height: 29px; } } }
                &:nth-child(4) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 32px;
                      height: 30px; } } }
                &:nth-child(5) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 25px;
                      height: 36px; } } }
                &:nth-child(6) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 31px;
                      height: 32px; } } }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; }
                &:not(:last-child) {
                  margin-bottom: 20px; } } } }
          &_text {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            color: #005EAD;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } } } }
  .step8 {
    position: relative;
    padding-bottom: 100px;
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 31%;
        @include for-phone {
          width: 100%;
          margin-bottom: 36px; }
        &_head {
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 18px;
          line-height: 30px;
          color: #039cdd;
          text-align: center;
          text-transform: uppercase; }
        &_wrp {
          height: 100%;
          padding: 48px 20px 80px;
          border: 1px solid #acdff5;
          border-radius: 10px;
          @include for-phone {
            padding: 40px 36px 36px; }
          .header {
            font-size: 16px;
            line-height: 18px;
            font-weight: bold;
            margin: 0 0 15px; }
          li {
            font-size: 14px;
            line-height: 18px; } } } }
    .img {
      position: absolute;
      right: 90px;
      bottom: -50px;
      @include for-phone {
        right: 50%;
        transform: translateX(50%);
        width: 240px;
        height: auto; } }
    .fix_height {
      min-height: 115px; } }

  .step9, .step10, .step11 {
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      line-height: 26px;
      color: #4C8EC5;
      text-transform: uppercase;
      &_icon {
        margin-right: 24px; } }
    .table {
      &_header {
        display: flex;
        @include for-phone {
          display: none; }
        &_el {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 72px;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #ffffff;
          background-color: #4C8EC5;
          text-transform: uppercase;
          text-align: center;
          border-bottom: 2px solid #ffffff;
          &:first-child {
            width: 240px;
            border-right: 2px solid #ffffff; }
          &:nth-child(2) {
            flex-grow: 1; } } }
      &_row {
        display: flex;
        background-color: #E6EFF7;
        border-bottom: 2px solid #ffffff;
        @include for-phone {
          flex-direction: column;
          border-bottom: 6px solid #ffffff; }
        &_el {
          display: flex;
          align-items: center;
          padding: 0 15px;
          font-size: 14px;
          line-height: 26px;
          color: #3E373C;
          &:first-child {
            justify-content: center;
            width: 240px;
            font-weight: bold;
            color: #4C8EC5;
            text-align: center;
            text-transform: uppercase;
            border-right: 2px solid #ffffff;
            flex-shrink: 0;
            @include for-phone {
              width: 100%;
              border-right: none;
              border-bottom: 2px solid #ffffff; } }
          &:nth-child(2) {
            padding-left: 20px;
            flex-grow: 1; } }
        &_subtext {
          font-weight: normal; }
        &.table_row_dark {
          background-color: #CCDFEF; }
        &_box {
          width: 240px;
          border-right: 2px solid #ffffff;
          @include for-phone {
            width: 100%;
            border-right: none; }
          &_el {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 240px;
            padding: 15px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #4C8EC5;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 2px solid #ffffff;
            @include for-phone {
              width: 100%;
              border-right: none; }
            &:last-child {
              border-bottom: none;
              @include for-phone {
                border-bottom: 2px solid #ffffff; } } }

          &_subtext {
            margin: 0;
            font-weight: normal; } }
        &_common {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 15px;
          font-size: 14px;
          line-height: 26px;
          color: #3E373C; } } } }
  .step12 {
    .head {
      margin-bottom: 48px;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #039cdd; } } }










