@import "../../../Page/mixin";
.step.lesson34 {
  padding-bottom: 48px;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  color: #3E373C;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .textBlue {
    color: #005EAD; }
  .textRed {
    color: #BD4E3B; }
  .infoPopap {
    position: fixed;
    z-index: 222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#345178, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrp {
      background: #fff;
      border-radius: 25px;
      padding: 70px;
      position: relative;
      width: 100%;
      max-width: 640px; }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba(#005EAD, .3);
        transition: .5s; }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: rgba(#005EAD, 1); }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }

    &_scroll {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
      overflow-y: auto; }
    &_iconDiv {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      overflow: hidden;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      &.red {
        color: #BD4E3B; } }
    &_text {
      font-size: 16px;
      color: #3E373C;
      margin-bottom: 30px;
      &_head {
        font-size: 16px;
        color: #005EAD;
        font-weight: bold;
        display: block; } }
    &_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      &ImgDiv {
        height: 96px;
        width: 96px;
        flex-shrink: 0;
        border: 2px solid #005EAD;
        border-radius: 50%;
        background: #fff;
        margin-right: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
        &.chain {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 100%;
            width: 3px;
            background: #005EAD; } } }
      &:last-child {
        .chain {
          &:before {
            display: none; } } }
      .infoPopap_text {
        margin-bottom: 0; } }


    &_wrp.danger {
      background: #F9EEEC;
      .infoPopap_iconDiv {
        background: #F9EEEC; }
      .infoPopap_close {
        &:before,
        &:after {
          background: rgba(#BD4E3B, .3); }
        &:hover {
          &:before,
          &:after {
            background: rgba(#BD4E3B, 1); } } }
      .infoPopap_text {
        li:before {
          background: #BD4E3B; } }
      .infoPopap_head {
        color: #BD4E3B; } }





    &_imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      max-width: 100%;
      img {
        margin-top: 45px;
        max-width: 100%; } }
    .btn {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      font-size: 14px;
      width: 160px; } }

  ul {
    padding-left: 0;
    li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      color: #3E373C;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #005EAD; } } }
  .ul {
    .liHead {
      font-size: 18px;
      color: #005EAD;
      font-weight: bold;
      text-transform: uppercase;
      margin: 40px 0 35px; }
    .li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 35px;
      @media screen and (max-width: 499px) {
        flex-direction: column; }
      .number {
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        border: 1px solid #005EAD;
        border-radius: 50%;
        margin-right: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #005EAD;
        @media screen and (max-width: 499px) {
          margin-bottom: 10px; } }
      .text {
        font-size: 18px;
        color: #3E373C; } } }

  .infoPopap {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    background: rgba(#345178, .8);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 112px;
    &_wrp {
      width: 100%;
      max-width: 640px;
      padding: 70px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      .popap_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99BFDE;
        cursor: pointer;
        transition: .5s;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s; }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover {
          &:before,
          &:after {
            background: #005EAD; }
          &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(45deg); } } }
      .image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        &Bg {
          width: 100%;
          height: 100%;
          background: #E6EFF7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            border-radius: 50%; } } }
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line; }
      .subHead {
        color: #1F72B7;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        white-space: pre-line;
        margin-bottom: 30px; }
      .slider {
        border: 2px solid #CCDFEF;
        border-radius: 10px;
        position: relative;
        padding: 25px;
        margin-bottom: 50px;
        .slide {
          color: #4C8EC5;
          font-size: 16px;
          line-height: 24px; }
        .slick-arrow {
          bottom: 0; } }
      .btn {
        position: relative;
        left: 50%;
        transform: translate(-50%); } } }

  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .flex_head {
      font-weight: bold;
      font-size: 22px;
      color: #005EAD; }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 50px;
      &Img {
        height: 240px;
        width: 240px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 25px;
        flex-shrink: 0; } }
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #146BB3;
      margin-bottom: 24px; }
    .text {
      font-size: 18px;
      line-height: 26px;
      color: #3E373C;
      margin-top: 20px; }
    .lessonSteps {
      margin-top: 25px;
      &_el {
        margin-bottom: 20px;
        padding: 15px 20px;
        border: 2px solid #005EAD;
        border-radius: 20px;
        font-size: 18px;
        font-weight: bold;
        color: #005EAD;
        &:last-child {
          margin-bottom: 0; } } } }
  .step2 {
    .flex {
      display: flex;
      margin-top: 48px;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_el {
        &:first-child {
          margin-right: 50px;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 25px; } } } }
    .good-luck {
      font-weight: bold;
      font-size: 30px;
      line-height: 29px;
      text-transform: uppercase;
      color: #1C4882;
      text-align: center;
      margin-bottom: 20px; } }
  .step3 {
    .box {
      padding: 35px 35px 40px 50px;
      border: 2px solid #DEDEDE;
      box-sizing: border-box;
      border-radius: 30px;
      @include for-phone {
        padding: 20px; } }
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:first-child {
          margin-right: 50px;
          @include for-phone {
            margin-right: 0; } }
        &_box-img {
          margin-top: -37px;
          @include for-phone {
            margin-top: -22px; } }
        &_img {
          @include for-phone {
            display: block;
            margin: 0 auto 25px; } } } }
    .pin-box {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      .pin_el {
        display: flex;
        padding-top: 20px;
        @include for-phone {
          flex-direction: column; }
        &:first-child {
          margin-right: 15px;
          @include for-phone {
            margin-right: 0; } }
        .pin {
          position: relative;
          width: 54px;
          height: 47px;
          margin-right: 20px;
          margin-top: -15px;
          background-image: url("../../../image/lessons/lesson19/step3-2.png");
          background-repeat: no-repeat;
          flex-shrink: 0;
          @include for-phone {
            margin: 0 auto 20px;
            transform: rotate(90deg); }
          &_number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-75%, -50%);
            font-weight: bold;
            font-size: 24px;
            line-height: 34px;
            color: #ffffff;
            @include for-phone {
              transform: translate(-75%, -50%) rotate(-90deg); } } } } } }
  .step4 {
    .box {
      margin-top: 50px;
      padding: 45px 65px;
      border: 1px solid #C4C4C4;
      box-sizing: border-box;
      border-radius: 30px;
      @include for-phone {
        padding: 20px;
        border-radius: 20px; }
      .VideoBlock {
        margin-top: 0;
        border: none; } } }
  .step5 {
    transition: 0.5s;
    &.infoTop {
      padding-bottom: 140px; }
    &.infoMiddle {
      padding-bottom: 230px; }
    &.infoBottom {
      padding-bottom: 1220px; }
    .desktop {
      .flex {
        position: relative;
        display: flex;
        margin-top: 50px;
        @include for-phone {
          flex-direction: column; }
        &_el {
          width: 25%;
          @include for-phone {
            display: none; }
          &.points {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include for-phone {
              display: none; }
            .point {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-top: 50px;
              font-weight: bold;
              font-size: 24px;
              line-height: 26px;
              border: 2px solid #99BFDE;
              box-sizing: border-box;
              box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
              border-radius: 50%;
              cursor: pointer;
              transition: 0.5s;
              &.active {
                color: #ffffff;
                background-color: #005EAD;
                border: 2px solid #005EAD; } } }
          &.img {
            width: 50%;
            .flex_el_img {
              display: block;
              margin: 0 auto; } } }
        .info {
          &_item {
            position: absolute;
            top: 120px;
            left: 0;
            width: 100%;
            height: 364px;
            padding: 50px 40px 40px;
            border: 1px solid #005EAD;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 0 30px rgba(43, 77, 115, 0.3);
            z-index: 10;
            transform: scaleY(0);
            transform-origin: 100% 0%;
            transition: 0.5s;
            &:before {
              content: '';
              position: absolute;
              left: 11.5%;
              top: -1px;
              transform: translate(0, -50%) rotate(-45deg);
              height: 12px;
              width: 12px;
              background: #fff;
              border-top: 1px solid #005EAD;
              border-right: 1px solid #005EAD;
              transition: .5s; }
            &_close {
              position: absolute;
              top: 20px;
              right: 20px;
              height: 25px;
              width: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #99BFDE;
              cursor: pointer;
              transition: .5s;
              &:before,
              &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                background: #99BFDE;
                transform-origin: center;
                top: 50%;
                left: 50%;
                transition: .5s; }
              &:before {
                transform: translate(-50%, -50%) rotate(45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:hover {
                &:before,
                &:after {
                  background: #005EAD; }
                &:before {
                  transform: translate(-50%, -50%) rotate(-45deg); }
                &:after {
                  transform: translate(-50%, -50%) rotate(45deg); } } }
            .TextBlock {
              width: 339px; }
            &:nth-child(5) {
              top: 215px; }
            &:nth-child(6) {
              top: -145px;
              &:before {
                top: auto;
                bottom: -14px;
                transform: translate(0, -50%) rotate(135deg); } }
            &:nth-child(7) {
              &:before {
                left: auto;
                right: 11.5%; } }
            &:nth-child(8) {
              top: 215px;
              &:before {
                left: auto;
                right: 11.5%; } }
            &:nth-child(9) {
              height: 1242px;
              top: 315px;
              &:before {
                left: auto;
                right: 11.5%; } }
            &_box {
              display: flex;
              justify-content: space-between;
              align-items: center; }
            &_show {
              transform: scaleY(1); }
            .result {
              &_head {
                margin-bottom: 15px;
                font-weight: bold;
                font-size: 18px;
                line-height: 26px;
                color: #0D57AB; }
              &_list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0 -10px; }
              &_item {
                width: 33.25%;
                padding: 0 10px;
                margin-bottom: 20px; }
              &_box {
                position: relative;
                height: 203px;
                padding-top: 20px;
                background: #F0F5FB;
                border: 1px solid #0D57AB;
                box-sizing: border-box;
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                overflow: hidden;
                cursor: pointer; }
              &_year {
                margin-bottom: 5px;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #0D57AB; }
              &_text {
                padding: 0 10px;
                font-size: 12px;
                line-height: 14px;
                text-align: center;
                color: #2C3032; }
              &_circle {
                position: absolute;
                left: 50%;
                bottom: -130px;
                transform: translateX(-50%);
                width: 194px;
                height: 194px;
                margin: 0 auto;
                border-radius: 50%;
                overflow: hidden; }
              &_stuck {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #ECF1F5;
                transform: scaleY(1);
                transform-origin: 100% 0%;
                transition: 0.5s;
                &_out {
                  transform: scaleY(0); }
                &_img {
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                  object-position: center; } } } } } } }
    .mobile {
      .InfoBlock {
        margin-bottom: 36px; }
      .info_item {
        &_box {
          display: flex;
          flex-wrap: wrap;
          justify-content: center; }
        &_head {
          width: 100%;
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
          color: #0D57AB; }
        &_logo {
          width: 60px;
          svg {
            width: 100%;
            height: auto; } }
        .TextBlock {
          .text_dark-blue {
            color: #3E373C; } } }
      .slick-button-arrow {
        position: relative;
        display: block;
        width: 60px;
        height: 60px;
        margin-left: auto;
        background-color: #ffffff;
        border-radius: 50%;
        border: 2px solid #005EAD;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-70%, -50%) rotate(-45deg);
          width: 10px;
          height: 10px;
          border-right: 2px solid #005EAD;
          border-bottom: 2px solid #005EAD; }
        &.hidden {
          display: none; } }
      .year {
        padding: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 23px;
        color: #0D57AB;
        text-align: center;
        border: 1px solid #0D57AB;
        box-sizing: border-box;
        border-radius: 5px;
        opacity: 0.3; }
      .result_list {
        margin-top: 20px; }
      .result_text {
        margin-bottom: 20px; }
      .result_circle {
        img {
          display: block;
          margin: 0 auto; } }
      .yearSlider {
        .slick-slide {
          padding: 0 5px;
          &.slick-current {
            .year {
              opacity: 1; } } } } } }
  .step6 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      .flex_el {
        &:first-child {
          margin-right: 30px;
          @include for-phone {
            margin-right: 0; } }
        &:nth-child(2) {
          flex-shrink: 0; }
        &_img {
          display: block;
          margin: 0 auto;
          width: 100%;
          max-width: 376px;
          height: auto; } } } }
  .step7 {
    .TextBlock {
      font-size: 25px;
      line-height: 35px;
      @include for-phone {
        font-size: 16px;
        line-height: 18px; } }
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column; }
      .flex_el {
        display: flex;
        align-items: center;
        &:first-child {
          flex-shrink: 0;
          margin-right: 30px;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 20px; } }
        &:last-child .TextBlock {
          font-size: 18px;
          line-height: 22px;
          @include for-phone {
            font-size: 16px;
            line-height: 18px; } }
        &_box-img {
          @include for-phone {
            flex-grow: 1; } }
        &_img {
          display: block;
          margin: 0 auto;
          width: 100%;
          max-width: 290px;
          height: auto; } } } }
  .step8 {
    .box {
      display: flex;
      justify-content: space-between;
      margin-top: 63px;
      @include for-tablet {
        flex-direction: column;
        margin-top: 40px;
        padding-bottom: 0; }
      .imgBg {
        width: 50%;
        height: auto;
        @include for-tablet {
          display: block;
          margin: 0 auto; }
        @include for-phone {
          margin-top: 20px; }
        @media screen and (max-width: 499px) {
          width: 100%; } } }
    .img-box {
      position: relative;
      width: 290px;
      height: 250px;
      flex-shrink: 0;
      margin-right: 15px;
      @include for-tablet {
        margin: 0 auto 30px; }
      .img {
        width: 100%;
        height: auto; }
      .flex {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &_el {
          position: absolute;
          top: 0;
          left: 0;
          height: 72px;
          width: 72px;
          margin-bottom: 20px;
          color: #99BFDE;
          background: transparent;
          border: 3px solid transparent;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:nth-child(1) {
            top: 28px;
            left: 5px; }
          &:nth-child(2) {
            top: 115px;
            left: 3px; }
          &:nth-child(3) {
            top: 167px;
            left: 70px; }
          &:nth-child(4) {
            top: 166px;
            left: 156px; }
          &:nth-child(5) {
            top: 97px;
            left: 210px; }
          &:hover {
            background: rgba(255, 255, 255, 0.5); }
          &.active {
            &.pink {
              border: 3px solid #E41466; }
            &.orange {
              border: 3px solid #F7951D; }
            &.green {
              border: 3px solid #83C77D; }
            &.yellow {
              border: 3px solid #F7C70A; }
            &.blue {
              border: 3px solid #4DADE2; } } } } }
    .imgDiv {
      flex-grow: 1;
      border: 2px solid #1F72B7;
      border-radius: 10px;
      padding: 40px 47px;
      position: relative;
      @include for-tablet {
        padding: 0;
        border: none;
        width: 100%; }
      &:before {
        content: '';
        position: absolute;
        left: -2px;
        top: 133px;
        transform: translate(-50%, -50%) rotate(-135deg);
        height: 12px;
        width: 12px;
        background: #fff;
        border-top: 2px solid #1F72B7;
        border-right: 2px solid #1F72B7;
        transition: .5s;
        @include for-tablet {
          display: none; } }
      &_wrap {
        display: flex;
        flex-direction: column;
        align-items: center; }
      &_head {
        width: 100%;
        margin-bottom: 24px;
        font-weight: bold;
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;
        &.pink {
          color: #E41466; }
        &.orange {
          color: #F7951D; }
        &.green {
          color: #83C77D; }
        &.yellow {
          color: #F7C70A; }
        &.blue {
          color: #4DADE2; } } } }
  .step9 {
    .flex {
      display: flex;
      flex-wrap: wrap;
      margin: 50px -15px 0;
      &_el {
        width: 50%;
        padding: 0 15px;
        margin-bottom: 40px;
        &_content {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          min-height: 135px;
          padding: 20px 160px 20px 20px;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #42AFE3;
          border: 1px solid #42AFE3;
          border-bottom: 5px solid #42AFE3;
          box-sizing: border-box;
          border-radius: 10px;
          cursor: pointer;
          &.pink {
            color: #E41466;
            border: 1px solid #E41466;
            border-bottom: 5px solid #E41466; } }
        &_img {
          position: absolute;
          width: 124px;
          height: auto;
          top: -30px;
          right: 20px; } } }
    .Popup {
      padding-top: 160px;
      @include for-phone {
        padding-top: 0; }
      &_block {
        max-width: 767px;
        padding-top: 150px;
        @include for-phone {
          padding-top: 30px; }
        .info {
          position: relative;
          display: flex;
          justify-content: space-between;
          @include for-phone {
            flex-direction: column; }
          &_img {
            position: absolute;
            top: -300px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 40%;
            @include for-phone {
              position: static;
              transform: translate(0, 0);
              margin: 0 auto;
              margin-bottom: 30px; }
            //@include for-tablet
            //  display: none
            //&.tablet
            //  display: none
            //  @include for-tablet
            //    position: static
            //    display: block
            //    width: 100px
 }            //    transform: translate(0, 0)
          &_el {
            width: 49%;
            @include for-phone {
              width: 100%; }
            &:nth-child(2) {
              @include for-phone {
                margin-bottom: 30px; } }
            &:last-child {
              padding: 24px;
              background: #42AFE3;
              border-radius: 7px; }
            &.pink {
              background: #E41466; } }
          &_head {
            display: flex;
            align-items: center;
            min-height: 120px;
            padding: 20px 50px;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #ffffff;
            text-transform: uppercase;
            text-align: center;
            background-color: #42AFE3;
            border-radius: 7px;
            @include for-phone {
              min-height: auto;
              color: #42AFE3;
              background-color: #ffffff; }
            &.pink {
              background: #E41466;
              @include for-phone {
                color: #E41466;
                background-color: #ffffff; } } }
          &_item {
            display: flex;
            margin-top: 20px; }
          &_properties {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            margin-right: 10px;
            flex-grow: 1;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #657993;
            text-align: center;
            text-transform: uppercase;
            background: #F0F5FB;
            border-radius: 10px; }
          &_text {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 18px;
            color: #ffffff;
            &.top {
              margin-bottom: 15px; }
            &:last-child {
              margin-bottom: 0; } }
          &_subhead {
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #ffffff;
            text-transform: uppercase; } } } } }
  .step10 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .cards {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
          &_el {
            display: flex;
            flex-direction: column;
            width: 118px;
            border: 1px solid #42AFE3;
            border-radius: 7px;
            opacity: 0.3;
            cursor: pointer;
            &:not(:last-child) {
              margin-right: 48px; }
            &.active {
              opacity: 1;
              box-shadow: 0 5px 15px rgba(112, 106, 150, 0.4); }
            &_text {
              display: flex;
              align-items: center;
              flex-grow: 1;
              margin: 0;
              padding: 3px 10px;
              font-size: 12px;
              line-height: 14px;
              color: #ffffff;
              text-align: center;
              background-color: #42AFE3; }
            &:nth-child(3) {
              border: 1px solid #E41466;
              & .cards_el_text {
                background-color: #E41466; } }
            &:nth-child(4) {
              border: 1px solid #E41466;
              & .cards_el_text {
                background-color: #E41466; } }
            &_box-img {
              height: 120px;
              padding: 12px 0; }
            &_img {
              display: block;
              margin: 0 auto; } } }
        .flex {
          display: flex;
          margin-top: 48px;
          margin-bottom: 24px;
          justify-content: space-between;
          align-items: center;
          .answers {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 0;
            width: 100%;
            &_item {
              position: relative;
              margin-bottom: 10px;
              &:nth-child(3n + 2) {
                width: 30%; }
              &:nth-child(3n) {
                width: 60%;
                .answer {
                  background-color: #F0F5FB;
                  border: 1px solid #D3DEEB; } } }
            &_number {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              left: 0;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 30px;
              height: 30px;
              border: 2px solid #4C8EC5;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #4C8EC5;
              background-color: #ffffff;
              border-radius: 50%;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } }
            .answer {
              position: relative;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 60px;
              font-weight: bold;
              font-size: 16px;
              line-height: 18px;
              color: #657993;
              text-align: center;
              background-color: #D3DEEB;
              border: 2px solid #D3DEEB;
              box-shadow: none;
              //padding: 10px
              &.correct {
                font-weight: bold;
                color: #4085D8;
                background-color: #ffffff;
                border: 2px solid #4085D8; }
              &.inCorrect {
                font-weight: bold;
                color: #BD4E3B;
                background-color: #ffffff;
                border: 2px solid #BD4E3B; } } }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } } } }
        .questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .question_el {
            width: 65%;
            height: 60px;
            margin-bottom: 24px;
            &:nth-child(1) {
              width: 30%;
              order: 2;
              .ddcontainersource, .ddcontainerghost {
                color: #ffffff;
                background-color: #42AFE3;
                border: 1px solid #42AFE3;
                text-transform: uppercase; } }
            &:nth-child(2) {
              order: 1;
              .ddcontainerghost {
                width: 470px; } }
            &:nth-child(3) {
              width: 30%;
              order: 3;
              .ddcontainersource, .ddcontainerghost {
                color: #ffffff;
                background-color: #42AFE3;
                border: 1px solid #42AFE3;
                text-transform: uppercase; } }
            &:nth-child(4) {
              order: 4;
              .ddcontainerghost {
                width: 470px; } }
            &:nth-child(5) {
              width: 30%;
              order: 6;
              .ddcontainersource, .ddcontainerghost {
                color: #ffffff;
                background-color: #42AFE3;
                border: 1px solid #42AFE3;
                text-transform: uppercase; } }
            &:nth-child(6) {
              order: 5;
              .ddcontainerghost {
                width: 470px; } }
            &.pink {
              &:nth-child(1), &:nth-child(3), &:nth-child(5) {
                .ddcontainersource, .ddcontainerghost {
                  background-color: #E41466;
                  border: 1px solid #E41466; } } }
            &.hidden {
              cursor: default;
              .ddcontainersource {
                font-size: 0;
                background-color: #E6EFF7;
                box-shadow: none;
                border: none; } }
            .ddcontainer {
              width: 100%; } }
          .ddcontainersource {
            width: 100%;
            height: 60px;
            padding: 5px 10px;
            display: flex!important;
            justify-content: center;
            align-items: center;
            border: 1px solid #4C8EC5;
            border-radius: 10px;
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 18px;
            color: #0D57AB;
            text-align: center;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            cursor: pointer; }
          .ddcontainerghost {
            width: 250px;
            height: 60px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #4C8EC5;
            border-radius: 10px;
            font-size: 16px;
            line-height: 18px;
            color: #0D57AB;
            text-align: center;
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center; } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } }
      //Стили мобильной версии
      .test_mobile {
        .flex {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
          &_el {
            width: 100%;
            max-width: 300px; }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } }
            .img {
              width: 100%;
              max-width: 300px;
              height: auto; } } }
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          padding-left: 15px;
          box-sizing: border-box;
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 60px;
            padding: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #99BFDE;
            background-color: #E6EFF7;
            border: 1px solid #99BFDE;
            border-radius: 10px;
            text-align: center;
            text-transform: uppercase;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } }
            &:not(:last-child) {
              margin-bottom: 24px; } }
          &_number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border: 1px solid #4C8EC5;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            color: #3E373C;
            background-color: #ffffff;
            border-radius: 50%;
            &.selected {
              color: #4C8EC5;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 48px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%; }
            .questions {
              display: flex;
              flex-direction: column;
              &_item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 60px;
                text-transform: uppercase;
                border: 1px solid #4C8EC5;
                border-radius: 10px;
                box-shadow: 0px 5px 10px rgba(0, 94, 173, 0.15);
                &:nth-child(1) {
                  order: 1; }
                &:nth-child(2) {
                  order: 4; }
                &:nth-child(3) {
                  order: 2; }
                &:nth-child(4) {
                  order: 5; }
                &:nth-child(5) {
                  order: 3; }
                &:nth-child(6) {
                  order: 6; }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; }
                &:not(:last-child) {
                  margin-bottom: 20px; } } } }
          &_text {
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%); } } } }
  .step11 {
    .flex {
      display: flex;
      margin-top: 60px;
      @include for-phone {
        flex-direction: column;
        margin-top: 30px; }
      &_el {
        &:first-child {
          margin-right: 30px;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 30px; } }
        &:last-child {
          flex-shrink: 0; }
        .img {
          display: block;
          width: 100%;
          max-width: 348px;
          margin: 0 auto; } } } }
  .step12 {
    .cards {
      display: flex;
      margin-top: 72px;
      &_empty {
        width: 40%; }
      &_box {
        display: flex;
        justify-content: center;
        width: 15%;
        padding-bottom: 10px;
        &:nth-child(4) {
          & .cards_el {
            border: 1px solid #E41466; }
          & .cards_el_text {
            background-color: #E41466; } }
        &:nth-child(5) {
          & .cards_el {
            border: 1px solid #E41466; }
          & .cards_el_text {
            background-color: #E41466; } } }
      &_el {
        display: flex;
        flex-direction: column;
        width: 118px;
        border: 1px solid #42AFE3;
        border-radius: 7px;
        opacity: 1;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 48px; }
        &_text {
          display: flex;
          align-items: center;
          flex-grow: 1;
          margin: 0;
          padding: 3px 10px;
          font-size: 12px;
          line-height: 14px;
          color: #ffffff;
          text-align: center;
          background-color: #42AFE3; }
        &_box-img {
          height: 120px;
          padding: 12px 0; }
        &_img {
          display: block;
          margin: 0 auto; } } }
    .test {
      display: flex;
      margin-bottom: 40px;
      .questions {
        flex-shrink: 0;
        width: 40%;
        border-right: 1px solid #D3DEEB;
        &_el {
          display: flex;
          align-items: center;
          height: 60px;
          margin: 0;
          font-size: 14px;
          line-height: 16px;
          border-top: 1px solid #D3DEEB;
          &:last-child {
            border-bottom: 1px solid #D3DEEB; } } }
      .answers {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        margin-top: 0;
        margin-bottom: 0;
        border-bottom: 1px solid #D3DEEB;
        @include for-phone {
          margin-top: 20px; }
        .answer {
          width: 25%;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-top: 1px solid #D3DEEB;
          border-right: 1px solid #D3DEEB;
          box-shadow: none;
          border-radius: 0;
          margin-bottom: 0;
          font-size: 18px;
          line-height: 26px;
          color: #3E373C;
          cursor: pointer;
          transition: .5s;
          @include for-phone {
            font-size: 16px;
            line-height: 18px; }
          &:last-child {
            margin-bottom: 0; }
          .circle {
            height: 30px;
            width: 30px;
            border: 2px solid #CCDFEF;
            border-radius: 50%;
            flex-shrink: 0;
            margin-right: 0;
            transition: .5s;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              transition: .5s;
              opacity: 0;
              display: none; } }
          //&:hover,
          //&.active
          //  border: 2px solid #4085D8
          //  box-sizing: border-box
          //  box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1)
          //  color: #4085D8
          //  .circle
          //    border-color: #4085D8
          &.active {
            .circle {
              background: #4085D8; }
            //svg
            //  opacity: 1

            &.correct {
              background-color: rgba(64, 133, 216, 0.2);
              border-color: #D3DEEB;
              .circle {
                background: #4085D8;
                border-color: #4085D8;
                svg.correct {
                  opacity: 1;
                  display: block; } } }
            &.inCorrect {
              background-color: #F9EEEC;
              border-color: #D3DEEB;
              .circle {
                background: #BD4E3B;
                border-color: #BD4E3B;
                svg.inCorrect {
                  opacity: 1;
                  display: block; } } } }

          &.correct {
            border-color: #D3DEEB;
            .circle {
              background: #4085D8;
              border-color: #4085D8;
              svg.correct {
                opacity: 1;
                display: block; } } } } } }
    .info {
      padding: 44px 48px;
      margin-top: 20px;
      border-radius: 10px;
      background: #005EAD;
      color: #fff;
      &_head {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px; }
      &Text {
        font-size: 18px; }
      &.error {
        background: #F9EEEC;
        .info_head {
          color: #BD4E3B; }
        .infoText {
          color: #3E373C; } } } } }




















