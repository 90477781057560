@import "../../../Page/mixin";
.step.lesson3 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .flex {
    display: flex; }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; } } }
  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    .head {
      font-weight: bold;
      color: #039cdd;
      font-size: 20px;
      margin-bottom: 45px;
      text-transform: uppercase; }
    .flex {
      justify-content: space-around;
      flex-wrap: wrap;
      margin-bottom: 20px;
      @include for-phone {
        flex-wrap: wrap;
        margin: 0 -10px; }
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 166px;
        margin-bottom: 30px;
        @include for-phone {
          width: 50%;
          max-width: 50%;
          margin-bottom: 30px;
          padding: 0 10px; }
        &:not(:last-child) {
          margin-right: 20px;
          @include for-phone {
            margin-right: 0; } }
        &_border {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          padding: 10px;
          border: 1px solid #b2e6fc;
          border-radius: 50%; }
        &_img {
          width: 100%;
          height: auto; }
        &_text {
          margin: 0;
          text-align: center; }
        &_little-text {
          font-size: 12px;
          margin: 0;
          text-align: center; } } } }
  .step3 {
    .flex {
      margin-bottom: 30px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:first-child {
          margin-right: 30px;
          @include for-phone {
            margin-right: 0; } }
        &:last-child {
          @include for-phone {
            align-items: center; } }
        &_imgBox {
          .img {
            @media screen and (max-width: 500px) {
              width: auto;
              height: 250px; } } }
        .head {
          font-size: 25px;
          line-height: 25px;
          font-weight: bold;
          margin-bottom: 25px; } } } }
  .step4 {
    .InfoBlock.hidden {
      @include for-tablet {
        display: none; } }
    .OnClickBlock {
      .imgDiv_wrap_flex {
        display: flex;
        justify-content: space-between;
        @include for-phone {
          flex-direction: column; }
        .imgDiv_wrap_img {
          @include for-phone {
            display: block;
            margin: 0 auto; } } } } }
  .step6 {
    .flex {
      @include for-phone {
        flex-direction: column; }
      &_el {
        &_head {
          font-size: 25px;
          line-height: 25px;
          font-weight: bold;
          margin-bottom: 25px; }
        &_blue {
          font-weight: bold;
          color: #039cdd;
          font-size: 20px;
          margin-bottom: 25px; }
        &_img {
          display: block;
          margin: 0 auto;
          @media screen and (max-width: 460px) {
            width: 100%;
            height: auto; } } } } }
  .step7 {
    padding-bottom: 50px;
    .wrap {
      display: flex;
      @include for-tablet {
        flex-direction: column-reverse;
        margin-top: 20px;
        padding-bottom: 0; }
      .flex {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 40%;
        margin-right: 30px;
        @include for-tablet {
          width: 100%; }
        &_wrp {
          position: relative;
          &:not(:last-child) {
            margin-bottom: 25px; }
          &:nth-child(2) {
            & .flex_el_content {
              &.active {
                height: 100px; } } }
          &:nth-child(4) {
            & .flex_el_content {
              &.active {
                height: 150px;
                @media screen and (max-width: 500px) {
                  height: 200px; } } } } }
        &_el {
          display: flex;
          align-items: center;
          height: 101px;
          padding: 25px;
          font-size: 18px;
          background: #fff;
          border: 1px solid #c9dbf0;
          //box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1)
          border-radius: 10px;
          cursor: pointer;
          transition: .5s;
          &.active,
          &:hover {
            color: #ffffff;
            background-color: #039cdd;
            @include for-tablet {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0; } }
          &_arrow {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 120%);
            width: 14px;
            height: 18px; }
          &_content {
            display: none;
            @include for-tablet {
              display: flex;
              align-items: center;
              height: 0;
              overflow: hidden;
              padding: 0 20px;
              background-color: #ffffff;
              transition: 0.5s;
              .TextBlock {
                margin-bottom: 0; } }
            &.active {
              height: 100px;
              padding: 20px;
              border: 1px solid #acdff5;
              border-top: none;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px; } } } }
      .box {
        flex-grow: 1;
        .imgDiv {
          height: 0;
          width: 100%;
          position: relative;
          border-radius: 10px;
          transition: 0.5s;
          @include for-tablet {
            display: none; }
          &.active {
            height: 100%;
            flex-grow: 1; }
          &_wrap {
            position: absolute;
            top: 130px;
            border: 1px solid #acdff5;
            padding: 26px 0;
            border-radius: 10px;
            overflow: hidden;
            transform: translateY(-936px);
            &.active {
              padding: 26px 50px;
              border: 1px solid #acdff5;
              transform: translateY(0);
              animation: translate-block 1s ease; }
            &:nth-child(2) {
              top: 340px; }
            &:nth-child(3) {
              top: 490px; }
            .TextBlock {
              margin-bottom: 0; } } }
        .imgBox {
          height: 605px;
          transition: 0.5s;
          @include for-tablet {
            height: auto; }
          &.active {
            height: 0;
            @include for-tablet {
              height: auto; } }
          .img {
            transform: translateY(0);
            transition: 0.5s ease-out;
            @include for-tablet {
              display: block;
              margin: 0 auto 30px; }
            @media screen and (max-width: 380px) {
              width: auto;
              height: 480px; }
            &.active {
              transform: translateY(250px);
              @include for-tablet {
                transform: translateY(0);
                transition: none; } } } } } }
    @keyframes translate-block {
      0% {
        transform: translateY(-736px); }
      50% {
        transform: translateY(50px); }
      80% {
        transform: translateY(-40px); }
      100% {
        transform: translateY(0); } } }
  .step9 {
    .TextBlock.hidden {
      @include for-tablet {
        display: none; } }
    .InfoBlock.hidden {
      @include for-tablet {
        display: none; } }
    .flex {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        height: 101px;
        margin-right: 12px;
        padding: 0 15px;
        font-size: 18px;
        text-align: center;
        color: #acdff5;
        font-weight: bold;
        background: #fff;
        border: 1px solid #c9dbf0;
        box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        transition: .5s;
        @include for-phone {
          width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
          font-size: 20px;
          line-height: 24px;
          color: #039cdd; }
        &.active,
        &:hover {
          box-shadow: none;
          color: #039cdd; }
        &:last-child {
          margin-right: 0; }
        &.flex_el_inactive {
          color: #ffffff;
          background-color: #039cdd;
          box-shadow: none; }
        &_sign {
          margin-right: 12px;
          font-size: 20px;
          font-weight: bold;
          color: #039cdd;
          @include for-phone {
            transform: rotate(90deg); } } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv {
      margin-top: 24px;
      border: 1px solid #acdff5;
      box-shadow: 0 2px 3px 0 rgba(74, 74, 74, 0.2);
      border-radius: 10px;
      margin-bottom: 40px;
      padding: 40px 47px;
      position: relative;
      @include for-tablet {
        padding: 0;
        border: none;
        box-shadow: none; }
      &_closed {
        display: none;
        @include for-tablet {
          position: absolute;
          bottom: -40px;
          right: 50%;
          height: 35px;
          width: 35px;
          transform: translateX(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #99BFDE;
          background-color: #005EAD;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            background: #ffffff;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: -1px;
        transform: translate(-50%, -50%) rotate(-45deg);
        height: 12px;
        width: 12px;
        background: #fff;
        border-top: 1px solid #acdff5;
        border-right: 1px solid #acdff5;
        transition: .5s;
        @include for-tablet {
          display: none; } }
      &.center {
        &:before {
          left: 88%; } }
      &.right {
        &:before {
          left: 91%; } }
      &_wrap {
        display: flex;
        @include for-phone {
          flex-direction: column; }
        .TextBlock {
          margin-right: 20px; }
        & img {
          display: block;
          margin: 0 auto;
          @media screen and (max-width: 450px) {
            width: 100%;
            max-width: 280px;
            height: auto; } } } }
    .flexBottom {
      display: flex;
      @media screen and (max-width: 550px) {
        flex-direction: column; }
      &.hidden {
        @include for-tablet {
          display: none; } }
      &_el {
        &.text {
          font-weight: bold;
          color: #039cdd;
          font-size: 20px;
          margin-right: 20px;
          text-transform: uppercase;
          @include for-phone {
            font-size: 18px;
            margin-right: 0; }
          @media screen and (max-width: 550px) {
            margin-bottom: 20px; } } }
      &_img {
        @media screen and (max-width: 550px) {
          display: block;
          margin: 0 auto;
          width: 100%;
          max-width: 280px;
          height: auto; } } } }
  .step10 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .test_wrp {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 38px;
          .flex {
            display: flex;
            .answers {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              margin-top: 0 !important;
              margin-bottom: 0 !important;
              &_item {
                position: relative;
                &:not(:last-child) {
                  margin-right: 30px; } }
              .answer {
                position: relative;
                align-items: center;
                justify-content: center;
                width: 140px;
                height: 140px;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #039cdd;
                text-align: center;
                text-transform: uppercase;
                background-color: #ffffff;
                border: 1px solid #b2e6fc;
                border-radius: 50%;
                box-shadow: none;
                padding: 0 15px;
                @include for-tablet {
                  width: 100px;
                  height: 100px; }
                img {
                  @include for-tablet {
                    width: 100px;
                    height: 100px; } }
                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8;
                  .icon svg {
                    fill: #4085D8; } }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B;
                  .icon svg {
                    fill: #BD4E3B; } } }
              &_plus {
                position: absolute;
                top: 0;
                right: -20px;
                font-size: 20px;
                font-weight: bold;
                color: #039cdd; } } }
          .questions {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .question_el {
              @include for-tablet {
                width: 100px;
                height: 100px; }
              &:not(:last-child) {
                margin-right: 15px;
                margin-bottom: 12px; }
              //&.hidden
              //  cursor: default
              //  .ddcontainersource
              //    font-size: 0
              //    background-color: #E6EFF7
              //    box-shadow: none
              //    border: none
              //    img
              //      opacity: 0
              .ddcontainer {
                width: 100%; } }
            .ddcontainersource {
              font-size: 14px;
              line-height: 22px;
              color: #039cdd;
              font-weight: bold;
              text-transform: uppercase;
              cursor: pointer;
              .icon {
                width: 100%;
                img {
                  width: 100%; } } }
            .ddcontainerghost {
              font-size: 14px;
              line-height: 22px;
              color: #039cdd;
              font-weight: bold;
              text-align: center;
              text-transform: uppercase;
              @include for-tablet {
                width: 100px;
                height: 100px; }
              .icon {
                width: 100%;
                img {
                  width: 100%; } } } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #e3fffb;
          color: #2c3032;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: #3fc4b1; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #ffe3e3;
            .info_head {
              color: #f12626; }
            .infoText {
              color: #2c3032; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -25px;
          right: 0;
          transform: translate(0, 100%); } }
      //Стили мобильной версии
      .test_mobile {
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 0;
          .flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 48px; }
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 140px;
            height: 140px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #039cdd;
            background-color: #ffffff;
            border: 1px solid #b2e6fc;
            border-radius: 50%;
            text-transform: uppercase;
            text-align: center;
            margin: 0 10px;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              //border: 2px solid #039cdd
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8;
                .answers_icon svg {
                  fill: #4085D8; } }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B;
                .answers_icon svg {
                  fill: #BD4E3B; } } }
            &:not(:last-child) {
              margin-bottom: 24px; } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          overflow-y: auto;
          padding-bottom: 92px;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 30px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #2c3032;
              background-color: #ffffff;
              border: 1px solid #039cdd;
              border-radius: 50%; }
            .questions {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              &_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                border-radius: 15px;
                margin: 0 15px;
                .question_icon {
                  margin-bottom: 8px;
                  svg {
                    fill: #4C8EC5; } }
                &:nth-child(1) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 30px;
                      height: 36px; } } }
                &:nth-child(2) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 36px;
                      height: 36px; } } }
                &:nth-child(3) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 48px;
                      height: 29px; } } }
                &:nth-child(4) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 32px;
                      height: 30px; } } }
                &:nth-child(5) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 25px;
                      height: 36px; } } }
                &:nth-child(6) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 31px;
                      height: 32px; } } }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; }
                &:not(:last-child) {
                  margin-bottom: 20px; } } } }
          &_text {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #e3fffb;
          margin-bottom: 48px;
          color: #2c3032;
          @include for-phone {
            padding: 20px; }
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: #3fc4b1; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #ffe3e3;
            .info_head {
              color: #f12626; }
            .infoText {
              color: #2c3032; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -25px;
          right: 0;
          transform: translate(0, 100%); } } } }
  .step11 {
    .flex {
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 48%;
        @include for-phone {
          width: 100%;
          margin-bottom: 20px; }
        &_head {
          font-size: 17px;
          color: #039cdd;
          margin-bottom: 13px; }
        .table {
          &_row {
            display: flex;
            margin-bottom: 14px;
            &_left {
              width: 123px;
              font-size: 15px; }
            &_right {
              flex-grow: 1;
              height: 24px;
              background-color: #f0f5fb;
              &_percent {
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                font-size: 11px;
                font-weight: bold;
                color: #ffffff;
                padding-left: 7px;
                background-color: #039cdd;
                .low_percent {
                  position: absolute;
                  color: #039cdd;
                  margin: 0;
                  right: -25px; }
                &.three {
                  width: 3%; }
                &.seven {
                  width: 7%; }
                &.nine {
                  width: 9%; }
                &.ten {
                  width: 10%; }
                &.eleven {
                  width: 11%; }
                &.twenty {
                  width: 20%; }
                &.thirtyOne {
                  width: 31%; }
                &.fortyFife {
                  width: 45%; }
                &.seventyTwo {
                  width: 72%; }
                &.ninetyTwo {
                  width: 92%; }
                &.ninety {
                  width: 90%; }
                &.sixty {
                  width: 60%; }
                &.seventyFive {
                  width: 75%; }
                &.eightyNine {
                  width: 89%; }
                &.sixtyThree {
                  width: 63%; }
                &.fiftyFour {
                  width: 54%; } } } } } } } }
  .step14 {
    .shema {
      margin-bottom: 20px; }
    .shema_img {
      display: block;
      width: 100%;
      max-width: 810px;
      height: auto;
      margin: 0 auto;
      @media screen and (max-width: 500px) {
        max-width: 280px; } }
    .footnote {
      display: flex;
      margin-bottom: 30px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-size: 18px;
        @include for-phone {
          margin-bottom: 20px;
          font-size: 14px; }
        &:nth-child(2) {
          & .footnote_point {
            background-color: #5394cf; } }
        &:last-child {
          margin-right: 0;
          @include for-phone {
            margin-bottom: 0; }
          & .footnote_point {
            background-color: #0077ae; } } }
      &_point {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        background-color: #8bb0dd;
        border-radius: 50px;
        flex-shrink: 0; }
      &_star {
        margin-right: 5px; } } }
  .step15, .step16, .step17, .step18, .step19, .step20 {
    .OnClickBlock {
      .imgDiv_wrap {
        &_flex {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 550px) {
            flex-direction: column; } }
        &_img {
          display: block;
          margin: 0 auto;
          @media screen and (max-width: 500px) {
            width: 100%;
            max-width: 280px;
            height: auto; } } } } }
  .step18, .step20 {
    .OnClickBlock {
      .imgDiv_wrap {
        &_img {
          @media screen and (max-width: 650px) {
            width: 100%;
            max-width: 280px;
            height: auto; } } } } }
  .step19 {
    .OnClickBlock {
      .imgDiv_wrap {
        &_img {
          @media screen and (max-width: 700px) {
            width: 100%;
            max-width: 280px;
            height: auto; } } } } }
  .step22 {
    position: relative;
    padding-bottom: 410px;
    .img {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(50px, 0); } } }



