.checkbox {
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  padding-left: 37px;
  position: relative;
  cursor: pointer;
  opacity: 1;
  &:not(:last-of-type) {
    margin-bottom: 15px; }
  &:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    transform: translate(0, 0);
    height: 15px;
    width: 15px;
    border: 1px solid #fff;
    opacity: 1; }
  &:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 5px;
    transform: translate(0, -50%) rotate(-45deg);
    height: 3px;
    width: 8px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 0;
    transition: .5s; }
  &.active {
    opacity: 1;
    &:before {
      opacity: 1; }
    &:after {
      opacity: 1; } }
  &.error {
    color: #BD4E3B;
    &:before {
      border-color: #BD4E3B; } }
  .agreement_link {
    color: #ffffff;
    font-style: italic;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    transition: 0.5s;
    &:hover {
      border-bottom: 1px solid rgba(255, 255, 255, 0); } } }


