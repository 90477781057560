.LeftSideBar {
  width: 218px;
  height: 100vh;
  background: #146BB3;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
  overflow-y: auto;

  @media screen and (max-width: 1366px) {
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 92px;
    padding-bottom: 0;
    background-color: #00559C;
    overflow-y: visible; }

  &.Blue {
    background: #001299; }
  &.pyaterochka {
    background: #E52322; }
  &.perekrestok {
    background: #24582E; }
  .top,
  .bottom {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
    @media screen and (max-width: 1366px) {
      margin-bottom: 43px; }
    @media screen and (max-width: 767px) {
      flex-grow: 0; } }
  .top {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @media screen and (max-width: 1366px) {
      position: absolute;
      top: 92px;
      right: 0;
      width: 384px;
      height: calc(100vh - 92px);
      background-color: #005EAD;
      z-index: 50;
      overflow-y: auto; }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 100vh; }
    &.Blue {
      background: #001299; }
    &.pyaterochka {
      background: #E52322; }
    &.perekrestok {
      background: #24582E; } }
  .logo {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    @media screen and (max-width: 1366px) {
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      flex-direction: row;
      font-size: 15px;
      text-align: left; }
    @media screen and (max-width: 767px) {
      font-size: 10px;
      line-height: 12px;
      padding-left: 13px; }
    img {
      @media screen and (max-width: 1366px) {
        height: 100%; } }
    &--Blue {
      @media screen and (max-width: 1366px) {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px; } }
    &--pyaterochka {
      padding-top: 20px;
      padding-bottom: 24px;
      @media screen and (max-width: 1366px) {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px; } }
    &--perekrestok {
      padding-top: 20px;
      padding-bottom: 24px;
      @media screen and (max-width: 1366px) {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px; } }
    &--Image {
      width: 100%;
      @media screen and (max-width: 1366px) {
        width: auto; } } }

  .profile {
    padding-top: 37px;
    padding-bottom: 37px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: #005EAD;

    &.Blue {
      background: #001299; }
    &.pyaterochka {
      background: #E52322; }
    &.perekrestok {
      background: #24582E; }
    &_name {
      margin-bottom: 10px;
      line-height: 24px; }
    &_photo {
      position: relative;
      width: 80px;
      margin: 0 auto;
      .edit {
        position: absolute;
        top: 0;
        right: 0;
        height: 25px;
        width: 25px;
        border-radius: 25px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: .5s;
        &:hover {
          transform: scale(1.2); } }
      &_img {
        height: 80px;
        width: 80px;
        border-radius: 40px;
        overflow: hidden;
        border: 1px solid #4C8EC5; } }
    &_status {
      text-transform: uppercase;
      line-height: 24px;
      margin-top: 17px; }
    &_points {
      font-size: 18px;
      line-height: 26px; } }


  .menuBtn {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    flex-shrink: 0;
    &_link {
      font-size: 12px;
      color: #99BFDE;
      text-decoration: underline;
      transition: .5s;
      @media screen and (max-width: 1366px) {
        font-size: 20px;
        margin-bottom: 30px; }
      &:hover {
        text-decoration: none; } }
    &.btn-border {
      height: 30px;
      width: 190px;
      border: 1px solid #fff;
      border-radius: 30px;
      font-size: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      transition: .5s;
      background: transparent;
      cursor: pointer;
      margin-top: auto;
      @media screen and (max-width: 1366px) {
        width: 280px;
        margin-top: 0; }
      &:hover {
        background: #fff;
        color: #146BB3; } }
    svg {
      margin-right: 15px; } }
  .closed {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1366px) {
      display: none; } }

  .openMenu {
    display: none;
    @media screen and (max-width: 1366px) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 92px;
      height: 100%;
      background-color: #00559C; }
    &.pyaterochka {
      background: #BC1110; }
    &.perekrestok {
      background: #1F6A2D; }
    p {
      position: absolute;
      width: 28px;
      height: 2px;
      margin: 0;
      background-color: #ffffff;
      transition: .3s;
      &:first-child {
        top: 40px; }
      &:last-child {
        top: 50px; } }
    &.opened p {
      top: 50%;
      transform: translateY(-50%);
      &:first-child {
        transform: rotate(-45deg); }
      &:last-child {}
      transform: rotate(45deg); } } }



