@import "../../../Page/mixin";
.step.lesson1 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;
  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .flex {
    display: flex; }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; } } }
  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step4, .step14 {
    .flex {
      margin-bottom: 20px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
          margin-right: 30px;
          @include for-phone {
            margin-right: 0;
            margin-bottom: 20px; } }
        &_img {
          @include for-phone {
            display: block;
            width: 70%;
            margin: 0 auto; }
          @media screen and (max-width: 450px) {
            width: 100%; } } } }
    .definitions {
      padding: 25px;
      border-radius: 10px;
      border: 1px solid #acdff5;
      &_head {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 25px;
        text-transform: uppercase;
        font-weight: bold; }
      &_item {
        &:not(:last-child) {
          border-bottom: 1px solid #acdff5;
          margin-bottom: 20px;
          padding-bottom: 20px; } }
      &_text {
        margin: 0;
        font-size: 15px;
        line-height: 17px; }
      &_word {
        color: #039cdd;
        font-weight: bold; }
      &_description {
        font-style: italic; } } }
  .step7 {
    .flex {
      align-items: center;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:not(:last-child) {
          margin-right: 20px;
          @include for-phone {
            margin-right: 0; } }
        .img {
          @media screen and (max-width: 450px) {
            width: 90%;
            height: auto;
            display: block;
            margin: 0 auto; } } } } }
  .step8 {
    padding-bottom: 300px;
    @include for-tablet {
      padding-bottom: 320px; }
    @include for-phone {
      padding-bottom: 0; }
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #039cdd;
      border-radius: 12px;
      @include for-phone {
        flex-direction: column;
        overflow: hidden; }
      &_wrp {
        width: 20%;
        @include for-phone {
          width: 100%; }
        &:first-child .flex_el {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          @include for-phone {
            border-bottom-left-radius: 0;
            border-top-right-radius: 10px; } }
        &:last-child .flex_el {
          border-right: none;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          @include for-phone {
            border-bottom: none;
            border-bottom-left-radius: 10px;
            border-top-right-radius: 0; }
          &.active {
            @include for-phone {
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0; } } }
        &:last-child .flex_content.active {
          @include for-phone {
            border-bottom: none; } } }
      &_el {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75px;
        color: #039cdd;
        border-right: 1px solid #039cdd;
        background: #fff;
        cursor: pointer;
        transition: .5s;
        @include for-phone {
          width: 100%;
          font-size: 20px;
          line-height: 24px;
          border-right: none;
          border-bottom: 1px solid #039cdd; }
        &.active,
        &:hover {
          background: #039cdd;
          color: #fff;
          font-weight: bold; }
        &.active {
          @include for-phone {
            margin-bottom: 20px; } } }
      &_content {
        display: none;
        @include for-phone {
          display: block;
          height: 0;
          overflow: hidden;
          padding: 0 20px;
          background-color: #ffffff;
          transition: 0.5s; }
        &.active {
          height: 100px;
          border-bottom: 1px solid #039cdd;
          overflow: visible;
          @media screen and (max-width: 470px) {
            height: 125px; }
          @media screen and (max-width: 400px) {
            height: 150px; } } } }
    .imgDiv {
      position: relative;
      @include for-phone {
        display: none; }
      &_wrap {
        position: absolute;
        top: 25px;
        width: 45%;
        padding: 25px;
        border: 1px solid #acdff5;
        border-radius: 10px;
        transform: scale(0);
        transition: 0.5s;
        &:before {
          content: '';
          position: absolute;
          top: -1px;
          transform: translate(-50%, -50%) rotate(-45deg);
          height: 12px;
          width: 12px;
          background: #fff;
          border-top: 1px solid #acdff5;
          border-right: 1px solid #acdff5;
          transition: .5s; }
        &:nth-child(1) {
          left: 0;
          &:before {
            left: 22%; } }
        &:nth-child(2) {
          left: 15%;
          &:before {
            left: 33%; } }
        &:nth-child(3) {
          left: 30%;
          &:before {
            left: 45%; } }
        &:nth-child(4) {
          left: 40%;
          &:before {
            left: 67%; } }
        &:nth-child(5) {
          right: 0;
          &:before {
            left: auto;
            right: 20%; } }
        &.active {
          transform: scale(1);
          transform-origin: top;
          &:nth-child(1) {
            left: 0;
            &:before {
              left: 22%; } }
          &:nth-child(2) {
            left: 15%;
            &:before {
              left: 33%; } }
          &:nth-child(3) {
            left: 30%;
            &:before {
              left: 45%; } }
          &:nth-child(4) {
            left: 40%;
            &:before {
              left: 67%; } }
          &:nth-child(5) {
            right: 0;
            &:before {
              left: auto;
              right: 20%; } } } } } }
  .step11, .step20 {
    .table {
      display: flex;
      margin-bottom: 20px;
      @include for-phone {
        flex-direction: column;
        border: 1px solid #c9dbf0; }
      &_flex {
        width: 25%;
        @include for-phone {
          width: 100%; }
        &_row {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 96px;
          padding: 5px 15px;
          border-right: 1px solid #c9dbf0;
          box-sizing: border-box;
          @include for-phone {
            border-right: none; }
          &:not(:last-child) {
            border-bottom: 1px solid #c9dbf0; }
          &_text, &.block {
            font-weight: bold;
            font-size: 25px;
            @include for-phone {
              display: none; } }
          &.data {
            @include for-phone {
              align-items: stretch; } } }
        &_head {
          &_img {
            margin-bottom: 10px; }
          &_text {
            margin: 0; } }
        &:last-child {
          .table_flex_row {
            border-right: none;
            &.data {
              @include for-phone {
                border-bottom: none; } } } }
        &.rows {
          @include for-phone {
            display: none; }
          .table_flex_row {
            align-items: flex-start;
            font-size: 14px; } }
        &_mobile {
          display: none;
          @include for-phone {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: center; }
          &_row {
            display: flex;
            &:last-child .table_flex_mobile_name {
              margin-bottom: 0; } }
          &_name {
            margin: 0 15px 10px 0; }
          &_data {
            margin: 0;
            margin-left: auto;
            font-weight: bold; } } } } }
  .step12 {
    .flex {
      flex-wrap: wrap;
      justify-content: center;
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20%;
        @media screen and (max-width: 550px) {
          width: 33.25%;
          margin-bottom: 20px; }
        .img {
          width: 100%;
          height: auto;
          margin-bottom: 10px; }
        &_text {
          margin: 0;
          &:nth-child(3) {
            margin-bottom: 15px; } }
        &_percent {
          margin: 0;
          color: #039cdd;
          font-size: 20px;
          font-weight: bold; } } } }
  .step13 {
    .flex {
      border-radius: 10px;
      border: 1px solid #acdff5;
      padding: 30px 25px 30px 35px;
      margin-bottom: 40px;
      @include for-phone {
        flex-direction: column-reverse;
        padding: 0;
        border: none;
        border-radius: 0; }
      &:nth-child(2) {
        @include for-phone {
          border-bottom: 1px solid #acdff5; } }
      &_el {
        &:first-child {
          margin-right: 50px;
          @include for-phone {
            display: flex;
            justify-content: center;
            margin-right: 0;
            margin-bottom: 20px; } }
        &_head {
          margin: 0 0 15px;
          color: #039cdd;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 18px;
          line-height: 18px; }
        &_text {
          font-size: 18px;
          @include for-phone {
            font-size: 16px; } }
        &_yellow {
          color: #f1ba12; } } } }
  .step15 {
    .flex {
      @include for-phone {
        flex-direction: column-reverse; }
      &_el {
        &:first-child {
          margin-right: 20px;
          @include for-phone {
            margin-right: 0; } }
        &_img {
          @include for-phone {
            display: block;
            margin: 0 auto; } } } } }
  .step16, .step18, .step24 {
    position: relative;
    .InfoBlock {
      @include for-phone {
        margin-top: 30px; }
      &.hidden {
        @include for-tablet {
          display: none; } } }
    .TextBlock {
      line-height: 26px;
      @include for-tablet {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px; }
      &.hidden {
        @include for-tablet {
          display: none; } } }
    .flex {
      margin-top: 35px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      @include for-tablet {
        flex-wrap: wrap; }
      @include for-phone {
        justify-content: center; }
      &.active {
        .flex_el:nth-child(-n+5) {
          margin-bottom: 470px;
          @include for-tablet {
            margin-bottom: 0; } } }
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20%;
        //margin-right: 24px
        color: #039cdd;
        text-align: center;
        background: #fff;
        //box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1)
        //border-radius: 10px
        cursor: pointer;
        transition: .5s;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        @include for-tablet {
          width: 20%;
          margin-bottom: 20px;
          color: #039cdd; }
        @include for-phone {
          width: 50%;
          margin-right: 0;
          font-size: 20px;
          line-height: 24px; }
        &_img {
          transform: scale(1);
          transition: 0.5s; }
        &.active,
        &:hover {
          color: #039cdd;
          //box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1)
          //border-radius: 10px
          //transform: scale(1.1)
          & .flex_el_img {
            transform: scale(1.2); } }
        &:last-child {
          margin-right: 0; } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv {
      position: absolute;
      top: 530px;
      width: 100%;
      border-radius: 10px;
      height: 0;
      overflow: hidden;
      padding: 0 47px;
      transition: 0.5s ease;
      @include for-tablet {
        position: relative;
        top: auto;
        overflow: visible;
        transition: none; }
      &.active {
        height: 400px;
        padding: 40px 47px;
        border: 1px solid #acdff5;
        @include for-tablet {
          border: none;
          padding: 0;
          height: auto;
          margin-bottom: 40px; } }
      &_closed {
        display: none;
        @include for-tablet {
          position: absolute;
          bottom: -40px;
          right: 50%;
          height: 35px;
          width: 35px;
          transform: translateX(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #99BFDE;
          background-color: #005EAD;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            background: #ffffff;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }
      &_flex {
        width: 100%; }
      &_img {
        display: block;
        margin: 0 auto;
        @media screen and (max-width: 600px) {
          width: 100%;
          height: auto; } } } }
  .step17 {
    @include for-phone {
      padding-bottom: 150px; }
    .flex {
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:first-child {
          margin-right: 30px;
          width: 50%;
          flex-shrink: 0;
          @include for-phone {
            width: 100%;
            padding-left: 30px; } }
        &_block {
          position: relative;
          padding: 25px 25px 80px;
          border-radius: 10px;
          background-color: #f0f5fb; }
        &_text {
          font-size: 16px; }
        &_img {
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 70%); } } }
    .head {
      margin-bottom: 40px;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase; }
    .wrap {
      @include for-phone {
        padding-left: 30px; } }
    .rating {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 62px;
      margin-bottom: 45px;
      margin-left: 45px;
      padding: 0 20px 0 50px;
      border-radius: 10px;
      border: 1px solid #acdff5;
      box-shadow: 0 2px 3px 0 rgba(74, 74, 74, 0.2);
      @include for-phone {
        margin-left: 0; }
      &_img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%); }
      &.height {
        width: 80%;
        @include for-phone {
          width: 100%; } }
      &.low {
        width: 80%;
        @include for-phone {
          width: 100%; } }
      &_text {
        margin: 0;
        font-size: 18px; }
      &_percent {
        margin: 0;
        font-size: 25px;
        font-weight: bold;
        color: #039cdd; } } }
  .step18, .step24 {
    .flex {
      &.active {
        .flex_el:nth-child(-n+5) {
          margin-bottom: 280px;
          @include for-tablet {
            margin-bottom: 0; } } }
      &_el {
        position: relative;
        &_number {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, 25%);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          border: 1px solid #039cdd;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          margin: 0;
          color: #039cdd;
          font-size: 24px; } } }
    .imgDiv {
      top: 390px;
      @include for-tablet {
        top: auto; }
      &.active {
        height: 260px;
        @include for-phone {
          height: auto; } }
      &_flex {
        display: flex;
        justify-content: center;
        @media screen and (max-width: 500px) {
          flex-direction: column;
          align-items: center; } }
      &_img {
        margin: 0;
        margin-right: 40px;
        @media screen and (max-width: 600px) {
          width: auto; }
        @media screen and (max-width: 500px) {
          margin-right: 0;
          width: 70%; } } } }
  .step21, .step22 {
    .table {
      display: flex;
      margin-bottom: 20px;
      @include for-phone {
        flex-direction: column;
        border: 1px solid #c9dbf0; }
      &_flex {
        width: 33%;
        @include for-phone {
          width: 100%; }
        &_el {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 15px 15px;
          border-right: 1px solid #c9dbf0;
          border-bottom: 1px solid #c9dbf0;
          box-sizing: border-box;
          @include for-phone {
            border-right: none; }
          &:last-child {
            border-bottom: none;
            @include for-phone {
              border-bottom: 1px solid #c9dbf0; } }
          &.head {
            height: 125px; } }
        &:last-child {
          .table_flex_el {
            border-right: none;
            &:last-child {
              @include for-phone {
                border-bottom: none; } } } } } }
    .footnote {
      display: flex;
      margin-bottom: 40px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-size: 18px;
        @include for-phone {
          margin-bottom: 20px;
          font-size: 14px; }
        &:last-child {
          margin-right: 0;
          @include for-phone {
            margin-bottom: 0; }
          & .footnote_point {
            background-color: #0077ae; } } }
      &_point {
        margin-right: 5px;
        width: 15px;
        height: 15px;
        background-color: #039cdd;
        border-radius: 50px;
        flex-shrink: 0; } }
    .flex {
      align-items: center;
      padding: 30px 25px 30px 35px;
      border: 1px solid #acdff5;
      border-radius: 10px;
      @include for-phone {
        padding: 20px 15px 20px 25px;
        flex-direction: column-reverse; }
      &_el {
        &:first-child {
          margin-right: 50px;
          @include for-phone {
            margin-right: 0; } } } } }
  .step22 {
    .footnote {
      &_el {
        &:nth-child(2) {
          & .footnote_point {
            background-color: #0077ae; } }
        &:last-child {
          & .footnote_point {
            background-color: #065380; } } } } }
  .step23 {
    @include for-phone {
      padding-bottom: 140px; }
    &.padding-none {
      @include for-phone {
        padding-bottom: 0; } }
    .InfoBlock {
      @include for-phone {
        margin-top: 30px; }
      &.hidden {
        @include for-tablet {
          display: none; } } }
    .TextBlock {
      line-height: 26px;
      @include for-tablet {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px; }
      &.hidden {
        @include for-tablet {
          display: none; } } }
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 40px; }
    .flex {
      margin-top: 35px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        //margin-right: 24px
        color: #039cdd;
        text-align: center;
        background: #fff;
        //box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1)
        //border-radius: 10px
        cursor: pointer;
        transition: .5s;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        @include for-tablet {
          width: 25%;
          margin-bottom: 20px;
          color: #039cdd; }
        @include for-phone {
          width: 50%;
          margin-right: 0;
          font-size: 20px;
          line-height: 24px; }
        &_img {
          transform: scale(1);
          transition: 0.5s; }
        &.active,
        &:hover {
          color: #039cdd;
          //box-shadow: 0 5px 10px rgba(42, 46, 126, 0.1)
          //border-radius: 10px
          //transform: scale(1.1)
          & .flex_el_img {
            transform: scale(1.2); } }
        &:last-child {
          margin-right: 0; } }
      &.hidden {
        @include for-tablet {
          display: none; } } }
    .imgDiv {
      position: relative;
      width: 100%;
      margin-bottom: 30px;
      border-radius: 10px;
      height: 0;
      overflow: hidden;
      padding: 0 37px;
      transition: 0.5s ease 0.1s;
      @include for-tablet {
        overflow: visible;
        transition: none; }
      &.active {
        height: 120px;
        padding: 30px 37px;
        border: 1px solid #acdff5;
        @include for-tablet {
          border: none;
          padding: 0;
          height: auto;
          margin-bottom: 40px; } }
      &_img-tablet {
        display: none;
        @include for-tablet {
          display: block;
          margin: 0 auto 20px; } } }
    .popular {
      position: relative;
      background-color: #f0f5fb;
      padding: 28px 35px 20px 220px;
      margin: 60px 0;
      border-radius: 10px;
      @include for-phone {
        padding: 20px 20px 60px;
        margin-top: 0; }
      &.hidden {
        @include for-tablet {
          display: none; } }
      &_img {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-10px, -50%);
        @include for-phone {
          width: 165px;
          top: auto;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 80%); } }
      &_text {
        margin: 0;
        white-space: pre-line;
        &:not(:last-child) {
          margin-bottom: 20px; } } } }
  .step24 {
    .flex {
      &.active {
        .flex_el:nth-child(-n+5) {
          margin-bottom: 310px;
          @include for-tablet {
            margin-bottom: 0; } } }
      &.active.newMargin {
        .flex_el:nth-child(-n+5) {
          margin-bottom: 420px;
          @include for-tablet {
            margin-bottom: 0; } } }
      &_el {
        &_text {
          margin-top: 30px; } } }
    .imgDiv {
      &.active {
        height: 280px;
        @include for-phone {
          height: auto; }
        &.newHeight {
          height: 400px;
          @include for-phone {
            height: auto; } } }
      &_detail {
        margin-top: 20px;
        border-top: 1px solid #b1138a;
        &.yellow {
          border-top: 1px solid #eee;
          & .imgDiv_detail_head {
            color: #dd9742; } }
        .text_purple {
          color: #b1138a; }
        .text_yellow {
          color: #dd9742; }
        &_head {
          font-size: 24px;
          font-weight: 500;
          color: #b1138a;
          margin: 20px 0 10px; } } } } }









