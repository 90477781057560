.input {

    width: 100%;
    border: none;
    outline:  none;
    padding: 10px 0;
    background-color: transparent;
    color: #FFF;
    font-size: 18px;
    line-height: 20px;
    border-bottom: 2px solid #FFF;
    margin-bottom: 30px;
    text-transform: uppercase;
    z-index: 1;
    position: relative;

    &::placeholder {
        color: #FFF;
        font-size: 18px;
        line-height: 20px;
    }

    &__body {
        margin: 17px 0;
        position: relative;

        span {
            position: absolute;
            bottom: 40px;
            left: 0;
            color: #FFF;
            font-size: 18px;
            line-height: 20px;
            text-transform: uppercase;
            z-index: 0;
            transition: all .5s linear;

        }

        .active {
            font-size: 8px;
            line-height: 10px;
            transform: translate(0, -30px);
        }

        .error {
            color: red;
        }
    }

    &.error {
        color: red;

        &::placeholder {
            color: red;
            font-size: 18px;
            line-height: 20px;
        }
    }
}

@media screen and (max-width:768px) {
    .input {

        font-size: 14px;
        line-height: 14px;
        margin-bottom: 20px;
    
        &::placeholder {
            font-size: 14px;
            line-height: 14px;
        }

    
        &.error {
    
            &::placeholder {
                font-size: 14px;
                line-height: 14px;
            }
        }

        &__body {

            span {
                font-size: 14px;
                line-height: 16px;

            }
        }
    }
  }