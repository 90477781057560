.DropDownPanel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #ffffff;
  padding: 20px;
  border: 1px solid #CCDFEF;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgba(42, 46, 126, 0.1);
  cursor: pointer;
  margin-bottom: 18px;
  &:last-child {
    margin-bottom: 0; }
  .number {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 20px;
    font-size: 32px;
    font-weight: bold;
    color: #CCDFEF; }
  .arrow {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    border: 1px solid #CCDFEF;
    flex-shrink: 0;
    position: relative;
    margin-left: 30px;
    transition: .5s;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 10px solid #CCDFEF;
      transition: .5s; } }
  &_wrp {
    width: calc(100% - 166px); }
  .question {
    font-size: 18px;
    line-height: 24px;
    color: #005EAD;
    font-weight: bold;
    width: 100%; }
  .answer {
    display: none;
    width: 100%;
    margin-top: 10px;
    color: #3E373C;
    font-size: 18px;
    line-height: 24px;
    padding: 0 80px; }
  &.open {
    .answer {
      display: block; }
    .arrow {
      background: #CCDFEF;
      &:before {
        border-top: 10px solid #fff;
        transform: translate(-50%, -50%) rotate(180deg); } } } }


