.PopupInStep {
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  background: rgba(#345178, .8);
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 112px;
  @media screen and (max-width: 767px) {
    top: 92px;
    height: 100%;
    padding-top: 0; }
  .popup {
    width: 100%;
    max-width: 640px;
    padding: 70px;
    background: #fff;
    position: relative;
    border-radius: 10px;
    @media screen and (max-width: 767px) {
      height: calc(100vh - 92px);
      padding: 40px 20px;
      max-width: initial;
      border-radius: 0;
      overflow-y: auto; }
    .popup_closed {
      position: absolute;
      top: 20px;
      right: 20px;
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #99BFDE;
      cursor: pointer;
      transition: .5s;
      @media screen and (max-width: 767px) {
        position: fixed;
        top: 0;
        right: 0;
        height: 92px;
        width: 92px;
        background-color: #00559C; }
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background: #99BFDE;
        transform-origin: center;
        top: 50%;
        left: 50%;
        transition: .5s;
        @media screen and (max-width: 767px) {
          background: #ffffff;
          width: 28px; } }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: #005EAD; }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }
    .popup_imgHead {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      @media screen and (max-width: 767px) {
        width: 155px;
        height: 155px;
        position: relative;
        padding: 0;
        transform: translate(-50%, 0); }
      picture {
        width: 100%;
        height: 100%; }
      &Bg {
        width: 100%;
        height: 100%;
        background: #E6EFF7;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center; } }
    .popup_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      white-space: pre-line;
      @media screen and (max-width: 767px) {
        margin-top: 30px; } }
    .popup_subHead {
      color: #1F72B7;
      text-align: center;
      font-style: italic;
      font-size: 18px;
      white-space: pre-line;
      margin-bottom: 30px; }
    .popup_text {
      font-size: 16px;
      line-height: 24px;
      color: #3E373C;
      white-space: pre-line;
      margin-bottom: 20px;
      padding-bottom: 10px;
      max-height: 30vh;
      overflow-y: auto;
      .text_blue {
        color: #039cdd;
        font-weight: bold; } }
    .btn {
      position: relative;
      left: 50%;
      transform: translate(-50%); }
    .popup_imgContent {
      width: 100%;
      height: auto;
      margin-bottom: 30px; }
    .slider {
      border: 2px solid #CCDFEF;
      border-radius: 10px;
      position: relative;
      padding: 25px;
      margin-bottom: 50px;
      @media screen and (max-width: 1023px) {
        padding: 0;
        padding-bottom: 10px;
        border: none; }
      .slide {
        color: #4C8EC5;
        font-size: 16px;
        line-height: 24px; }
      .slick-arrow {
        bottom: 0; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .SwipeBlock {
    display: none;
    @media screen and (max-width: 1023px) {
      display: flex;
      margin-bottom: 20px; } } }
