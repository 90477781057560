.dostupnost {
  background: #fff;
  border-radius: 20px;
  padding: 70px 20px;
  min-height: 600px; }
.dostupnost__container {
  max-width: 768px;
  width: 100%;
  margin: auto; }
.dostupnost__title {
  font-weight: normal;
  font-size: 40px;
  line-height: 38px;
  text-align: center;
  color: #00B1D8;
  margin-bottom: 48px; }
.dostupnost__subtitle {
  font-weight: bold;
  font-size: 20px;
  line-height: 19px;
  text-transform: uppercase;
  color: #2C3032;
  margin-bottom: 24px; }
.dostupnost__desc {
  margin-bottom: 20px;
  & p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #2C3032;
    margin-bottom: 20px;
    & a {
      color: #00B1D8; } } }

@media screen and (max-width: 1367px) {
  .coociesForm {
    border-radius: 0; } }
