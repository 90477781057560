@import "../../../Page/mixin";
.OnClickTest {
  position: relative;
  --quantity: 33%;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 36px -12px;
    @include for-phone {
      flex-direction: column; }
    &_el {
      width: var(--quantity);
      padding: 0 12px;
      @include for-phone {
        width: 100%;
        padding: 0;
        margin-bottom: 20px; }
      &_wrp {
        min-height: 87px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0 10px;
        font-size: 18px;
        color: #039cdd;
        font-weight: bold;
        text-align: center;
        border: 2px solid #acdff5;
        box-sizing: border-box;
        box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
        border-radius: 10px;
        transition: .5s;
        cursor: pointer;
        &:hover,
        &.active {
          background: #f0f5fb;
          box-shadow: none; }
        &.correct {
          background: #e3fffb;
          border: 2px solid #BD4E3B;
          color: #BD4E3B; }
        &.active {
          &.correct {
            color: #3fc4b1;
            background: #e3fffb;
            border: 2px solid #acdff5; }
          &.inCorrect {
            background: #F9EEEC;
            border: 2px solid #BD4E3B;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            color: #BD4E3B; } } } } }
  .typeImg {
    display: flex;
    justify-content: center;
    margin: 0 -15px 25px;
    flex-wrap: wrap;
    &_el {
      width: var(--quantity);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 15px;
      cursor: pointer;
      transition: 0.5s;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      margin-bottom: 20px;
      &_box {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        padding: 12px;
        border: 1px solid #b2e6fc;
        border-radius: 50%;
        transition: 0.5s; }
      &_img {
        width: 100%;
        height: auto;
        transition: 0.5s; }
      &_text {
        width: 168px;
        margin: 0 auto;
        font-size: 18px;
        line-height: 24px;
        text-align: center; }
      &_checked {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100% - 24px);
        height: calc(100% - 24px);
        border-radius: 50%;
        transition: 0.5s; }
      &:hover {
        opacity: 0.7; }
      &.correct {
        .typeImg_el_box {
          background-color: #f7e1e1;
          border: 1px solid #f7e1e1; }
        .typeImg_el_checked {
          background-color: rgba(150, 238, 225, 0.4); } }
      &.active {
        &:hover {
          opacity: 1; }
        .typeImg_el_box {
          border: 1px solid #1dade6; }
        .typeImg_el_checked {
          background-color: rgba(29, 173, 230, 0.4); }
        &.correct {
          .typeImg_el_box {
            background-color: #d9f3ef;
            border: 1px solid #d9f3ef; }
          .typeImg_el_checked {
            background-color: rgba(150, 238, 225, 0.4); } }
        &.inCorrect {
          .typeImg_el_box {
            background-color: #f7e1e1;
            border: 1px solid #f7e1e1; }
          .typeImg_el_checked {
            background-color: rgba(204, 51, 51, 0.4); } } } } }
  .typeImgText {
    display: flex;
    margin: 0 -10px 25px;
    @include for-phone {
      flex-direction: column;
      align-items: center; }
    &_el {
      width: var(--quantity);
      padding: 0 10px;
      transition: 0.5s;
      @include for-phone {
        width: 100%;
        margin-bottom: 25px; }
      &_wrp {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 20px;
        border: 2px solid #acdff5;
        border-radius: 10px;
        cursor: pointer; }
      &.correct {
        .typeImgText_el_wrp {
          background-color: #d9f3ef;
          border: 2px solid #f7e1e1; } }
      &.active {
        .typeImgText_el_wrp {
          background: #f0f5fb; }
        &.correct {
          .typeImgText_el_wrp {
            background-color: #d9f3ef;
            border: 2px solid #acdff5; } }
        &.inCorrect {
          .typeImgText_el_wrp {
            background-color: #f7e1e1; } } }
      &:hover {
        opacity: 0.7; }
      &_box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1; }
      &_text {
        margin-bottom: 0;
        text-align: center; } } }
  .typeLine {
    margin-top: 25px;
    margin-bottom: 25px;
    @include for-phone {
      margin-top: 20px; }
    &_el {
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 2px solid #CCDFEF;
      box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
      border-radius: 10px;
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 26px;
      color: #3E373C;
      cursor: pointer;
      transition: .5s;
      @include for-phone {
        font-size: 16px;
        line-height: 18px; }
      &:last-child {
        margin-bottom: 0; }
      .circle {
        height: 30px;
        width: 30px;
        border: 2px solid #CCDFEF;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 15px;
        transition: .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          transition: .5s;
          opacity: 0;
          display: none; } }
      &:hover,
      &.active {
        border: 2px solid #4085D8;
        box-sizing: border-box;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        color: #4085D8;
        .circle {
          border-color: #4085D8; } }
      &.active {
        .circle {
          background: #4085D8; }
        //svg
        //  opacity: 1

        &.correct {
          border-color: #4085D8;
          .circle {
            background: #4085D8;
            border-color: #4085D8;
            svg.correct {
              opacity: 1;
              display: block; } } }
        &.inCorrect {
          border-color: #BD4E3B;
          .circle {
            background: #BD4E3B;
            border-color: #BD4E3B;
            svg.inCorrect {
              opacity: 1;
              display: block; } } } }

      &.correct {
        border-color: #BD4E3B;
        .circle {
          background: #4085D8;
          border-color: #4085D8;
          svg.correct {
            opacity: 1;
            display: block; } } }
      &_text {
        margin: 0; } } }
  .imgCenter {
    margin: 48px 0; }
  &_img {
    display: block;
    max-width: 100%;
    margin: 0 auto; }
  .info {
    margin-bottom: 20px;
    padding: 44px 48px;
    border-radius: 10px;
    background: #e3fffb;
    @include for-phone {
      padding: 30px 20px; }
    &_head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 15px;
      color: #3fc4b1; }
    &Text {
      color: #2c3032;
      font-size: 18px;
      white-space: pre-line; }
    &.error {
      background: #ffe3e3;
      .info_head {
        color: #f12626; } } }
  .btn {
    position: absolute;
    z-index: 2;
    bottom: -48px;
    right: 50%;
    transform: translate(50%, 100%);
    @include for-tablet {
      right: 0;
      transform: translate(0, 100%); } } }


