@import "../../../Page/mixin";
.step.lesson4 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .flex {
    display: flex; }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; } } }
  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    .flex {
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        justify-content: center;
        width: 48%;
        @include for-phone {
          width: 100%; }
        .imgBox {
          .img {
            width: 100%;
            max-width: 400px;
            height: auto; } } } } }
  .step3 {
    .wrap {
      display: flex;
      justify-content: space-between;
      @include for-tablet {
        flex-direction: column; }
      .flex {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 50%;
        margin-right: 30px;
        @include for-tablet {
          width: 100%;
          margin-right: 0;
          margin-bottom: 30px; }
        &_wrp {
          position: relative;
          &:not(:last-child) {
            margin-bottom: 25px; } }
        &_el {
          display: flex;
          align-items: center;
          height: 101px;
          padding: 25px;
          font-size: 18px;
          background: #fff;
          border: 1px solid #c9dbf0;
          border-radius: 10px;
          cursor: pointer;
          &_arrow {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 120%);
            width: 14px;
            height: 18px; } } }
      .imgBox {
        .img {
          display: block;
          margin: 0 auto;
          @media screen and (max-width: 400px) {
            width: 100%;
            height: auto; } } } } }
  .step5 {
    .img {
      display: block;
      width: 100%;
      max-width: 765px;
      height: auto;
      margin: 0 auto; } }
  .step6, step11 {
    .DefinitionBlock.hidden {
      @include for-tablet {
        display: none; } }
    .InfoBlock.hidden {
      @include for-tablet {
        display: none; } }
    .head {
      font-weight: bold;
      color: #039cdd;
      font-size: 20px;
      margin-bottom: 25px;
      text-transform: uppercase;
      &.hidden {
        @include for-tablet {
          display: none; } } }
    .OnClickBlock {
      .flex {
        &_el {
          &_wrp {
            height: 101px;
            font-weight: normal;
            font-size: 14px;
            text-transform: none; } }
        &.hidden {
          @include for-tablet {
            display: none; } } }
      .imgDiv_wrap_flex {
        display: flex;
        justify-content: space-between;
        @include for-phone {
          flex-direction: column; }
        .imgDiv_wrap_img {
          @include for-phone {
            display: block;
            margin: 0 auto; } } } } }
  .step7, .step8, .step12, .step13, .step14 {
    .OnClickBlock {
      .flex {
        &.hidden {
          @include for-tablet {
            display: none; } } }
      .imgDiv_wrap {
        &_flex {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 550px) {
            flex-direction: column; }
          .imgBox {
            margin-left: 15px;
            .text_italic {
              font-style: italic;
              font-size: 18px;
              &_blue {
                color: #039cdd;
                font-weight: bold; } } } }
        &_img {
          display: block;
          margin: 0 auto;
          @media screen and (max-width: 650px) {
            width: 100%;
            max-width: 280px;
            height: auto; } } } } }
  .step10 {
    padding-bottom: 100px;
    @include for-phone {
      padding-bottom: 340px; }
    .flex {
      position: relative;
      justify-content: space-between;
      margin-bottom: 20px;
      flex-wrap: wrap;
      @include for-phone {
        flex-wrap: wrap;
        margin: 0 -10px; }
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;
        @include for-phone {
          width: 50%;
          max-width: 50%;
          margin-bottom: 30px;
          padding: 0 10px;
          @include for-phone {
            margin-right: 0; } }
        &_border {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          padding: 10px;
          border: 1px solid #b2e6fc;
          border-radius: 50%; }
        &_img {
          width: 100%;
          height: auto; }
        &_text {
          width: 100%;
          max-width: 190px;
          margin: 0;
          text-align: center; } }
      .img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 40%);
        @include for-phone {
          position: absolute;
          transform: translate(-50%, 100%); } } } }
  .step14 {
    .OnClickBlock {
      .imgDiv_wrap {
        &_img {
          margin-bottom: 20px;
          @include for-phone {
            max-width: 280px; } } } } } }


