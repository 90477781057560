.footer {
  z-index: 11;
  width: 100%;
  position: static;
  bottom: 0;
  background: #dde7f2;
  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    color: #668e9e;
    font-size: 12px;
    padding: 20px;
    @media screen and (max-width: 1099px) {
      justify-content: center; }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 0; } }
  &__rights {
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 20px;
      text-align: center; } }
  &__policy {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 20px;
    @media screen and (max-width: 1099px) {
      flex-grow: 0;
      margin-left: 30px; }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      width: 100%; }
    &__box {
      display: flex;
      flex-direction: column;
      &:first-child {
        @media screen and (max-width: 767px) {
          margin-right: 0;
          width: 100%; } }
      &:last-child {
        font-size: 8px; }
      &--rights {
        font-size: 10px;
        max-width: 142px; } } }
  &__item {
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 20px;
      text-align: center; }
    &:first-child {
      margin-right: 10px;
      @media screen and (max-width: 767px) {
        margin-right: 0; } }
    &--hint {
      width: 262px;
      font-size: 8px; } }
  &__link {
    font-family: 'GothamPro', sans-serif;
    font-weight: bold;
    font-size: 9px;
    line-height: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: #668E9E;
    &:hover {
      opacity: 0.6; } }
  .ot-sdk-show-settings {
    padding: 0!important;
    font-family: 'GothamPro', sans-serif;
    font-weight: bold;
    font-size: 9px!important;
    line-height: 14px!important;
    text-transform: uppercase;
    text-decoration: none;
    color: #668E9E!important;
    border: none!important;
    background-color: transparent;
    &:hover {
      opacity: 0.6;
      background-color: transparent!important; } } }
#onetrust-consent-sdk #onetrust-banner-sdk {
  background-color: rgba(0, 0, 0, 0.6)!important; }
#onetrust-policy-text {
  color: #FFFFFF!important; }

.login {
  .footer {
    .footer__policy__box {
      &:nth-child(4) {
        .ot-sdk-show-settings {
          display: none; } } } } }
