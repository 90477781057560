@import "../../../Page/mixin";
.step.lesson13 {
  padding-bottom: 48px;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  color: #3E373C;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #039cdd;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #039cdd;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #039cdd;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #039cdd;
      background: #039cdd;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #039cdd; } } }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .textBlue {
    color: #005EAD; }
  .textRed {
    color: #BD4E3B; }
  .infoPopap {
    position: fixed;
    z-index: 222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#345178, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrp {
      background: #fff;
      border-radius: 25px;
      padding: 70px;
      position: relative;
      width: 100%;
      max-width: 640px; }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba(#005EAD, .3);
        transition: .5s; }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: rgba(#005EAD, 1); }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }

    &_scroll {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
      overflow-y: auto; }
    &_iconDiv {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      overflow: hidden;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      &.red {
        color: #BD4E3B; } }
    &_text {
      font-size: 16px;
      color: #3E373C;
      margin-bottom: 30px;
      &_head {
        font-size: 16px;
        color: #005EAD;
        font-weight: bold;
        display: block; } }
    &_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      &ImgDiv {
        height: 96px;
        width: 96px;
        flex-shrink: 0;
        border: 2px solid #005EAD;
        border-radius: 50%;
        background: #fff;
        margin-right: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
        &.chain {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 100%;
            width: 3px;
            background: #005EAD; } } }
      &:last-child {
        .chain {
          &:before {
            display: none; } } }
      .infoPopap_text {
        margin-bottom: 0; } }


    &_wrp.danger {
      background: #F9EEEC;
      .infoPopap_iconDiv {
        background: #F9EEEC; }
      .infoPopap_close {
        &:before,
        &:after {
          background: rgba(#BD4E3B, .3); }
        &:hover {
          &:before,
          &:after {
            background: rgba(#BD4E3B, 1); } } }
      .infoPopap_text {
        li:before {
          background: #BD4E3B; } }
      .infoPopap_head {
        color: #BD4E3B; } }





    &_imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      max-width: 100%;
      img {
        margin-top: 45px;
        max-width: 100%; } }
    .btn {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      font-size: 14px;
      width: 160px; } }

  .ul {
    .liHead {
      font-size: 18px;
      color: #005EAD;
      font-weight: bold;
      text-transform: uppercase;
      margin: 40px 0 35px; }
    .li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 35px;
      @media screen and (max-width: 499px) {
        flex-direction: column; }
      .number {
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        border: 1px solid #005EAD;
        border-radius: 50%;
        margin-right: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #005EAD;
        @media screen and (max-width: 499px) {
          margin-bottom: 10px; } }
      .text {
        font-size: 18px;
        color: #3E373C; } } }

  .infoPopap {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    background: rgba(#345178, .8);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 112px;
    &_wrp {
      width: 100%;
      max-width: 640px;
      padding: 70px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      .popap_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99BFDE;
        cursor: pointer;
        transition: .5s;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s; }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover {
          &:before,
          &:after {
            background: #005EAD; }
          &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(45deg); } } }
      .image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        &Bg {
          width: 100%;
          height: 100%;
          background: #E6EFF7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            border-radius: 50%; } } }
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line; }
      .subHead {
        color: #1F72B7;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        white-space: pre-line;
        margin-bottom: 30px; }
      .slider {
        border: 2px solid #CCDFEF;
        border-radius: 10px;
        position: relative;
        padding: 25px;
        margin-bottom: 50px;
        .slide {
          color: #4C8EC5;
          font-size: 16px;
          line-height: 24px; }
        .slick-arrow {
          bottom: 0; } }
      .btn {
        position: relative;
        left: 50%;
        transform: translate(-50%); } } }

  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    .flex {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 530px) {
        flex-direction: column; }
      img {
        @include for-tablet {
          width: auto;
          height: 110px; }
        @include for-phone {
          height: 100px; }
        @media screen and (max-width: 530px) {
          transform: rotate(90deg); } }
      &_el {
        width: auto;
        &:not(:first-child) {
          .flex_text {
            margin-left: 35px;
            @media screen and (max-width: 530px) {
              margin-left: 0; } } }
        //&:first-child
        //  img
        //    width: 79%
 }        //    max-width: 167px
      &_wrp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      &_text {
        width: 50%;
        font-weight: bold;
        font-size: 18px;
        color: #039cdd;
        text-align: center;
        @include for-tablet {
          width: 80%; } } } }
  .step3 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include for-phone {
        flex-direction: column-reverse; }
      &_img {
        margin-right: 30px;
        @include for-phone {
          margin-right: 0; } }
      .img_block {
        margin-bottom: 20px;
        @include for-phone {
          display: block;
          margin: 0 auto 20px; } } } }
  .step4 {
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 550px) {
        flex-direction: column; }
      .img {
        @include for-tablet {
          width: 100%;
          height: auto; } } } }
  .step5 {
    .InfoBlock {
      @media screen and (max-width: 1023px) {
        display: none; } }
    .SwipeBlock {
      .swiper-block__tablet {
        @media screen and (max-width: 767px) {
          display: none; } }
      .swiper-block__mobile {
        @media screen and (max-width: 1023px) {
          display: none; }
        @media screen and (max-width: 767px) {
          display: block; } } }
    .slick-slider {
      @media screen and (max-width: 1023px) {
        margin-bottom: 40px; } }
    .slick-list {
      margin: 0 -24px; }
    .slick-slide {
      display: flex;
      padding: 0 24px;
      height: auto;
      @include for-tablet {
        padding-left: 139px; }
      @include for-phone {
        padding-left: 24px;
        justify-content: center; }
      & div:first-child {
        display: flex; } }
    .slick-track {
      display: flex;
      @media screen and (max-width: 767px) {
        display: block; } }
    .flex {
      display: flex !important;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 132px;
      margin-bottom: 25px;
      &_el {
        width: 50%;
        margin-top: 132px;
        position: relative;
        border: 1px solid #acdff5;
        border-radius: 25px;
        padding: 52px 24px 30px;
        text-align: center;
        @include for-tablet {
          padding-left: 80px;
          margin-top: 30px; }
        @include for-phone {
          padding: 0;
          border: none; }
        .img {
          position: absolute;
          top: -84px;
          left: 50%;
          transform: translate(-50%);
          border-radius: 50%;
          background: #fff;
          @include for-tablet {
            top: 50%;
            left: 0;
            transform: translate(-70%, -50%); }
          @include for-phone {
            position: initial;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate(0, 0);
            border: none;
            padding: 0;
            margin-bottom: 20px; }
          img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            @include for-tablet {
              width: 150px;
              height: 150px; }
            @include for-phone {
              width: 155px;
              height: 155px; } } } } }
    .slick-prev,
    .slick-next {
      bottom: 5px !important; }
    .attention {
      margin-top: 50px;
      background-color: #039cdd;
      border-radius: 10px;
      color: #fff;
      font-weight: 700;
      padding: 25px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      @include for-phone {
        font-size: 16px;
        padding: 15px; } } }
  .step6 {
    .TextBlock.hidden {
      @include for-tablet {
        display: none; } }
    .InfoBlock.hidden {
      @include for-tablet {
        display: none; } }
    .flex {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include for-phone {
        flex-direction: column; }
      &_el {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 50%;
        margin-right: 24px;
        padding: 20px;
        color: #acdff5;
        background: #fff;
        border: 1px solid #acdff5;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        transition: .5s;
        text-align: center;
        @include for-tablet {
          height: 120px;
          border: 2px solid #005EAD;
          color: #005EAD;
          font-weight: bold; }
        @include for-phone {
          width: 100%;
          margin-right: 0;
          margin-bottom: 40px;
          font-size: 20px;
          line-height: 24px; }
        &.active,
        &:hover {
          background: #039cdd;
          box-shadow: none;
          border: 1px solid #039cdd;
          color: #fff;
          font-weight: bold; }
        &:last-child {
          margin-right: 0; } }
      &_arrow {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
        @include for-phone {
          top: auto;
          bottom: 0;
          right: 50%;
          transform: translate(50%, 90%) rotate(90deg); } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv {
      margin-top: 24px;
      border: 1px solid #acdff5;
      border-radius: 10px;
      padding: 40px 47px;
      position: relative;
      @include for-tablet {
        padding: 0;
        border: none; }
      &_closed {
        display: none;
        @include for-tablet {
          display: block;
          position: absolute;
          bottom: -40px;
          right: 50%;
          height: 35px;
          width: 35px;
          transform: translateX(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #99BFDE;
          background-color: #005EAD;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            background: #ffffff;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }

      &:before {
        content: '';
        position: absolute;
        left: 15%;
        top: -1px;
        transform: translate(-50%, -50%) rotate(-45deg);
        height: 12px;
        width: 12px;
        background: #fff;
        border-top: 1px solid #acdff5;
        border-right: 1px solid #acdff5;
        transition: .5s;
        @include for-tablet {
          display: none; } }
      &.center {
        &:before {
          left: 50%; }
        .imgDiv_flex {
          margin: 0 0 30px; }
        .imgDiv_flex img {
          margin: 0 25px 0 85px; } }
      &.right {
        &:before {
          left: 85%; }
        .imgDiv_flex {
          margin: 0; }
        .imgDiv_flex img {
          margin: 6px 0 0 45px; } }

      &_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        img {
          margin: 0 57px 7px 70px;
          flex-shrink: 0;
          @include for-phone {
            display: none; } } }

      .TextBlock {
        font-size: 20px;
        line-height: 26px;
        margin: 0;
        @include for-tablet {
          font-size: 16px;
          line-height: 24px; } }

      .imgDiv_flex {
        margin: 25px 0 0; }
      .imgPhone {
        display: none;
        @include for-phone {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0 30px; }
        .imgWidth {
          @media screen and (max-width: 550px) {
            width: 100%;
            height: auto; } } }
      .imgDesktop {
        @include for-phone {
          display: none; } } }
    .img {
      display: block;
      margin: 50px auto 0;
      @include for-phone {
        margin-top: 0; }
      &.hidden {
        display: none; }
      &_bottom {
        @include for-phone {
          width: 100%;
          height: auto; } } } }
  .step7 {
    .img {
      width: 100%;
      height: auto; } }
  .step8 {
    .attention {
      margin-top: 50px;
      background-color: #039cdd;
      border-radius: 10px;
      color: #fff;
      font-weight: 700;
      padding: 25px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      @include for-phone {
        font-size: 16px;
        padding: 15px; } }
    .flex {
      display: flex;
      justify-content: space-between;
      margin: 32px -10px 0;
      @include for-phone {
        margin-top: 100px; }
      &_el {
        display: flex;
        width: 25%;
        padding: 0 10px; }
      &_wrp {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        padding: 45px 10px 18px;
        text-align: center;
        border: 1px solid #0db7ff;
        border-radius: 10px; }
      &_img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 66px;
        height: 66px;
        border-radius: 50%;
        border: 1px solid #0db7ff;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        &_mobile {
          display: none;
          @include for-phone {
            display: flex;
            top: auto;
            bottom: 0;
            transform: translate(-50%, 100%); } } }
      &_head {
        min-height: 60px;
        margin: 0 0 20px;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #0db7ff;
        @include for-phone {
          min-height: auto; } }
      &_text {
        flex-grow: 1;
        font-size: 18px;
        line-height: 24px; }
      &.flex_arrow {
        margin-top: 0;
        .flex_el {
          display: block; }
        .flex_wrp {
          flex-direction: row;
          justify-content: center;
          border: none;
          padding: 0; } }
      &.flex_info {
        @include for-phone {
          flex-direction: column; }
        .flex_el {
          width: 100%;
          margin-bottom: 50px; } } } }
  .step9, .step10 {
    .attention {
      background-color: #039cdd;
      border-radius: 10px;
      color: #fff;
      font-weight: 700;
      padding: 15px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      @include for-phone {
        font-size: 16px;
        padding: 15px; } }
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      @include for-phone {
        flex-direction: column;
        border: 1px solid #0db7ff;
        border-radius: 10px; }
      &.flex_header {
        margin-bottom: 20px;
        @include for-phone {
          display: none; } }
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 32%;
        padding: 20px;
        border: 1px solid #0db7ff;
        border-radius: 10px;
        @include for-phone {
          width: 100%;
          border: none; }
        &_header {
          width: 32%; } }
      &_head {
        display: none;
        @include for-phone {
          display: block;
          margin: 0;
          color: #039cdd;
          font-weight: bold;
          font-size: 20px;
          line-height: 28px; } }
      &_wrp {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center; }
      .TextBlock {
        font-size: 16px;
        margin-bottom: 0;
        text-align: center; }
      .img {
        width: 100%;
        height: auto; } } }
  .step11 {
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      padding: 30px;
      font-size: 16px;
      line-height: 24px;
      border-radius: 10px;
      @include for-phone {
        flex-direction: column-reverse;
        margin-top: 40px;
        padding: 20px; }
      &.flex_error {
        border: 2px solid #c33; }
      &.flex_correct {
        flex-direction: row-reverse;
        border: 2px solid #3fc4b1;
        @include for-phone {
          flex-direction: column-reverse; } }
      &_text {
        font-weight: bold;
        &_error {
          color: #c33; }
        &_correct {
          color: #3fc4b1; } }
      .img_up {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%); } }
    ul {
      padding-left: 0; }
    li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      color: #3E373C;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #3fc4b1; } } }
  .step12 {
    .flex {
      position: relative;
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
          margin-right: 20px;
          @include for-phone {
            margin: 0 0 20px; } } }
      &_wrp {
        padding: 30px 40px;
        border: 1px solid #0db7ff;
        border-radius: 10px;
        flex-grow: 1;
        @include for-phone {
          padding: 20px; } }
      &_head {
        font-weight: bold; }
      .attention {
        margin-bottom: 20px;
        background-color: #039cdd;
        border-radius: 10px;
        color: #fff;
        font-weight: 700;
        padding: 15px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        @include for-phone {
          font-size: 16px;
          padding: 15px; } }
      .img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-65%, 15%);
        @include for-phone {
          position: initial;
          display: block;
          margin: 0 auto;
          transform: translate(0, 0); } }
      .List li {
        margin-bottom: 0; } } }
  .step13, .step14 {
    .imgDiv {
      position: relative;
      padding-top: 40px;
      .point {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #039cdd;
        color: #fff;
        border: 2px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        transition: .5s;
        cursor: pointer;
        z-index: 10;
        @media screen and (max-width: 550px) {
          width: 30px;
          height: 30px; }
        &:nth-child(1) {
          left: 70%;
          top: 9%;
          @media screen and (max-width: 550px) {
            top: 15%; }
          @media screen and (max-width: 440px) {
            left: 69%;
            top: 16%; } }
        &:nth-child(2) {
          left: 33%;
          top: 32%;
          @media screen and (max-width: 550px) {
            top: 36%; } }
        &:nth-child(3) {
          left: 33%;
          top: 55%;
          @media screen and (max-width: 550px) {
            top: 58%; } }
        &:nth-child(4) {
          left: 33%;
          top: 78%;
          @media screen and (max-width: 550px) {
            top: 79%; } }
        &:nth-child(5) {
          left: 70%;
          top: 78%;
          @media screen and (max-width: 550px) {
            top: 79%; } } } }
    .PopupInStep {
      padding-top: 90px;
      overflow-y: auto;
      @include for-phone {
        padding-top: 0; }
      .popup_imgContent {
        display: block;
        width: 150px;
        margin: 0 auto 20px; }
      .popup_text2 {
        font-weight: bold;
        color: #005EAD; }
      .popup_imgHead picture {
        display: flex;
        justify-content: center;
        align-items: center; }
      .popup_imgHeadBg {
        width: 80%;
        height: 80%;
        border-radius: 0; } }
    .img {
      width: 100%;
      height: auto; }
    .List {
      margin-top: 0;
      li {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 5px; } } }
  .step14 {
    .imgDiv {
      .point {
        &:nth-child(1) {
          left: 71.5%;
          top: 10%;
          @media screen and (max-width: 550px) {
            top: 15%; }
          @media screen and (max-width: 440px) {
            left: 70%;
            top: 18%; } }
        &:nth-child(2) {
          left: 36%;
          @media screen and (max-width: 550px) {
            top: 36%; }
          @media screen and (max-width: 440px) {
            left: 34%;
            top: 38%; } }
        &:nth-child(3) {
          left: 40%;
          top: 62%;
          @media screen and (max-width: 550px) {
            top: 64%; }
          @media screen and (max-width: 440px) {
            left: 38%;
            top: 62%; } }
        &:nth-child(4) {
          left: 36%;
          @media screen and (max-width: 440px) {
            left: 35%; } }
        &:nth-child(5) {
          left: 70.5%;
          @media screen and (max-width: 550px) {
            left: 70%; } } } }
    .PopupInStep {
      .popup_imgContent {
        width: 200px; } } }
  .step15, .step16 {
    position: relative;
    .test {
      @include for-phone {
        display: block;
        margin-top: 20px; } }
    .answers {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .answer {
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 54px;
        margin-bottom: 30px;
        font-weight: bold;
        font-size: 16px;
        line-height: 15px;
        color: #039cdd;
        text-align: center;
        background-color: #ffffff;
        border: 2px solid #039cdd;
        box-shadow: none;
        padding: 10px;
        @media screen and (max-width: 550px) {
          font-size: 10px;
          line-height: 13px; }
        &.correct {
          font-weight: bold;
          color: #4085D8;
          background-color: #ffffff;
          border: 2px solid #4085D8;
          //&:before
          //  position: absolute
          //  content: ""
          //  width: 24px
          //  height: 24px
          //  bottom: 0
          //  left: 50%
          //  background-image: url("../../../image/tests/correct.png")
          //  background-repeat: no-repeat
 }          //  transform: translate(-50%, 50%)
        &.inCorrect {
          font-weight: bold;
          color: #BD4E3B;
          background-color: #ffffff;
          border: 2px solid #BD4E3B;
          //&:before
          //  position: absolute
          //  content: ""
          //  width: 24px
          //  height: 24px
          //  bottom: 0
          //  left: 50%
          //  background-image: url("../../../image/tests/in-correct.png")
          //  background-repeat: no-repeat
 } }          //  transform: translate(-50%, 50%)
      .arrow {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 3px;
        height: 20px;
        background-color: #039cdd;
        @media screen and (max-width: 550px) {
          height: 10px; }
        &::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 3px;
          top: 15px;
          left: -6px;
          background-color: #039cdd;
          transform: rotate(-135deg);
          border-radius: 10px;
          @media screen and (max-width: 550px) {
            top: 6px; } }
        &::before {
          content: '';
          position: absolute;
          width: 10px;
          height: 3px;
          top: 15px;
          left: -1px;
          background-color: #039cdd;
          transform: rotate(135deg);
          border-radius: 10px;
          @media screen and (max-width: 550px) {
            top: 6px; } }
        &.arrow_right {
          left: auto;
          top: auto;
          right: 0;
          bottom: 0;
          transform: translate(25px, -45px) rotate(-90deg);
          @include for-phone {
            transform: translate(15px, -45px) rotate(-90deg); }
          @media screen and (max-width: 550px) {
            transform: translate(8px, -45px) rotate(-90deg); } }
        &.arrow_top {
          top: auto;
          bottom: 0;
          transform: translate(-50%, -89px) rotate(-180deg); }
        &.arrow_long {
          height: 100px;
          top: 60px;
          &:before {
            top: 95px; }
          &:after {
            top: 95px; } } }
      .flex_left {
        width: 70%;
        & > .droptarget .answer {
          color: #ffffff;
          background-color: #039cdd; }
        .flex_wrp {
          display: flex;
          justify-content: space-between;
          &_left {
            position: relative;
            width: 46%;
            .arrow:nth-child(5) {
              top: 58px; }
            .arrow:nth-child(6) {
              top: 143px; } }
          &_right {
            position: relative;
            width: 46%;
            .answer_hidden {
              opacity: 0; } } } }
      .flex_right {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        width: 25%; } }
    .questions {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .question_el {
        width: 24%;
        height: 50px;
        margin-bottom: 10px;
        @include for-phone {
          width: 47%; }
        &:nth-child(4n) {
          margin-right: 0; }
        &.hidden {
          cursor: default;
          .ddcontainersource {
            font-size: 0;
            background-color: #CCDFEF;
            box-shadow: none;
            border: none; } }
        .ddcontainer {
          width: 100%; } }
      .ddcontainersource {
        width: 100%;
        height: 50px;
        padding: 5px 10px;
        display: flex!important;
        justify-content: center;
        align-items: center;
        border: 2px solid #039cdd;
        border-radius: 10px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #039cdd;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer; }
      .ddcontainerghost {
        width: 207px;
        height: 50px;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border: 2px solid #039cdd;
        border-radius: 10px;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #039cdd;
        text-align: center;
        text-transform: uppercase;
        @media screen and (max-width: 550px) {
          width: 135px; } } }
    .info {
      padding: 44px 48px;
      border-radius: 10px;
      background: #005EAD;
      color: #fff;
      &_head {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px; }
      &Text {
        font-size: 18px; }
      &.error {
        background: #F9EEEC;
        .info_head {
          color: #BD4E3B; }
        .infoText {
          color: #3E373C; } } }
    .btn {
      position: absolute;
      z-index: 2;
      bottom: -48px;
      right: 0;
      transform: translate(0, 100%); } }
  .step16 {
    .answers {
      .arrow {
        &.arrow_long {
          top: -25px; }
        &.arrow_angle {
          top: 140px;
          left: 0;
          transform: translate(-30px, 0) rotate(45deg);
          height: 30px;
          @include for-phone {
            transform: translate(-20px, 0) rotate(45deg); }
          @media screen and (max-width: 550px) {
            transform: translate(-10px, 0) rotate(45deg); }
          &:before {
            top: 26px; }
          &:after {
            top: 26px; } } } }
    .questions {
      .question_el {
        width: 30%;
        @media screen and (max-width: 550px) {
          width: 47%; } }
      .ddcontainersource {
        @media screen and (max-width: 550px) {
          font-size: 11px; } }
      .ddcontainerghost {
        width: 260px;
        @media screen and (max-width: 550px) {
          width: 135px; } } } }
  .step17 {
    .flex {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 500px) {
        flex-direction: column; }
      &_el {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 31%;
        @media screen and (max-width: 500px) {
          width: 100%;
          margin-bottom: 20px; } }
      &_text {
        font-weight: bold;
        color: #039cdd;
        text-align: center; }
      .img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
        @media screen and (max-width: 500px) {
          width: 50%; } } } }
  .step18 {
    position: relative;
    padding-bottom: 15px;
    .answers {
      margin-top: 25px;
      margin-bottom: 0;
      @include for-phone {
        margin-top: 20px; }
      .answer {
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 2px solid #CCDFEF;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 26px;
        color: #3E373C;
        cursor: pointer;
        transition: .5s;
        @include for-phone {
          font-size: 16px;
          line-height: 18px; }
        &:last-child {
          margin-bottom: 0; }
        .circle {
          height: 30px;
          width: 30px;
          border: 2px solid #CCDFEF;
          border-radius: 50%;
          flex-shrink: 0;
          margin-right: 15px;
          transition: .5s;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            transition: .5s;
            opacity: 0;
            display: none; } }
        &:hover,
        &.active {
          border: 2px solid #4085D8;
          box-sizing: border-box;
          box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
          color: #4085D8;
          .circle {
            border-color: #4085D8; } }
        &.active {
          .circle {
            background: #4085D8; }
          //svg
          //  opacity: 1

          &.correct {
            border-color: #4085D8;
            .circle {
              background: #4085D8;
              border-color: #4085D8;
              svg.correct {
                opacity: 1;
                display: block; } } }
          &.inCorrect {
            border-color: #BD4E3B;
            .circle {
              background: #BD4E3B;
              border-color: #BD4E3B;
              svg.inCorrect {
                opacity: 1;
                display: block; } } } }

        &.correct {
          border-color: #BD4E3B;
          .circle {
            background: #4085D8;
            border-color: #4085D8;
            svg.correct {
              opacity: 1;
              display: block; } } } } }
    .info {
      padding: 44px 48px;
      margin-top: 20px;
      border-radius: 10px;
      background: #005EAD;
      color: #fff;
      &_head {
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 5px; }
      &Text {
        font-size: 18px; }
      &.error {
        background: #F9EEEC;
        .info_head {
          color: #BD4E3B; }
        .infoText {
          color: #3E373C; } } }
    .btn {
      position: absolute;
      z-index: 2;
      bottom: -48px;
      right: 0;
      transform: translate(0, 100%); } }
  .step19 {
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-direction: column; }
      &_head {
        font-size: 18px;
        line-height: 24px;
        color: #039cdd;
        font-weight: bold;
        margin: 0 0 20px; } }
    .img {
      @include for-phone {
        display: block;
        margin: 0 auto; } } } }







