@import "../../../Page/mixin";
.step.lesson14 {
  padding-bottom: 48px;
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  color: #3E373C;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .textBlue {
    color: #005EAD; }
  .textRed {
    color: #BD4E3B; }
  .infoPopap {
    position: fixed;
    z-index: 222;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(#345178, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrp {
      background: #fff;
      border-radius: 25px;
      padding: 70px;
      position: relative;
      width: 100%;
      max-width: 640px; }

    &_close {
      position: absolute;
      top: 0;
      right: 0;
      width: 72px;
      height: 72px;
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        width: 34px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        background: rgba(#005EAD, .3);
        transition: .5s; }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
      &:hover {
        &:before,
        &:after {
          background: rgba(#005EAD, 1); }
        &:before {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(45deg); } } }

    &_scroll {
      max-height: calc(100vh - 400px);
      overflow-x: hidden;
      overflow-y: auto; }
    &_iconDiv {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 120px;
      width: 120px;
      border-radius: 50%;
      overflow: hidden;
      padding: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; } }
    &_head {
      text-align: center;
      font-size: 25px;
      color: #005EAD;
      margin-bottom: 30px;
      &.red {
        color: #BD4E3B; } }
    &_text {
      font-size: 16px;
      color: #3E373C;
      margin-bottom: 30px;
      &_head {
        font-size: 16px;
        color: #005EAD;
        font-weight: bold;
        display: block; } }
    &_flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      &ImgDiv {
        height: 96px;
        width: 96px;
        flex-shrink: 0;
        border: 2px solid #005EAD;
        border-radius: 50%;
        background: #fff;
        margin-right: 35px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%; }
        &.chain {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            height: 100%;
            width: 3px;
            background: #005EAD; } } }
      &:last-child {
        .chain {
          &:before {
            display: none; } } }
      .infoPopap_text {
        margin-bottom: 0; } }


    &_wrp.danger {
      background: #F9EEEC;
      .infoPopap_iconDiv {
        background: #F9EEEC; }
      .infoPopap_close {
        &:before,
        &:after {
          background: rgba(#BD4E3B, .3); }
        &:hover {
          &:before,
          &:after {
            background: rgba(#BD4E3B, 1); } } }
      .infoPopap_text {
        li:before {
          background: #BD4E3B; } }
      .infoPopap_head {
        color: #BD4E3B; } }





    &_imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 45px;
      max-width: 100%;
      img {
        margin-top: 45px;
        max-width: 100%; } }
    .btn {
      margin-top: 45px;
      position: relative;
      left: 50%;
      transform: translate(-50%);
      font-size: 14px;
      width: 160px; } }

  ul {
    padding-left: 0;
    li {
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      color: #3E373C;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #005EAD; } } }
  .ul {
    .liHead {
      font-size: 18px;
      color: #005EAD;
      font-weight: bold;
      text-transform: uppercase;
      margin: 40px 0 35px; }
    .li {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 35px;
      @media screen and (max-width: 499px) {
        flex-direction: column; }
      .number {
        flex-shrink: 0;
        height: 48px;
        width: 48px;
        border: 1px solid #005EAD;
        border-radius: 50%;
        margin-right: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 25px;
        color: #005EAD;
        @media screen and (max-width: 499px) {
          margin-bottom: 10px; } }
      .text {
        font-size: 18px;
        color: #3E373C; } } }

  .infoPopap {
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    background: rgba(#345178, .8);
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 112px;
    &_wrp {
      width: 100%;
      max-width: 640px;
      padding: 70px;
      background: #fff;
      position: relative;
      border-radius: 10px;
      .popap_close {
        position: absolute;
        top: 20px;
        right: 20px;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #99BFDE;
        cursor: pointer;
        transition: .5s;
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #99BFDE;
          transform-origin: center;
          top: 50%;
          left: 50%;
          transition: .5s; }
        &:before {
          transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
          transform: translate(-50%, -50%) rotate(-45deg); }
        &:hover {
          &:before,
          &:after {
            background: #005EAD; }
          &:before {
            transform: translate(-50%, -50%) rotate(-45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(45deg); } } }
      .image {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        &Bg {
          width: 100%;
          height: 100%;
          background: #E6EFF7;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            object-fit: cover;
            border-radius: 50%; } } }
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line; }
      .subHead {
        color: #1F72B7;
        text-align: center;
        font-style: italic;
        font-size: 18px;
        white-space: pre-line;
        margin-bottom: 30px; }
      .slider {
        border: 2px solid #CCDFEF;
        border-radius: 10px;
        position: relative;
        padding: 25px;
        margin-bottom: 50px;
        .slide {
          color: #4C8EC5;
          font-size: 16px;
          line-height: 24px; }
        .slick-arrow {
          bottom: 0; } }
      .btn {
        position: relative;
        left: 50%;
        transform: translate(-50%); } } }

  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }

  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    .flex {
      display: flex;
      justify-content: space-between;
      @include for-phone {
        flex-wrap: wrap;
        margin-top: 70px; }
      &_el {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 138px;
        @include for-phone {
          position: relative;
          width: 100%;
          height: 220px;
          margin-bottom: 78px;
          padding: 68px 20px 32px;
          border: 1px solid #005EAD;
          box-sizing: border-box;
          border-radius: 10px; }
        &_head {
          min-height: 40px;
          margin: 0 0 7px;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          color: #005EAD;
          text-align: center;
          text-transform: uppercase;
          min-height: 54px;
          @include for-phone {
            color: #3E373C; } }
        &_img-box {
          padding: 10px;
          background-color: #ffffff;
          border: 1px solid #99BFDE;
          border-radius: 50%;
          margin-bottom: 13px;
          @include for-phone {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 84px;
            height: 84px;
            padding: 5px; } }
        &_img {
          display: block;
          @include for-phone {
            width: 100%;
            height: auto; } }
        &_text {
          margin: 0;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          color: #3E373C; } } } }
  .step3 {
    .digestion {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 55px;
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      background-color: #005EAD;
      border-radius: 10px; }
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
      @include for-phone {
        margin-bottom: 90px; }
      &_el {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48%;
        height: 55px;
        color: #005EAD;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-transform: uppercase;
        text-align: center;
        border: 2px solid #005EAD;
        box-sizing: border-box;
        border-radius: 10px;
        @include for-phone {
          font-size: 12px;
          line-height: 14px; }
        &_arrow {
          position: absolute;
          top: -30px;
          left: 50%;
          transform: translate(-50%, 0);
          &.mobile {
            display: none;
            @include for-phone {
              display: block;
              position: absolute;
              top: auto;
              bottom: -30px; } } }
        .point {
          position: absolute;
          bottom: -63px;
          height: 72px;
          width: 72px;
          border-radius: 50%;
          background: #e6eff7;
          border: 2px solid #ccdfef;
          box-shadow: 0 5px 10px rgba(0,94,173,.15);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          color: #99bfde;
          font-weight: 700;
          transition: .5s;
          cursor: pointer;
          z-index: 10;
          outline: none;
          @include for-phone {
            bottom: -42px;
            height: 52px;
            width: 52px; }
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 68px;
            height: 68px;
            background-color: transparent;
            border: 9px solid #fff;
            border-radius: 50%;
            box-sizing: border-box;
            @include for-phone {
              width: 48px;
              height: 48px;
              border: 4px solid #fff; } } } } }
    .block {
      display: flex;
      justify-content: space-between;
      width: 48%;
      margin-bottom: 10px;
      @include for-phone {
        flex-direction: column;
        align-items: center;
        width: 100%; }
      &_el {
        position: relative;
        width: 48%;
        padding: 35px 10px 10px;
        border: 1px solid #005ead;
        box-sizing: border-box;
        border-radius: 10px;
        @include for-phone {
          width: 100%;
          max-width: 175px;
          margin-bottom: 70px; }
        &_arrow {
          position: absolute;
          top: -55px;
          left: 50%;
          transform: translate(-50%, 0);
          @include for-phone {
            display: none; } }
        &_head {
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          color: #005EAD;
          text-align: center;
          text-transform: uppercase; }
        &_text {
          padding: 0 25px;
          text-align: center; }
        &_box {
          position: absolute;
          display: flex;
          top: 0;
          left: 50%;
          transform: translate(-50%,-50%); }
        &_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 55px;
          height: 55px;
          border: 1px solid #005ead;
          border-radius: 50%;
          background-color: #fff;
          box-sizing: border-box; }
        &_plus {
          position: absolute;
          display: flex;
          top: 50%;
          right: -22px;
          transform: translate(0, -50%);
          font-size: 50px;
          line-height: 65px;
          color: #005EAD;
          @include for-phone {
            top: auto;
            bottom: -53px;
            right: 50%;
            transform: translate(50%, 0); } } } }
    .img {
      @include for-phone {
        width: 100%;
        max-width: 337px; } } }
  .step4 {
    .head {
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #005EAD;
      text-transform: uppercase;
      @include for-phone {
        text-align: center; } }
    .flex {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24%;
        height: 67px;
        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
        color: #005EAD;
        text-align: center;
        border: 1px solid #005EAD;
        border-radius: 10px;
        @include for-phone {
          height: 55px;
          width: 100%;
          margin-bottom: 20px; } } }
    .img {
      display: block;
      margin: 0 auto; } }
  .step5, .step7, .step8 {
    .digestion {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 55px;
      margin-bottom: 40px;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      background-color: #005EAD;
      border-radius: 10px;
      @include for-phone {
        margin-bottom: 84px; } }
    .block {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 10px;
      @include for-phone {
        flex-direction: column;
        align-items: center;
        width: 100%; }
      &_el {
        position: relative;
        width: 46%;
        padding: 35px 25px 25px;
        border: 1px solid #005ead;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 50px;
        @include for-phone {
          width: 100%;
          margin-bottom: 70px; }
        &:first-child {
          margin-right: 50px;
          @include for-phone {
            margin-right: 0; } }
        &:nth-child(2) {
          .block_el_arrow {
            @include for-phone {
              display: none; } } }
        &_arrow {
          position: absolute;
          top: -30px;
          left: 50%;
          transform: translate(-50%, 0);
          @include for-phone {
            top: -60px; }
          &.right {
            left: auto;
            right: 30%;
            transform: translate(-50%, 0) rotate(45deg);
            @include for-phone {
              display: none; } }
          &.left {
            left: 30%;
            transform: translate(-50%, 0) rotate(-45deg);
            @include for-phone {
              left: 50%;
              transform: translate(-50%, 0) rotate(0deg); } } }
        &_head {
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          color: #005EAD;
          text-transform: uppercase;
          @include for-phone {
            text-align: center; } }
        &_text {
          font-size: 16px;
          line-height: 22px; }
        li {
          font-size: 16px;
          line-height: 18px; }
        &_box {
          position: absolute;
          display: flex;
          top: 0;
          right: 15px;
          transform: translate(50%,-50%);
          @include for-phone {
            right: 50%; } }
        &_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 55px;
          height: 55px;
          border: 1px solid #005ead;
          border-radius: 50%;
          background-color: #fff;
          box-sizing: border-box; }
        &_plus {
          position: absolute;
          display: flex;
          top: 50%;
          right: -40px;
          transform: translate(0, -50%);
          font-size: 50px;
          line-height: 65px;
          color: #005EAD;
          @include for-phone {
            top: auto;
            bottom: -53px;
            right: 50%;
            transform: translate(50%, 0); } } } }
    .img {
      display: block;
      margin: 0 auto; } }
  .step6 {
    .img {
      display: block;
      margin: 0 auto 30px; } }
  .step7, .step8 {
    .block {
      &_el {
        &_box {
          right: 50px;
          @include for-phone {
            right: 50%; } }
        &_text {
          @include for-phone {
            text-align: center; } } } }
    .img {
      width: 100%;
      max-width: 489px; } }
  .step8 {
    .block {
      &_el {
        padding-right: 150px;
        @include for-phone {
          padding-right: 20px;
          padding-left: 20px; }
        &:not(:last-child) {
          @include for-phone {
            margin-bottom: 120px; } }
        &:nth-child(2) .block_el_arrow {
          display: block; }
        .point {
          position: absolute;
          bottom: 0;
          right: 15px;
          transform: translate(0, 50%);
          height: 72px;
          width: 72px;
          border-radius: 50%;
          background: #e6eff7;
          border: 2px solid #ccdfef;
          box-shadow: 0 5px 10px rgba(0,94,173,.15);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          color: #99bfde;
          font-weight: 700;
          transition: .5s;
          cursor: pointer;
          z-index: 10;
          outline: none;
          @include for-phone {
            //bottom: -42px
            left: 50%;
            transform: translate(-50%, 50%);
            height: 52px;
            width: 52px; }
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 68px;
            height: 68px;
            background-color: transparent;
            border: 9px solid #fff;
            border-radius: 50%;
            box-sizing: border-box;
            @include for-phone {
              width: 48px;
              height: 48px;
              border: 4px solid #fff; } } } } }
    .img {
      width: 100%;
      max-width: 326px; }
    .Popup {
      .head {
        text-align: center;
        font-size: 25px;
        color: #005EAD;
        margin-bottom: 30px;
        white-space: pre-line;
        @media screen and (max-width: 767px) {
          margin-top: 30px; } }
      .text {
        font-size: 16px;
        line-height: 24px;
        color: #3E373C;
        white-space: pre-line;
        margin-bottom: 20px;
        padding-bottom: 10px;
        max-height: 30vh;
        overflow-y: auto; }
      .img-box {
        margin-bottom: 20px;
        .img-popup {
          display: block;
          margin: 0 auto; } }
      .btn_blue {
        margin: 0 auto; } } }
  .step9, .step11 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .flex {
          display: flex;
          flex-wrap: wrap;
          margin-top: 48px;
          margin-bottom: 24px;
          justify-content: space-between;
          align-items: center;
          &_el {
            width: 48%;
            margin-bottom: 58px;
            .answers {
              position: relative;
              display: flex;
              flex-direction: column;
              margin-top: 0;
              margin-bottom: 0;
              .property {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 55px;
                margin-bottom: 25px;
                font-weight: bold;
                font-size: 16px;
                line-height: 18px;
                color: #ffffff;
                text-transform: uppercase;
                background-color: #005EAD;
                border-radius: 10px;
                &_img {
                  position: absolute;
                  top: -15px;
                  left: 7px; }
                &_arrow, &_plus {
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  transform: translate(-50%, 0); }
                &_arrow {
                  bottom: -25px; }
                &_plus {
                  font-weight: normal;
                  font-size: 30px;
                  line-height: 45px;
                  color: #005EAD;
                  bottom: -104px; } }
              &_item {
                position: relative;
                &:not(:last-child) {
                  margin-bottom: 35px; } }
              .answer {
                position: relative;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 40px;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #99BFDE;
                text-align: center;
                text-transform: uppercase;
                background-color: #ffffff;
                border: 1px solid #005EAD;
                box-shadow: none;
                padding: 10px;
                @media screen and (max-width: 550px) {
                  font-size: 10px;
                  line-height: 13px; }
                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8; }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B; } } } }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } } } }
        .questions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .question_el {
            width: 48%;
            height: 50px;
            margin-bottom: 24px;
            &:nth-child(1) {
              order: 8; }
            &:nth-child(2) {
              order: 3; }
            &:nth-child(3) {
              order: 2; }
            &:nth-child(4) {
              order: 7; }
            &:nth-child(5) {
              order: 4; }
            &:nth-child(6) {
              order: 5; }
            &:nth-child(7) {
              order: 6; }
            &:nth-child(8) {
              order: 1; }
            &.hidden {
              cursor: default;
              .ddcontainersource {
                font-size: 0;
                background-color: #E6EFF7;
                box-shadow: none;
                border: none; } }
            .ddcontainer {
              width: 100%; } }
          .ddcontainersource {
            width: 100%;
            height: 40px;
            padding: 5px 10px;
            display: flex!important;
            justify-content: center;
            align-items: center;
            border: 1px solid #4C8EC5;
            border-radius: 10px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #005EAD;
            text-align: center;
            text-transform: uppercase;
            box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
            cursor: pointer; }
          .ddcontainerghost {
            width: 410px;
            height: 40px;
            padding: 5px 10px;
            justify-content: center;
            align-items: center;
            background-color: #ffffff;
            border: 1px solid #4C8EC5;
            border-radius: 10px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #005EAD;
            text-align: center;
            text-transform: uppercase;
            @include for-tablet {
              width: 340px; }
            & > div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center; } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 14px;
          line-height: 16px; } }
      //Стили мобильной версии
      .test_mobile {
        .flex {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
          &_el {
            width: 100%;
            max-width: 300px; }
          &_img {
            position: relative;
            .point {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 30px;
              height: 30px;
              font-size: 14px;
              line-height: 18px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%;
              &:nth-child(1) {
                top: 2%;
                left: 46%; }
              &:nth-child(2) {
                top: 17%;
                left: 12%; }
              &:nth-child(3) {
                top: 19%;
                right: 23%; }
              &:nth-child(4) {
                top: 48%;
                left: 37%; }
              &:nth-child(5) {
                bottom: 27%;
                left: 15%; }
              &:nth-child(6) {
                bottom: 9%;
                left: 8%; }
              &.correct {
                color: #ffffff;
                background-color: #4085D8;
                border: 1px solid #ffffff; }
              &.inCorrect {
                color: #ffffff;
                background-color: #BD4E3B;
                border: 1px solid #ffffff; } }
            .img {
              width: 100%;
              max-width: 300px;
              height: auto; } } }
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 0;
          box-sizing: border-box;
          .property {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            margin-bottom: 65px;
            padding-left: 80px;
            padding-right: 70px;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #ffffff;
            text-transform: uppercase;
            background-color: #005EAD;
            border-radius: 10px;
            &_img {
              position: absolute;
              top: -15px;
              left: 7px; }
            &_arrow, &_plus {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0); }
            &_arrow {
              bottom: -45px; }
            &_plus {
              font-weight: normal;
              font-size: 30px;
              line-height: 45px;
              color: #005EAD;
              bottom: -174px; } }
          &_item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 55px;
            margin-bottom: 60px;
            padding: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #99BFDE;
            background-color: #ffffff;
            border: 2px solid #99BFDE;
            border-radius: 10px;
            text-align: center;
            text-transform: uppercase;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } }
          &_number {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            left: 0;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 30px;
            height: 30px;
            border: 1px solid #4C8EC5;
            font-size: 14px;
            line-height: 18px;
            font-weight: bold;
            color: #3E373C;
            background-color: #ffffff;
            border-radius: 50%;
            &.selected {
              color: #4C8EC5;
              border: 2px solid #4C8EC5;
              &.correct {
                border: 2px solid #4085D8;
                color: #4085D8; }
              &.inCorrect {
                border: 2px solid #BD4E3B;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 48px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #3E373C;
              background-color: #ffffff;
              border: 1px solid #4C8EC5;
              border-radius: 50%; }
            .questions {
              display: flex;
              flex-direction: column;
              &_item {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 55px;
                font-weight: bold;
                color: #005EAD;
                text-transform: uppercase;
                border: 1px solid #99BFDE;
                border-radius: 10px;
                box-shadow: 0 5px 10px rgba(0, 94, 173, 0.15);
                margin-bottom: 20px;
                &:nth-child(1) {
                  order: 1; }
                &:nth-child(2) {
                  order: 4; }
                &:nth-child(3) {
                  order: 2; }
                &:nth-child(4) {
                  order: 5; }
                &:nth-child(5) {
                  order: 3; }
                &:nth-child(6) {
                  order: 6; }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; } } } }
          &_text {
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          margin-bottom: 48px;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -48px;
          right: 0;
          transform: translate(0, 100%);
          font-size: 10px; } } } }
  .step10 {
    .InfoBlock {
      margin-bottom: 20px;
      &Tablet {
        display: none;
        @include for-tablet {
          display: flex;
          margin-top: 20px; }
        &.hidden {
          @include for-tablet {
            display: none; } } } }
    .wrp {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      overflow: visible;
      margin-top: 60px;
      .box {
        width: 58%;
        @include for-phone {
          width: auto; } }
      &_img {
        width: 100%;
        height: auto;
        @include for-tablet {
          display: block;
          width: 100%;
          margin: 0 auto; }
        @include for-phone {
          margin-top: 20px; }
        @media screen and (max-width: 499px) {
          width: 100%; } }
      &_right {
        padding-top: 30px;
        width: 100%; } }
    .flex {
      display: flex;
      flex-direction: column;
      width: 40%;
      @include for-phone {
        width: 100%; }
      &_el {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 67px;
        width: 100%;
        margin-bottom: 20px;
        color: #99BFDE;
        text-align: center;
        background: #fff;
        border: 2px solid #CCDFEF;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        border-radius: 10px;
        cursor: pointer;
        transition: .5s;
        @include for-phone {
          width: 100%;
          height: auto;
          margin-right: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 18px;
          color: #4C8EC5;
          border: 2px solid #99BFDE;
          text-transform: uppercase;
          padding: 15px 20px; }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translate(100%, -50%) scale(0);
          width: 21px;
          height: 3px;
          background: #005EAD;
          transition: 0.5s;
          transform-origin: 0 0;
          @media screen and (max-width: 1023px) {
            width: 19px; }
          @media screen and (max-width: 850px) {
            width: 18px; } }
        &.active,
        &:hover {
          background: #146BB3;
          box-shadow: none;
          border: 2px solid #146BB3;
          color: #fff;
          font-weight: bold; }
        &:last-child {
          margin-bottom: 0; }
        &.active:after {
          transform: translate(100%, -50%) scale(1);
          transform-origin: 0 0; } }
      &.hidden {
        @include for-tablet {
          display: none; } } }

    .imgDiv {
      width: 100%;
      border: 2px solid #1F72B7;
      border-radius: 10px;
      padding: 50px 35px;
      position: relative;
      &_closed {
        display: none;
        @include for-tablet {
          display: block;
          position: absolute;
          bottom: -40px;
          right: 50%;
          height: 35px;
          width: 35px;
          transform: translateX(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #99BFDE;
          background-color: #005EAD;
          border-radius: 50%;
          cursor: pointer;
          transition: .5s;
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 60%;
            height: 2px;
            background: #ffffff;
            transform-origin: center;
            top: 50%;
            left: 50%;
            transition: .5s;
            @media screen and (max-width: 767px) {
              background: #ffffff; } }
          &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
          &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }

      .img_box {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 55px;
        background: #ffffff;
        border: 2px solid #005EAD;
        border-radius: 50%;
        @include for-phone {
          left: 50%;
          transform: translate(-50%, 0); } }
      .TextBlock {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 20px; }
      .List li {
        font-size: 16px;
        line-height: 24px;
        &:before {
          top: 10px; } }
      &_picture {
        width: 100%;
        height: auto;
        margin-bottom: 20px; }
      &_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        img {
          flex-shrink: 0;
          margin-left: 5px; } } }
    .TextBlock {
      line-height: 26px;
      &.hidden {
        @include for-tablet {
          display: none; } } }
    .PopupInStep {
      .popup {
        &_imgHead {
          width: 96px;
          height: 96px;
          padding: 10px;
          border: 2px solid #99BFDE;
          picture {
            display: flex;
            align-items: center;
            justify-content: center; }
          &Bg {
            width: 70%;
            height: 70%;
            background: #ffffff;
            border-radius: 0; } }
        &_text {
          height: auto;
          overflow: visible; } } } }
  .step11 {
    .test {
      .test_desktop {
        .flex {
          &_el {
            width: 49%;
            .answers {
              &_item {
                &:not(:last-child) {
                  margin-bottom: 15px; } }
              .property {
                height: 48px;
                margin-bottom: 15px;
                &:last-child {
                  margin-bottom: 0; }
                &:after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translate(100%, -50%);
                  width: 21px;
                  height: 3px;
                  background: #005EAD; } }
              .answer {
                height: 48px; } } } } }
      .test_mobile {
        .answers {
          .property {
            height: 55px;
            margin-bottom: 30px;
            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              right: 50%;
              transform: translate(50%, 100%);
              width: 3px;
              height: 30px;
              background: #005EAD; } } } } } } }













