@import "../../../Page/mixin";
.step.lesson5 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #005EAD;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; }
      &_blue {
        font-weight: bold;
        color: #039cdd; } } }
  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2 {
    .flex {
      display: flex;
      flex-wrap: wrap;
      margin: 0 - 10px;
      margin-bottom: 40px;
      &_el {
        width: 33%;
        padding: 0 10px;
        &:not(:last-child) {
          margin-bottom: 30px; }
        &.big {
          width: 100%;
          & .flex_el_wrp {
            background-color: #039cdd;
            color: #ffffff; } }
        &_wrp {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 80px;
          border: 1px solid #acdff5;
          border-radius: 5px;
          font-size: 17px;
          font-weight: bold;
          color: #039cdd;
          text-transform: uppercase;
          @include for-phone {
            font-size: 13px; } }
        &_img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 120%);
          width: 18px;
          height: auto; } } } }
  .step3 {
    .data {
      display: flex;
      margin: 0 -20px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        width: 50%;
        padding: 0 20px;
        @include for-phone {
          width: 100%; }
        &_head {
          font-size: 18px;
          color: #039cdd;
          margin-bottom: 13px; } }
      &_tablet {
        display: flex;
        margin-bottom: 20px;
        &_column {
          &.left {
            width: 30%;
            font-size: 20px;
            color: #039cdd;
            font-weight: bold;
            .data_tablet_item {
              border-right: 1px solid #c9dbf0; } }
          &.right {
            width: 70%;
            font-size: 18px; } }
        &_item {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 90px;
          font-size: 18px;
          text-align: center;
          padding: 5px;
          border-bottom: 1px solid #c9dbf0;
          &:first-child {
            border-top: 1px solid #c9dbf0; } } } }
    .text {
      &_head {
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 20px; }
      &.desktop {
        @include for-phone {
          display: none; } }
      &.mobile {
        display: none;
        @include for-phone {
          display: block; } } } }
  .step4, .step5, .step6, .step7, .step9, .step10, .step11, .step12, .step13, .step14, .step15, .step16, .step17 {
    .OnClickBlock {
      .imgDiv_wrap {
        &_flex {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 550px) {
            flex-direction: column; }
          .TextBlock {
            margin-right: 20px;
            @media screen and (max-width: 550px) {
              margin-right: 0; } } }
        &_img {
          display: block;
          margin: 0 auto;
          @media screen and (max-width: 650px) {
            width: 280px;
            height: auto; } } } } }
  .step5 {
    .OnClickBlock {
      .imgDiv_wrap {
        &_img {
          @media screen and (max-width: 750px) {
            width: 100%;
            max-width: 280px;
            height: auto; } } } } }
  .step8 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        .test_wrp {
          display: flex;
          flex-direction: column;
          margin-bottom: 38px;
          .flex {
            display: flex;
            flex-grow: 1;
            .answers {
              position: relative;
              display: flex;
              justify-content: space-between;
              width: 100%;
              &_item {
                position: relative;
                &:not(:last-child) {
                  margin-right: 30px; } }
              &_text {
                width: 100%;
                max-width: 150px;
                margin: 0;
                font-size: 15px;
                font-weight: bold;
                color: #039cdd;
                text-align: center; }
              .answer {
                position: relative;
                align-items: center;
                justify-content: center;
                width: 150px;
                height: 150px;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #039cdd;
                text-align: center;
                text-transform: uppercase;
                background-color: #ffffff;
                border: 1px solid #b2e6fc;
                border-radius: 50%;
                box-shadow: none;
                padding: 0 12px;
                margin-bottom: 20px;
                img {
                  display: block; }
                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8;
                  .icon svg {
                    fill: #4085D8; } }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B;
                  .icon svg {
                    fill: #BD4E3B; } } } } }
          .questions {
            display: flex;
            justify-content: space-between;
            .question_el {
              &:not(:last-child) {
                margin-right: 15px;
                margin-bottom: 12px; }
              //&.hidden
              //  cursor: default
              //  .ddcontainersource
              //    font-size: 0
              //    background-color: #E6EFF7
              //    box-shadow: none
              //    border: none
              //    img
              //      opacity: 0
              .ddcontainer {
                width: 100%; } }
            .ddcontainersource {
              width: 150px;
              height: 150px;
              font-size: 14px;
              line-height: 22px;
              color: #039cdd;
              font-weight: bold;
              text-transform: uppercase;
              cursor: pointer;
              padding: 12px;
              border: 1px solid #b2e6fc;
              border-radius: 50%;
              .icon {
                width: 100%;
                img {
                  display: block;
                  width: 100%; } } }
            .ddcontainerghost {
              width: 150px;
              height: 150px;
              font-size: 14px;
              line-height: 22px;
              color: #039cdd;
              font-weight: bold;
              text-align: center;
              text-transform: uppercase;
              padding: 12px;
              border: 1px solid #b2e6fc;
              border-radius: 50%;
              @include for-tablet {
                width: 100px;
                height: 100px; }
              .icon {
                width: 100%;
                img {
                  display: block;
                  width: 100%; } } } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #e3fffb;
          color: #2c3032;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: #3fc4b1; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #ffe3e3;
            .info_head {
              color: #f12626; }
            .infoText {
              color: #2c3032; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -25px;
          right: 0;
          transform: translate(0, 100%); } }
      //Стили мобильной версии
      .test_mobile {
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 0;
          .flex {
            display: flex;
            flex-direction: column;
            margin-bottom: 48px;
            &_el {
              display: flex;
              align-items: center;
              width: 95%;
              height: 70px;
              margin-bottom: 40px;
              border: 1px solid #acdff5;
              padding: 15px 80px 15px 15px;
              border-radius: 15px;
              color: #039cdd;
              font-size: 14px;
              font-weight: bold; } }
          &_item {
            position: absolute;
            right: 0;
            transform: translate(0, 0);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 90px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #039cdd;
            background-color: #ffffff;
            border: 1px solid #b2e6fc;
            border-radius: 50%;
            text-transform: uppercase;
            text-align: center;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid transparent;
              img {
                width: 90px; }
              &.correct {
                width: 100px;
                height: 100px;
                border-top-color: #4085D8;
                border-right-color: #4085D8;
                border-bottom-color: #4085D8;
                border-left-color: #4085D8;
                transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s, border-left-color 0.15s linear 0.30s;
                color: #4085D8; }
              &.inCorrect {
                width: 100px;
                height: 100px;
                border-top-color: #BD4E3B;
                border-right-color: #BD4E3B;
                border-bottom-color: #BD4E3B;
                border-left-color: #BD4E3B;
                transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s, border-left-color 0.15s linear 0.30s;
                color: #BD4E3B; } }
            &:not(:last-child) {
              margin-bottom: 24px; } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          overflow-y: auto;
          padding-bottom: 92px;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 30px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #2c3032;
              background-color: #ffffff;
              border: 1px solid #039cdd;
              border-radius: 50%; }
            .questions {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              &_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                border-radius: 15px;
                margin: 0 15px;
                .question_icon {
                  margin-bottom: 8px;
                  svg {
                    fill: #4C8EC5; } }
                &:nth-child(1) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 30px;
                      height: 36px; } } }
                &:nth-child(2) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 36px;
                      height: 36px; } } }
                &:nth-child(3) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 48px;
                      height: 29px; } } }
                &:nth-child(4) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 32px;
                      height: 30px; } } }
                &:nth-child(5) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 25px;
                      height: 36px; } } }
                &:nth-child(6) {
                  .question_icon {
                    margin-bottom: 8px;
                    svg {
                      width: 31px;
                      height: 32px; } } }
                &.selected {
                  color: #99BFDE;
                  border: 1px solid #CCDFEF;
                  box-shadow: none; }
                &:not(:last-child) {
                  margin-bottom: 20px; } } } }
          &_text {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #e3fffb;
          margin-bottom: 48px;
          color: #2c3032;
          @include for-phone {
            padding: 20px; }
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: #3fc4b1; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #ffe3e3;
            .info_head {
              color: #f12626; }
            .infoText {
              color: #2c3032; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -25px;
          right: 0;
          transform: translate(0, 100%); } } } }
  .step18 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .test_wrp {
          display: flex;
          flex-direction: column;
          margin-bottom: 38px;
          .flex {
            display: flex;
            flex-grow: 1;
            .answers {
              position: relative;
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: 0;
              margin-bottom: 0;
              .product {
                display: flex;
                flex-direction: column;
                align-items: center;
                //width: 18%
                &:not(:last-child) {
                  margin-right: 15px; }
                &:nth-child(2) {
                  width: 160px;
                  flex-shrink: 0; }
                .head {
                  height: 50px;
                  font-size: 13px;
                  font-weight: bold;
                  color: #039cdd;
                  text-align: center; }
                &_box {
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap; } }
              &_item {
                position: relative;
                //&:not(:last-child)
 }                //  margin-bottom: 12px
              .answer {
                position: relative;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 80px;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #039cdd;
                text-align: center;
                text-transform: uppercase;
                background-color: #ffffff;
                border: 1px solid #b2e6fc;
                border-radius: 50%;
                box-shadow: none;
                padding: 0;
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center; }

                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8;
                  .icon svg {
                    fill: #4085D8; } }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B;
                  .icon svg {
                    fill: #BD4E3B; } } } } }
          .questions {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 24px;
            border-bottom: 1px solid #039cdd;
            .question_el {
              display: flex;
              justify-content: center;
              width: 20%;
              height: 100px;
              &:not(:last-child) {
                margin-bottom: 12px; }
              //&.hidden
              //  cursor: default
              //  .ddcontainersource
              //    font-size: 0
              //    background-color: #E6EFF7
              //    box-shadow: none
              //    border: none
              //    img
              //      opacity: 0
              //.ddcontainer
 }              //  margin: 0 auto
            .ddcontainersource {
              margin: 0 auto;
              font-size: 14px;
              line-height: 22px;
              color: #039cdd;
              font-weight: bold;
              text-transform: uppercase;
              cursor: pointer;
              .icon {
                width: 100%;
                img {
                  width: 100%; } } }
            .ddcontainerghost {
              font-size: 14px;
              line-height: 22px;
              color: #039cdd;
              font-weight: bold;
              text-align: center;
              text-transform: uppercase;
              @include for-tablet {
                width: 100px;
                height: 100px; }
              .icon {
                width: 100%;
                img {
                  width: 100%; } } } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        //.btn
        //  position: absolute
        //  z-index: 2
        //  bottom: -48px
        //  right: 0
 }        //  transform: translate(0, 100%)
      //Стили мобильной версии
      .test_mobile {
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 0;
          .flex {
            display: flex;
            flex-direction: column;
            margin-bottom: 48px;
            &_el {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 40px;
              border: 1px solid #acdff5;
              padding: 15px;
              border-radius: 15px;
              color: #039cdd;
              font-size: 14px;
              font-weight: bold;
              &_head {
                margin-right: 15px; }
              &_box {
                display: flex;
                justify-content: flex-end;
                align-items: center; } } }
          &_item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #039cdd;
            background-color: #ffffff;
            border: 1px solid #b2e6fc;
            border-radius: 50%;
            text-transform: uppercase;
            text-align: center;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid transparent;
              img {
                width: 60px; }
              &.correct {
                width: 62px;
                height: 62px;
                border-top-color: #4085D8;
                border-right-color: #4085D8;
                border-bottom-color: #4085D8;
                border-left-color: #4085D8;
                transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s, border-left-color 0.15s linear 0.30s;
                color: #4085D8; }
              &.inCorrect {
                width: 62px;
                height: 62px;
                border-top-color: #BD4E3B;
                border-right-color: #BD4E3B;
                border-bottom-color: #BD4E3B;
                border-left-color: #BD4E3B;
                transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s, border-left-color 0.15s linear 0.30s;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          overflow-y: auto;
          padding-bottom: 92px;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 30px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #2c3032;
              background-color: #ffffff;
              border: 1px solid #039cdd;
              border-radius: 50%; }
            .questions {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              &_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                border-radius: 15px;
                margin: 0 15px 20px;
                &.selected {
                  opacity: 0.3; } } } }
          &_text {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #e3fffb;
          margin-bottom: 48px;
          color: #2c3032;
          @include for-phone {
            padding: 20px; }
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: #3fc4b1; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #ffe3e3;
            .info_head {
              color: #f12626; }
            .infoText {
              color: #2c3032; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -25px;
          right: 0;
          transform: translate(0, 100%); } } } }
  .step19 {
    position: relative;
    padding-bottom: 5px;
    .test {
      margin-top: 48px;
      // Стили десктопной версии
      .test_desktop {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .test_wrp {
          display: flex;
          flex-direction: column;
          margin-bottom: 38px;
          .flex {
            display: flex;
            flex-grow: 1;
            .answers {
              position: relative;
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: 0;
              margin-bottom: 0;
              .product {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 48%;
                &:not(:last-child) {
                  margin-right: 15px; }
                .head {
                  font-size: 18px;
                  color: #039cdd;
                  font-weight: bold;
                  text-transform: uppercase;
                  margin: 0 0 20px; }
                &_box {
                  display: flex;
                  justify-content: center;
                  flex-wrap: wrap;
                  flex-grow: 1;
                  width: 100%;
                  border: 1px solid #acdff5;
                  border-radius: 15px;
                  position: relative;
                  padding: 20px 30px 10px; } }
              &_box {
                display: flex;
                justify-content: center;
                width: 33%;
                margin-bottom: 10px; }
              &_item {
                position: relative; }
              //&:not(:last-child)
              //  margin-bottom: 12px
              .answer {
                position: relative;
                align-items: center;
                justify-content: center;
                width: 80px;
                height: 80px;
                font-weight: bold;
                font-size: 16px;
                line-height: 22px;
                color: #039cdd;
                text-align: center;
                text-transform: uppercase;
                background-color: #ffffff;
                border: 1px solid #b2e6fc;
                border-radius: 50%;
                box-shadow: none;
                padding: 0;
                .icon {
                  display: flex;
                  justify-content: center;
                  align-items: center; }

                &.correct {
                  font-weight: bold;
                  color: #4085D8;
                  background-color: #ffffff;
                  border: 2px solid #4085D8;
                  .icon svg {
                    fill: #4085D8; } }
                &.inCorrect {
                  font-weight: bold;
                  color: #BD4E3B;
                  background-color: #ffffff;
                  border: 2px solid #BD4E3B;
                  .icon svg {
                    fill: #BD4E3B; } } } } }
          .questions {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 70%;
            margin: 0 auto 24px;
            .question_el {
              display: flex;
              justify-content: center;
              width: 20%;
              height: 100px;
              &:not(:last-child) {
                margin-bottom: 12px; } }
            //&.hidden
            //  cursor: default
            //  .ddcontainersource
            //    font-size: 0
            //    background-color: #E6EFF7
            //    box-shadow: none
            //    border: none
            //    img
            //      opacity: 0
            //.ddcontainer
            //  margin: 0 auto
            .ddcontainersource {
              margin: 0 auto;
              font-size: 14px;
              line-height: 22px;
              color: #039cdd;
              font-weight: bold;
              text-transform: uppercase;
              cursor: pointer;
              .icon {
                width: 100%;
                img {
                  width: 100%; } } }
            .ddcontainerghost {
              font-size: 14px;
              line-height: 22px;
              color: #039cdd;
              font-weight: bold;
              text-align: center;
              text-transform: uppercase;
              @include for-tablet {
                width: 100px;
                height: 100px; }
              .icon {
                width: 100%;
                img {
                  width: 100%; } } } } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #4085D8;
          color: #fff;
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #F9EEEC;
            width: 100%;
            .info_head {
              color: #BD4E3B; }
            .infoText {
              color: #3E373C; } } }
        //.btn
        //  position: absolute
        //  z-index: 2
        //  bottom: -48px
        //  right: 0
 }        //  transform: translate(0, 100%)
      //Стили мобильной версии
      .test_mobile {
        .answers {
          position: relative;
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 0;
          .flex {
            display: flex;
            flex-direction: column;
            margin-bottom: 48px;
            &_el {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              margin-bottom: 40px;
              color: #039cdd;
              font-size: 14px;
              font-weight: bold;
              &_head {
                margin-right: 15px;
                text-transform: uppercase; }
              &_box {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 270px;
                border: 1px solid #acdff5;
                border-radius: 15px;
                padding: 20px 10px 10px 20px; } } }
          &_item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;
            margin-right: 10px;
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #039cdd;
            background-color: #ffffff;
            border: 1px solid #b2e6fc;
            border-radius: 50%;
            text-transform: uppercase;
            text-align: center;
            &.selected {
              color: #3E373C;
              background-color: #ffffff;
              border: 2px solid transparent;
              img {
                width: 60px; }
              &.correct {
                width: 62px;
                height: 62px;
                border-top-color: #4085D8;
                border-right-color: #4085D8;
                border-bottom-color: #4085D8;
                border-left-color: #4085D8;
                transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s, border-left-color 0.15s linear 0.30s;
                color: #4085D8; }
              &.inCorrect {
                width: 62px;
                height: 62px;
                border-top-color: #BD4E3B;
                border-right-color: #BD4E3B;
                border-bottom-color: #BD4E3B;
                border-left-color: #BD4E3B;
                transition: border-top-color 0.15s linear, border-right-color 0.15s linear 0.10s, border-bottom-color 0.15s linear 0.20s, border-left-color 0.15s linear 0.30s;
                color: #BD4E3B; } } } }
        .popup {
          position: fixed;
          z-index: 555;
          top: 92px;
          left: 0;
          background: rgba(#345178, .8);
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          overflow-y: auto;
          padding-bottom: 92px;
          &_wrp {
            width: 100%;
            padding: 40px 20px;
            background: #fff;
            position: relative;
            overflow-y: auto; }
          &_closed {
            position: absolute;
            top: 20px;
            right: 20px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #99BFDE;
            cursor: pointer;
            transition: .5s;
            @media screen and (max-width: 767px) {
              position: fixed;
              top: 0;
              right: 0;
              height: 92px;
              width: 92px;
              background-color: #00559C; }
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              height: 2px;
              background: #99BFDE;
              transform-origin: center;
              top: 50%;
              left: 50%;
              transition: .5s;
              @media screen and (max-width: 767px) {
                background: #ffffff;
                width: 28px; } }
            &:before {
              transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
              transform: translate(-50%, -50%) rotate(-45deg); }
            &:hover {
              &:before,
              &:after {
                background: #005EAD; }
              &:before {
                transform: translate(-50%, -50%) rotate(-45deg); }
              &:after {
                transform: translate(-50%, -50%) rotate(45deg); } } }
          &_flex {
            display: flex;
            flex-direction: column;
            .point {
              align-self: center;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 48px;
              height: 48px;
              margin-bottom: 30px;
              font-size: 25px;
              line-height: 30px;
              font-weight: bold;
              color: #2c3032;
              background-color: #ffffff;
              border: 1px solid #039cdd;
              border-radius: 50%; }
            .questions {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              &_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 18px;
                text-transform: uppercase;
                border-radius: 15px;
                margin: 0 15px 20px;
                &.selected {
                  opacity: 0.3; } } } }
          &_text {
            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            color: #039cdd;
            text-align: center;
            text-transform: uppercase;
            margin: 0 0 30px; } }
        .info {
          padding: 44px 48px;
          border-radius: 10px;
          background: #e3fffb;
          margin-bottom: 48px;
          color: #2c3032;
          @include for-phone {
            padding: 20px; }
          &_head {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 5px;
            color: #3fc4b1; }
          &Text {
            font-size: 18px; }
          &.error {
            background: #ffe3e3;
            .info_head {
              color: #f12626; }
            .infoText {
              color: #2c3032; } } }
        .btn {
          position: absolute;
          z-index: 2;
          bottom: -25px;
          right: 0;
          transform: translate(0, 100%); } } } } }

