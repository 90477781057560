.PlanInFirstStep {
  margin-top: 25px;
  &_el {
    margin-bottom: 20px;
    padding: 15px 20px;
    border: 1px solid #005EAD;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0 2px 3px 0 rgba(74, 74, 74, 0.2);
    &.blue {
      border: 1px solid #acdff5; }
    &.yellow {
      border: 1px solid #fde69a; }
    &.purple {
      border: 1px solid #a7268e; }
    &.light-blue {
      border: 1px solid #acdff5; }
    &.brown {
      border: 1px solid #d5c3a3; }
    &:last-child {
      margin-bottom: 0; } } }
