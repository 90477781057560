@import "../../../Page/mixin";
.answers {
  margin-top: 25px;
  margin-bottom: 45px;
  @include for-phone {
    margin-top: 0; }
  .answer {
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 2px solid #CCDFEF;
    box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
    color: #3E373C;
    cursor: pointer;
    transition: .5s;
    @include for-phone {
      font-size: 16px;
      line-height: 18px; }
    &:last-child {
      margin-bottom: 0; }
    .circle {
      height: 30px;
      width: 30px;
      border: 2px solid #CCDFEF;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 15px;
      transition: .5s;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        transition: .5s;
        opacity: 0;
        display: none; } }
    &:hover,
    &.active {
      border: 2px solid #4085D8;
      box-sizing: border-box;
      box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
      color: #4085D8;
      .circle {
        border-color: #4085D8; } }
    &.active {
      .circle {
        background: #4085D8;
        //svg
 }        //  opacity: 1

      &.correct {
        border-color: #4085D8;
        .circle {
          background: #4085D8;
          border-color: #4085D8;
          svg.correct {
            opacity: 1;
            display: block; } } }
      &.inCorrect {
        border-color: #BD4E3B;
        .circle {
          background: #BD4E3B;
          border-color: #BD4E3B;
          svg.inCorrect {
            opacity: 1;
            display: block; } } } }

    &.correct {
      border-color: #BD4E3B;
      .circle {
        background: #4085D8;
        border-color: #4085D8;
        svg.correct {
          opacity: 1;
          display: block; } } }
    &-text {
      margin: 0; } } }


