.TextBlock {
  font-size: 18px;
  line-height: 28px;
  color: #2c3032;
  white-space: pre-line;
  margin-bottom: 30px;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px; }
  .text_blue {
    color: #039cdd;
    font-weight: bold; }
  .text_dark-blue {
    color: #1c3e94;
    font-weight: bold; }
  .text_brown {
    color: #BD4E3B; }
  .text_green {
    color: #009a68; }
  .text_yellow {
    color: #f1ba12; }
  .text_whiskas {
    color: #a63485;
    font-weight: bold; }
  .text_light-blue {
    color: #00b1d8;
    font-weight: bold; }
  .text_sheba {
    color: #ac8748;
    font-weight: bold; }
  .text_subheader {
    font-size: 20px;
    line-height: 24px; }
  .text_error {
    color: #c33;
    font-weight: bold; }
  .text_correct {
    color: #3fc4b1;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px; } } }
