@import "../../../Page/mixin";
.step.lesson10 {
  padding-bottom: 25px;
  width: 100%;
  color: #2c3032;
  max-width: 864px;
  margin: 0 auto;

  .slick-prev {
    position: absolute;
    z-index: 3;
    bottom: -50px;
    top: auto;
    left: 55px;
    transform: translate(0, 50%);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 2px solid #1F72B7;
    background: #fff;
    cursor: pointer;
    transition: .5s;
    &.slick-disabled {
      cursor: default;
      opacity: 0; }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% - 1px), -50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 8px solid #1F72B7;
      border-bottom: 5px solid transparent;
      transition: .5s; }
    &:hover {
      background: #1F72B7;
      &:before {
        border-right: 8px solid #fff; } } }
  .slick-next {
      position: absolute;
      bottom: -50px;
      top: auto;
      right: 55px;
      transform: translate(0, 50%);
      height: 46px;
      width: 46px;
      border-radius: 50%;
      border: 2px solid #1F72B7;
      background: #1F72B7;
      cursor: pointer;
      transition: .5s;
      &.slick-disabled {
        cursor: default;
        opacity: 0; }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(calc(-50% + 1px), -50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-left: 8px solid #fff;
        border-bottom: 5px solid transparent;
        transition: .5s; }
      &:hover {
        background: #fff;
        &:before {
          border-left: 8px solid #1F72B7; } } }
  .slick-dots {
    li {
      width: 8px;
      height: 8px;
      margin: 0 10px;
      @media screen and (max-width: 767px) {
        width: 6px;
        height: 6px;
        margin: 0 5px; }
      button {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #99BFDE;
        padding: 0;
        @media screen and (max-width: 767px) {
          width: 6px;
          height: 6px; }
        &:before {
          content: ""; } } }
    .slick-active {
      width: 40px;
      height: 8px;
      border-radius: 6px;
      @media screen and (max-width: 767px) {
        width: 31px;
        border-radius: 5px; }
      button {
        width: 40px;
        height: 8px;
        background-color: #039cdd;
        border-radius: 6px;
        @media screen and (max-width: 767px) {
          width: 31px;
          border-radius: 5px; } } } }
  .flex {
    display: flex; }
  .list {
    list-style: none;
    padding: 0;
    .list_item {
      position: relative;
      padding-left: 25px;
      margin-bottom: 20px;
      font-size: 18px;
      @include for-phone {
        font-size: 16px; }
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #039cdd; } } }
  .imgCenter {
    max-width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%); }
  .step1 {
    .head {
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
      color: #333;
      margin-bottom: 20px; } }
  .step2, .step16 {
    .imgDiv {
      position: relative;
      padding-top: 40px;
      @include for-phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 60px; }
      .point {
        position: absolute;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        background: #ffffff;
        border: 2px solid #039cdd;
        box-shadow: 0px 5px 10px rgba(0, 94, 173, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #039cdd;
        font-weight: bold;
        transition: .5s;
        cursor: pointer;
        z-index: 10;
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        @include for-phone {
          position: relative;
          margin-bottom: 66px; }
        //&:after
        //  content: ''
        //  position: absolute
        //  top: 50%
        //  left: 50%
        //  transform: translate(-50%, -50%)
        //  width: 68px
        //  height: 68px
        //  background-color: transparent
        //  border: 9px solid #ffffff
        //  border-radius: 50%
        //  box-sizing: border-box
        &.active {
          color: #ffffff;
          background: #039cdd;
          border: 2px solid #039cdd; }
        &:hover {
          color: #ffffff;
          background: #039cdd;
          border: 2px solid #039cdd; }
        &:nth-child(1) {
          left: 100px;
          top: 60px;
          @media screen and (max-width: 850px) {
            left: 85px; }
          @include for-phone {
            left: auto;
            top: auto; } }
        &:nth-child(2) {
          right: 100px;
          top: 60px;
          @media screen and (max-width: 850px) {
            right: 85px; }
          @include for-phone {
            right: auto;
            top: auto; } }
        &:nth-child(3) {
          left: 100px;
          bottom: 20px;
          @media screen and (max-width: 850px) {
            left: 85px; }
          @include for-phone {
            left: auto;
            bottom: auto; } }
        &:nth-child(4) {
          right: 100px;
          bottom: 20px;
          @media screen and (max-width: 850px) {
            right: 85px; }
          @include for-phone {
            right: auto;
            bottom: auto; } }
        &.point_out {
          animation: scale-down-center 0.4s ease both; } }
      &_answers {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        @include for-phone {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 35px; }
        .answer {
          position: absolute;
          display: none;
          justify-content: center;
          align-items: center;
          width: 286px;
          height: 150px;
          padding: 0 24px;
          border: 1px solid #039cdd;
          box-sizing: border-box;
          border-radius: 10px;
          @media screen and (max-width: 850px) {
            width: 250px; }
          @include for-phone {
            position: relative;
            margin-bottom: 18px; }
          &:nth-child(1) {
            top: 10%;
            left: 0;
            @include for-phone {
              top: auto;
              left: auto; } }
          &:nth-child(2) {
            top: 10%;
            right: 0;
            @include for-phone {
              top: auto;
              right: auto; } }
          &:nth-child(3) {
            bottom: 0;
            left: 0;
            @include for-phone {
              bottom: auto;
              left: auto; } }
          &:nth-child(4) {
            bottom: 0;
            right: 0;
            @include for-phone {
              bottom: auto;
              right: auto; } }
          &_text {
            margin: 0;
            font-size: 16px;
            line-height: 22px;
            color: #3E373C; }
          &.answer_in {
            display: flex;
            transform: scale(0);
            animation: scale-up-center 0.4s ease 0.3s both; } } }
      .imgCenter {
        @include for-phone {
          left: auto;
          transform: translate(0, 0); } } }
    @keyframes scale-down-center {
      0% {
        transform: scale(1); }

      100% {
        transform: scale(0); } }
    @keyframes scale-up-center {
      0% {
        transform: scale(0); }

      100% {
        transform: scale(1); } } }
  .step3 {
    .InfoBlock {
      @include for-tablet {
        display: none; } }
    .flex {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_el {
        width: 50%;
        margin-right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #FFFFFF;
        border: 2px solid #CCDFEF;
        box-sizing: border-box;
        box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
        height: 67px;
        transition: .5s;
        font-size: 16px;
        color: #99BFDE;
        //cursor: pointer
        &:last-child {
          margin-right: 0; }
        &.active {
          background: #146BB3;
          color: #fff;
          font-weight: bold;
          box-shadow: none;
          border: none; } } }


    .slider {
      margin-top: 25px;
      padding: 50px;
      border: 2px solid #039cdd;
      border-radius: 10px;
      display: none;
      position: relative;
      transition: .5s;
      margin-bottom: 20px;
      @include for-tablet {
        padding: 40px 0 47px;
        border: none;
        border-radius: 0; }
      &.show {
        display: block; }
      &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: calc(25% - 12px);
        transform: translate(-50%) rotate(45deg);
        height: 15px;
        width: 15px;
        border-left: 2px solid #1F72B7;
        border-top: 2px solid #1F72B7;
        background: #fff;
        transition: 1s;
        @include for-tablet {
          display: none; } }
      &_right {
        &:before {
          left: calc(75% + 12px); } }
      &_noArrow {
        &:before {
          display: none; } }
      &_el {
        font-size: 18px;
        line-height: 26px;
        color: #3E373C;
        position: relative;
        transition: .5s;
        .head {
          color: #039cdd;
          font-size: 20px;
          font-weight: bold;
          margin-bottom: 30px;
          text-transform: uppercase; }
        img {
          margin-left: 35px;
          margin-bottom: 30px;
          @media screen and (max-width: 767px) {
            display: none; } }
        .TextBlock {
          font-size: 17px;
          line-height: 22px; }
        .imgMobile {
          display: none;
          @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center; }
          img {
            @media screen and (max-width: 767px) {
              display: block; } } } }
      &_flex {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        transition: .5s; }
      &_popup {
        position: absolute;
        top: 100px;
        right: 50px;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #1f72b7;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #005ead;
        font-weight: 700;
        transition: .5s;
        cursor: pointer;
        @include for-phone {
          top: 15px;
          right: calc(40% - 20px); } }
      .slick-prev {
        position: absolute;
        bottom: -50px;
        top: auto;
        left: 55px;
        transform: translate(0, 50%);
        height: 36px;
        width: 36px;
        border-radius: 50%;
        border: 2px solid #039cdd;
        background: #fff;
        cursor: pointer;
        transition: .5s;
        &.slick-disabled {
          cursor: default;
          opacity: 0; }
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% - 1px), -50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 8px solid #039cdd;
          border-bottom: 5px solid transparent;
          transition: .5s; }
        &:hover {
          background: #039cdd;
          &:before {
            border-right: 8px solid #fff; } } }
      .slick-next {
        position: absolute;
        bottom: -50px;
        top: auto;
        right: 55px;
        transform: translate(0, 50%);
        height: 46px;
        width: 46px;
        border-radius: 50%;
        border: 2px solid #039cdd;
        background: #039cdd;
        cursor: pointer;
        transition: .5s;
        &.slick-disabled {
          cursor: default;
          opacity: 0; }
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(calc(-50% + 1px), -50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-left: 8px solid #fff;
          border-bottom: 5px solid transparent;
          transition: .5s; }
        &:hover {
          background: #fff;
          &:before {
            border-left: 8px solid #039cdd; } } } } }
  .step4 {
    .flex {
      display: flex;
      align-items: center;
      @include for-phone {
        flex-direction: column; }
      &_img {
        flex-shrink: 0; } } }
  .step7, .step8, .step11, .step14 {
    .flex {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:last-child {
          flex-shrink: 0;
          margin-left: 30px;
          @include for-phone {
            margin-left: 0; } } } } }
  .step12 {
    .flex {
      display: flex;
      @include for-phone {
        flex-direction: column;
        align-items: center; }
      &_el {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include for-phone {
          flex-direction: column; }
        &.text {
          position: relative;
          width: 24%;
          height: 152px;
          padding: 45px 25px;
          font-size: 14px;
          line-height: 18px;
          border: 1px solid #acdff5;
          border-radius: 10px;
          @include for-phone {
            width: 100%;
            height: auto; } }
        &.plus {
          width: 14%;
          font-size: 55px;
          line-height: 55px;
          color: #039cdd; }
        &_img {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 90%);
          @include for-phone {
            position: relative;
            bottom: auto;
            left: auto;
            transform: translate(0, 0); } }
        &_text {
          font-size: 14px;
          line-height: 20px;
          @include for-phone {
            margin-bottom: 20px; }
          &_blue {
            font-weight: bold;
            color: #039cdd; } } } } }
  .step13 {
    .flex {
      display: flex;
      margin: 0 -5px;
      @include for-tablet {
        flex-wrap: wrap;
        justify-content: center; }
      &_el {
        display: flex;
        width: 33.25%;
        min-height: 320px;
        font-size: 15px;
        line-height: 18px;
        padding: 0 5px;
        @include for-tablet {
          width: 260px; }
        &:not(:last-child) {
          @include for-tablet {
            margin-bottom: 60px; } }
        &:last-child {
          @include for-tablet {
            width: 100%;
            justify-content: center; } } }
      &_wrp {
        padding: 26px 13px 13px;
        position: relative;
        text-align: center;
        font-size: 15px;
        line-height: 18px;
        border: 2px solid #c33;
        border-radius: 10px;
        @include for-tablet {
          width: 260px; }
        &--green {
          border: 2px solid #3fc4b1; } }
      &_red {
        color: #c33;
        font-weight: bold; }
      &_green {
        color: #3fc4b1;
        font-weight: bold; }
      &_status {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
        height: 30px;
        border-radius: 50%; }
      .img {
        position: absolute;
        bottom: -32px;
        left: 50%;
        transform: translateX(-50%);
        &.img_2 {
          bottom: -29px; }
        &.img_3 {
          bottom: -43px; } } } }
  .step15 {
    .OnClickBlock {
      margin-bottom: 40px;
      .imgDiv_wrap {
        &_flex {
          display: flex;
          @include for-phone {
            flex-direction: column;
            align-items: center; }
          .TextBlock {
            margin-right: 20px; } } } }
    .imgCenter.hidden {
      display: none; } }
  .step17 {
    .flex {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      @include for-phone {
        flex-direction: column; }
      &_el {
        &:last-child {
          flex-shrink: 0;
          margin-left: 30px;
          @include for-phone {
            margin-left: 0; } } } }
    .info {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      @include for-phone {
        flex-direction: column;
        margin: 0; }
      &_el {
        width: 33.25%;
        margin-bottom: 30px;
        padding: 0 15px;
        @include for-phone {
          width: 100%;
          padding: 0; }
        &_wrp {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 45px 25px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          border: 1px solid #acdff5;
          border-radius: 10px;
          &_blue {
            font-weight: bold;
            color: #039cdd; } } } } } }










