.test_dnd {
  width: 768px;
  .questions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .questions_el {
      width: 120px;
      height: 72px;
      margin-right: 42px;
      margin-bottom: 44px;
      &:nth-child(5n) {
        margin-right: 0; }
      &.hidden {
        cursor: default;
        .ddcontainersource {
          font-size: 0;
          background-color: #CCDFEF;
          box-shadow: none; } } }
    .ddcontainersource {
      width: 120px;
      height: 72px;
      padding: 15px 10px;
      display: flex!important;
      justify-content: center;
      align-items: center;
      border: 2px solid #CCDFEF;
      box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
      border-radius: 10px;
      margin-bottom: 44px;
      font-size: 16px;
      line-height: 15px;
      color: #3E373C;
      text-align: center;
      cursor: pointer; }
    .ddcontainerghost {
      width: 120px;
      height: 72px;
      padding: 15px 10px;
      justify-content: center;
      align-items: center;
      border: 2px solid #CCDFEF;
      box-shadow: 0px 5px 10px rgba(42, 46, 126, 0.1);
      border-radius: 10px;
      margin-bottom: 44px;
      font-size: 16px;
      line-height: 15px;
      color: #3E373C;
      text-align: center; } }
  .answers {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .answer {
      position: relative;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 72px;
      font-size: 16px;
      line-height: 15px;
      color: #3E373C;
      text-align: center;
      background-color: #E6EFF7;
      border: none;
      box-shadow: none;
      padding: 10px;
      &.correct {
        font-weight: bold;
        color: #4085D8;
        background-color: #ffffff;
        border: 2px solid #4085D8;
        &:before {
          position: absolute;
          content: "";
          width: 24px;
          height: 24px;
          bottom: 0;
          left: 50%;
          background-image: url("../../../image/tests/correct.png");
          background-repeat: no-repeat;
          transform: translate(-50%, 50%); } }
      &.inCorrect {
        font-weight: bold;
        color: #BD4E3B;
        background-color: #ffffff;
        border: 2px solid #BD4E3B;
        &:before {
          position: absolute;
          content: "";
          width: 24px;
          height: 24px;
          bottom: 0;
          left: 50%;
          background-image: url("../../../image/tests/in-correct.png");
          background-repeat: no-repeat;
          transform: translate(-50%, 50%); } } }
    .droptarget {
      margin-right: 24px;
      margin-bottom: 36px;
      z-index: 10;
      &:nth-child(5) {
        margin-right: 72px; }
      &:nth-child(6) {
        order: 5; }
      &:nth-child(7) {
        order: 4; }
      &:nth-child(8) {
        order: 3; }
      &:nth-child(9) {
        order: 2; }
      &:nth-child(10) {
        order: 1;
        margin-left: 25px; } }
    .answers_img {
      position: absolute;
      top: 30px;
      right: 0; }
    .arrow_track {
      position: absolute;
      right: 0;
      top: 0;
      width: 18px;
      height: 21px;
      &--1 {
        right: 42px;
        top: 7px; }
      &--2 {
        right: 0;
        top: 40px;
        transform: rotate(63deg); }
      &--3 {
        right: 3px;
        top: 91px;
        transform: rotate(115deg); }
      &--4 {
        left: 0;
        top: 119px;
        transform: rotate(180deg); } } } }



